import { Grid, IconButton, Box } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import FWAutocomplete from '../../../../Common/FWAutocomplete';
import FWIcon from '../../../../Common/FWIcon';
import SpecValueItem from './SpecValueItem';
import {
    IAttribute,
    IAttributeValue,
} from '../../../../Specifications/Models/SpecificationInterfaces';
import { FWInput } from '../../../../Common/FWInput';
import {
    ICustomAttributeNew,
    ICustomAttributePayloadNew,
    ICustomAttributeValue,
} from '../../../../Models/Attributes.model';
import { useGetMeasurementUnitListQuery } from '../../../../UOM&Currency/Services/UOM.service';
import { useGetAllCurrencyOptionsQuery } from '../../../../AdditionalCost/Services/additionalCosts.services';
import { FWCombinedInput } from '../../../../Common/FWCombinedInput';
import { FWMenuItem } from '../../../../Common/FWCustomMenu';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';

interface SpecificationItemProps {
    spec: IAttribute;
    index: number;
    myOptionsForSpecs: IAttribute[];
    onSelectSpec: (newVal: IAttribute, index: number) => void;
    setTransformedData: Dispatch<SetStateAction<ICustomAttributePayloadNew[]>>;
    autoFilldata: ICustomAttributeNew[];
}

const SpecificationItem = ({
    spec,
    index,
    myOptionsForSpecs,
    onSelectSpec,
    setTransformedData,
    autoFilldata,
}: SpecificationItemProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    const myOptionsForSpecValues = useMemo(() => {
        const specValues = spec.attribute_values.filter(
            (val) => val.attribute_value_id !== null
        );
        return {
            ...spec,
            attribute_values: specValues,
        };
    }, [spec]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [options, setOptions] = useState<IAttributeValue[]>([]);
    const [customVal, setCustomVal] = useState<ICustomAttributeValue[]>([]);

    const { data: measurementUnitList } = useGetMeasurementUnitListQuery({});
    const { data: allCurrencies } = useGetAllCurrencyOptionsQuery({});

    const firstMeasurementUnitId =
        measurementUnitList && measurementUnitList.length > 0
            ? measurementUnitList[0].measurement_unit.measurement_unit_id
            : '';
    const firstCurrencyId =
        allCurrencies && allCurrencies.length > 0
            ? allCurrencies[0].entry_id
            : '';

    const handleAddORSpecification = () => {
        if (
            spec.attribute_type === 'TEXT' ||
            spec.attribute_type === 'NUMBER'
        ) {
            setCustomVal((prev) => [
                ...prev,
                {
                    attribute_value_linkage_id: null,
                    attribute_value_id: null,
                    value: '',
                    measurement_unit_id: null,
                    currency_id: null,
                },
            ]);

            handlePayloadUpdate([
                ...customVal,
                ...[
                    {
                        attribute_value_linkage_id: null,
                        attribute_value_id: null,
                        value: '',
                        measurement_unit_id: null,
                        currency_id: null,
                    },
                ],
            ]);

            // setTransformedData((prev) => {
            //     const updatedSpecs = [...prev];
            //     updatedSpecs[index].attribute_values = [
            //         ...updatedSpecs[index].attribute_values,
            //         {
            //             attribute_value_id: '',
            //             created_datetime: '',
            //             modified_datetime: '',
            //             deleted_datetime: null,
            //             value: '',
            //             created_by_user: null,
            //             modified_by_user: null,
            //             deleted_by_user: null,
            //             attribute: '',
            //             attribute_linkage: null,
            //             measurement_unit: null,
            //             currency: null,
            //         },
            //     ];
            //     return updatedSpecs;
            // });
        } else if (spec.attribute_type === 'CURRENCY') {
            setCustomVal((prev) => [
                ...prev,

                {
                    attribute_value_linkage_id: null,
                    attribute_value_id: null,
                    value: '',
                    measurement_unit_id: null,
                    currency_id: firstCurrencyId,
                },
            ]);
            // setTransformedData((prev) => {
            //     const updatedSpecs = [...prev];
            //     updatedSpecs[index].attribute_values = [
            //         ...updatedSpecs[index].attribute_values,
            //         {
            //             attribute_value_id: '',
            //             created_datetime: '',
            //             modified_datetime: '',
            //             deleted_datetime: null,
            //             value: '',
            //             created_by_user: null,
            //             modified_by_user: null,
            //             deleted_by_user: null,
            //             attribute: '',
            //             attribute_linkage: null,
            //             measurement_unit: null,
            //             currency: firstCurrencyId,
            //         },
            //     ];
            //     return updatedSpecs;
            // });
        } else {
            setCustomVal((prev) => [
                ...prev,

                {
                    attribute_value_linkage_id: null,
                    attribute_value_id: null,
                    value: '',
                    measurement_unit_id: firstMeasurementUnitId,
                    currency_id: null,
                },
            ]);

            // setTransformedData((prev) => {
            //     const updatedSpecs = [...prev];
            //     updatedSpecs[index].attribute_values = [
            //         ...updatedSpecs[index].attribute_values,
            //         {
            //             attribute_value_id: '',
            //             created_datetime: '',
            //             modified_datetime: '',
            //             deleted_datetime: null,
            //             value: '',
            //             created_by_user: null,
            //             modified_by_user: null,
            //             deleted_by_user: null,
            //             attribute: '',
            //             attribute_linkage: null,
            //             measurement_unit: null,
            //             currency: firstMeasurementUnitId,
            //         },
            //     ];
            //     return updatedSpecs;
            // });
        }
    };

    const handleRemoveSpecification = (indexToRemove: number) => {
        const updatedValues = customVal.filter((_, i) => i !== indexToRemove);
        setCustomVal(updatedValues);
        handlePayloadUpdate(updatedValues);
    };

    useEffect(() => {
        if (spec && spec.attribute_values) {
            const transformedValues = spec.attribute_values
                .map((val) => ({
                    attribute_value_linkage_id: val.attribute_value_linkage_id,
                    attribute_value_id: val.attribute_value_id,
                    value: val.value,
                    measurement_unit_id: val.measurement_unit
                        ? val.measurement_unit
                        : null,
                    currency_id: val.currency ? val.currency : null,
                }))
                .filter(
                    (attributeVal) =>
                        attributeVal.attribute_value_id === null ||
                        attributeVal.attribute_value_id === ''
                );
            setCustomVal(transformedValues);
        }
    }, [spec]);

    // useEffect(() => {
    //     setCustomVal([]);
    // }, [spec]);

    const handlePayloadUpdate = (customVal: ICustomAttributeValue[]) => {
        setTransformedData((prev) => {
            const updatedSpecs = prev.map((spec, i) => {
                // If the current index matches the index you want to update
                if (i === index) {
                    // Create a new object with updated attribute_values
                    const updatedAttributeValues = spec.attribute_values.filter(
                        (attribVal) => attribVal.attribute_value_id !== ''
                    );

                    // Create an array of new attribute values from customVal

                    const customValAttributes: ICustomAttributeValue[] =
                        customVal.map((singleCustomVal) => ({
                            attribute_value_linkage_id:
                                singleCustomVal.attribute_value_linkage_id ??
                                '',
                            attribute_value_id:
                                singleCustomVal.attribute_value_id ?? '',
                            value: singleCustomVal.value,
                            measurement_unit_id:
                                singleCustomVal.measurement_unit_id ?? null,
                            currency_id: singleCustomVal.currency_id ?? null,
                        }));

                    const attributeValuesWithCustom: ICustomAttributeValue[] = [
                        ...updatedAttributeValues.filter(
                            (val) => val.attribute_value_id !== null
                        ),
                        ...customValAttributes,
                    ];

                    const filteredData = attributeValuesWithCustom.filter(
                        (item, index, self) =>
                            index ===
                            self.findIndex(
                                (t) =>
                                    Boolean(t.attribute_value_id) ===
                                        Boolean(item.attribute_value_id) &&
                                    t.value === item.value
                            )
                    );

                    return {
                        ...spec,
                        attribute_values: filteredData,
                    };
                }

                return spec;
            });
            return updatedSpecs; // Return the updated specs
        });
    };

    const getCustomValueRenderer = (
        val: ICustomAttributeValue,
        valIndex: number
    ) => {
        switch (spec.attribute_type) {
            case 'TEXT':
                return (
                    <FWInput
                        value={val.value}
                        onChange={(e) => {
                            const updatedValues = [...customVal];
                            updatedValues[valIndex].value = e.target.value;
                            setCustomVal(updatedValues);
                            handlePayloadUpdate(customVal);
                        }}
                        placeholder="Enter text"
                        sx={{ width: '100%' }}
                        inputProps={{
                            maxLength: 200,
                        }}
                    />
                );

            case 'NUMBER':
                return (
                    <FWInput
                        value={val.value}
                        onChange={(e) => {
                            const updatedValues = [...customVal];
                            updatedValues[valIndex].value = e.target.value;
                            setCustomVal(updatedValues);
                            handlePayloadUpdate(customVal);
                        }}
                        placeholder="Enter number"
                        allowOnly="DECIMAL_NUMBER"
                        sx={{ width: '100%' }}
                        inputProps={{
                            maxLength: 200,
                        }}
                    />
                );
            case 'CURRENCY':
                const handleCurrencyChange = (currencyCodeId: string) => {
                    const updatedValues = [...customVal];
                    updatedValues[valIndex] = {
                        ...updatedValues[valIndex],
                        currency_id: currencyCodeId,
                    };
                    setCustomVal(updatedValues);
                    handlePayloadUpdate(customVal);
                };

                return (
                    <FWCombinedInput
                        textFieldOnChange={(e) => {
                            const updatedValues = [...customVal];
                            updatedValues[valIndex].value = e.target.value;
                            setCustomVal(updatedValues);
                            handlePayloadUpdate(customVal);
                        }}
                        dropdownSx={{
                            width: '100%',
                        }}
                        inputSx={{
                            width: '150%',
                        }}
                        required={true}
                        isDropLeft={true}
                        allowOnly="NUMBER"
                        textFieldValue={customVal[valIndex].value}
                        selectFieldValue={
                            customVal[valIndex].currency_id || firstCurrencyId
                        }
                        selectFieldOnChange={(e) =>
                            handleCurrencyChange(e.target.value)
                        }
                        selectFieldMenuItems={
                            allCurrencies?.map((currency) => (
                                <FWMenuItem
                                    value={currency.entry_id}
                                    key={currency.entry_id}
                                >
                                    {currency.currency_code_abbreviation}
                                </FWMenuItem>
                            )) || []
                        }
                    />
                );
            case 'UOM':
                const handleUOMChange = (
                    decimal: string,
                    measurementUnitId: string
                ) => {
                    const updatedValues = [...customVal];
                    updatedValues[valIndex] = {
                        ...updatedValues[valIndex],
                        value: decimal,
                        measurement_unit_id: measurementUnitId,
                    };
                    setCustomVal(updatedValues);
                    handlePayloadUpdate(updatedValues);
                };

                return (
                    <FWCombinedInput
                        dropdownSx={{
                            width: '100%',
                        }}
                        inputSx={{
                            width: '260%',
                        }}
                        required={true}
                        isDropLeft={true}
                        allowOnly="DECIMAL_NUMBER"
                        textFieldValue={customVal[valIndex].value}
                        adornMentPosition="end"
                        textFieldOnChange={(e) =>
                            handleUOMChange(
                                e.target.value,
                                customVal[valIndex]?.measurement_unit_id ??
                                    firstMeasurementUnitId
                            )
                        }
                        selectFieldValue={
                            customVal[valIndex].measurement_unit_id ||
                            firstMeasurementUnitId
                        }
                        selectFieldOnChange={(e) =>
                            handleUOMChange(
                                customVal[valIndex].value,
                                e.target.value
                            )
                        }
                        selectFieldMenuItems={
                            measurementUnitList?.map((unit) => (
                                <FWMenuItem
                                    value={
                                        unit.measurement_unit
                                            .measurement_unit_id
                                    }
                                    key={unit.enterprise_measurement_unit_id}
                                >
                                    {
                                        unit.measurement_unit
                                            .measurement_unit_primary_name
                                    }
                                </FWMenuItem>
                            )) || []
                        }
                    />
                );
            default:
                return 'Unknown type';
        }
    };
    return (
        <Grid item className="flex flex--aifs" sx={{ mb: '15px' }} xs={12}>
            <Grid item xs={5} sx={{ mb: 0, mr: 0.4 }}>
                <FWAutocomplete
                    disableClearable
                    popupIcon={
                        <Box fontSize={'16px'}>
                            <i className="bi bi-chevron-down"></i>
                        </Box>
                    }
                    renderInput={(params) => <FWInput {...params} />}
                    options={myOptionsForSpecs}
                    getOptionLabel={(option: IAttribute) =>
                        option.attribute_name
                    }
                    value={spec}
                    onChange={(_, newValue: IAttribute) => {
                        onSelectSpec(newValue, index);
                        setOptions(newValue.attribute_values);
                    }}
                />
            </Grid>

            <Grid item xs={7} paddingLeft={'16px'}>
                <Grid item xs={12} className="flex">
                    <Grid item xs={10}>
                        <SpecValueItem
                            currentSpec={myOptionsForSpecValues}
                            setTransformedData={setTransformedData}
                            index={index}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        {spec.allow_custom_value && customVal.length === 0 && (
                            <FWTooltip
                                title="Add custom specification value"
                                style={{ borderRadius: '100%' }}
                            >
                                <IconButton
                                    sx={{
                                        width: '40px',
                                        height: '40px',
                                        marginLeft: '5px',
                                    }}
                                    onClick={() => {
                                        handleAddORSpecification();
                                    }}
                                    color="primary"
                                    disabled={spec.attribute_id === ''}
                                >
                                    <FWIcon
                                        name="bi bi-plus-circle"
                                        size={22}
                                    />
                                </IconButton>
                            </FWTooltip>
                        )}
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: '15px' }}>
                    {customVal.map((val, valIndex) => (
                        <Grid
                            container
                            alignItems="flex-start"
                            marginBottom="15px"
                            key={valIndex}
                            direction="column"
                        >
                            <Grid item xs={12} container>
                                <Grid item xs={10}>
                                    {getCustomValueRenderer(val, valIndex)}
                                </Grid>
                                <FWTooltip
                                    title="Remove"
                                    style={{ borderRadius: '100%' }}
                                >
                                    <IconButton
                                        onClick={() =>
                                            handleRemoveSpecification(valIndex)
                                        }
                                        color="error"
                                    >
                                        <FWIcon
                                            name="bi bi-dash-circle"
                                            size={22}
                                        />
                                    </IconButton>
                                </FWTooltip>
                                {spec.allow_custom_value &&
                                valIndex === customVal.length - 1 ? (
                                    <FWTooltip
                                        title="Add custom specification value"
                                        style={{ borderRadius: '100%' }}
                                    >
                                        <IconButton
                                            onClick={handleAddORSpecification}
                                            color="primary"
                                        >
                                            <FWIcon
                                                name="bi bi-plus-circle"
                                                size={22}
                                            />
                                        </IconButton>
                                    </FWTooltip>
                                ) : null}
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SpecificationItem;

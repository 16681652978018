import { InputAdornment } from '@mui/material';
import { clone } from 'lodash';
import { CSSProperties } from 'react';
import { FWInput } from '../../Common/FWInput';
import {
    AllocationTypeEnum,
    CostTypeEnum,
    IAdditionalCostWithIsRequired,
    IAdditionalCostsWithValue,
} from '../models/AdditionalCost.model';

const AdditionalCostDiscount = ({
    currency_code_abbreviation,
    currency_code_symbol,
    localCopyOfAdditionalCost,
    measurementUnitName,
    updateSingleAdditionalCost,
    baseQuantity,
    baseRate,
    totalAdditionalCost,
    updateHandler,
    additionalCostTemplateOptions,
    sx,
    disableHelper = false,
}: {
    localCopyOfAdditionalCost: IAdditionalCostsWithValue[];
    updateSingleAdditionalCost: (costValue: string, idx: number) => void;
    currency_code_abbreviation: string;
    currency_code_symbol: string;
    measurementUnitName: string;
    baseRate: number | string;
    baseQuantity: number | string;
    sx?: CSSProperties;
    totalAdditionalCost: {
        additionalCostValue: number;
        effectiveRateDeviation: number;
    };
    updateHandler: (newAdditionalCost: IAdditionalCostsWithValue[]) => void;
    additionalCostTemplateOptions: IAdditionalCostWithIsRequired[];
    disableHelper?: boolean;
}) => {
    return (
        <>
            <FWInput
                style={sx}
                value={
                    localCopyOfAdditionalCost[0]?.value !== null
                        ? localCopyOfAdditionalCost[0]?.value.toString() ?? ''
                        : ''
                }
                maxNumberOfDecimal={
                    localCopyOfAdditionalCost[0]?.costType ===
                    CostTypeEnum.PERCENTAGE
                        ? 2
                        : 10
                }
                allowOnly={
                    localCopyOfAdditionalCost[0]?.costType ===
                    CostTypeEnum.PERCENTAGE
                        ? 'PERCENT'
                        : 'DECIMAL_NUMBER'
                }
                //MOVE THE FUNCTION TO A SEPERATE FUNCTION AND CALL IT ONBLUR, JUST UPDATE THE VALUE HERE
                onChange={(e) => {
                    const newAdditionalCost = clone(localCopyOfAdditionalCost);
                    const newValue = e.target.value;

                    if (newAdditionalCost[0]) {
                        newAdditionalCost[0].value =
                            newValue === '' ? '' : newValue;
                    } else {
                        if (
                            additionalCostTemplateOptions[0] &&
                            additionalCostTemplateOptions[0].costType ===
                                CostTypeEnum.ABSOLUTE_VALUE
                        ) {
                            newAdditionalCost.push({
                                value: newValue === '' ? '' : newValue,
                                costType: CostTypeEnum.ABSOLUTE_VALUE,
                                cost_source:
                                    additionalCostTemplateOptions[0]
                                        .cost_source ?? null,
                                additional_cost_id:
                                    additionalCostTemplateOptions[0]
                                        ?.additional_cost_id,
                                allocationType:
                                    additionalCostTemplateOptions[0]
                                        .allocationType,
                                costName:
                                    additionalCostTemplateOptions[0].costName,
                            });
                        } else {
                            newAdditionalCost.push({
                                value: newValue === '' ? '' : newValue,
                                costType: CostTypeEnum.PERCENTAGE,
                                allocationType: null,
                                costName: 'Discount',
                                cost_source:
                                    additionalCostTemplateOptions[0]
                                        .cost_source ?? null,
                                additional_cost_id:
                                    additionalCostTemplateOptions[0]
                                        ?.additional_cost_id,
                            });
                        }
                    }

                    updateHandler(newAdditionalCost);
                }}
                error={
                    (additionalCostTemplateOptions[0] &&
                        additionalCostTemplateOptions[0].is_required &&
                        (localCopyOfAdditionalCost.length === 0 ||
                            String(
                                localCopyOfAdditionalCost[0].value
                            )?.trim() === '')) ||
                    (totalAdditionalCost.effectiveRateDeviation !== 0 &&
                        totalAdditionalCost.effectiveRateDeviation >= +baseRate)
                }
                helper={
                    disableHelper
                        ? { text: '' }
                        : {
                              text:
                                  additionalCostTemplateOptions[0] &&
                                  additionalCostTemplateOptions[0]
                                      .is_required &&
                                  (localCopyOfAdditionalCost.length === 0 ||
                                      String(
                                          localCopyOfAdditionalCost[0].value
                                      )?.trim() === '')
                                      ? 'Input is required'
                                      : totalAdditionalCost.effectiveRateDeviation !==
                                            0 &&
                                        totalAdditionalCost.effectiveRateDeviation >=
                                            +baseRate
                                      ? 'Discount cannot exceed target rate'
                                      : '',
                          }
                }
                size="small"
                InputProps={{
                    ...(localCopyOfAdditionalCost[0]?.costType ===
                    CostTypeEnum.PERCENTAGE
                        ? {
                              startAdornment: (
                                  <InputAdornment
                                      position={'start'}
                                      sx={{
                                          m: '0 0.75rem',
                                      }}
                                      style={sx}
                                  ></InputAdornment>
                              ),
                              endAdornment: (
                                  <InputAdornment
                                      position={'end'}
                                      sx={{
                                          m: '0 0.75rem',
                                      }}
                                      style={sx}
                                  >
                                      %
                                  </InputAdornment>
                              ),
                          }
                        : {
                              startAdornment: (
                                  <InputAdornment
                                      position={'start'}
                                      sx={{
                                          m: '0 0 0 0.75rem',
                                          padding: '0px',
                                      }}
                                  >
                                      {currency_code_symbol}
                                  </InputAdornment>
                              ),
                              endAdornment: (
                                  <InputAdornment
                                      position={'end'}
                                      sx={{
                                          m: '0 0.75rem',
                                      }}
                                  >
                                      {localCopyOfAdditionalCost[0]
                                          ?.allocationType ===
                                          AllocationTypeEnum.PER_UNIT &&
                                          `/${measurementUnitName}`}
                                  </InputAdornment>
                              ),
                          }),
                }}
            />
        </>
    );
};

export default AdditionalCostDiscount;

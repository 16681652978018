import { memo } from 'react';
import { CustomFieldType } from '../../../Global/Interfaces/TemplateInterface';
import {
    FieldTypeLabel,
    RenderInputByFieldType,
    TFilterUpdateParams,
} from '../../constants/projectDashboardConstants';
import { DashBoardFilter } from '../../interface/project.model';

export const areAllFieldsFilled = (filters: DashBoardFilter[]) => {
    return filters.every((filter) => {
        if (!filter.single) return false;
        const {
            field,
            condition_type,
            value,
            value_from,
            datetime_from,
            values,
        } = filter.single;

        if (!field || !condition_type) return false;

        if (['BLANK', 'NOT_BLANK'].includes(filter.single.condition_type)) {
            return true;
        }
        switch (filter.single.type) {
            case CustomFieldType.SHORTTEXT:
            case CustomFieldType.LONG_TEXT:
            case CustomFieldType.SHORT_TEXT:
            case CustomFieldType.LONGTEXT:
            case CustomFieldType.LOCATION:
                return Boolean(value);
            case CustomFieldType.CURRENCY:
            case CustomFieldType.DECIMAL:
            case CustomFieldType.INTEGER:
            case CustomFieldType.FLOAT:
                return Boolean(value_from);
            case CustomFieldType.DATE:
            case CustomFieldType.DATETIME:
                return Boolean(datetime_from);
            case CustomFieldType.BOOLEAN:
            case CustomFieldType.CHOICE:
            case CustomFieldType.MULTI_CHOICE:
                return values && values.length > 0;
            default:
                return false;
        }
    });
};

const ProjectDashboardFilterInput = ({
    filter,
    index,
    updateFilterObject,
    filterDataInOptions,
}: {
    filter: DashBoardFilter;
    filterDataInOptions: FieldTypeLabel;
    index: number;
    updateFilterObject: (
        index: number,
        updatedField: TFilterUpdateParams
    ) => void;
}) => {
    console.log('meow meow', filter);
    return filter.single === null ? (
        <></>
    ) : (
        <RenderInputByFieldType
            filter={filter}
            condition={filter.single?.condition_type}
            fieldType={
                [
                    CustomFieldType.SHORTTEXT,
                    CustomFieldType.LONG_TEXT,
                    CustomFieldType.SHORT_TEXT,
                    CustomFieldType.LONGTEXT,
                    CustomFieldType.LOCATION,
                ].includes(filter.single.type)
                    ? {
                          type: filter.single.type,
                          value: filter.single.value ?? '',
                      }
                    : [
                          CustomFieldType.DECIMAL,
                          CustomFieldType.INTEGER,
                          CustomFieldType.CURRENCY,
                          CustomFieldType.FLOAT,
                      ].includes(filter.single.type)
                    ? {
                          type: filter.single.type,
                          value: filter.single.value_from ?? '',
                      }
                    : [CustomFieldType.DATE, CustomFieldType.DATETIME].includes(
                          filter.single.type
                      )
                    ? {
                          type: filter.single.type,
                          value: filter.single.datetime_from ?? '',
                      }
                    : filter.single.type === CustomFieldType.BOOLEAN
                    ? {
                          type: CustomFieldType.BOOLEAN,
                          value: filter.single.values,
                      }
                    : filter.single.type === CustomFieldType.CHOICE
                    ? {
                          type: CustomFieldType.CHOICE,
                          value: filter.single.values,
                          choices: filterDataInOptions.choices ?? [],
                      }
                    : filter.single.type === CustomFieldType.MULTI_CHOICE
                    ? {
                          type: CustomFieldType.MULTI_CHOICE,
                          value: filter.single.values,
                          choices: filterDataInOptions.choices ?? [],
                      }
                    : ({} as any)
            }
            customUpdateHandler={(newValue: any) => {
                if (filter.single?.condition_type) {
                    if (filter.single?.condition_type === 'RANGE') {
                        updateFilterObject(index, {
                            key: 'VALUE',
                            value: {
                                filterOption: filter.single?.condition_type,
                                type: filter.single.type as any,
                                value_from: newValue.datetime_from ?? '',
                                value_to: newValue.datetime_to ?? '',
                            },
                        });
                    } else {
                        updateFilterObject(index, {
                            key: 'VALUE',
                            value: {
                                filterOption: filter.single?.condition_type,
                                type: filter.single.type as any,
                                value: newValue,
                            },
                        });
                    }
                }
            }}
        />
    );
};

export default memo(ProjectDashboardFilterInput);

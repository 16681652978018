import {
    IPurchaseOrder,
    PurchaseOrderStatus,
    PurchaseOrderType,
} from '../Interfaces/PO.model';

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FWButton } from '../../Common/FWButton';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { FWPopup } from '../../Common/FWPopup';
import FWPopupWithNotes from '../../Common/FWPopupWithNotes';
import { HookStateValue } from '../../Common/Hooks/StateHook';
import LoadingBackDrop from '../../Components/Shared/LoadingBackdrop';
import { useFeatureQuota } from '../../FeatureAccess/Hooks/FeatureQuotaHook';
import { planUpgradeTooltipText } from '../../FeatureAccess/Utils/planUpgradeTooltipText';
import { useFetchTabCountMutation } from '../../Redux/Slices/tabCountApiSlice';
import { usePurchaseOrderApprovalActions } from '../Hooks/PurchaseOrderApprovalActions';
import { usePurchaseOrderIssuedBuyerActions } from '../Hooks/PurchaseOrderIssuedBuyerActions';
import { usePurchaseOrderIssuedSellerActions } from '../Hooks/PurchaseOrderIssuedSellerActions';
import { approvalPendingPoHasValidDeliverySchedule } from '../Misc/deliveryScheduleFunctions';

export default function PurchaseOrderReviewButtonsContainer({
    purchaseOrderData,
    enterpriseType,
}: {
    purchaseOrderData: IPurchaseOrder;
    enterpriseType: 'BUYER' | 'SELLER';
}) {
    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    padding: '24px 24px 40px 24px',
                }}
            >
                {enterpriseType === 'BUYER' &&
                    purchaseOrderData.status ===
                        PurchaseOrderStatus.APPROVAL_PENDING && (
                        <BuyerApprovalPendingButtonsSubContainer
                            purchaseOrderData={purchaseOrderData}
                        />
                    )}
                {enterpriseType === 'BUYER' &&
                    purchaseOrderData.status === PurchaseOrderStatus.ISSUED && (
                        <BuyerIssuedButtonsSubContainer
                            purchaseOrderData={purchaseOrderData}
                        />
                    )}
                {enterpriseType === 'SELLER' &&
                    purchaseOrderData.status === PurchaseOrderStatus.ISSUED && (
                        <SellerIssuedButtonsSubContainer
                            purchaseOrderData={purchaseOrderData}
                        />
                    )}
            </div>
        </div>
    );
}

function BuyerApprovalPendingButtonsSubContainer({
    purchaseOrderData,
}: {
    purchaseOrderData: IPurchaseOrder;
}) {
    const history = useHistory();
    const {
        hookState,
        showApprove,
        showSubmit,
        showReject,
        // showRework,
        showDelete,
        approvePo,
        submitPo,
        rejectPo,
        reworkPo,
        deletePo,
    } = usePurchaseOrderApprovalActions(purchaseOrderData);

    const deliveryScheduleIsValid =
        approvalPendingPoHasValidDeliverySchedule(purchaseOrderData);

    const [openDeletePopup, setOpenDeletePopup] =
        React.useState<boolean>(false);
    const [openRejectPopup, setOpenRejectPopup] =
        React.useState<boolean>(false);
    const [openReworkPopup, setOpenReworkPopup] =
        React.useState<boolean>(false);
    const [openSubmitPopup, setOpenSubmitPopup] =
        React.useState<boolean>(false);

    const backToDashboard = () => {
        history.push('/buyer/purchase_orders');
    };

    const { quota, getQuotaData, quotaHookState } = useFeatureQuota();

    useEffect(() => {
        getQuotaData();
    }, [getQuotaData]);

    const eventPoQuotaExhausted =
        quotaHookState.state === HookStateValue.READY &&
        quota['feature-po-events-count']?.remaining === 0;
    const totalPoQuotaExhausted =
        quotaHookState.state === HookStateValue.READY &&
        quota['feature-po-total-count']?.remaining === 0;

    const quotaExhausted =
        purchaseOrderData.purchase_order_type === PurchaseOrderType.FROM_EVENT
            ? eventPoQuotaExhausted || totalPoQuotaExhausted
            : totalPoQuotaExhausted;

    if (hookState.state === HookStateValue.ERROR) {
        return <></>;
    }
    return (
        <>
            {hookState.state === HookStateValue.LOADING && <LoadingBackDrop />}
            {showDelete && (
                <FWButton
                    sx={{ marginRight: '0.75rem' }}
                    variant="outlined"
                    color="error"
                    onClick={() => {
                        setOpenDeletePopup(true);
                    }}
                >
                    Discard
                </FWButton>
            )}
            {showReject && (
                <FWButton
                    sx={{ marginRight: '0.75rem' }}
                    variant="outlined"
                    color="error"
                    onClick={() => {
                        setOpenRejectPopup(true);
                    }}
                >
                    Reject
                </FWButton>
            )}
            {/* {showRework && (
                <FWButton
                    sx={{ marginRight: '0.75rem' }}
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        setOpenReworkPopup(true);
                    }}
                >
                    Request Changes
                </FWButton>
            )} */}
            {showApprove && (
                <FWTooltip
                    title={
                        quotaExhausted
                            ? planUpgradeTooltipText.quotaExhaustedText
                            : !deliveryScheduleIsValid
                            ? 'Invalid delivery schedule'
                            : ''
                    }
                >
                    <div>
                        <FWButton
                            sx={{ marginRight: '0.75rem' }}
                            variant="contained"
                            onClick={() => {
                                approvePo()
                                    .then(() => {
                                        toast.success('PO approved');
                                        backToDashboard();
                                    })
                                    .catch(() => {
                                        toast.error('Failed to approve PO');
                                    });
                            }}
                            disabled={
                                !deliveryScheduleIsValid || quotaExhausted
                            }
                        >
                            Send for Approval
                        </FWButton>
                    </div>
                </FWTooltip>
            )}
            {showSubmit && (
                <FWTooltip
                    title={
                        quotaExhausted
                            ? planUpgradeTooltipText.quotaExhaustedText
                            : !deliveryScheduleIsValid
                            ? 'Invalid delivery schedule'
                            : ''
                    }
                >
                    <div>
                        <FWButton
                            sx={{ marginRight: '0.75rem' }}
                            variant="contained"
                            onClick={() => {
                                setOpenSubmitPopup(true);
                            }}
                            disabled={
                                !deliveryScheduleIsValid || quotaExhausted
                            }
                        >
                            Issue PO
                        </FWButton>
                    </div>
                </FWTooltip>
            )}

            <FWPopup
                open={openDeletePopup}
                setOpen={setOpenDeletePopup}
                onClickConfirm={() => {
                    deletePo()
                        .then(() => {
                            toast.success('PO deleted');
                            backToDashboard();
                        })
                        .catch(() => {
                            toast.error('Failed to delete PO');
                        });
                }}
                title={'Discard PO'}
                msg={'Are you sure you want to delete this PO?'}
            />
            <FWPopupWithNotes
                open={openRejectPopup}
                handleClose={() => {
                    setOpenRejectPopup(false);
                }}
                onClickConfirm={(msg) => {
                    rejectPo(msg)
                        .then(() => {
                            toast.success('PO rejected');
                            backToDashboard();
                        })
                        .catch(() => {
                            toast.error('Failed to reject PO');
                        });
                }}
                title={'Reject PO'}
                msg={'Are you sure you want to reject this PO?'}
                placeholderText={'Enter rejection reason (optional)'}
            />
            <FWPopupWithNotes
                open={openReworkPopup}
                handleClose={() => {
                    setOpenReworkPopup(false);
                }}
                onClickConfirm={(msg) => {
                    reworkPo(msg)
                        .then(() => {
                            toast.success('PO sent for rework');
                            backToDashboard();
                        })
                        .catch(() => {
                            toast.error('PO sent for rework');
                        });
                }}
                title={'Sent PO for Rework'}
                msg={'Are you sure you want to sent this PO for Rework?'}
                placeholderText={
                    'Enter reason for sending to rework (optional)'
                }
            />
            <FWPopup
                open={openSubmitPopup}
                setOpen={setOpenSubmitPopup}
                onClickConfirm={() => {
                    submitPo()
                        .then(() => {
                            toast.success('Successfully issued PO');
                            backToDashboard();
                        })
                        .catch(() => {
                            toast.error('Failed to issue PO');
                        });
                }}
                title={'Issue PO'}
                msg={'The PO will be approved and issued to vendor'}
            />
        </>
    );
}

function BuyerIssuedButtonsSubContainer({
    purchaseOrderData,
}: {
    purchaseOrderData: IPurchaseOrder;
}) {
    const history = useHistory();
    const { hookState, showRescind, rescindPo } =
        usePurchaseOrderIssuedBuyerActions(purchaseOrderData);

    const [openRescindPopup, setOpenRescindPopup] =
        React.useState<boolean>(false);

    const backToDashboard = () => {
        history.push('/buyer/purchase_orders');
    };

    if (hookState.state === HookStateValue.ERROR) {
        return <></>;
    }
    return (
        <>
            {hookState.state === HookStateValue.LOADING && <LoadingBackDrop />}
            {showRescind && (
                <FWButton
                    sx={{ marginRight: '0.75rem' }}
                    variant="contained"
                    color="error"
                    onClick={() => {
                        setOpenRescindPopup(true);
                    }}
                >
                    Rescind
                </FWButton>
            )}
            <FWPopupWithNotes
                open={openRescindPopup}
                handleClose={() => setOpenRescindPopup(false)}
                onClickConfirm={(msg) => {
                    rescindPo(msg)
                        .then(() => {
                            toast.success('PO rescinded');
                            backToDashboard();
                        })
                        .catch(() => {
                            toast.error('Failed to rescind PO');
                        });
                }}
                title={'Rescind PO'}
                msg={'Are you sure you want to rescind this PO?'}
                placeholderText="Enter rescind reason (optional)"
            />
        </>
    );
}

function SellerIssuedButtonsSubContainer({
    purchaseOrderData,
}: {
    purchaseOrderData: IPurchaseOrder;
}) {
    const history = useHistory();
    const { hookState, showButtons, acceptPo, declinePo } =
        usePurchaseOrderIssuedSellerActions(purchaseOrderData);

    const [openDeclinePopup, setOpenDeclinePopup] =
        React.useState<boolean>(false);

    const [openAcceptPopup, setOpenAcceptPopup] =
        React.useState<boolean>(false);

    const backToDashboard = () => {
        history.push('/seller/purchase_orders');
    };
    const [fetchTabData] = useFetchTabCountMutation({
        fixedCacheKey: 'purchase_order_seller',
    });

    if (hookState.state === HookStateValue.ERROR) {
        return <></>;
    }

    return (
        <>
            {hookState.state === HookStateValue.LOADING && <LoadingBackDrop />}
            {showButtons && (
                <>
                    <FWButton
                        sx={{ marginRight: '0.75rem' }}
                        variant="outlined"
                        color="error"
                        onClick={() => {
                            setOpenDeclinePopup(true);
                        }}
                    >
                        Decline PO
                    </FWButton>
                    <FWButton
                        sx={{ marginRight: '0.75rem' }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setOpenAcceptPopup(true);
                        }}
                    >
                        Accept PO
                    </FWButton>
                </>
            )}
            <FWPopupWithNotes
                btnColor="error"
                open={openDeclinePopup}
                handleClose={() => {
                    setOpenDeclinePopup(false);
                }}
                onClickConfirm={(notes: string) => {
                    declinePo(
                        purchaseOrderData.purchase_order_type ===
                            PurchaseOrderType.DIRECT_PO
                            ? 'DIRECT'
                            : 'FROM_EVENT',
                        notes
                    )
                        .then(() => {
                            toast.success('PO declined');
                            backToDashboard();
                        })
                        .catch(() => {
                            toast.error('Failed to decline PO');
                        });
                }}
                title={'Decline PO'}
                msg={'Are you sure you want to decline this PO?'}
                placeholderText={'Enter reason for declining PO'}
                mandatory
            />
            <FWPopup
                btnColor="primary"
                open={openAcceptPopup}
                setOpen={setOpenAcceptPopup}
                onClickConfirm={() => {
                    acceptPo(
                        purchaseOrderData.purchase_order_type ===
                            PurchaseOrderType.DIRECT_PO
                            ? 'DIRECT'
                            : 'FROM_EVENT'
                    )
                        .then(() => {
                            toast.success('Successfully accepted PO');
                            fetchTabData('purchase_order_seller');
                            backToDashboard();
                        })
                        .catch(() => {
                            toast.error('Failed to accepted PO');
                        });
                }}
                title={'Accept PO'}
                msg={'Are you sure you want to accept this PO?'}
            />
        </>
    );
}

import { Box, Grid, IconButton } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FWLink } from '../../../../Common/FWLink';
import { FWTypography } from '../../../../Common/FWTypograhy';
import { AuthContext } from '../../../../Contexts/AuthContext';
import { useGetSpecificationsListQueryQuery } from '../../../../Specifications/Services/specifications.service';
import { IAttribute } from '../../../../Specifications/Models/SpecificationInterfaces';
import SpecificationItem from './SpecificationItem';
import {
    ICustomAttributeNew,
    ICustomAttributePayloadNew,
} from '../../../../Models/Attributes.model';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';

interface IItemSpecificationsProps {
    data: ICustomAttributeNew[];
    emitSpecificationAction: (value: ICustomAttributeNew[]) => void;
}

enum ATTRIBUTE_TYPE {
    TEXT = 'TEXT',
    NUMBER = 'NUMBER',
    UOM = 'UOM',
    CURRENCY = 'CURRENCY',
}

const convertPayloadToNewAttribute = (
    payload: ICustomAttributePayloadNew
): ICustomAttributeNew => {
    return {
        attribute_linkage_id: payload.attribute_linkage_id,
        attribute_id: payload.attribute_id,
        attribute_name: payload.attribute_name,
        attribute_type: payload.attribute_type as ATTRIBUTE_TYPE, // Ensure enum type here
        attribute_values: payload.attribute_values.map((value) => ({
            attribute_value_linkage_id: value.attribute_value_linkage_id,
            attribute_value_id: value.attribute_value_id,
            value: value.value, // Assumes backend is saving as string, so no conversion needed here
            measurement_unit: value.measurement_unit_id
                ? {
                      measurement_unit_id: value.measurement_unit_id,
                      measurement_unit_primary_name: '',
                  }
                : null,
            currency: value.currency_id
                ? {
                      entry_id: value.currency_id,
                      currency_code_abbreviation: '',
                      currency_name: '',
                      currency_symbol: '',
                  }
                : null,
        })),
    };
};

const convertToPayload = (
    attribute: ICustomAttributeNew
): ICustomAttributePayloadNew => {
    return {
        attribute_linkage_id: attribute.attribute_linkage_id,
        attribute_id: attribute.attribute_id,
        attribute_name: attribute.attribute_name,
        attribute_type: attribute.attribute_type,
        attribute_values: attribute.attribute_values.map((value) => ({
            attribute_value_linkage_id: value.attribute_value_linkage_id,
            attribute_value_id: value.attribute_value_id,
            value: value.value,
            measurement_unit_id: value.measurement_unit
                ? value.measurement_unit.measurement_unit_id
                : null,
            currency_id: value.currency ? value.currency.entry_id : null,
        })),
    };
};

const ItemSpecifications = ({
    data,
    emitSpecificationAction,
}: IItemSpecificationsProps) => {
    const { authData } = useContext(AuthContext);
    const { data: myOptionsForSpecs } = useGetSpecificationsListQueryQuery({});

    const getAllowCustomValue = (id: string | null) => {
        const selectedSpec = myOptionsForSpecs?.find(
            (spec) => spec.attribute_id === id
        );
        return selectedSpec?.allow_custom_value;
    };

    const convertToOption = (attribute: ICustomAttributeNew): IAttribute => {
        return {
            attribute_linkage_id: attribute.attribute_linkage_id,
            attribute_id: attribute.attribute_id,
            created_by_user_name: '',
            modified_by_user_name: '',
            attribute_values: attribute.attribute_values.map((value) => ({
                attribute_value_id: value.attribute_value_id,
                created_datetime: '',
                modified_datetime: '',
                deleted_datetime: null,
                value: value.value,
                created_by_user: null,
                modified_by_user: null,
                deleted_by_user: null,
                attribute: '',
                attribute_value_linkage_id: value.attribute_value_linkage_id,
                measurement_unit:
                    value.measurement_unit?.measurement_unit_id || null,
                currency: value.currency?.entry_id || null,
            })),
            created_datetime: '',
            modified_datetime: '',
            deleted_datetime: null,
            attribute_name: attribute.attribute_name,
            allow_custom_value:
                getAllowCustomValue(attribute.attribute_id) ?? false,
            attribute_type: attribute.attribute_type,
            status: '',
            created_by_user: '',
            modified_by_user: '',
            deleted_by_user: null,
            enterprise: '',
        };
    };

    const [selectedSpec, setSelectedSpec] = useState<IAttribute[]>(
        data.map(convertToOption)
    );

    const [transformedData, setTransformedData] = useState<
        ICustomAttributePayloadNew[]
    >(data.map(convertToPayload));
    useEffect(() => {
        const transformedAttributes = transformedData.map(
            convertPayloadToNewAttribute
        );
        emitSpecificationAction(transformedAttributes);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transformedData]);

    const validOptionsForSpecName = useMemo(() => {
        const val = myOptionsForSpecs?.filter(
            (option: { status: string }) => option.status === 'ACTIVE'
        );
        const listOfExistingSpecs = transformedData.map(
            (spec) => spec.attribute_name
        );
        const filteredOptionsForSpecName = val?.filter(
            (spec: { attribute_name: string }) =>
                !listOfExistingSpecs.includes(spec.attribute_name)
        );
        return filteredOptionsForSpecName;
    }, [myOptionsForSpecs, transformedData]);

    const handleSelectSpec = (newVal: IAttribute, index: number) => {
        const transformedVal: ICustomAttributePayloadNew = {
            attribute_linkage_id: null, // Assuming no linkage ID for now
            attribute_id: newVal.attribute_id,
            attribute_name: newVal.attribute_name,
            attribute_type: newVal.attribute_type,
            attribute_values: newVal.attribute_values.map((val) => ({
                attribute_value_linkage_id: null, // Assuming no linkage ID for now
                attribute_value_id: val.attribute_value_id,
                value: val.value,
                measurement_unit_id: val.measurement_unit
                    ? val.measurement_unit
                    : null,
                currency_id: val.currency ? val.currency : null,
            })),
        };
        setSelectedSpec((prev) => {
            const updatedSpecs = [...prev];
            updatedSpecs[index] = newVal;
            return updatedSpecs;
        });

        setTransformedData((prev) => {
            const updatedSpecs = [...prev];
            updatedSpecs[index] = transformedVal;

            // updatedSpecs[index] = newVal;
            return updatedSpecs;
        });
    };

    const handleAddSpecification = () => {
        const dummyAttribute: IAttribute = {
            attribute_linkage_id: null,
            attribute_id: '',
            created_by_user_name: '',
            modified_by_user_name: '',
            attribute_values: [],
            created_datetime: '',
            modified_datetime: '',
            deleted_datetime: null,
            attribute_name: '',
            attribute_type: '',
            status: '',
            created_by_user: '',
            modified_by_user: '',
            deleted_by_user: null,
            enterprise: '',
        };
        const dummyAttributeForPayload: ICustomAttributePayloadNew = {
            attribute_linkage_id: null,
            attribute_id: '', // or generate a unique ID if needed
            attribute_name: '', // Default empty name
            attribute_type: '', // Default empty type
            attribute_values: [
                // Add at least one empty value
                {
                    attribute_value_linkage_id: null,
                    attribute_value_id: '', // or generate a unique ID if needed
                    value: '', // Default empty value
                    measurement_unit_id: null,
                    currency_id: null,
                },
            ],
        };

        // data.push({ name: '', value: [''] });
        setSelectedSpec((prev) => [...prev, dummyAttribute]);
        setTransformedData((prev) => [...prev, dummyAttributeForPayload]);
        // emitSpecificationAction([...data]);
    };

    const handleDeleteSpecification = (index: number) => {
        setSelectedSpec((prev) => prev.filter((_, i) => i !== index));
        setTransformedData((prev) => prev.filter((_, i) => i !== index));
    };

    return (
        <Grid container className="flex flex--aifs">
            {selectedSpec.map((spec, index) => (
                <>
                    <Grid
                        item
                        xs={data.length > 0 ? 11 : 0}
                        border={'1px solid lightgrey'}
                        borderRadius={'15px'}
                        marginY={'15px'}
                    >
                        <Grid>
                            <Grid
                                item
                                className="flex"
                                xs={12}
                                marginTop={'10px'}
                            >
                                <Grid item xs={12} className="flex">
                                    <Grid item xs={5} marginLeft={'15px'}>
                                        <FWTypography color="text.secondary">
                                            Specification name
                                        </FWTypography>
                                    </Grid>
                                    {/* <Grid item xs={0.2} /> */}
                                    <Grid item xs={7}>
                                        <FWTypography
                                            color="text.secondary"
                                            paddingLeft={'16px'}
                                        >
                                            Specification value
                                        </FWTypography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                // width={'100%'}
                                paddingRight={2}
                                paddingLeft={2}
                                marginBottom={2}
                                // marginX={'auto'}
                            >
                                <SpecificationItem
                                    key={index}
                                    spec={spec}
                                    index={index}
                                    myOptionsForSpecs={
                                        validOptionsForSpecName ?? []
                                    }
                                    onSelectSpec={handleSelectSpec}
                                    setTransformedData={setTransformedData}
                                    autoFilldata={data}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            color="error"
                            aria-label="delete"
                            onClick={() => handleDeleteSpecification(index)}
                            sx={{ marginY: '15px' }}
                        >
                            <i className="bi bi-trash flex flex--aic"></i>
                        </IconButton>
                    </Grid>
                </>
            ))}

            <Grid item sx={{ mb: data.length > 0 ? '33px' : '14px' }}>
                {authData.details?.role === 'ADMIN' ? (
                    // validOptionsForSpecName?.length === 0
                    validOptionsForSpecName?.length === 0 ? (
                        <Box>
                            <FWTooltip
                                title="No active specifications available"
                                placement="bottom"
                            >
                                <div>
                                    <FWTypography
                                        color="text.disabled"
                                        sx={{
                                            display: 'inline-block',
                                            cursor: 'not-allowed',
                                        }}
                                    >
                                        Add new Specification
                                    </FWTypography>
                                </div>
                            </FWTooltip>
                        </Box>
                    ) : (
                        <FWLink onClick={handleAddSpecification}>
                            Add new Specification
                        </FWLink>
                    )
                ) : null}
            </Grid>
        </Grid>
    );
};

export default ItemSpecifications;

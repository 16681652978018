import { createSlice } from '@reduxjs/toolkit';
import {
    IBomItemDeliverScheduleStructure,
    IEventItemDetails,
} from '../../../../Events/Interfaces/Buyer/RFQ.model';
import { IRfqAnalyticsStoreListOfBomStructWIthHeiriacy } from '../../../../Events/Slices/RfqAnalyticsSlice';
import {
    IGetAllEventSeller,
    IGetBuyerRFQBidItemDetails,
} from '../../../../Models/RFQ.model';

export enum BidItemPageTypes {
    ALL_ITEMS = 'ALL_ITEMS',
    EXCLUDED_ITEMS = 'EXCLUDED_ITEMS',
}

export interface IQouteComparisionStore {
    paginationInformation: {
        currentPage: number;
        searchQuery: string;
    };
    listOfRfqItemName: { item_id: string; item_name: string; itemNo: number }[];
    listOfRfqItemNameIsLoading: boolean;
    filteringType: BidItemPageTypes;
    selectedItems: string[];
    selectedVendors: string[];
    importAndExportInformation: {
        [seller_entity_id: string]:
            | {
                  internal_vendor_quote_id: string | null;
                  rfq_event_id: string;
                  validity_datetime: string | null;
                  bulk_discount_minimum_order_value: string | null;
                  bulk_discount_percentage: string | null;
                  parent_bid_id: string | null;
                  action: 'SAVE_PROXY_QUOTE' | 'SAVE_DRAFT_COUNTEROFFER';
                  currency_code_id: string;
                  proxy_quote_submission_time: string | null;
              }
            | { rfq_bid_id: string }
            | null;
    };
    listOfBomsOfCurrentEventsWithSubBomOptions: IRfqAnalyticsStoreListOfBomStructWIthHeiriacy[];
    cachingRfqData: {
        [rfq_item_entry_id: string]: IEventItemDetails;
    };
    requisitionIds: { requisitionId: string; label: string }[];

    allRFqVendor: IGetAllEventSeller[];
    listOfAllBomUse: {
        enterprise_bom_id: string;
        bom_code: string;
    }[];
    selectedRequisitionForFilterting: string;
    selectedBomForFiltering: string | null;
    selectedSubBomForFiltering: string | null;
    isDsAllocationPopupOpen: boolean;
    isSingleVendorDsAllocationPopup: boolean;

    templateInformation: {
        templateId: string | null;
        entityId: string | null;
    };

    DSAllocationPopupData: {
        selectedVendor: string | null;
        listofBidItems: IGetBuyerRFQBidItemDetails[];
        rfqBidIdSelected: string | null;
        pageNo: number;
        itemsFilteringType: DsAllocationFIlterTypes;
        allocationPopupData: {
            [rfqBidUid: string]: {
                [rfqBidItemid: string]: {
                    updated: boolean;
                    dsData: {
                        delivery_schedule_item_id: string | null;
                        bom_item: IBomItemDeliverScheduleStructure | null;
                        quantity: string;
                        delivery_date: string | null;
                        cost_centre_id: string | null;
                        cost_centre_name: string | null;
                        gl_code: string | null;
                        customer_entity_id: string | null;
                        general_ledger_id: string | null;
                        project_code: string | null;
                        project_id: string | null;
                        parent_delivery_schedule_item?: {
                            delivery_schedule_item_id: string | null;
                            parent_delivery_schedule_item: string | null;
                            quantity: number;
                            requisition_item: {
                                custom_requisition_id: string;
                                requisition_id: string;
                                shipping_address: {
                                    shipping_address_id: string;
                                    shipping_full_address: string;
                                };
                            };
                        } | null;
                    }[];
                };
            };
        };
    };
}

export interface IQouteComparisionStoreAction {
    type: string;
    payload:
        | {
              type: 'UPDATE_PAGINATION_CURRENT_PAGE';
              value: number;
          }
        | {
              type: 'UPDATE_PAGINATION_SEARCH_QUERY';
              value: string;
          }
        | {
              type: 'UPDATE_RFQ_ITEM_NAMES';
              value: {
                  type: 'APPEND_MORE_NAMES';
                  values: {
                      item_id: string;
                      item_name: string;
                      itemNo: number;
                  }[];
              };
          }
        | {
              type: 'UPDATE_RFQ_ITEM_NAMES_IS_LOADING';
              value: boolean;
          }
        | {
              type: 'UPDATE_RFQ_CACHE_DATA';
              value: {
                  [rfq_item_entry_id: string]: IEventItemDetails;
              };
          }
        | {
              type: 'UPDATE_FILTERING_TYPE';
              value: BidItemPageTypes;
          }
        | {
              type: 'UPDATE_SELECTED_ITEMS';
              value: string[];
          }
        | {
              type: 'UPDATE_REQUISITION_IDS_USED';
              value: { requisitionId: string; label: string }[];
          }
        | {
              type: 'UPDATE_SELECTED_REQUISITION';
              value: string;
          }
        | {
              type: 'UPDATE_SELECTED_VENDORS';
              value: string[];
          }
        | {
              type: 'UPDATE_LIST_ALL_VENDOR_FOR_EVENT';
              value: IGetAllEventSeller[];
          }
        | {
              type: 'UPDATE_LIST_OF_ALL_BOM_USED';
              value: {
                  enterprise_bom_id: string;
                  bom_code: string;
              }[];
          }
        | {
              type: 'UDPATE_SELECTED_BOM_FOR_FILTERING';
              value: string | null;
          }
        | {
              type: 'UDPATE_SELECTED_SUB_BOM_FOR_FILTERING';
              value: string | null;
          }
        | { type: 'UDPATE_DS_ALLOCATION_POPUP_OPEN'; value: boolean }
        | { type: 'UDPATE_SINGLE_VENDOR_DS_ALLOCATION_POPUP'; value: boolean }
        | {
              type: 'UPDATE_QUOTE_COMPARISON_TEMPLATE_INFORMATION_TEMPLATE_ID';
              value: string | null;
          }
        | {
              type: 'UPDATE_QUOTE_COMPARISON_TEMPLATE_INFORMATION_ENTITY_ID';
              value: string | null;
          }
        | {
              type: 'UPDATE_LIST_OF_BOMS_OF_CURRENT_EVENT_WITH_HEIRARY';
              value: IRfqAnalyticsStoreListOfBomStructWIthHeiriacy[];
          }
        | {
              type: 'RESET';
              value: null;
          };
}

export type DsAllocationFIlterTypes = 'ALL_ITEMS' | 'NOT_ALLOCATED_ITEMS';

interface IDSAllocationPopupStoreAction {
    type: string;
    payload:
        | {
              type: 'UPDATE_PAGE_NO';
              value: number;
          }
        | {
              type: 'UPDATE_LIST_BIDS_ITEMS_FILTER';
              value: DsAllocationFIlterTypes;
          }
        | {
              type: 'UPDATE_DS_ALLOCATION_POPUP_VENDOR';
              value: string | null;
          }
        | {
              type: 'UPDATE_DS_ALLOCATION_POPUP_VENDOR_BID_ID';
              value: string | null;
          }
        | {
              type: 'UPDATE_DS_ALLOCATION_POPUP_LIST_OF_BID_ITEM';
              valuePayload:
                  | {
                        type: 'APPEND';
                        value: IGetBuyerRFQBidItemDetails[];
                    }
                  | {
                        type: 'UPDATE';
                        value: IGetBuyerRFQBidItemDetails[];
                    };
          }
        | {
              type: 'UPDATE_DS_ALLOCATION_DATA_FOR_ALL_RFQ_BID';
              value: {
                  [rfqBidUid: string]: {
                      [rfqBidItemid: string]: {
                          updated: boolean;
                          dsData: {
                              delivery_schedule_item_id: string | null;
                              bom_item: IBomItemDeliverScheduleStructure | null;
                              quantity: string;
                              delivery_date: string | null;
                              cost_centre_id: string | null;
                              cost_centre_name: string | null;
                              gl_code: string | null;
                              customer_entity_id: string | null;
                              general_ledger_id: string | null;
                              project_code: string | null;
                              project_id: string | null;
                          }[];
                      };
                  };
              };
          }
        | {
              type: 'UPDATE_DS_ALLOCATION_WHOLE_RFQ_BID';
              value: {
                  rfqbidId: string;
                  wholeBidData: {
                      [rfqBidItemid: string]: {
                          updated: boolean;
                          dsData: {
                              delivery_schedule_item_id: string | null;
                              bom_item: IBomItemDeliverScheduleStructure | null;
                              quantity: string;
                              delivery_date: string | null;
                              cost_centre_id: string | null;
                              cost_centre_name: string | null;
                              gl_code: string | null;
                              customer_entity_id: string | null;
                              general_ledger_id: string | null;
                              project_code: string | null;
                              project_id: string | null;
                          }[];
                      };
                  };
              };
          }
        | {
              type: 'UPDATE_DS_ALLOCATION_SINGLE_RFQ_BID_ITEM';
              value: {
                  rfqbidId: string;
                  rfqBidItemid: string;
                  wholeDsData: {
                      updated: boolean;
                      dsData: {
                          delivery_schedule_item_id: string | null;
                          bom_item: IBomItemDeliverScheduleStructure | null;
                          quantity: string;
                          delivery_date: string | null;
                          cost_centre_id: string | null;
                          cost_centre_name: string | null;
                          gl_code: string | null;
                          customer_entity_id: string | null;
                          general_ledger_id: string | null;
                          project_code: string | null;
                          project_id: string | null;
                      }[];
                  };
              };
          };
}

const initialState: IQouteComparisionStore = {
    paginationInformation: {
        currentPage: 1,
        searchQuery: '',
    },
    requisitionIds: [],
    listOfBomsOfCurrentEventsWithSubBomOptions: [],
    selectedItems: [],
    filteringType: BidItemPageTypes.ALL_ITEMS,
    listOfRfqItemNameIsLoading: true,
    listOfRfqItemName: [],
    selectedVendors: [],
    importAndExportInformation: {},
    cachingRfqData: {},
    allRFqVendor: [],
    listOfAllBomUse: [],
    selectedRequisitionForFilterting: 'null',
    selectedBomForFiltering: null,
    selectedSubBomForFiltering: null,
    isDsAllocationPopupOpen: false,
    isSingleVendorDsAllocationPopup: false,
    DSAllocationPopupData: {
        itemsFilteringType: 'ALL_ITEMS',
        pageNo: 1,
        listofBidItems: [],
        selectedVendor: null,
        rfqBidIdSelected: null,
        allocationPopupData: {},
    },
    templateInformation: {
        entityId: null,
        templateId: null,
    },
};

export const quoteComparisonSlice = createSlice<
    IQouteComparisionStore,
    {
        updateQuoteComparisonStore: (
            state: IQouteComparisionStore,
            action: IQouteComparisionStoreAction
        ) => void;
        updateDSAllocationPopupStore: (
            state: IQouteComparisionStore,
            action: IDSAllocationPopupStoreAction
        ) => void;
    },
    string
>({
    initialState,
    name: 'quoteComparisonStore',
    reducers: {
        updateQuoteComparisonStore: (state, action) => {
            switch (action.payload.type) {
                case 'UPDATE_PAGINATION_CURRENT_PAGE':
                    state.paginationInformation.currentPage =
                        action.payload.value;
                    break;

                case 'UPDATE_PAGINATION_SEARCH_QUERY':
                    state.paginationInformation.searchQuery =
                        action.payload.value;
                    break;

                case 'UPDATE_RFQ_ITEM_NAMES':
                    if (action.payload.value.type === 'APPEND_MORE_NAMES') {
                        state.listOfRfqItemName = [
                            ...state.listOfRfqItemName,
                            ...action.payload.value.values,
                        ];
                    }
                    break;

                case 'UPDATE_RFQ_ITEM_NAMES_IS_LOADING':
                    state.listOfRfqItemNameIsLoading = action.payload.value;
                    break;

                case 'UPDATE_RFQ_CACHE_DATA':
                    state.cachingRfqData = {
                        ...state.cachingRfqData,
                        ...action.payload.value,
                    };
                    break;

                case 'UPDATE_FILTERING_TYPE':
                    state.filteringType = action.payload.value;
                    break;

                case 'UPDATE_SELECTED_ITEMS':
                    state.selectedItems = action.payload.value;
                    break;
                case 'UPDATE_SELECTED_VENDORS':
                    state.selectedVendors = action.payload.value;
                    break;

                case 'UPDATE_LIST_ALL_VENDOR_FOR_EVENT':
                    state.allRFqVendor = action.payload.value;
                    break;

                case 'UPDATE_LIST_OF_ALL_BOM_USED':
                    state.listOfAllBomUse = [
                        ...state.listOfAllBomUse,
                        ...action.payload.value.filter(
                            (newBom) =>
                                state.listOfAllBomUse.find(
                                    (oldBom) =>
                                        oldBom.enterprise_bom_id ===
                                        newBom.enterprise_bom_id
                                ) === undefined
                        ),
                    ];
                    break;
                case 'UDPATE_SELECTED_BOM_FOR_FILTERING':
                    state.selectedBomForFiltering =
                        action.payload.value !== 'null'
                            ? action.payload.value
                            : null;
                    break;
                case 'UDPATE_SELECTED_SUB_BOM_FOR_FILTERING':
                    state.selectedSubBomForFiltering =
                        action.payload.value !== 'null'
                            ? action.payload.value
                            : null;
                    break;
                case 'UPDATE_LIST_OF_BOMS_OF_CURRENT_EVENT_WITH_HEIRARY':
                    state.listOfBomsOfCurrentEventsWithSubBomOptions =
                        action.payload.value;
                    break;
                case 'UPDATE_QUOTE_COMPARISON_TEMPLATE_INFORMATION_ENTITY_ID':
                    state.templateInformation.entityId = action.payload.value;
                    break;
                case 'UPDATE_QUOTE_COMPARISON_TEMPLATE_INFORMATION_TEMPLATE_ID':
                    state.templateInformation.templateId = action.payload.value;
                    break;

                case 'UDPATE_DS_ALLOCATION_POPUP_OPEN':
                    state.isDsAllocationPopupOpen = action.payload.value;
                    break;
                case 'UDPATE_SINGLE_VENDOR_DS_ALLOCATION_POPUP':
                    state.isSingleVendorDsAllocationPopup =
                        action.payload.value;
                    break;
                case 'UPDATE_REQUISITION_IDS_USED':
                    state.requisitionIds = action.payload.value;
                    break;
                case 'UPDATE_SELECTED_REQUISITION':
                    state.selectedRequisitionForFilterting =
                        action.payload.value;
                    break;
                case 'RESET':
                    state.paginationInformation.currentPage = 1;
                    state.paginationInformation.searchQuery = '';
                    state.listOfRfqItemNameIsLoading = true;
                    state.listOfRfqItemName = [];
                    state.importAndExportInformation = {};
                    state.cachingRfqData = {};
                    state.listOfBomsOfCurrentEventsWithSubBomOptions = [];
                    state.filteringType = BidItemPageTypes.ALL_ITEMS;
                    state.selectedItems = [];
                    state.allRFqVendor = [];
                    state.listOfAllBomUse = [];
                    state.selectedBomForFiltering = null;
                    state.selectedSubBomForFiltering = null;
                    state.isDsAllocationPopupOpen = false;
                    state.isSingleVendorDsAllocationPopup = false;
                    state.DSAllocationPopupData.listofBidItems = [];
                    state.DSAllocationPopupData.selectedVendor = null;
                    state.templateInformation.entityId = null;
                    state.templateInformation.templateId = null;
                    break;

                default:
                    break;
            }
        },
        updateDSAllocationPopupStore: (state, action) => {
            switch (action.payload.type) {
                case 'UPDATE_PAGE_NO':
                    state.DSAllocationPopupData.pageNo = action.payload.value;
                    break;
                case 'UPDATE_LIST_BIDS_ITEMS_FILTER':
                    state.DSAllocationPopupData.itemsFilteringType =
                        action.payload.value;
                    break;
                case 'UPDATE_DS_ALLOCATION_POPUP_VENDOR':
                    state.DSAllocationPopupData.selectedVendor =
                        action.payload.value;
                    break;

                case 'UPDATE_DS_ALLOCATION_POPUP_VENDOR_BID_ID':
                    state.DSAllocationPopupData.rfqBidIdSelected =
                        action.payload.value;
                    break;

                case 'UPDATE_DS_ALLOCATION_POPUP_LIST_OF_BID_ITEM':
                    switch (action.payload.valuePayload.type) {
                        case 'APPEND':
                            state.DSAllocationPopupData.listofBidItems = [
                                ...state.DSAllocationPopupData.listofBidItems,
                                ...action.payload.valuePayload.value,
                            ];
                            break;
                        case 'UPDATE':
                            if (
                                action.payload.valuePayload.value.length === 0
                            ) {
                                state.DSAllocationPopupData.listofBidItems = [];
                            } else {
                                state.DSAllocationPopupData.listofBidItems =
                                    action.payload.valuePayload.value;
                            }

                            break;

                        default:
                            break;
                    }
                    break;

                case 'UPDATE_DS_ALLOCATION_WHOLE_RFQ_BID':
                    state.DSAllocationPopupData.allocationPopupData[
                        action.payload.value.rfqbidId
                    ] = {
                        ...(state.DSAllocationPopupData.allocationPopupData[
                            action.payload.value.rfqbidId
                        ]
                            ? state.DSAllocationPopupData.allocationPopupData[
                                  action.payload.value.rfqbidId
                              ]
                            : {}),
                        ...action.payload.value.wholeBidData,
                    };
                    break;
                case 'UPDATE_DS_ALLOCATION_DATA_FOR_ALL_RFQ_BID':
                    state.DSAllocationPopupData.allocationPopupData =
                        action.payload.value;

                    break;
                case 'UPDATE_DS_ALLOCATION_SINGLE_RFQ_BID_ITEM':
                    state.DSAllocationPopupData.allocationPopupData[
                        action.payload.value.rfqbidId
                    ] = {
                        ...(state.DSAllocationPopupData.allocationPopupData[
                            action.payload.value.rfqbidId
                        ]
                            ? state.DSAllocationPopupData.allocationPopupData[
                                  action.payload.value.rfqbidId
                              ]
                            : {}),

                        [action.payload.value.rfqBidItemid]:
                            action.payload.value.wholeDsData,
                    };
                    break;

                default:
                    break;
            }
        },
    },
});

export const { updateQuoteComparisonStore, updateDSAllocationPopupStore } =
    quoteComparisonSlice.actions;

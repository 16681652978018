import {
    Box,
    DialogActions,
    DialogContent,
    Divider,
    Grid,
    IconButton,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import React, {
    CSSProperties,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FWCurrencyTypoGraphyWithCurrencyUid,
    roundToNDecimalPlace,
} from '../../Common/CurrencyUtilsComponent';
import { FWButton } from '../../Common/FWButton';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { FWLink } from '../../Common/FWLink';
import { FWPopupWithChildren } from '../../Common/FWPopupWithChildren';
import { FWTypography } from '../../Common/FWTypograhy';
import { FWNumberFormatter } from '../../Common/Utils/CurrencyUtils';
import {
    calculateTotalAdditionalCostAndDeviation,
    calculateTotalAdditionalCostAndDeviationEventLevel,
} from '../helpers';
import {
    AllocationTypeEnum,
    CostTypeEnum,
    IAdditionalCostWithIsRequired,
    IAdditionalCostsWithValue,
} from '../models/AdditionalCost.model';
import AdditionalCostDiscount from './AdditionalCostDiscount';
import AdditionalCostListPopUp from './AdditionalCostListPopUp';
import AdditionalCostTaxListPopUp from './AdditionalCostTaxListPopUp';
import AdditionalDiscountWithThreshold from './AdditionalCostDiscountWithThreshold';
import { getAdditionalCostDataByID } from '../Services/additionalCosts.services';
// import { calculatePercentageChange } from '../../Global/Helpers/percentageCalculator';

export type AdditionalCostPopUpProps =
    | {
          type: 'Taxes';
          additionalData?: { bomCode: string; bomName: string };
          additionalCost: IAdditionalCostsWithValue[];
          updateHandler: (
              newAdditionalCost: IAdditionalCostsWithValue[]
          ) => void;
          disountAdditionalCost: IAdditionalCostsWithValue[];
          baseRate: number | string;
          baseQuantity: number | string;
          currency_code_abbreviation: string;
          currency_code_symbol: string;
          measurementUnitName: string;
          additionalCostTemplateOptions: IAdditionalCostWithIsRequired[];
          showTextOrPencilIcon: 'TEXT' | 'PENCIL';
          viewMode?: boolean;
          portal: 'BUYER' | 'SELLER';
          module?: 'QUOTE_CALCULATOR';
          sx?: CSSProperties;
          conversionRate?: number;
          conversionPrecision?: number;
          rfqCurrencyAbbreviation?: string;
          displayIconNextToRate?: boolean;
          iconToDisplayNextToRate?: React.ReactNode;
          isEventLevel?: boolean;
          isBOMLevel?: boolean;
          isAgreedCost?: boolean;
          eventSubtotal?: number | string;
          eventTotal?: number | string;
          autoFillEventLevelAdditionalCosts?: boolean;
          showThreshold?: boolean;
      }
    | {
          type: 'AdditionalCost';
          additionalData?: { bomCode: string; bomName: string };
          additionalCost: IAdditionalCostsWithValue[];
          updateHandler: (
              newAdditionalCost: IAdditionalCostsWithValue[]
          ) => void;
          baseRate: number | string;
          baseQuantity: number | string;
          currency_code_abbreviation: string;
          currency_code_symbol: string;
          measurementUnitName: string;
          additionalCostTemplateOptions: IAdditionalCostWithIsRequired[];
          showTextOrPencilIcon: 'TEXT' | 'PENCIL';
          viewMode?: boolean;
          portal: 'BUYER' | 'SELLER';
          module?: 'QUOTE_CALCULATOR';
          sx?: CSSProperties;
          conversionRate?: number;
          conversionPrecision?: number;
          rfqCurrencyAbbreviation?: string;
          displayIconNextToRate?: boolean;
          iconToDisplayNextToRate?: React.ReactNode;
          isEventLevel?: boolean;
          isBOMLevel?: boolean;
          isAgreedCost?: boolean;
          eventSubtotal?: number | string;
          eventTotal?: number | string;
          autoFillEventLevelAdditionalCosts?: boolean;
          showThreshold?: boolean;
      }
    | {
          type: 'Discount';
          additionalData?: { bomCode: string; bomName: string };
          DiscountMode: 'EDIT' | 'VIEW';
          additionalCost: IAdditionalCostsWithValue[];
          updateHandler: (
              newAdditionalCost: IAdditionalCostsWithValue[]
          ) => void;
          baseRate: number | string;
          baseQuantity: number | string;
          currency_code_abbreviation: string;
          currency_code_symbol: string;
          measurementUnitName: string;
          additionalCostTemplateOptions: IAdditionalCostWithIsRequired[];
          showTextOrPencilIcon: 'TEXT' | 'PENCIL';
          viewMode?: boolean;
          portal: 'BUYER' | 'SELLER';
          module?: 'QUOTE_CALCULATOR';
          sx?: CSSProperties;
          conversionRate?: number;
          conversionPrecision?: number;
          rfqCurrencyAbbreviation?: string;
          displayIconNextToRate?: boolean;
          iconToDisplayNextToRate?: React.ReactNode;
          disableHelper?: boolean;
          isEventLevel?: boolean;
          isBOMLevel?: boolean;
          isAgreedCost?: boolean;
          eventSubtotal?: number | string;
          eventTotal?: number | string;
          autoFillEventLevelAdditionalCosts?: boolean;
          showThreshold?: boolean;
      };

const AdditionalCostPopUp = (props: AdditionalCostPopUpProps) => {
    const {
        type,
        additionalCost,
        baseRate,
        baseQuantity,
        updateHandler,
        currency_code_abbreviation,
        measurementUnitName,
        currency_code_symbol,
        additionalCostTemplateOptions,
        showTextOrPencilIcon,
        portal,
        module,
        sx,
        viewMode = false,
        conversionRate = 1,
        conversionPrecision = 2,
        rfqCurrencyAbbreviation = 'USD',
        displayIconNextToRate,
        iconToDisplayNextToRate,
        isEventLevel = false,
        isBOMLevel = false,
        isAgreedCost = false,
        eventSubtotal = 0,
        eventTotal = 0,
        autoFillEventLevelAdditionalCosts = false,
        showThreshold = true,
        additionalData,
    } = props;

    const [open, setOpen] = useState(false);

    const [localCopyOfAdditionalCost, setLocalCopyOfAdditionalCost] = useState(
        cloneDeep(additionalCost)
    );
    const availableAdditionalCostTemplateOptions = useMemo(() => {
        return additionalCostTemplateOptions.filter((option) => {
            let names = localCopyOfAdditionalCost?.map((cost) => cost.costName);
            if (names?.includes(option.costName)) return false;
            return true;
        });
    }, [additionalCostTemplateOptions, localCopyOfAdditionalCost]);

    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        if (showThreshold) {
            const hasAnyError = localCopyOfAdditionalCost.some(
                (cost) =>
                    (Boolean(!cost.value && cost.threshold && isEventLevel) ||
                        Boolean(
                            !cost.threshold && cost.value && isEventLevel
                        )) &&
                    isEventLevel &&
                    type !== 'Taxes'
            );

            setHasError(hasAnyError);
        }
    }, [localCopyOfAdditionalCost, isEventLevel, type, showThreshold]);

    // this useffect udpated the discount tvalue template and saves it
    useEffect(() => {
        if (
            additionalCost.length === 0 &&
            additionalCostTemplateOptions.length > 0
        )
            if (type === 'Discount' && !isEventLevel) {
                // some funny thing for discount if additional cost is empty then add discount such that if there is something availbalbe use the values from to update the local copy
                if (additionalCostTemplateOptions[0]) {
                    if (
                        additionalCostTemplateOptions[0].costType ===
                        CostTypeEnum.ABSOLUTE_VALUE
                    ) {
                        updateHandler([
                            {
                                costName:
                                    additionalCostTemplateOptions[0].costName,
                                costType: CostTypeEnum.ABSOLUTE_VALUE,
                                allocationType:
                                    additionalCostTemplateOptions[0]
                                        .allocationType,
                                value: '',
                                additional_cost_id: null,
                                cost_source: null,
                            },
                        ]);
                    } else {
                        updateHandler([
                            {
                                costName:
                                    additionalCostTemplateOptions[0].costName,
                                costType: CostTypeEnum.PERCENTAGE,
                                allocationType: null,
                                value: '',
                                additional_cost_id: null,
                                cost_source: null,
                            },
                        ]);
                    }
                } else {
                    updateHandler([
                        {
                            costName: '',
                            costType: CostTypeEnum.PERCENTAGE,
                            allocationType: null,
                            value: '',
                            additional_cost_id: null,
                            cost_source: null,
                        },
                    ]);
                }
            }
    }, [
        additionalCost.length,
        additionalCostTemplateOptions,
        isEventLevel,
        type,
        updateHandler,
    ]);

    // updates localCopyOfAdditionalCost to an empty additioncal cost depending on value
    useEffect(() => {
        if (!open)
            if (additionalCost.length === 0) {
                if (type === 'Discount') {
                    // some funny thing for discount if additional cost is empty then add discount such that if there is something availbalbe use the values from to update the local copy
                    if (additionalCostTemplateOptions[0]) {
                        if (
                            additionalCostTemplateOptions[0].costType ===
                            CostTypeEnum.ABSOLUTE_VALUE
                        ) {
                            setLocalCopyOfAdditionalCost([
                                {
                                    costName:
                                        additionalCostTemplateOptions[0]
                                            .costName,
                                    costType: CostTypeEnum.ABSOLUTE_VALUE,
                                    allocationType:
                                        additionalCostTemplateOptions[0]
                                            .allocationType,
                                    value: '',
                                    additional_cost_id: null,
                                    cost_source: null,
                                },
                            ]);
                        } else {
                            setLocalCopyOfAdditionalCost([
                                {
                                    costName:
                                        additionalCostTemplateOptions[0]
                                            .costName,
                                    costType: CostTypeEnum.PERCENTAGE,
                                    allocationType: null,
                                    value: '',
                                    additional_cost_id: null,
                                    cost_source: null,
                                },
                            ]);
                        }
                    } else {
                        setLocalCopyOfAdditionalCost([
                            {
                                costName: '',
                                costType: CostTypeEnum.PERCENTAGE,
                                allocationType: null,
                                additional_cost_id: null,
                                cost_source: null,
                                value: '',
                            },
                        ]);
                    }
                } else {
                    const listOfAdditionalCost =
                        additionalCostTemplateOptions.filter(
                            (costOption) => costOption.is_required
                        );
                    if (listOfAdditionalCost.length > 0) {
                        setLocalCopyOfAdditionalCost(
                            listOfAdditionalCost.map((costOption) => {
                                if (
                                    costOption.costType ===
                                    CostTypeEnum.ABSOLUTE_VALUE
                                ) {
                                    return {
                                        costName: costOption.costName,
                                        costType: CostTypeEnum.ABSOLUTE_VALUE,
                                        additional_cost_id: Boolean(
                                            costOption.additional_cost_id
                                        )
                                            ? costOption.additional_cost_id
                                            : null,
                                        cost_source: null,
                                        allocationType:
                                            costOption.allocationType ??
                                            AllocationTypeEnum.OVERALL_QUANTITY,
                                        value: '',
                                    };
                                } else {
                                    return {
                                        costName: costOption.costName,
                                        costType: CostTypeEnum.PERCENTAGE,
                                        allocationType: null,
                                        value: '',
                                        cost_source: null,
                                        additional_cost_id: Boolean(
                                            costOption.additional_cost_id
                                        )
                                            ? costOption.additional_cost_id
                                            : null,
                                    };
                                }
                            })
                        );
                    } else {
                        // tax and additional filling an blank row
                        setLocalCopyOfAdditionalCost([
                            {
                                costName: '',
                                costType: CostTypeEnum.PERCENTAGE,
                                allocationType: null,
                                additional_cost_id: null,
                                value: '',
                                cost_source: null,
                            },
                        ]);
                    }
                }
            } else {
                setLocalCopyOfAdditionalCost(cloneDeep(additionalCost));
            }
    }, [type, additionalCost, open, additionalCostTemplateOptions]);

    const updateSingleAdditionalCost = useCallback(
        (costValue: string, idx: number) => {
            setLocalCopyOfAdditionalCost((prev) => {
                let newAdditionalCost = cloneDeep(prev);
                newAdditionalCost[idx].value = costValue;
                if (isBOMLevel) {
                    newAdditionalCost[idx].cost_per_unit =
                        newAdditionalCost[idx].costType ===
                        CostTypeEnum.ABSOLUTE_VALUE
                            ? newAdditionalCost[idx].allocationType ===
                              AllocationTypeEnum.OVERALL_QUANTITY
                                ? +costValue / +baseQuantity
                                : costValue
                            : (+costValue / 100) * +baseRate;
                    newAdditionalCost[idx].cost_total = roundToNDecimalPlace(
                        newAdditionalCost[idx].costType ===
                            CostTypeEnum.ABSOLUTE_VALUE
                            ? newAdditionalCost[idx].allocationType ===
                              AllocationTypeEnum.OVERALL_QUANTITY
                                ? +costValue
                                : +costValue * +baseQuantity
                            : (+costValue / 100) * +baseRate * +baseQuantity,
                        10
                    );

                    if (!newAdditionalCost[idx].is_agreed_cost_manual) {
                        newAdditionalCost[idx].agreed_cost_value =
                            newAdditionalCost[idx].value;
                        newAdditionalCost[idx].agreed_cost_per_unit =
                            newAdditionalCost[idx].cost_per_unit;
                        newAdditionalCost[idx].agreed_cost_total =
                            newAdditionalCost[idx].cost_total;
                    }
                }
                return newAdditionalCost;
            });
        },
        [baseQuantity, baseRate, isBOMLevel]
    );
    const updateSingleAdditionalCostThresholdValue = useCallback(
        (thresholdValue: string, idx: number) => {
            setLocalCopyOfAdditionalCost((prev) => {
                let newAdditionalCost = cloneDeep(prev);
                newAdditionalCost[idx].threshold = thresholdValue;
                return newAdditionalCost;
            });
        },
        []
    );
    const updateSingleAdditionalCostThresholdAppliedOn = useCallback(
        (thresholdAppliedOn: 'TOTAL' | 'SUBTOTAL', idx: number) => {
            setLocalCopyOfAdditionalCost((prev) => {
                let newAdditionalCost = cloneDeep(prev);
                newAdditionalCost[idx].threshold_applied_on =
                    thresholdAppliedOn;
                return newAdditionalCost;
            });
        },
        []
    );

    const updateSingleAdditionalCostPerUnit = useCallback(
        (value: string, idx: number) => {
            setLocalCopyOfAdditionalCost((prev) => {
                let newAdditionalCost = cloneDeep(prev);
                newAdditionalCost[idx].cost_per_unit = value;
                newAdditionalCost[idx].value = roundToNDecimalPlace(
                    newAdditionalCost[idx].costType ===
                        CostTypeEnum.ABSOLUTE_VALUE
                        ? newAdditionalCost[idx].allocationType ===
                          AllocationTypeEnum.OVERALL_QUANTITY
                            ? +value * +baseQuantity
                            : +value
                        : (+value / +baseRate) * 100,
                    10
                );
                newAdditionalCost[idx].cost_total = roundToNDecimalPlace(
                    +value * +baseQuantity,
                    10
                );

                if (!newAdditionalCost[idx].is_agreed_cost_manual) {
                    newAdditionalCost[idx].agreed_cost_value =
                        newAdditionalCost[idx].value;
                    newAdditionalCost[idx].agreed_cost_per_unit =
                        newAdditionalCost[idx].cost_per_unit;
                    newAdditionalCost[idx].agreed_cost_total =
                        newAdditionalCost[idx].cost_total;
                }

                return newAdditionalCost;
            });
        },
        [baseQuantity, baseRate]
    );

    const updateSingleAdditionalCostTotal = useCallback(
        (value: string, idx: number) => {
            setLocalCopyOfAdditionalCost((prev) => {
                let newAdditionalCost = cloneDeep(prev);
                newAdditionalCost[idx].cost_total = value;
                newAdditionalCost[idx].value = roundToNDecimalPlace(
                    newAdditionalCost[idx].costType ===
                        CostTypeEnum.ABSOLUTE_VALUE
                        ? newAdditionalCost[idx].allocationType ===
                          AllocationTypeEnum.OVERALL_QUANTITY
                            ? +value
                            : +value / +baseQuantity
                        : (+value / (+baseQuantity * +baseRate)) * 100,
                    10
                );
                newAdditionalCost[idx].cost_per_unit = roundToNDecimalPlace(
                    +value / +baseQuantity,
                    10
                );

                if (!newAdditionalCost[idx].is_agreed_cost_manual) {
                    newAdditionalCost[idx].agreed_cost_value =
                        newAdditionalCost[idx].value;
                    newAdditionalCost[idx].agreed_cost_per_unit =
                        newAdditionalCost[idx].cost_per_unit;
                    newAdditionalCost[idx].agreed_cost_total =
                        newAdditionalCost[idx].cost_total;
                }

                return newAdditionalCost;
            });
        },
        [baseQuantity, baseRate]
    );

    const updateSingleAdditionalCostAgreedValue = useCallback(
        (value: string, idx: number) => {
            setLocalCopyOfAdditionalCost((prev) => {
                let newAdditionalCost = cloneDeep(prev);
                newAdditionalCost[idx].agreed_cost_value = value;
                newAdditionalCost[idx].agreed_cost_per_unit =
                    roundToNDecimalPlace(
                        newAdditionalCost[idx].costType ===
                            CostTypeEnum.ABSOLUTE_VALUE
                            ? newAdditionalCost[idx].allocationType ===
                              AllocationTypeEnum.OVERALL_QUANTITY
                                ? +value / +baseQuantity
                                : +value
                            : (+value / 100) * +baseRate,
                        10
                    );
                newAdditionalCost[idx].agreed_cost_total = roundToNDecimalPlace(
                    newAdditionalCost[idx].costType ===
                        CostTypeEnum.ABSOLUTE_VALUE
                        ? newAdditionalCost[idx].allocationType ===
                          AllocationTypeEnum.OVERALL_QUANTITY
                            ? +value
                            : +value * +baseQuantity
                        : (+value / 100) * +baseRate * +baseQuantity,
                    10
                );
                newAdditionalCost[idx].is_agreed_cost_manual = true;
                return newAdditionalCost;
            });
        },
        [baseQuantity, baseRate]
    );

    const updateSingleAdditionalCostAgreedCostPerUnit = useCallback(
        (value: string, idx: number) => {
            setLocalCopyOfAdditionalCost((prev) => {
                let newAdditionalCost = cloneDeep(prev);
                newAdditionalCost[idx].agreed_cost_value = roundToNDecimalPlace(
                    newAdditionalCost[idx].costType ===
                        CostTypeEnum.ABSOLUTE_VALUE
                        ? newAdditionalCost[idx].allocationType ===
                          AllocationTypeEnum.OVERALL_QUANTITY
                            ? +value * +baseQuantity
                            : +value
                        : (+value / +baseRate) * 100,
                    10
                );
                newAdditionalCost[idx].agreed_cost_per_unit = value;
                newAdditionalCost[idx].agreed_cost_total = roundToNDecimalPlace(
                    +value * +baseQuantity,
                    10
                );
                newAdditionalCost[idx].is_agreed_cost_manual = true;
                return newAdditionalCost;
            });
        },
        [baseQuantity, baseRate]
    );

    const updateSingleAdditionalCostAgreedTotal = useCallback(
        (value: string, idx: number) => {
            setLocalCopyOfAdditionalCost((prev) => {
                let newAdditionalCost = cloneDeep(prev);
                newAdditionalCost[idx].agreed_cost_total = value;
                newAdditionalCost[idx].agreed_cost_value = roundToNDecimalPlace(
                    newAdditionalCost[idx].costType ===
                        CostTypeEnum.ABSOLUTE_VALUE
                        ? newAdditionalCost[idx].allocationType ===
                          AllocationTypeEnum.OVERALL_QUANTITY
                            ? +value
                            : +value / +baseQuantity
                        : (+value / (+baseQuantity * +baseRate)) * 100,
                    10
                );
                newAdditionalCost[idx].agreed_cost_per_unit =
                    roundToNDecimalPlace(+value / +baseQuantity, 10);
                newAdditionalCost[idx].is_agreed_cost_manual = true;
                return newAdditionalCost;
            });
        },
        [baseQuantity, baseRate]
    );

    const updateSingleAdditionalCostDiscount = useCallback(
        (costValue: string, idx: number) => {
            setLocalCopyOfAdditionalCost((prev) => {
                let newAdditionalCost = cloneDeep(prev);

                newAdditionalCost[idx].value = costValue;

                updateHandler(newAdditionalCost);

                return newAdditionalCost;
            });
        },
        [updateHandler]
    );

    const addNewAdditionalCost = useCallback(async () => {
        const newAdditionalCostlist = cloneDeep(localCopyOfAdditionalCost);
        if (availableAdditionalCostTemplateOptions.length === 1) {
            // const newAdditionalCostList = cloneDeep(prev);
            const foundAdditionalCost =
                availableAdditionalCostTemplateOptions[0];

            const additionalCostId =
                foundAdditionalCost.additional_cost_id ?? '';

            if (
                isEventLevel &&
                portal === 'BUYER' &&
                autoFillEventLevelAdditionalCosts &&
                props.type === 'AdditionalCost'
            ) {
                let res = await getAdditionalCostDataByID([additionalCostId]);
                if (res && res[0]) {
                    newAdditionalCostlist.push({
                        ...availableAdditionalCostTemplateOptions[0],
                        value: res[0].cost_value ?? '',
                    });

                    setLocalCopyOfAdditionalCost(newAdditionalCostlist);
                } else {
                    newAdditionalCostlist.push({
                        ...availableAdditionalCostTemplateOptions[0],
                        value: '',
                    });
                    setLocalCopyOfAdditionalCost(newAdditionalCostlist);
                }
            } else {
                newAdditionalCostlist.push({
                    ...availableAdditionalCostTemplateOptions[0],
                    value: '',
                });
                setLocalCopyOfAdditionalCost(newAdditionalCostlist);
            }

            // return newAdditionalCostlist;
        } else {
            newAdditionalCostlist.push({
                costName: '',
                costType: CostTypeEnum.PERCENTAGE,
                allocationType: null,
                additional_cost_id: null,
                cost_source: null,
                value: '',
            });
            setLocalCopyOfAdditionalCost(newAdditionalCostlist);
        }
    }, [
        autoFillEventLevelAdditionalCosts,
        availableAdditionalCostTemplateOptions,
        isEventLevel,
        localCopyOfAdditionalCost,
        portal,
        props.type,
    ]);

    const removeSingleAdditionalCost = useCallback((idx: number) => {
        setLocalCopyOfAdditionalCost((prev) => {
            let newAdditionalCost = cloneDeep(prev);

            newAdditionalCost.splice(idx, 1);

            if (newAdditionalCost.length === 0) {
                newAdditionalCost.push({
                    costName: '',
                    costType: CostTypeEnum.PERCENTAGE,
                    cost_source: null,
                    allocationType: null,
                    additional_cost_id: null,
                    value: '',
                });
            }

            return newAdditionalCost;
        });
    }, []);

    const totalAdditionalCostDiscountRateDeviation = useMemo(() => {
        if (props.type === 'Taxes') {
            if (isEventLevel) {
                return (
                    props.disountAdditionalCost?.reduce(
                        (acc, curr) => {
                            const {
                                additionalCostValue,
                                effectiveRateDeviation,
                            } = calculateTotalAdditionalCostAndDeviationEventLevel(
                                {
                                    additionalCost: curr,
                                    eventSubTotal: eventSubtotal,
                                    eventTotal: eventTotal,
                                    type: props.type,
                                }
                            );
                            return {
                                additionalCostValue:
                                    acc.additionalCostValue +
                                    additionalCostValue,
                                effectiveRateDeviation:
                                    acc.effectiveRateDeviation +
                                    effectiveRateDeviation,
                            };
                        },
                        {
                            additionalCostValue: 0,
                            effectiveRateDeviation: 0,
                        } as {
                            additionalCostValue: number;
                            effectiveRateDeviation: number;
                        }
                    ).effectiveRateDeviation || 0
                );
            } else {
                return (
                    props.disountAdditionalCost?.reduce(
                        (acc, curr) => {
                            const {
                                additionalCostValue,
                                effectiveRateDeviation,
                            } = calculateTotalAdditionalCostAndDeviation({
                                additionalCost: curr,
                                baseRate: baseRate,
                                baseQuantity: baseQuantity,
                            });

                            return {
                                additionalCostValue:
                                    acc.additionalCostValue +
                                    additionalCostValue,
                                effectiveRateDeviation:
                                    acc.effectiveRateDeviation +
                                    effectiveRateDeviation,
                            };
                        },
                        {
                            additionalCostValue: 0,
                            effectiveRateDeviation: 0,
                        } as {
                            additionalCostValue: number;
                            effectiveRateDeviation: number;
                        }
                    ).effectiveRateDeviation || 0
                );
            }
        }
        return 0;
    }, [
        baseQuantity,
        baseRate,
        eventSubtotal,
        eventTotal,
        isEventLevel,
        props,
    ]);

    const totalAdditionalCost = useMemo(() => {
        if (isEventLevel) {
            return localCopyOfAdditionalCost?.reduce(
                (acc, curr) => {
                    const { additionalCostValue, effectiveRateDeviation } =
                        calculateTotalAdditionalCostAndDeviationEventLevel({
                            additionalCost: curr,
                            eventSubTotal: eventSubtotal,
                            eventTotal: eventTotal,
                            type: props.type,
                        });
                    return {
                        additionalCostValue:
                            acc.additionalCostValue + additionalCostValue,
                        effectiveRateDeviation:
                            acc.effectiveRateDeviation + effectiveRateDeviation,
                    };
                },
                { additionalCostValue: 0, effectiveRateDeviation: 0 }
            );
        } else
            return localCopyOfAdditionalCost?.reduce(
                (acc, curr) => {
                    const { additionalCostValue, effectiveRateDeviation } =
                        calculateTotalAdditionalCostAndDeviation({
                            additionalCost: curr,
                            baseRate:
                                +baseRate -
                                (props.type === 'Taxes'
                                    ? totalAdditionalCostDiscountRateDeviation
                                    : 0),
                            baseQuantity: baseQuantity,
                        });

                    return {
                        additionalCostValue:
                            acc.additionalCostValue + additionalCostValue,
                        effectiveRateDeviation:
                            acc.effectiveRateDeviation + effectiveRateDeviation,
                    };
                },
                { additionalCostValue: 0, effectiveRateDeviation: 0 }
            );
    }, [
        baseQuantity,
        baseRate,
        eventSubtotal,
        eventTotal,
        isEventLevel,
        localCopyOfAdditionalCost,
        props.type,
        totalAdditionalCostDiscountRateDeviation,
    ]);

    const totalAgreedAdditionalCost = useMemo(() => {
        if (isBOMLevel)
            return localCopyOfAdditionalCost?.reduce(
                (acc, curr) => {
                    const { additionalCostValue, effectiveRateDeviation } =
                        calculateTotalAdditionalCostAndDeviation({
                            additionalCost: {
                                ...curr,
                                value: curr.agreed_cost_value ?? 0,
                            },
                            baseRate:
                                +baseRate -
                                (props.type === 'Taxes'
                                    ? totalAdditionalCostDiscountRateDeviation
                                    : 0),
                            baseQuantity: baseQuantity,
                        });

                    return {
                        additionalCostValue:
                            acc.additionalCostValue + additionalCostValue,
                        effectiveRateDeviation:
                            acc.effectiveRateDeviation + effectiveRateDeviation,
                    };
                },
                { additionalCostValue: 0, effectiveRateDeviation: 0 }
            );
        return { additionalCostValue: 0, effectiveRateDeviation: 0 };
    }, [
        baseQuantity,
        baseRate,
        isBOMLevel,
        localCopyOfAdditionalCost,
        props.type,
        totalAdditionalCostDiscountRateDeviation,
    ]);

    const selectedAdditionalCostTemplateOptions = useCallback(
        (costName: string, idx: number) => {
            setLocalCopyOfAdditionalCost((prev) => {
                const newAdditionalCostList = cloneDeep(prev);
                const foundAdditionalCost = additionalCostTemplateOptions.find(
                    (cost) => cost.costName === costName
                );

                if (
                    foundAdditionalCost &&
                    isEventLevel &&
                    portal === 'BUYER' &&
                    autoFillEventLevelAdditionalCosts &&
                    props.type === 'AdditionalCost'
                ) {
                    const additionalCostId =
                        foundAdditionalCost.additional_cost_id ?? '';

                    getAdditionalCostDataByID([additionalCostId])
                        .then((res) => {
                            if (res && res[0]) {
                                newAdditionalCostList[idx] = {
                                    ...foundAdditionalCost,
                                    value: res[0].cost_value ?? '',
                                    additional_cost_id:
                                        additionalCostId || null,
                                };
                            } else {
                                newAdditionalCostList[idx] = {
                                    ...foundAdditionalCost,
                                    value: '',
                                    additional_cost_id: null,
                                };
                            }
                            setLocalCopyOfAdditionalCost(newAdditionalCostList);
                        })
                        .catch((error) => {
                            console.error(
                                'Error fetching additional cost data:',
                                error
                            );
                            newAdditionalCostList[idx] = {
                                ...foundAdditionalCost,
                                value: '',
                                additional_cost_id: null,
                            };
                            setLocalCopyOfAdditionalCost(newAdditionalCostList);
                        });
                } else if (foundAdditionalCost) {
                    newAdditionalCostList[idx] = {
                        ...foundAdditionalCost,
                        value: '',
                        additional_cost_id:
                            foundAdditionalCost.additional_cost_id,
                    };
                    setLocalCopyOfAdditionalCost(newAdditionalCostList);
                }

                return prev;
            });
        },
        [
            additionalCostTemplateOptions,
            autoFillEventLevelAdditionalCosts,
            isEventLevel,
            portal,
            props.type,
        ]
    );

    const disableAdditionalCostSaveBUtton = useMemo(() => {
        if (portal === 'BUYER') {
            let temp = additionalCostTemplateOptions.filter(
                (obj) => obj.is_required
            );
            let bool = false;
            if (temp.length > 0) {
                for (let i = 0; i < temp.length; i++) {
                    let mandatoryTax = localCopyOfAdditionalCost.find(
                        (local) => local.costName === temp[i].costName
                    );
                    if (
                        mandatoryTax &&
                        (mandatoryTax.value === '' ||
                            mandatoryTax.value === '0')
                    ) {
                        bool = true;
                    } else if (mandatoryTax === undefined) {
                        bool = true;
                    }
                }
            }
            return bool;
            //     // if (
            //     //     localCopyOfAdditionalCost.length <
            //     //     temp.length
            //     // )
            //     //     return true;

            //     // return localCopyOfAdditionalCost.reduce(
            //     //     (acc, localAdditionalCopy) => {
            //     //         let foundAdditionalCostinOptino =
            //     //             additionalCostTemplateOptions?.find(
            //     //                 (opt) =>
            //     //                     opt.costName?.trim() ===
            //     //                     localAdditionalCopy.costName?.trim()
            //     //             );
            //     //         if (foundAdditionalCostinOptino) {
            //     //             if (
            //     //                 typeof localAdditionalCopy.value === 'string' &&
            //     //                 localAdditionalCopy.value.trim() === ''
            //     //             ) {
            //     //                 return acc || true;
            //     //             } else return acc || false;
            //     //         }
            //     //         return acc || false;
            //     //     },
            //     //     false
            //     );
        } else {
            return false;
        }
    }, [additionalCostTemplateOptions, localCopyOfAdditionalCost, portal]);

    // if type is discount render a discount component
    if (type === 'Discount' && !isEventLevel) {
        if (props.DiscountMode === 'EDIT')
            return (
                <AdditionalCostDiscount
                    sx={sx}
                    currency_code_abbreviation={currency_code_abbreviation}
                    currency_code_symbol={currency_code_symbol}
                    localCopyOfAdditionalCost={localCopyOfAdditionalCost}
                    measurementUnitName={measurementUnitName}
                    updateSingleAdditionalCost={
                        updateSingleAdditionalCostDiscount
                    }
                    updateHandler={updateHandler}
                    baseQuantity={baseQuantity}
                    baseRate={baseRate}
                    totalAdditionalCost={totalAdditionalCost}
                    additionalCostTemplateOptions={
                        additionalCostTemplateOptions
                    }
                    disableHelper={props.disableHelper}
                />
            );
        else {
            return null;
        }
    }
    return (
        <>
            {showTextOrPencilIcon === 'TEXT' ? (
                <FWLink
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setOpen(true);
                    }}
                    sx={{
                        paddingY: '0.25rem',
                        color: disableAdditionalCostSaveBUtton
                            ? 'error.main'
                            : '',
                    }}
                >
                    {totalAdditionalCost.effectiveRateDeviation > 0 ? (
                        <div
                            style={{
                                display: 'flex',
                                // In create event overall level if cost value is present it is coming right aligned
                                // justifyContent: 'flex-end',
                            }}
                        >
                            <FWCurrencyTypoGraphyWithCurrencyUid
                                currencyUidOrAbbrevaition={
                                    currency_code_abbreviation
                                }
                                currency_code_abbreviation={
                                    currency_code_abbreviation
                                }
                                value={
                                    totalAdditionalCost.effectiveRateDeviation
                                }
                                measurementUnitName={measurementUnitName}
                            />
                        </div>
                    ) : (
                        'Included in rate'
                    )}
                </FWLink>
            ) : (
                <Box className="flex flex--aic">
                    {(
                        isBOMLevel
                            ? isAgreedCost
                                ? totalAgreedAdditionalCost.additionalCostValue >
                                  0
                                : totalAdditionalCost.additionalCostValue > 0
                            : totalAdditionalCost.effectiveRateDeviation > 0
                    ) ? (
                        <>
                            <FWCurrencyTypoGraphyWithCurrencyUid
                                sx={{ paddingY: '0.25rem' }}
                                currencyUidOrAbbrevaition={
                                    currency_code_abbreviation
                                }
                                currency_code_abbreviation={
                                    currency_code_abbreviation
                                }
                                value={
                                    isBOMLevel
                                        ? isAgreedCost
                                            ? totalAgreedAdditionalCost.additionalCostValue
                                            : totalAdditionalCost.additionalCostValue
                                        : totalAdditionalCost.effectiveRateDeviation
                                }
                                measurementUnitName={
                                    isBOMLevel ? undefined : measurementUnitName
                                }
                                fontWeight={isBOMLevel ? 600 : undefined}
                            />
                            <Box marginBottom={'5px'}>
                                <FWTooltip
                                    title={
                                        // isAgreedCost
                                        //     ? totalAdditionalCost.additionalCostValue <
                                        //       totalAgreedAdditionalCost.additionalCostValue
                                        //         ? `Increased ${calculatePercentageChange(
                                        //               totalAdditionalCost.additionalCostValue,
                                        //               totalAgreedAdditionalCost.additionalCostValue
                                        //           )}`
                                        //         : totalAdditionalCost.additionalCostValue >
                                        //           totalAgreedAdditionalCost.additionalCostValue
                                        //         ? `Decreased ${calculatePercentageChange(
                                        //               totalAdditionalCost.additionalCostValue,
                                        //               totalAgreedAdditionalCost.additionalCostValue
                                        //           )}`
                                        //         : ''
                                        //     : ''
                                        ''
                                    }
                                >
                                    <Box>
                                        {isAgreedCost && (
                                            <Box
                                                component="span"
                                                color={
                                                    totalAdditionalCost.additionalCostValue <
                                                    totalAgreedAdditionalCost.additionalCostValue
                                                        ? 'warning.main'
                                                        : 'success.main'
                                                }
                                                style={{
                                                    fontSize: '0.6rem',
                                                    marginLeft: '5px',
                                                    visibility: 'hidden',
                                                    // totalAdditionalCost.additionalCostValue ===
                                                    // totalAgreedAdditionalCost.additionalCostValue
                                                    //     ? 'hidden'
                                                    //     : 'visible',
                                                }}
                                            >
                                                <i className="bi bi-circle-fill"></i>
                                            </Box>
                                        )}
                                    </Box>
                                </FWTooltip>
                            </Box>
                        </>
                    ) : (
                        <>
                            <FWTypography
                                sx={{
                                    paddingY: '0.25rem',
                                }}
                                color={
                                    additionalCostTemplateOptions.filter(
                                        (option) => option.is_required
                                    ).length === 0
                                        ? ''
                                        : ''
                                }

                                // color={localCopyOfAdditionalCost.length === 0 ? 'red' : ''}
                            >
                                Included in rate
                            </FWTypography>
                            {/* For proper aligning we need the space hence rendered it and kept hidden */}
                            {isAgreedCost && (
                                <Box
                                    component="span"
                                    style={{
                                        fontSize: '0.6rem',
                                        marginLeft: '5px',
                                        visibility: 'hidden',
                                        // position: 'absolute',
                                    }}
                                >
                                    <i className="bi bi-circle-fill"></i>
                                </Box>
                            )}
                        </>
                    )}
                    {
                        <FWTooltip
                            title={
                                type === 'AdditionalCost'
                                    ? 'Edit additional costs'
                                    : type === 'Taxes'
                                    ? 'Edit taxes'
                                    : ''
                            }
                        >
                            <div>
                                <IconButton
                                    style={{
                                        visibility: !viewMode
                                            ? 'visible'
                                            : 'hidden',
                                    }}
                                    // color="primary"
                                    color={
                                        disableAdditionalCostSaveBUtton
                                            ? 'error'
                                            : 'primary'
                                    }
                                    onClick={(e) => {
                                        setOpen(true);
                                        // e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                >
                                    <i className="bi bi-pencil fs--9" />
                                </IconButton>
                            </div>
                        </FWTooltip>
                    }
                </Box>
            )}

            <FWPopupWithChildren
                title={
                    isEventLevel
                        ? type === 'AdditionalCost'
                            ? 'Overall Additional Costs'
                            : type === 'Taxes'
                            ? 'Overall Taxes'
                            : 'Overall Discount'
                        : isBOMLevel
                        ? type === 'AdditionalCost'
                            ? `${
                                  additionalData?.bomName
                                      ? additionalData.bomName
                                      : 'BOM'
                              } Additional Costs`
                            : type === 'Taxes'
                            ? `${
                                  additionalData?.bomName
                                      ? additionalData.bomName
                                      : 'BOM'
                              } Taxes`
                            : `${
                                  additionalData?.bomName
                                      ? additionalData.bomName
                                      : 'BOM'
                              } Discount`
                        : type === 'AdditionalCost'
                        ? 'Additional Costs'
                        : type === 'Taxes'
                        ? 'Taxes'
                        : 'Discount'
                }
                open={open}
                handleClose={() => setOpen(false)}
                size={isEventLevel || isBOMLevel ? 'custom' : 'large'}
                popupWidth={isEventLevel ? '80%' : isBOMLevel ? '85%' : ''}
            >
                <DialogContent onClick={(e) => e.stopPropagation()}>
                    {/* base rate and quantity info here */}
                    {!isEventLevel && (
                        <div className="flex flex--aic">
                            <Grid container gap={1}>
                                {isBOMLevel && (
                                    <Grid item xs={3}>
                                        <FWTypography color="text.secondary">
                                            BOM code
                                        </FWTypography>
                                        <FWTypography>
                                            {additionalData?.bomCode}
                                        </FWTypography>
                                    </Grid>
                                )}
                                <Grid item xs={3}>
                                    <FWTypography color="text.secondary">{`${
                                        isBOMLevel ? 'BOM ' : ''
                                    }Quantity`}</FWTypography>
                                    <FWTypography>
                                        {FWNumberFormatter(+baseQuantity)}
                                        &nbsp;
                                        {measurementUnitName}
                                        (s)
                                    </FWTypography>
                                </Grid>
                                <Grid item xs={3}>
                                    <FWTypography color="text.secondary">
                                        {isBOMLevel
                                            ? 'Per unit BOM cost'
                                            : `Base rate:`}
                                    </FWTypography>
                                    <FWTypography>
                                        <FWCurrencyTypoGraphyWithCurrencyUid
                                            currencyUidOrAbbrevaition={
                                                currency_code_abbreviation
                                            }
                                            currency_code_abbreviation={
                                                currency_code_abbreviation
                                            }
                                            value={baseRate}
                                            measurementUnitName={
                                                measurementUnitName
                                            }
                                        />
                                    </FWTypography>
                                    {conversionRate !== 1 && (
                                        <>
                                            {/* <i
                                            className="bi bi-shuffle"
                                            style={{ width: '14px' }}
                                        ></i> */}
                                            <FWTypography
                                                style={
                                                    {
                                                        // paddingLeft: '10px',
                                                    }
                                                }
                                                color={'orange'}
                                            >
                                                <FWCurrencyTypoGraphyWithCurrencyUid
                                                    currencyUidOrAbbrevaition={
                                                        rfqCurrencyAbbreviation
                                                    }
                                                    currency_code_abbreviation={
                                                        rfqCurrencyAbbreviation
                                                    }
                                                    value={
                                                        Number(baseRate) *
                                                        conversionRate
                                                    }
                                                    measurementUnitName={
                                                        measurementUnitName
                                                    }
                                                />
                                            </FWTypography>
                                        </>
                                    )}
                                </Grid>

                                {/* discount values  */}
                                <Grid item xs={3}>
                                    {props.type === 'Taxes' && (
                                        <>
                                            <FWTypography color="text.secondary">{`Discount:`}</FWTypography>
                                            <FWTypography>
                                                <FWCurrencyTypoGraphyWithCurrencyUid
                                                    currencyUidOrAbbrevaition={
                                                        currency_code_abbreviation
                                                    }
                                                    currency_code_abbreviation={
                                                        currency_code_abbreviation
                                                    }
                                                    value={
                                                        totalAdditionalCostDiscountRateDeviation
                                                    }
                                                    measurementUnitName={
                                                        measurementUnitName
                                                    }
                                                />
                                            </FWTypography>
                                            {conversionRate !== 1 && (
                                                <>
                                                    {/* <i
                                                    className="bi bi-shuffle"
                                                    style={{ width: '14px' }}
                                                ></i> */}
                                                    <FWTypography
                                                        style={
                                                            {
                                                                // paddingLeft: '10px',
                                                            }
                                                        }
                                                        color={'orange'}
                                                    >
                                                        <FWCurrencyTypoGraphyWithCurrencyUid
                                                            currencyUidOrAbbrevaition={
                                                                rfqCurrencyAbbreviation
                                                            }
                                                            currency_code_abbreviation={
                                                                rfqCurrencyAbbreviation
                                                            }
                                                            value={
                                                                Number(
                                                                    totalAdditionalCostDiscountRateDeviation
                                                                ) *
                                                                conversionRate
                                                            }
                                                            measurementUnitName={
                                                                measurementUnitName
                                                            }
                                                        />
                                                    </FWTypography>
                                                </>
                                            )}
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                    )}

                    <br />
                    <Divider />
                    <br />
                    {/* render list of additional cost with at least one row here */}

                    {/* render rows if there is an additional cost available  */}
                    {type === 'AdditionalCost' && (
                        <AdditionalCostListPopUp
                            displayIconNextToRate={displayIconNextToRate}
                            iconToDisplayNextToRate={iconToDisplayNextToRate}
                            localCopyOfAdditionalCost={
                                localCopyOfAdditionalCost
                            }
                            updateSingleAdditionalCost={
                                updateSingleAdditionalCost
                            }
                            updateSingleAdditionalCostThresholdValue={
                                updateSingleAdditionalCostThresholdValue
                            }
                            updateSingleAdditionalCostThresholdAppliedOn={
                                updateSingleAdditionalCostThresholdAppliedOn
                            }
                            updateSingleAdditionalCostPerUnit={
                                updateSingleAdditionalCostPerUnit
                            }
                            updateSingleAdditionalCostTotal={
                                updateSingleAdditionalCostTotal
                            }
                            updateSingleAdditionalCostAgreedValue={
                                updateSingleAdditionalCostAgreedValue
                            }
                            updateSingleAdditionalCostAgreedCostPerUnit={
                                updateSingleAdditionalCostAgreedCostPerUnit
                            }
                            updateSingleAdditionalCostAgreedTotal={
                                updateSingleAdditionalCostAgreedTotal
                            }
                            currency_code_abbreviation={
                                currency_code_abbreviation
                            }
                            currency_code_symbol={currency_code_symbol}
                            measurementUnitName={measurementUnitName}
                            baseQuantity={baseQuantity}
                            baseRate={baseRate}
                            removeSingleAdditionalCost={
                                removeSingleAdditionalCost
                            }
                            availableAdditionalCostTemplateOptions={
                                availableAdditionalCostTemplateOptions
                            }
                            addNewAdditionalCost={addNewAdditionalCost}
                            selectedAdditionalCostTemplateOptions={
                                selectedAdditionalCostTemplateOptions
                            }
                            totalAdditionalCost={totalAdditionalCost}
                            totalAgreedAdditionalCost={
                                totalAgreedAdditionalCost
                            }
                            additionalCostTemplateOptions={
                                additionalCostTemplateOptions
                            }
                            conversionRate={conversionRate}
                            conversionPrecision={conversionPrecision}
                            rfqCurrencyAbbreviation={rfqCurrencyAbbreviation}
                            module={module}
                            isEventLevel={isEventLevel}
                            isBOMLevel={isBOMLevel}
                            isAgreedCost={isAgreedCost}
                            eventSubtotal={eventSubtotal}
                            eventTotal={eventTotal}
                            showThreshold={showThreshold}
                        />
                    )}

                    {type === 'Taxes' && (
                        <AdditionalCostTaxListPopUp
                            localCopyOfAdditionalCost={
                                localCopyOfAdditionalCost
                            }
                            updateSingleAdditionalCost={
                                updateSingleAdditionalCost
                            }
                            currency_code_abbreviation={
                                currency_code_abbreviation
                            }
                            currency_code_symbol={currency_code_symbol}
                            measurementUnitName={measurementUnitName}
                            baseQuantity={baseQuantity}
                            baseRate={
                                +baseRate -
                                totalAdditionalCostDiscountRateDeviation
                            }
                            removeSingleAdditionalCost={
                                removeSingleAdditionalCost
                            }
                            availableAdditionalCostTemplateOptions={
                                availableAdditionalCostTemplateOptions
                            }
                            addNewAdditionalCost={addNewAdditionalCost}
                            selectedAdditionalCostTemplateOptions={
                                selectedAdditionalCostTemplateOptions
                            }
                            totalAdditionalCost={totalAdditionalCost}
                            additionalCostTemplateOptions={
                                additionalCostTemplateOptions
                            }
                            conversionRate={conversionRate}
                            conversionPrecision={conversionPrecision}
                            rfqCurrencyAbbreviation={rfqCurrencyAbbreviation}
                            isEventLevel={isEventLevel}
                            eventSubtotal={+eventSubtotal}
                            eventTotal={+eventTotal}
                        />
                    )}
                    {type === 'Discount' && isEventLevel && (
                        <AdditionalDiscountWithThreshold
                            displayIconNextToRate={displayIconNextToRate}
                            iconToDisplayNextToRate={iconToDisplayNextToRate}
                            localCopyOfAdditionalCost={
                                localCopyOfAdditionalCost
                            }
                            updateSingleAdditionalCost={
                                updateSingleAdditionalCost
                            }
                            updateSingleAdditionalCostThresholdValue={
                                updateSingleAdditionalCostThresholdValue
                            }
                            updateSingleAdditionalCostThresholdAppliedOn={
                                updateSingleAdditionalCostThresholdAppliedOn
                            }
                            currency_code_abbreviation={
                                currency_code_abbreviation
                            }
                            currency_code_symbol={currency_code_symbol}
                            measurementUnitName={measurementUnitName}
                            // baseQuantity={baseQuantity}
                            // baseRate={baseRate}
                            removeSingleAdditionalCost={
                                removeSingleAdditionalCost
                            }
                            availableAdditionalCostTemplateOptions={
                                availableAdditionalCostTemplateOptions
                            }
                            addNewAdditionalCost={addNewAdditionalCost}
                            selectedAdditionalCostTemplateOptions={
                                selectedAdditionalCostTemplateOptions
                            }
                            totalAdditionalCost={totalAdditionalCost}
                            additionalCostTemplateOptions={
                                additionalCostTemplateOptions
                            }
                            conversionRate={conversionRate}
                            conversionPrecision={conversionPrecision}
                            rfqCurrencyAbbreviation={rfqCurrencyAbbreviation}
                            isEventLevel={isEventLevel}
                            eventSubtotal={eventSubtotal}
                            eventTotal={eventTotal}
                        />
                    )}
                </DialogContent>
                <DialogActions
                    sx={{ m: '12px 24px 24px 24px' }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <FWButton
                        variant="outlined"
                        color="error"
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpen(false);
                        }}
                    >
                        Cancel
                    </FWButton>
                    <FWButton
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                            e.stopPropagation();

                            let res = localCopyOfAdditionalCost.map((ac) => {
                                if (ac.additional_cost_id === undefined) {
                                    return {
                                        ...ac,
                                        additional_cost_id: null,
                                    };
                                } else return ac;
                            });

                            updateHandler(res);
                            setOpen(false);
                        }}
                        disabled={disableAdditionalCostSaveBUtton || hasError}
                    >
                        Save
                    </FWButton>
                </DialogActions>
            </FWPopupWithChildren>
        </>
    );
};

export default React.memo(AdditionalCostPopUp);

// const AvailableAdditionCostPopOver = (props: {
//     availableAdditionalCostTemplateOptions: IAdditionalCost[];
//     addNewAdditionalCost: (
//         newAdditionalCost: IAdditionalCostsWithValue
//     ) => void;
//     anchorEl: HTMLElement | null;
//     setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
// }) => {
//     const {
//         addNewAdditionalCost,
//         availableAdditionalCostTemplateOptions,
//         anchorEl,
//         setAnchorEl,
//     } = props;
//     const open = Boolean(anchorEl);

//     const handleClose = () => setAnchorEl(null);
//     const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
//         setAnchorEl(event.currentTarget);

//     return !(availableAdditionalCostTemplateOptions.length > 0) ? null : (
//         <>
//             <ClickAwayListener onClickAway={handleClose}>
//                 <>
//                     <FWIconButton
//                         color="error"
//                         onClick={(e: any) => {
//                             // e.stopPropagation();
//                             if (
//                                 availableAdditionalCostTemplateOptions.length ===
//                                 1
//                             )
//                                 addNewAdditionalCost({
//                                     ...availableAdditionalCostTemplateOptions[0],
//                                     value: '',
//                                 });
//                         }}
//                         onMouseEnter={(e: any) => {
//                             handleClick(e);
//                         }}
//                         // onMouseLeave={(e: any) => {
//                         //     handleClose();
//                         // }}
//                     >
//                         <i className="bi bi-dash-circle"></i>
//                     </FWIconButton>

//                     <FWPopover
//                         open={open}
//                         anchorEl={anchorEl}
//                         onClose={handleClose}
//                         anchorOrigin={{
//                             vertical: 'bottom',
//                             horizontal: 'left',
//                         }}
//                         transformOrigin={{
//                             vertical: 'top',
//                             horizontal: 'left',
//                         }}
//                         sx={{
//                             pointerEvents: 'none',
//                         }}
//                     >
//                         {availableAdditionalCostTemplateOptions.map(
//                             (cost, idx) => (
//                                 <FWButton
//                                     onClick={(e) => {
//                                         e.stopPropagation();
//                                         addNewAdditionalCost({
//                                             ...cost,
//                                             value: '',
//                                         });
//                                     }}
//                                 >
//                                     <FWTypography key={idx}>
//                                         {idx + 1} {cost.costName}
//                                     </FWTypography>
//                                 </FWButton>
//                             )
//                         )}
//                     </FWPopover>
//                 </>
//             </ClickAwayListener>
//         </>
//     );
// };

import { IAdditionalCostsBackend } from '../../AdditionalCost/models/AdditionalCost.model';
import {
    IEventItemCustomFieldsBackend,
    IRFQLevelCustomField,
} from '../../Events/Interfaces/Buyer/RFQ.model';
import { IRFQItemCustomIDs } from '../../Events/Interfaces/Buyer/SharedRFQ.model';
import {
    BackendInvoiceItemPaymentTerms,
    BackendPaymentType,
    BackendPoItemsPaymentTerms,
    IPaymentTerms,
} from '../../Global/Interfaces/PaymentTermsInterface';
import { ICustomAttributeNew } from '../../Models/Attributes.model';
import { IAttachment } from '../../Models/RFQ.model';
import { VerificationStatus } from '../../Redux/Slices/VerificationSlice';
import { ISimpleDsItem } from '../Services/DeliverySchedule.service';

export enum PurchaseOrderStatus {
    APPROVAL_PENDING = 'APPROVAL_PENDING',
    ISSUED = 'ISSUED',
    ONGOING = 'ONGOING',
    COMPLETED = 'COMPLETED',
    RESCINDED = 'RESCINDED',
    REJECTED = 'REJECTED',
    DECLINED = 'DECLINED',
    TERMINATED = 'TERMINATED',
}

export enum PurchaseOrderDisplayStatusBuyer {
    APPROVAL_PENDING = 'APPROVAL_PENDING',
    ISSUED = 'ISSUED',
    ONGOING = 'ONGOING',
    COMPLETED = 'COMPLETED',
    RESCINDED = 'RESCINDED',
    REJECTED = 'REJECTED',
    DECLINED = 'DECLINED',
    TERMINATED = 'TERMINATED',
    ON_HOLD = 'ON_HOLD',
    TERMINATION_REQUESTED = 'TERMINATION_REQUESTED',
    REWORK = 'REWORK',
    REVISING = 'REVISING',
}

export enum PurchaseOrderDisplayStatusSeller {
    ISSUED = 'ISSUED',
    ONGOING = 'ONGOING',
    COMPLETED = 'COMPLETED',
    RESCINDED = 'RESCINDED',
    DECLINED = 'DECLINED',
    TERMINATED = 'TERMINATED',
    ON_HOLD = 'ON_HOLD',
    TERMINATION_REQUESTED = 'TERMINATION_REQUESTED',
    UNKNOWN = '-',
    REVISING = 'REVISING',
}

export enum PurchaseOrderType {
    AWARD_CONTAINER_ONLY = 'STAGING',
    FROM_EVENT = 'FINAL',
    DIRECT_PO = 'PO_GROUP',
}

export interface IStatusMap {
    direct_PO_groups?: string;
    pending?: string;
    received?: string;
    ongoing: string;
    finished: string;
}

export interface IPageDetails {
    current_page: number;
    has_next: boolean;
    has_previous: boolean;
    total_pages: number;
}

export interface IPurchaseOrder {
    purchase_order_id: string;
    custom_purchase_order_id: string;
    status: PurchaseOrderStatus;
    purchase_order_type: PurchaseOrderType;
    buyer_attachments?: IAttachment[];
    seller_attachments?: IAttachment[];

    buyer_enterprise: string;
    buyer_entity: string;
    buyer_information: IPurchaseOrderBuyerInformation;
    buyer_contact_list: IPurchaseOrderBuyerContact[];

    seller_enterprise: string;
    seller_entity: string;
    seller_information: IPurchaseOrderSellerInformation;
    seller_contact_list: IPurchaseOrderSellerContact[];

    hold_information: IPurchaseOrderHoldInformation | null;
    termination_information: IPurchaseOrderTerminationInformation | null;

    notes: string;
    seller_notes: string;

    event: string;
    event_name: string;
    additional_information: {
        last_action_information: {
            notes: string;
            user_id: string;
        };
        purchase_order_items_count: string;
        event_details?: {
            custom_event_id: string;
            event_name: string;
            original_event_creator_id: string;
            original_event_creator_name: string;
        };
    };
    terms_and_conditions: {
        data: string;
        name: string;
        terms_and_conditions_id: string | null;
    };
    pricing_information: IPurchaseOrderTotalPricingInformation;
    submission_datetime: string | null;
    acceptance_datetime: string | null;
    final_buyer_approval: string | null;
    final_seller_approval: string | null;
    created_by_user_name: string;
    created_by_user: string;
    purchase_order_items: IPurchaseOrderItem[];
    event_additional_details?: {
        template_id?: string;
    };
    custom_additional_information: string | null;
    gr_tolerance: string | null;
    project_id: string | null;
    gl_id: string | null;
    cost_centre_id: string | null;
    custom_fields: {
        section_list: {
            name: string;
            fields: IRFQLevelCustomField[];
        }[];
    };
    lead_time: string | null;
    lead_time_period: string | null; //can be null,
    incoterm_id: string | null;
    payment_type: BackendPaymentType | null;
    prepayment_percentage: number | null;
    payment_terms: BackendInvoiceItemPaymentTerms | null;
    deliverables_payment_terms: BackendPoItemsPaymentTerms[] | null;
    requisition_information: {
        requisition_number: string | null;
        requisition_approved: boolean | null;
    }[];
    past_versions?: {
        [key: string]: {
            version: number;
            created_datetime: string;
            submission_datetime: string;
        };
    };
}

export interface IPurchaseOrderItem {
    purchase_order_item_id: string;
    purchase_order: string;
    event: string;
    buyer_enterprise: string;
    seller_enterprise: string;
    buyer_entity: string;
    seller_entity: string;
    buyer_item: string;
    item_information: IItemInformation;
    fulfilment_information: IPurchaseOrderItemFulfilmentInformation;
    quantity_information: IPurchaseOrderItemQuantityInformation;
    pricing_information: IPurchaseOrderItemPricingInformation;
    attributes: ICustomAttributeNew[];
    attribute_information: {
        buyer_item_attributes: IAttributeDetails[];
        custom_item_attributes: IAttributeDetails[];
    };
    additional_details: {
        po_group_item_id: string | undefined;
        po_group_id: string | undefined;
        rfq_event_id: string;
        rfq_item_id: string;
    };
    incoterm: string;
    incoterm_abbreviation: string;
    procurement_information: IPurchaseOrderItemProcurementInformation;
    internal_notes: { notes: string };
    external_notes: { notes: string };
    delivery_schedule_information: IDeliveryScheduleInformation;
    attachments: IAttachment[];
    custom_fields?: IEventItemCustomFieldsBackend;
    custom_fields_negotiate?: IEventItemCustomFieldsBackend;
    paymentTerms: IPaymentTerms | null;
}

export interface IReviewPurchaseOrderItem {
    purchase_order_item_id: string;
    purchase_order: string;
    event: string;
    buyer_enterprise: string;
    seller_enterprise: string;
    buyer_entity: string;
    seller_entity: string;
    buyer_item: string;
    item_information: IItemInformation;
    fulfilment_information: IPurchaseOrderItemFulfilmentInformation;
    quantity_information: IPurchaseOrderItemQuantityInformation;
    pricing_information: IPurchaseOrderItemPricingInformation;
    attribute_information: {
        buyer_item_attributes: IAttributeDetails[];
        custom_item_attributes: IAttributeDetails[];
    };
    additional_details: {
        rfq_item_id: string;
        rfq_event_id: string;
    };
    incoterm: string;
    incoterm_abbreviation: string;
    procurement_information: IPurchaseOrderItemProcurementInformation;
    internal_notes: { notes: string };
    external_notes: { notes: string };
    delivery_schedule_information: IDeliveryScheduleInformation;
    attachments: IAttachment[];
    custom_fields?: IEventItemCustomFieldsBackend;
    custom_fields_negotiate?: IEventItemCustomFieldsBackend;
    requisition_information: {
        requisition_number: string | null;
        requisition_approved: boolean | null;
    }[];
}
export interface IPurchaseOrderTotalPricingInformation {
    total: string;
    shipping: string;
    subtotal: string;
    currency_name: string;
    currency_symbol: string;
    currency_code_id: string;
    taxes_percentage: string;
    discount_percentage: string;
    currency_code_abbreviation: string;
}

export interface IPurchaseOrderTerminationInformation {
    status: 'REQUESTED' | 'ACCEPTED';
    buyer_user_id: string;
    seller_user_id: string | null;
    buyer_datetime: string;
    seller_datetime: string | null;
    buyer_notes: string;
    seller_notes: string | null;
}

export interface IPurchaseOrderSellerContact {
    seller_contact_id: string;
    seller_contact_name: string;
    seller_contact_email: string;
    seller_contact_phone: string;
}

export interface IPurchaseOrderBuyerInformation {
    billing_address: string;
    shipping_address: string;
    buyer_entity_name: string;
    billing_address_id: string;
    shipping_address_id: string;
    buyer_entity_address: string;
    buyer_identification_info: IEntityIdentification[];
    buyer_entity_logo: string;
}

export interface IPurchaseOrderBuyerContact {
    buyer_contact_id: string;
    buyer_contact_name: string;
    buyer_contact_email: string;
    buyer_contact_phone: string;
}

export interface IPurchaseOrderSellerInformation {
    seller_address_id: string;
    seller_entity_name: string;
    seller_entity_address: string;
    seller_identification_info: IEntityIdentification[];
    is_virtual?: boolean;
    verification_status: VerificationStatus;
}

export interface IEntityIdentification {
    identification_id: string;
    identification_name: string;
    identification_value: string;
}

export interface IPurchaseOrderHoldInformation {
    user_id: string;
    hold_datetime: string;
    notes: string;
    status: 'AUTOMATIC' | 'MANUAL';
}

export interface IItemInformation {
    buyer_item_id: string;
    global_item_id: string | null;
    buyer_item_name: string;
    custom_item_name: string;
    buyer_item_description: string;
    item_additional_details: string;
    custom_ids?: IRFQItemCustomIDs[];
}

export interface IPurchaseOrderItemFulfilmentInformation {
    invoiced: string;
    delivered: string;
    on_hold: string;
    discarded: string;
    rescheduled: string;
}

export interface IPurchaseOrderItemQuantityInformation {
    quantity: string;
    measurement_unit_id: string;
    measurement_unit_name: string;
    quantity_tolerance_percentage: string;
}

export interface IPurchaseOrderItemPricingInformation {
    taxes: IAdditionalCostsBackend[];
    price: string;
    currency_code_abbreviation: string;
    currency_name: string;
    currency_symbol: string;
    currency_code_id: string;
    shipping_per_unit: string;
    additional_charges: IAdditionalCharge[];
    additional_costs: IAdditionalCostsBackend[];
    discounts: IAdditionalCostsBackend[];
}

export interface IAdditionalCharge {
    charge_name: string;
    charge_value: string;
}

export interface IAttributeDetails {
    attribute_name: string;
    attribute_value: string[];
    attribute_exclude: boolean; //TODO: Make true/false after confirming backend
}

export interface IPurchaseOrderItemProcurementInformation {
    lead_time: string;
    lead_time_period: string;
}
export interface IDeliveryScheduleInformation {
    delivery_schedule_id: string;
    status: 'ACTIVE' | 'INACTIVE';
    confirmation_status: DeliveryScheduleConfirmationStatus;
    seller_notes: string | null;
    delivery_schedule_items: IDeliveryScheduleItem[];
    modified_by_user?: {
        user_id: string;
        name: string;
        email: string;
        status: string;
    };
    created_by_user?: {
        user_id: string;
        name: string;
        email: string;
        status: string;
    };
    modified_datetime?: string;
    created_datetime?: string;
}

export interface INewDeliveryScheduleInformation {
    delivery_schedule_id: string;
    confirmation_status: DeliveryScheduleConfirmationStatus;
    status: 'ACTIVE' | 'INACTIVE';
    seller_notes: string | null;
    delivery_schedule_items: ISimpleDsItem[];
}

export interface IDeliveryScheduleItem {
    bom_item: any;
    delivery_schedule_item_id: string;
    delivery_schedule: string;
    purchase_order_item: string;
    quantity: string;
    delivery_date: string | null;

    buyer_enterprise: string;
    seller_enterprise: string;
    buyer_entity: string;
    seller_entity: string;
}

export enum DeliveryScheduleConfirmationStatus {
    DS_BUYER_CONFIRMATION_PENDING = 'BUYER_CONFIRMATION_PENDING',
    DS_BUYER_CONFIRMATION_REJECTED = 'BUYER_CONFIRMATION_REJECTED',
    DS_SELLER_CONFIRMATION_PENDING = 'SELLER_CONFIRMATION_PENDING',
    DS_SELLER_CONFIRMATION_REJECTED = 'SELLER_CONFIRMATION_REJECTED',
    DS_CONFIRMATION_ACCEPTED = 'CONFIRMATION_ACCEPTED',
}

export interface IPurchaseOrderList {
    purchase_order_id: string;
    buyer_enterprise: string;
    seller_enterprise: string;
    event: string;
    custom_purchase_order_id: string;
    hold_information: string;
    buyer_entity: string;
    seller_entity: string;
    seller_entity_name: string;
    invoice_count: string;
    buyer_information: {
        billing_address: string;
        shipping_address: string;
        buyer_entity_name: string;
        billing_address_id: string;
        shipping_address_id: string;
        buyer_entity_address: string;
        buyer_identification_info: {
            identification_id: string;
            identification_name: string;
            identification_value: string;
        }[];
    };
    seller_information: {
        seller_address_id: string;
        seller_entity_name: string;
        seller_entity_address: string;
        seller_identification_info: {
            identification_id: string;
            identification_name: string;
            identification_value: string;
        }[];
    };
    buyer_contact_list: {
        buyer_contact_id: string;
        buyer_contact_name: string;
        buyer_contact_email: string;
        buyer_contact_phone: string | null;
    }[];
    seller_contact_list: {
        seller_contact_id: '943c6f7a-0573-4c38-aba0-577148346d40';
        seller_contact_name: 'Gaurav ';
        seller_contact_email: 'hufort.gaurav@gmail.com';
        seller_contact_phone: '';
    }[];
    internal_status: string;
    status: string;
    purchase_order_type: string;
    termination_information: { notes: string } | null;
    terms_and_conditions: {
        terms_and_conditions_id: string;
        name: string;
        data: string | null;
    };
    notes: string | null;
    seller_notes: string | null;
    pricing_information: {
        total: string;
        shipping: string;
        subtotal: string;
        currency_name: string;
        currency_symbol: string;
        currency_code_id: string;
        taxes_percentage: string;
        discount_percentage: string;
        currency_code_abbreviation: string;
    };
    submission_datetime: string;
    acceptance_datetime: string;
    additional_information: {
        event_details: {
            event_name: string;
            custom_event_id: string;
            original_event_creator_id: string;
            original_event_creator_name: string;
        };
        last_action_information: {
            notes: string;
            user_id: string;
        };
        purchase_order_items_count: string;
    };
    requisition_department: string | null;
    department: string | null;
    final_buyer_approval: string;
    final_seller_approval: string;
    created_by_user_name: string;
    created_by_user: string;
    modified_by_user: string;
    created_datetime: string;
    modified_datetime: string;
    warning_information: {
        units_on_hold: number;
        rejected_delivery_schedule: number;
        unconfirmed_delivery_schedule: number;
        buyer_unconfirmed_delivery_schedule: number;
        buyer_rejected_delivery_schedule: number;
    };
}

export interface IPurchaseOrderApproval {
    requestorId: string;
    requestorUserName: string;
    requestorFirstName: string;
    requestorLastName: string;
    requestorNotes: string;
    approverId: string;
    approverUserName: string;
    approverFirstName: string;
    approverLastName: string;
    approverNotes: string;
    status: 'PENDING' | 'ISSUED' | 'REJECTED';
}

export interface INextEventApprover {
    userId: string;
    userName: string;
    firstName: string;
    lastName: string;
    userRole: string;
}

export interface IPurchaseOrderComment {
    message: string;
    userId: string;
    userName: string;
    fullName: string;
    createdDatetime: string;
    enterpriseDetails: {
        enterpriseId: string;
        enterpriseName: string;
    };
}

export interface IVendorContactDetails {
    vendor_contact_id: string;
    buyer_entity: string;
    seller_entity: string;
    full_name: string;
    primary_email: string;
    emails?:
        | {
              email: string;
              type: string;
          }[]
        | undefined;
    phone_numbers: string[];
    tags: any[];
    is_primary: boolean;
    user: string;
    status: string;
}

import { Box, IconButton, Typography } from '@mui/material';
import { Fragment, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import { FWTypography } from '../../../Common/FWTypograhy';

import { Styledi } from '../../../Events/Shared/SharedStyle';
import { VerificationStatus } from '../../../Redux/Slices/VerificationSlice';
import { IGlobalAppStore } from '../../../Redux/Store';
import { ItemCartAccessContext } from '../../Pages/ItemCartAccess';
import { CartItemAccordionContext } from './CartItemAccordion';
import { ICustomAttributeNew } from '../../../Models/Attributes.model';
import AddAttributeButton from '../../../Global/Components/AddAttributeButton';

export default function CartItemAccordionSummaryData() {
    const {
        itemInCart,
        cartItemId,
        allItemVendors,
        updateAttributeNew,
        deleteAttributeNew,
    } = useContext(CartItemAccordionContext);

    const { buyerEntityId } = useContext(ItemCartAccessContext);

    const attributes = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].attributes
            : state.ItemCartStore.itemsSavedForLater[cartItemId].attributes
    );

    const custom_ids = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].item_information
                  .custom_ids
            : state.ItemCartStore.itemsSavedForLater[cartItemId]
                  .item_information.custom_ids
    );

    const buyer_item_code = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].item_information
                  .item_code
            : state.ItemCartStore.itemsSavedForLater[cartItemId]
                  .item_information.item_code
    );

    const selectedVendorRowIDs = useSelector((state: IGlobalAppStore) =>
        itemInCart
            ? state.ItemCartStore.cartItems[cartItemId].seller_entities
            : state.ItemCartStore.itemsSavedForLater[cartItemId].seller_entities
    );

    const [openAttributePopup, setOpenAttributePopup] = useState<{
        isOpen: boolean;
        currentAttribute: ICustomAttributeNew | null;
    }>({ isOpen: false, currentAttribute: null });

    const TOTAL_NO_OF_SELECTED_VENDORS =
        selectedVendorRowIDs == null ? 0 : selectedVendorRowIDs.length;

    const vendorSummaryValue = useMemo(() => {
        const vendorDetails = allItemVendors;

        if (
            vendorDetails === null ||
            vendorDetails.length === 0 ||
            selectedVendorRowIDs === null
        ) {
            return null;
        } else {
            const vendorSummaryValue: {
                name: string;
                is_preferred: boolean;
                is_virtual: boolean;
                verification_status: VerificationStatus;
                seller_entity_id: string;
            }[] = [];
            selectedVendorRowIDs?.forEach((vendor_row_id: any, idx) => {
                const foundVendorDetails = vendorDetails.find(
                    (v) => v.id === vendor_row_id
                );

                if (foundVendorDetails) {
                    vendorSummaryValue.push({
                        name: foundVendorDetails.seller_entity.entity_name,
                        is_preferred: foundVendorDetails.status === 'PREFERRED',
                        is_virtual: foundVendorDetails.seller_entity.is_virtual,
                        verification_status:
                            foundVendorDetails.seller_entity
                                .verification_status,
                        seller_entity_id:
                            foundVendorDetails.seller_entity.entity_id,
                    });
                }
            });

            return vendorSummaryValue;
        }
    }, [allItemVendors, selectedVendorRowIDs]);

    return (
        <>
            <Box
                display="flex"
                alignItems={'center'}
                flexWrap={'wrap'}
                margin="0 0.5rem 0 1rem"
            >
                <Typography
                    color="text.secondary"
                    sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }}
                >
                    {`Item ID: ${buyer_item_code}`}
                    &nbsp;
                    {(custom_ids ?? []).map((id, idx) => {
                        return (
                            <Fragment key={idx}>
                                &nbsp; | &nbsp;
                                {id.name}: {id.value}
                            </Fragment>
                        );
                    })}
                    {attributes
                        .filter((attr) => attr.attribute_id !== null)
                        .map((attr, idx) => {
                            return (
                                <Fragment key={idx}>
                                    &nbsp; | &nbsp;
                                    {attr.attribute_name}:{' '}
                                    {attr.attribute_values
                                        .map((val) => val.value)
                                        .join('/')}
                                </Fragment>
                            );
                        })}
                    {attributes
                        .filter((attr) => attr.attribute_id === null)
                        .map((attr, idx) => {
                            return (
                                <Fragment key={idx}>
                                    &nbsp; | &nbsp;
                                    <Box
                                        component="span"
                                        sx={{ color: 'warning.main' }}
                                    >
                                        {attr.attribute_name}:{' '}
                                        {attr.attribute_values
                                            .map((val) => val.value)
                                            .join(', ')}
                                    </Box>
                                    &nbsp;
                                    <FWTooltip title="Edit specification">
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            sx={{ fontSize: '16px' }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setOpenAttributePopup({
                                                    isOpen: true,
                                                    currentAttribute: attr,
                                                });
                                            }}
                                        >
                                            <i className="bi bi-pencil" />
                                        </IconButton>
                                    </FWTooltip>
                                </Fragment>
                            );
                        })}
                    <AddAttributeButton
                        entity_id={buyerEntityId}
                        item_attributes={attributes}
                        updateAttribute={updateAttributeNew}
                        deleteAttribute={deleteAttributeNew}
                        openAttributePopup={openAttributePopup}
                        setOpenAttributePopup={setOpenAttributePopup}
                    />
                </Typography>
                <Box width={'100%'} sx={{ display: 'flex', gap: '4px' }}>
                    <Typography sx={{ color: 'text.secondary' }}>
                        {TOTAL_NO_OF_SELECTED_VENDORS > 0
                            ? TOTAL_NO_OF_SELECTED_VENDORS === 1
                                ? '1 Vendor: '
                                : `${TOTAL_NO_OF_SELECTED_VENDORS} Vendors: `
                            : '0 Vendor: '}
                    </Typography>
                    <FWTypography
                        sx={{ color: 'text.secondary', display: 'flex' }}
                    >
                        {vendorSummaryValue &&
                            vendorSummaryValue.map((vendor, index) => (
                                <Typography
                                    sx={{
                                        whiteSpace: 'pre-wrap',
                                        wordWrap: 'break-word',
                                    }}
                                    color="text.secondary"
                                    key={index}
                                >
                                    {vendor.name}
                                    &nbsp;
                                    {vendor.is_preferred && (
                                        <Styledi className="bi bi-check-circle-fill" />
                                    )}
                                    {index !==
                                    vendorSummaryValue!.length - 1 ? (
                                        <>&nbsp;|&nbsp;</>
                                    ) : (
                                        ''
                                    )}
                                </Typography>
                            ))}
                    </FWTypography>
                </Box>
            </Box>
        </>
    );
}

import { FWTypography } from '../../Common/FWTypograhy';
import { Box } from '@mui/material';
import { FWTooltip } from '../../Common/FWCustomTooltip';

export const StatusChip = ({
    status,
    hoverText = '',
}: {
    status: string;
    hoverText?: string;
}) => {
    const StatusBlock = (param_status: string, param_color: string) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <FWTypography sx={{ color: param_color }}>
                    {param_status}
                </FWTypography>
                &nbsp;
                {hoverText.length > 0 && (
                    <FWTooltip title={hoverText}>
                        <Box color={param_color}>
                            <i className="bi bi-info-circle" />
                        </Box>
                    </FWTooltip>
                )}
            </div>
        );
    };

    switch (status) {
        case 'ONGOING':
            return StatusBlock('Negotiations', 'success.main');
        case 'NEGOTIATIONS':
            return StatusBlock('Negotiations', 'success.main');
        case 'PAUSED':
            return StatusBlock('Paused', 'warning.main');
        case 'APPROVAL_PENDING':
            return StatusBlock('Approval pending', 'warning.main');
        case 'AWARD_STAGE':
            return StatusBlock('Award stage', 'warning.main');
        case 'AWARD':
            return StatusBlock('Award', 'warning.main');
        case 'REWORK':
            return StatusBlock('Changes requested', 'warning.main');
        case 'COMPLETED':
            return StatusBlock('Completed', 'success.main');
        case 'TERMINATED':
            return StatusBlock('Terminated', 'error.main');
        case 'REJECTED':
            return StatusBlock('Rejected', 'error.main');
        case 'DECLINED':
            return StatusBlock('Declined', 'error.main');
        case 'DRAFT':
            return StatusBlock('Draft', 'secondary.main');
        case 'REVISED':
            return StatusBlock('Revised', 'primary.main');
        case 'INVITED':
            return StatusBlock('Invited', 'primary.main');
        case 'INVITATION_REJECTED':
            return StatusBlock('Invitation declined', 'error.main');
        case 'INVITATION_LAPSED':
            return StatusBlock('Invitation lapsed', 'warning.main');
        case 'DEADLINE_PASSED':
            return StatusBlock('Deadline passed', 'warning.main');
        case 'INVITATION_PENDING':
            return StatusBlock('Invitation received', 'warning.main');
        case 'BID_PENDING':
            return StatusBlock('Bid pending', 'warning.main');
        case 'BID_RECEIVED':
            return StatusBlock('Bid received', 'warning.main');
        case 'BID_SUBMITTED':
            return StatusBlock('Bid submitted', 'warning.main');
        case 'ACCEPTED':
            return StatusBlock('Accepted', 'success.main');
        case 'REVERT':
            return StatusBlock('Revert', 'primary.main'); // Negotiating
        case 'DEAL_WON':
            return StatusBlock('Deal won', 'success.main');
        case 'DEAL_LOST':
            return StatusBlock('Deal lost', 'error.main');
        case 'RESPOND':
            return StatusBlock('Respond', 'primary.main'); // Negotiating
        case 'EXCLUDE':
            return StatusBlock('Item excluded', 'error.main');
        case 'REMOVED':
            return StatusBlock('Item excluded', 'error.main');
        case 'ADDED':
            return StatusBlock('Added', 'primary.main');
        case 'REQUESTED':
            return StatusBlock('Requested', 'primary.main');
        case 'RENEGOTIATING':
            return StatusBlock('Negotiating', 'primary.main');
        case 'PO_PENDING':
            return StatusBlock('PO pending', 'warning.main');
        case 'PO_RECEIVED':
            return StatusBlock('PO received', 'success.main');
        case 'PO_REVIEWED':
            return StatusBlock('PO reviewed', 'primary.main');
        case 'ISSUED':
            return StatusBlock('Issued', 'primary.main');
        case 'DELIVERY_PENDING':
            return StatusBlock('Delivery pending', '');
        case 'PURCHASE_ORDER_ISSUED':
            return StatusBlock('PO(s) Issued', 'primary.main');
        case 'PURCHASE_ORDER_ACCEPTED':
            return StatusBlock('PO(s) Accepted', 'success.main');
        default:
            return StatusBlock(status, '');
    }
};

export default StatusChip;

import { Box, Grid } from '@mui/material';
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useParams } from 'react-router-dom';

import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import ChatNavBar from '../../Chats/Components/ChatNavBar';
import { FWButton } from '../../Common/FWButton';
import { HookStateValue } from '../../Common/Hooks/StateHook';
import CreateEventPopup from '../../Components/Buyer/Events/Dashboard/CreateEventPopup';
import LazyFallback from '../../Components/Shared/LazyFallBack';
import TitleBar from '../../Components/Shared/TitleBar';
import { AuthContext } from '../../Contexts/AuthContext';
import { IItemCartAccessContext } from '../../Events/Interfaces/Buyer/itemAnalytics.interface';
import BlankCartItem from '../Components/ItemCart/BlankCartItem';
import ItemCartModuleChips from '../Components/Relationship/ItemCartTabs';
import IAPagination from '../Constants/IAPagination';
import CartMainContainer from '../Containers/Relationship/CartMainContainer';
import { IAPaginationContext } from '../Helpers/IAPaginationHelpers';
import { blankItemCartTemplate } from '../Helpers/itemAnalyticsTemplateHelpers';
import useCreateNewCartItem from '../Hooks/Relationship/useCreateNewCartItem';
import useItemCart from '../Hooks/Relationship/useItemCart';
import useItemCartIntegrationHook from '../Hooks/useItemCartIntegrationHook';
import {
    CART_ITEM_PER_PAGE,
    ITEM_ANALYTICS_GAP,
    ItemCartModuleNameMapping,
} from '../Interfaces/ItemAnalyticsInterfaces';
import { CartItemDetails } from '../Interfaces/itemCartItemInterfaces';
import { updateCartDataStore } from '../Slices/ItemAnalyticsCartSlice';
import OrderImportPopup from '../../Events/Containers/Buyer/OrderImportPopup';
import LoadingBackDrop from '../../Components/Shared/LoadingBackdrop';

export const ItemCartAccessContext = createContext<IItemCartAccessContext>({
    hideBlankItemAccordion: (itemAdded: boolean) => {},
    currentPage: 1,
    templateDetails: blankItemCartTemplate,
    cartId: null,
    buyerEntityId: '',
});

export interface IItemCartAccessProps {
    cartId?: string;
    buyerEntityId?: string;
}

export default function ItemCartAccess({
    buyerEntityId,
    cartId,
}: IItemCartAccessProps) {
    const currencyId =
        useContext(AuthContext).authData.details?.enterprise.billing_currency;
    const paramData: { cart_id: string; buyer_entity_id: string } = useParams();
    const [open, setOpen] = useState(false);
    const {
        templateDetails,
        updateDashboardSettings,
        updateCurrentCartSection,
        hookState,
        currentCartSection,
        dashboardSettings,
        itemCount,
        canCreateEvent,
    } = useItemCart(
        cartId ?? paramData.cart_id,
        buyerEntityId ?? paramData.buyer_entity_id
    );
    const { createOrder } = useItemCartIntegrationHook();
    const [openAddItemContainer, setOpenAddItemContainer] = useState(false);
    const [selectedReqId, setSelectedReqId] = useState('');
    const [openTemplateSelectionPopup, setOpenTemplateSelectionPopup] =
        useState(false);

    useEffect(() => {
        if (
            itemCount === 0 &&
            hookState.state === HookStateValue.READY
            // &&
            // currentCartSection !== ItemCartModuleSectionEnum.ORDERS
        ) {
            setOpenAddItemContainer(true);
        } else {
            setOpenAddItemContainer(false);
        }
    }, [currentCartSection, hookState.state, itemCount]);

    const hideBlankItemAccordion = useCallback((itemAdded: boolean) => {
        setOpenAddItemContainer(false);
    }, []);

    const debouncedSearch = useMemo(
        () =>
            debounce((text: string) => {
                updateDashboardSettings('searchText', text);
            }, 1000),
        [updateDashboardSettings]
    );

    const { checkPermission } = useContext(AuthContext);

    const allowUserToAddItemToCart = useMemo(() => {
        return checkPermission('BUYER', 'CART', 'CART_EDIT', null);
    }, [checkPermission]);

    const {
        buyerMasterItemList,
        hasMoreItemsinList,
        fetchingItemDetails,
        itemAddedSuccessfully,
        fetchAndLoadSelectedItemDetails,
        onShowMoreItems,
        toggleAddItem,
        onSearchItem,
        searchItemToAdd,
    } = useCreateNewCartItem(
        cartId ?? paramData.cart_id,
        buyerEntityId ?? paramData.buyer_entity_id,
        false,
        currentCartSection
    );
    const dispatch = useDispatch();
    const [addItemLoader, setAddItemLoader] = useState(false);
    const createNewItem = useCallback(
        async (
            enterprise_item_id: string,
            itemAlreadyPresentInTab: CartItemDetails | null,
            additionalQuantityForCurrentItem?: number | undefined
        ) => {
            setAddItemLoader(true);
            try {
                await fetchAndLoadSelectedItemDetails(
                    enterprise_item_id,
                    itemAlreadyPresentInTab,
                    additionalQuantityForCurrentItem
                );
                // setOpenAddItemContainer(false);
            } catch (err) {
            } finally {
                setAddItemLoader(false);
            }
        },
        [fetchAndLoadSelectedItemDetails]
    );
    const currency =
        useContext(AuthContext).authData.details?.enterprise.billing_currency;
    useEffect(() => {
        if (itemAddedSuccessfully) {
            setOpenAddItemContainer(false);
            toggleAddItem(false);
        }
    }, [itemAddedSuccessfully, toggleAddItem]);

    if (hookState.state === HookStateValue.LOADING) {
        return (
            <Box className="flex flex--aic flex--jcc">
                <LazyFallback />
            </Box>
        );
    }
    const handleOrderCreation = (from: boolean) => {
        try {
            if (currency) {
                createOrder(
                    currency,
                    cartId ?? paramData.cart_id,
                    templateDetails.templateId,
                    'ORDER_REQUEST'
                )
                    .then((response: any) => {
                        setSelectedReqId(response.data.requisition_id);
                        dispatch(
                            updateCartDataStore({
                                type: 'RESET_CART_STATE',
                                value: null,
                            })
                        );
                        toast.success('Order created successfully');
                        if (from) setOpen(true);
                    })
                    .catch((e) => {
                        toast.error('Error while creating order');
                    });
            }
        } catch (e) {}
    };
    return (
        <Grid container>
            <ItemCartAccessContext.Provider
                value={{
                    currentPage: dashboardSettings.currentPage,
                    hideBlankItemAccordion,
                    templateDetails,
                    cartId: cartId ?? paramData.cart_id,
                    buyerEntityId: buyerEntityId ?? paramData.buyer_entity_id,
                }}
            >
                {paramData.cart_id && <ChatNavBar />}
                {paramData.cart_id && (
                    <TitleBar title="Cart" gotoURL="/buyer/cart/" />
                )}

                <ItemCartModuleChips
                    currentSection={
                        ItemCartModuleNameMapping[currentCartSection]
                    }
                    allowPositionSticky={false}
                    updateSection={updateCurrentCartSection}
                    updateSearchTerm={debouncedSearch}
                />
                {addItemLoader && <LoadingBackDrop />}
                <CartMainContainer
                    tab={currentCartSection}
                    templateDetails={templateDetails}
                    cartId={cartId ?? paramData.cart_id}
                    buyerEntityId={buyerEntityId ?? paramData.buyer_entity_id}
                />

                {openAddItemContainer && (
                    <Grid item xs={12}>
                        <BlankCartItem
                            tab={currentCartSection}
                            searchText={searchItemToAdd.searchText}
                            buyerMasterItemList={buyerMasterItemList}
                            hasMoreItemsinList={hasMoreItemsinList}
                            fetchingItemDetails={fetchingItemDetails}
                            itemAddedSuccessfully={itemAddedSuccessfully}
                            fetchAndLoadSelectedItemDetails={createNewItem}
                            onShowMoreItems={onShowMoreItems}
                            onSearchItem={onSearchItem}
                        />
                    </Grid>
                )}

                {allowUserToAddItemToCart && (
                    // currentCartSection !== ItemCartModuleSectionEnum.ORDERS &&
                    <Grid item xs={'auto'} marginX={'auto'} marginTop={4}>
                        <FWButton
                            variant="contained"
                            disabled={openAddItemContainer}
                            onClick={() => {
                                setOpenAddItemContainer(true);
                            }}
                        >
                            Add Item
                        </FWButton>
                    </Grid>
                )}
            </ItemCartAccessContext.Provider>

            <Grid container>
                <Grid
                    item
                    xs={'auto'}
                    marginRight={4}
                    marginLeft={'auto'}
                    marginBottom={4}
                >
                    <IAPaginationContext.Provider
                        value={{
                            currentPage: dashboardSettings.currentPage,
                            limitItemPerPage: CART_ITEM_PER_PAGE,
                            onPageChange: (newPage: number) => {
                                updateDashboardSettings('currentPage', newPage);
                            },
                            totalPages: dashboardSettings.totalPages,
                        }}
                    >
                        <IAPagination />
                    </IAPaginationContext.Provider>
                </Grid>
            </Grid>

            <Grid
                container
                paddingY={'auto'}
                marginBottom={4}
                gap={ITEM_ANALYTICS_GAP}
                justifyContent={'center'}
            >
                <Grid item xs={'auto'}>
                    {canCreateEvent && (
                        <FWButton
                            variant="contained"
                            sx={{ marginX: 'auto' }}
                            disabled={openAddItemContainer}
                            onClick={() => {
                                handleOrderCreation(true);
                            }}
                        >
                            Create Event
                        </FWButton>
                    )}
                </Grid>
                <Grid item xs={'auto'}>
                    <FWButton
                        variant="contained"
                        sx={{ marginX: 'auto' }}
                        disabled={openAddItemContainer}
                        onClick={() => {
                            handleOrderCreation(false);
                            // setOpenAddItemContainer(true);
                        }}
                    >
                        Submit Order Request
                    </FWButton>
                </Grid>

                <Grid item xs={'auto'}>
                    <FWButton
                        variant="contained"
                        sx={{ marginX: 'auto' }}
                        disabled={openAddItemContainer}
                        onClick={() => {
                            // setOpenAddItemContainer(true);
                        }}
                    >
                        Create PO
                    </FWButton>
                </Grid>
                <Grid item xs={'auto'}>
                    <FWButton
                        variant="contained"
                        sx={{ marginX: 'auto' }}
                        disabled={openAddItemContainer}
                        onClick={() => {
                            // setOpenAddItemContainer(true);
                        }}
                    >
                        Create Requisition
                    </FWButton>
                </Grid>
            </Grid>
            <CreateEventPopup
                open={openTemplateSelectionPopup}
                setOpen={setOpenTemplateSelectionPopup}
                url="/buyer/events/create/"
                showCancel={true}
                templateType="RFQ"
                cartId={cartId ?? paramData.cart_id}
            />
            <OrderImportPopup
                open={open}
                handleClose={() => {
                    setOpen(false);
                }}
                title={'Select Cart Order Request'}
                defaultCurrency={currencyId ?? ''} //MIGHT NEED UPDATE (RIGHT NOW USING ENTERPRISE CURRENCY ID)
                cartId={paramData.cart_id}
                selectedReqId={selectedReqId}
                integrationType={'CART_ORDERS'}
                setOpenTemplateSelectionPopup={setOpenTemplateSelectionPopup}
            />
        </Grid>
    );
}

export interface IGetAttributeDetails {
    exclude: boolean;
    name: string;
    value: string[];
}

export interface IAttributeValueOptionDetails {
    measurement_unit_category: string;
    measurement_unit_id: string;
    measurement_unit_primary_name: string;
    measurement_unit_value_type: string;
}
export enum ATTRIBUTE_TYPE {
    TEXT = 'TEXT',
    UOM = 'UOM',
    CURRENCY = 'CURRENCY',
    DIMENSION = 'DIMENSION',
    NUMBER = 'NUMBER',
}

export interface ICustomAttributeNew {
    attribute_linkage_id: string | null;
    attribute_id: string | null;
    attribute_name: string;
    attribute_type: ATTRIBUTE_TYPE; // TEXT | NUMBER | UOM | CURRENCY
    attribute_values: {
        attribute_value_linkage_id: string | null;
        attribute_value_id: string | null;
        //CAN BE STRING OR NUMBER (BACKEDN IS SAVING IN STRING FORMAT USE THAT FOR PAYLOAD, CONVERT TO NUMBER AFTER RECIEVING)
        value: string;
        measurement_unit: {
            measurement_unit_id: string;
            measurement_unit_primary_name: string;
        } | null;
        currency: {
            entry_id: string;
            currency_code_abbreviation: string;
            currency_name: string;
            currency_symbol: string;
        } | null;
    }[];
}

export interface ICustomAttributePayloadNew {
    attribute_linkage_id: string | null;
    attribute_id: string | null;
    attribute_name: string;
    attribute_type: string;
    attribute_values: ICustomAttributeValue[];
}

export interface ICustomAttributeValue {
    attribute_value_linkage_id: string | null;
    attribute_value_id: string | null;
    value: string;
    measurement_unit_id: string | null;
    currency_id: string | null;
}

/**
 * STEPS TO MAKE THE ATTRIBUTE CHANGES
 * 1. ACCEPT THE NEW ATTRIBUTES FROM THE BACKEND AND SAVE THEM IN REDUX
 * 2. MAKE NEW ATTRIBUTE FUNCTIONS (UPDATE/DELETE) AS PER THE NEW STRUCTURE
 *   (REFER TO useRequisitionAccordionHook.tsx and search for updateAttributeNew and deleteAttributeNew)
 * 3. USE THE NEW ATTRIBUTE POPUP CREATED IN THE GLOBAL COMPONENTES FOLDER IN THE COMPONENET WHERE THE ATTRIBUTES ARE BEING CALLED
 * 4. IT SHOULD WORK WITH THIS
 */

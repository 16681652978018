import { cloneDeep, isEqual } from 'lodash';
import moment from 'moment';
import { capitaliseFirstLetter } from '../../BuyerTemplates/Component/TemplateSuggestedFieldsSection';
import {
    TemplateData,
    TemplateStatus,
    templateType,
} from '../../BuyerTemplates/Interfaces/TemplatePageInterfaces';
import { convertDateTimeFormatForPayload } from '../../Common/Utils/DateUtils';
import { IRFQTemplateBackendResponse } from '../../Global/Interfaces/TemplateInterface';
import { ProjectPermissions } from '../../Organizations/Admin/Interfaces/UsersInterface';
import { CustomFieldType } from '../../SRM/Constants/Interfaces/documentTemplate';
import { IProjectTemplateStructure } from '../Template/ProjectTemplate';
import {
    CreateProjectErrorFieldEnum,
    ICreateProjectPayload,
    IMultipleProjectTemplate,
    IProjectCustomField,
    IProjectCustomSection,
    IProjectDetailsResponse,
    IProjectErrors,
    IProjectPayload,
    IProjectSectionMode,
    IProjectTemplate,
    IProjectTemplateCustomFieldOptions,
    IProjectTemplateStandardFieldOptions,
    ISectionField,
    ProjectSectionMode,
    ProjectStatusEnum,
    ProjectTemplateFWSectionFieldsEnum,
    ProjectTemplateStandardFieldBackendNames,
} from '../interface/project.model';

export const ONGOING_PROJECT_URL = '/custom/cost-tracking/projects/ongoing/';
export const DRAFT_PROJECT_URL = '/custom/cost-tracking/projects/draft/';
export const FINISHED_PROJECT_URL = '/custom/cost-tracking/projects/finished/';

export const BlankCreateProject: ICreateProjectPayload = {
    buyer_entity_id: '',
    project_code: '',
    project_manager_ids: [],
    project_name: '',
    template_id: '',
};

export const BlankProject: IProjectPayload = {
    project_code: '',
    project_name: '',
    buyer_entity_id: '',
    customer_entity_id: '',
    template_id: '',
    description: '',
    rfq_responsible_user_ids: [],

    project_status: ProjectStatusEnum.DRAFT,
    internal_notes: '',
    validity_from: null,
    validity_to: null,
    tags: [],
    project_type: '',
    attachment_ids: [],
    cost_centre_ids: [],
    project_manager_ids: [],
    custom_sections: [],
};

export const convertTemplateDetailsToProjectFieldsForProject = (
    templateDetails: IRFQTemplateBackendResponse | undefined,
    existingProjectStructure: IProjectTemplateStructure
): IProjectTemplateStructure => {
    let currProjectField = existingProjectStructure.PROJECT_DETAILS;

    templateDetails?.section_list.forEach((sectionDetail) => {
        sectionDetail.section_items.forEach((item) => {
            // make custom for start_Date and end_Date, project_manager w & w/o _id
            if (
                !['start_date', 'end_date', 'project_manager'].includes(
                    item.name.split(' ').join('_').toLowerCase()
                )
            ) {
                currProjectField.forEach((projectField: any) => {
                    if (
                        projectField.backendField ===
                        item.name.split(' ').join('_').toLowerCase()
                    ) {
                        projectField.is_hidden =
                            item.additional_information?.is_hidden;

                        projectField.required = item.is_required;
                    }
                });
            } else if (
                item.name.split(' ').join('_').toLowerCase() === 'start_date'
            ) {
                currProjectField.forEach((projectField: any) => {
                    if (projectField.backendField === 'validity_from') {
                        projectField.is_hidden =
                            item.additional_information?.is_hidden;

                        projectField.required = item.is_required;
                    }
                });
            } else if (
                item.name.split(' ').join('_').toLowerCase() === 'end_date'
            ) {
                currProjectField.forEach((projectField: any) => {
                    if (projectField.backendField === 'validity_to') {
                        projectField.is_hidden =
                            item.additional_information?.is_hidden;

                        projectField.required = item.is_required;
                    }
                });
            } else if (
                item.name.split(' ').join('_').toLowerCase() ===
                'project_manager'
            ) {
                currProjectField.forEach((projectField: any) => {
                    if (projectField.backendField === 'project_manager_ids') {
                        projectField.is_hidden =
                            item.additional_information?.is_hidden;

                        projectField.required = item.is_required;
                    }
                });
            }
        });
    });
    return existingProjectStructure;
};

export const trimProjectPayload = (
    payload: IProjectPayload
): IProjectPayload => ({
    ...payload,
    project_code: payload.project_code.trim(),
    project_name: payload.project_name.trim(),
    description: payload.description?.trim() ?? null,
    internal_notes: payload.internal_notes?.trim() ?? null,
    tags: payload.tags.map((tag) => tag.trim()),
    custom_sections: payload.custom_sections,
});

export const cleanUpProjectCustomfields = (payload: IProjectPayload) => {
    if (payload.validity_from === '') {
        payload.validity_from = null;
    }
    if (payload.validity_to === '') {
        payload.validity_to = null;
    }

    payload.custom_sections = payload.custom_sections.filter(
        (customSection) =>
            (!Boolean(customSection.status) &&
                customSection.assigned_users?.length !== 0) ||
            Boolean(customSection.status)
    );

    payload.custom_sections.forEach((customSection) => {
        customSection.custom_fields.forEach((field) => {
            switch (field.type) {
                case CustomFieldType.SHORTTEXT:
                case CustomFieldType.LONGTEXT:
                    if (field.text_value === '') field.text_value = null;
                    break;
                case 'DATE':
                    if (field.date_value === '') field.date_value = null;

                    break;
                case 'DATETIME':
                    if (field.datetime_value === '')
                        field.datetime_value = null;
                    else {
                        if (field.datetime_value) {
                            field.datetime_value = moment
                                .parseZone(field.datetime_value) // Parses without converting timezone
                                .toISOString(); // Formats as ISO 8601
                        }
                    }

                    break;
                case 'CURRENCY':
                    if (field.currency === '') field.currency = null;
                    break;
                case 'CHOICE':
                    if (field.text_value === '') field.text_value = null;
                    break;
                case CustomFieldType.INTEGER:
                    if (field.integer_value === '') field.integer_value = null;
                    break;
                case CustomFieldType.FLOAT:
                    if (field.decimal_value?.toString() === '')
                        field.decimal_value = null;
            }
        });
    });

    return payload;
};

export const getEditableValuesForCustomFields = (
    section: IProjectCustomSection
) => {
    section.custom_fields.forEach((customField) => {
        if (customField.type === CustomFieldType.FLOAT) {
            customField = {
                ...cloneDeep(customField),
                decimal_value: '' as any,
            };
        } else if (customField.type === CustomFieldType.INTEGER) {
            customField = {
                ...cloneDeep(customField),
                integer_value: '' as any,
            };
        }
    });
    return section;
};

export const convertProjectTemplateCFToPayload = (
    templateCF: ISectionField,
    templateDetails: IProjectTemplate,
    sectionNameInTemplate: string,
    fieldInPayload: IProjectCustomField | undefined,
    defaultCurrencyId: string
) => {
    const customFieldInfoInTemplate =
        templateCF.fieldType === 'CUSTOM'
            ? templateDetails.fieldSettings[sectionNameInTemplate].customFields[
                  templateCF.field
              ]
            : templateDetails.fieldSettings[sectionNameInTemplate]
                  .standardFields[templateCF.field];

    switch (customFieldInfoInTemplate?.constraints?.fieldType) {
        case CustomFieldType.INTEGER:
            return {
                custom_field_id: fieldInPayload?.custom_field_id ?? null,
                name: customFieldInfoInTemplate.label,
                integer_value: fieldInPayload
                    ? fieldInPayload.type ===
                      customFieldInfoInTemplate.constraints.fieldType
                        ? fieldInPayload.integer_value ?? ''
                        : ''
                    : '',
                type: CustomFieldType.INTEGER,
                description: customFieldInfoInTemplate.description,
                is_locked: true,
                is_required: customFieldInfoInTemplate.buyerSettings.required,
                is_visible: true,
            };
        case CustomFieldType.CURRENCY:
            const defaultCurrency = defaultCurrencyId
                ? defaultCurrencyId
                : null;
            return {
                custom_field_id: fieldInPayload?.custom_field_id ?? null,
                name: customFieldInfoInTemplate.label,
                decimal_value: fieldInPayload
                    ? fieldInPayload.type ===
                      customFieldInfoInTemplate.constraints.fieldType
                        ? fieldInPayload.decimal_value ?? ''
                        : ''
                    : '',
                currency: fieldInPayload
                    ? fieldInPayload.type ===
                      customFieldInfoInTemplate.constraints.fieldType
                        ? fieldInPayload.currency ?? defaultCurrency
                        : defaultCurrency
                    : defaultCurrency,
                type: CustomFieldType.CURRENCY,
                description: customFieldInfoInTemplate.description,
                is_locked: true,
                is_required: customFieldInfoInTemplate.buyerSettings.required,
                is_visible: true,
            };
        case CustomFieldType.BOOLEAN:
            return {
                custom_field_id: fieldInPayload?.custom_field_id ?? null,
                name: customFieldInfoInTemplate.label,
                boolean_value: fieldInPayload
                    ? fieldInPayload.type ===
                      customFieldInfoInTemplate.constraints.fieldType
                        ? fieldInPayload.boolean_value
                        : null
                    : null,
                type: CustomFieldType.BOOLEAN,
                description: customFieldInfoInTemplate.description,
                is_locked: true,
                is_required: customFieldInfoInTemplate.buyerSettings.required,
                is_visible: true,
            };
        case CustomFieldType.SHORTTEXT:
            return {
                custom_field_id: fieldInPayload?.custom_field_id ?? null,
                name: customFieldInfoInTemplate.label,
                text_value: fieldInPayload
                    ? fieldInPayload.type ===
                      customFieldInfoInTemplate.constraints.fieldType
                        ? fieldInPayload.text_value
                        : null
                    : null,
                type: CustomFieldType.SHORTTEXT,
                description: customFieldInfoInTemplate.description,
                is_locked: true,
                is_required: customFieldInfoInTemplate.buyerSettings.required,
                is_visible: true,
            };
        case CustomFieldType.LONGTEXT:
            return {
                custom_field_id: fieldInPayload?.custom_field_id ?? null,
                name: customFieldInfoInTemplate.label,
                text_value: fieldInPayload
                    ? fieldInPayload.type ===
                      customFieldInfoInTemplate.constraints.fieldType
                        ? fieldInPayload.text_value
                        : null
                    : null,
                description: customFieldInfoInTemplate.description,
                type: CustomFieldType.LONGTEXT,
                is_locked: true,
                is_required: customFieldInfoInTemplate.buyerSettings.required,
                is_visible: true,
            };
        case CustomFieldType.DATE:
            return {
                custom_field_id: fieldInPayload?.custom_field_id ?? null,
                name: customFieldInfoInTemplate.label,
                date_value: fieldInPayload
                    ? fieldInPayload.type ===
                      customFieldInfoInTemplate.constraints.fieldType
                        ? fieldInPayload.date_value
                            ? moment(fieldInPayload.date_value).format(
                                  'YYYY-MM-DD'
                              )
                            : null
                        : null
                    : null,
                type: CustomFieldType.DATE,
                description: customFieldInfoInTemplate.description,

                is_locked: true,
                is_required: customFieldInfoInTemplate.buyerSettings.required,
                is_visible: true,
            };

        case CustomFieldType.DATETIME:
            console.log('iuiuiuiui', fieldInPayload);
            return {
                custom_field_id: fieldInPayload?.custom_field_id ?? null,
                name: customFieldInfoInTemplate.label,
                datetime_value: fieldInPayload
                    ? fieldInPayload.type ===
                      customFieldInfoInTemplate.constraints.fieldType
                        ? fieldInPayload.datetime_value
                              ?.split(':')
                              .slice(0, -1)
                              .join(':')
                        : null
                    : null,

                type: CustomFieldType.DATETIME,
                description: customFieldInfoInTemplate.description,

                is_locked: true,
                is_required: customFieldInfoInTemplate.buyerSettings.required,
                is_visible: true,
            };

        case CustomFieldType.FLOAT:
            return {
                custom_field_id: fieldInPayload?.custom_field_id ?? null,
                name: customFieldInfoInTemplate.label,
                decimal_value: fieldInPayload
                    ? fieldInPayload.type ===
                      customFieldInfoInTemplate.constraints.fieldType
                        ? fieldInPayload.decimal_value ?? ''
                        : ''
                    : '',
                type: CustomFieldType.FLOAT,
                description: customFieldInfoInTemplate.description,
                is_locked: true,
                is_required: customFieldInfoInTemplate.buyerSettings.required,
                is_visible: true,
            };

        case CustomFieldType.CHOICE:
            if (
                customFieldInfoInTemplate.constraints.choiceType === 'DROPDOWN'
            ) {
                return {
                    custom_field_id: fieldInPayload?.custom_field_id ?? null,
                    name: customFieldInfoInTemplate.label,
                    text_value: fieldInPayload
                        ? fieldInPayload.type ===
                          customFieldInfoInTemplate.constraints.fieldType
                            ? fieldInPayload.text_value
                            : null
                        : null,
                    type: 'CHOICE',
                    is_locked: true,
                    is_required:
                        customFieldInfoInTemplate.buyerSettings.required,

                    description: customFieldInfoInTemplate.description,
                    is_visible: true,
                };
            } else {
                return {
                    custom_field_id: fieldInPayload?.custom_field_id ?? null,
                    name: customFieldInfoInTemplate.label,
                    multi_choice_value: fieldInPayload
                        ? fieldInPayload.type === CustomFieldType.MULTI_CHOICE
                            ? fieldInPayload.multi_choice_value
                            : []
                        : [],
                    description: customFieldInfoInTemplate.description,
                    type: 'MULTI_CHOICE',
                    is_locked: true,
                    is_required:
                        customFieldInfoInTemplate.buyerSettings.required,
                    is_visible: true,
                };
            }
        case CustomFieldType.ATTACHMENT:
            return {
                custom_field_id: fieldInPayload?.custom_field_id ?? null,
                name: customFieldInfoInTemplate.label,
                attachment_values: fieldInPayload
                    ? fieldInPayload.type === CustomFieldType.ATTACHMENT
                        ? fieldInPayload.attachment_values
                        : []
                    : [],
                type: CustomFieldType.ATTACHMENT,
                description: customFieldInfoInTemplate.description,
                is_locked: true,
                is_required: customFieldInfoInTemplate.buyerSettings.required,
                is_visible: true,
            };
        case CustomFieldType.TEMPLATE:
            return {
                custom_field_id: fieldInPayload?.custom_field_id ?? null,
                name: customFieldInfoInTemplate.label,
                attachment_values: fieldInPayload
                    ? fieldInPayload.type === CustomFieldType.TEMPLATE
                        ? fieldInPayload.attachment_values
                        : []
                    : [],
                type: CustomFieldType.TEMPLATE,
                description: customFieldInfoInTemplate.description,
                is_locked: true,
                is_required: customFieldInfoInTemplate.buyerSettings.required,
                is_visible: true,
            };
        default:
    }
};

export const convertTemplateDetailsToCFPayload = (
    templateDetails: IProjectTemplate,
    projectDetails: IProjectPayload,
    currentUserId: string,
    isCurrentUserCreator: boolean,
    doesCurrentUserHaveProjectEditPermissionInAdmin: boolean,
    defaultCurrencyId: string,
    sectionIndexThatsBeingEdited: number
) => {
    const sectionNames = Object.keys(templateDetails.sections).map(
        (section) => templateDetails.sections[section].label
    );

    let custom_sections: IProjectCustomSection[] = sectionNames.map(
        (sectionInTemplate, index) => {
            const sectionNameInTemplate =
                sectionInTemplate ===
                templateDetails.sections.PROJECT_DETAILS.label
                    ? 'PROJECT_DETAILS'
                    : sectionInTemplate;

            const customFieldsInCurrentSection =
                sectionInTemplate ===
                templateDetails.sections.PROJECT_DETAILS.label
                    ? templateDetails.sections[
                          sectionNameInTemplate
                      ].fieldList.filter(
                          (field) => field.fieldType === 'CUSTOM'
                      )
                    : templateDetails.sections[sectionNameInTemplate].fieldList;

            const currentSectionInPayload = projectDetails.custom_sections.find(
                (section) => section.name === sectionInTemplate
            );

            let autoAssignCurrentUser = false;

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const templatePermissionInTemplate =
                templateDetails.assigned_users.TEMPLATE_LEVEL.find(
                    (user) => user.user_id === currentUserId
                );

            const sectionLevelPermissionInTemplate =
                templateDetails.assigned_users[sectionNameInTemplate]
                    ?.length === 0 &&
                doesCurrentUserHaveProjectEditPermissionInAdmin
                    ? {
                          user_id: currentUserId,
                          permission: ProjectPermissions.PROJECT_ASSIGN_USERS,
                      }
                    : templateDetails.assigned_users[
                          sectionNameInTemplate
                      ].find((user) => user.user_id === currentUserId);

            if (sectionLevelPermissionInTemplate) {
                if (
                    sectionLevelPermissionInTemplate.permission ===
                    ProjectPermissions.PROJECT_ASSIGN_USERS
                ) {
                    autoAssignCurrentUser =
                        true && index === sectionIndexThatsBeingEdited;
                }
            }

            let currentSection: IProjectCustomSection = {
                name: sectionInTemplate,
                last_modified_time:
                    currentSectionInPayload?.last_modified_time ?? null,
                created_datetime:
                    currentSectionInPayload?.created_datetime ?? null,
                assigned_users: autoAssignCurrentUser
                    ? currentSectionInPayload?.assigned_users
                        ? currentSectionInPayload?.assigned_users?.length ===
                              0 &&
                          currentUserId &&
                          isCurrentUserCreator
                            ? [currentUserId]
                            : currentSectionInPayload?.assigned_users
                        : currentUserId && isCurrentUserCreator
                        ? [currentUserId]
                        : []
                    : currentSectionInPayload?.assigned_users ?? [],
                custom_fields: [],
                submission_time:
                    currentSectionInPayload?.submission_time ?? null,
                start_time: currentSectionInPayload?.start_time
                    ? moment(currentSectionInPayload?.start_time).toISOString()
                    : null,
                status:
                    currentSectionInPayload?.status ??
                    ProjectStatusEnum.ASSIGNING_USERS,
                custom_section_id: currentSectionInPayload
                    ? currentSectionInPayload.custom_section_id
                    : null,
                target_duration: currentSectionInPayload
                    ? currentSectionInPayload.target_duration
                    : null,
                target_duration_period: currentSectionInPayload
                    ? currentSectionInPayload.target_duration_period
                    : null,
            };

            customFieldsInCurrentSection.forEach((customFieldInTemplate) => {
                const templateSettings =
                    customFieldInTemplate.fieldType === 'CUSTOM'
                        ? templateDetails.fieldSettings[sectionNameInTemplate]
                              .customFields[customFieldInTemplate.field]
                        : templateDetails.fieldSettings[sectionNameInTemplate]
                              .standardFields[customFieldInTemplate.field];

                const customFieldInPayload = projectDetails.custom_sections
                    .find((section) => section.name === sectionInTemplate)
                    ?.custom_fields?.find(
                        (customField) =>
                            customField.name === templateSettings.label
                    );

                if (templateSettings) {
                    const fieldForPayload: any =
                        convertProjectTemplateCFToPayload(
                            customFieldInTemplate,
                            templateDetails,
                            sectionNameInTemplate,
                            customFieldInPayload,
                            defaultCurrencyId
                        );

                    currentSection.custom_fields.push(fieldForPayload);
                }
            });

            return currentSection;
        }
    );

    return custom_sections;
};

export const convertProjectDetailsResponseToProjectDetailsPayload = (
    projectDetailsResponse: IProjectDetailsResponse
) => {
    const formattedData: IProjectPayload = {
        project_code: projectDetailsResponse.project_code,
        customer_entity_id: projectDetailsResponse.customer_entity,
        rfq_responsible_user_ids:
            projectDetailsResponse.rfq_responsible_users?.map(
                (user) => user.user_id
            ) ?? [],
        project_name: projectDetailsResponse.project_name,
        project_manager_ids: projectDetailsResponse.project_managers.map(
            (manager) => manager.user_id
        ),
        buyer_entity_id: projectDetailsResponse.buyer_entity,
        description: projectDetailsResponse.description,
        custom_sections: projectDetailsResponse.custom_sections,
        internal_notes: projectDetailsResponse.internal_notes,
        project_type: '',

        validity_from: projectDetailsResponse.validity_from
            ? convertDateTimeFormatForPayload(
                  projectDetailsResponse.validity_from
              )
            : null,
        validity_to: projectDetailsResponse.validity_to
            ? convertDateTimeFormatForPayload(
                  projectDetailsResponse.validity_to
              )
            : null,
        tags: projectDetailsResponse.tags,

        template_id: projectDetailsResponse?.additional_details?.template_id,
        status: projectDetailsResponse.status,
        project_status: projectDetailsResponse.project_status,
        attachment_ids: projectDetailsResponse.attachments.map(
            (attachment) => attachment.attachment_id
        ),
        attachments: projectDetailsResponse.attachments,
        cost_centre_ids: projectDetailsResponse.cost_centres,
    };

    return formattedData;
};

export const validateProjectDetails = ({
    field,
    newProjectDetails,
    errors,
    templateDetails,
}: {
    field: CreateProjectErrorFieldEnum | 'ALL';
    newProjectDetails: IProjectPayload;
    errors: IProjectErrors;
    templateDetails: IProjectTemplate;
}): IProjectErrors => {
    let newError = cloneDeep(errors);

    if (isEqual(errors, {})) {
        errors = {
            PROJECT_DETAILS: {},
        };
    }

    if (field === CreateProjectErrorFieldEnum.project_code || field === 'ALL') {
        if (
            newError.PROJECT_DETAILS &&
            (!Boolean(newProjectDetails?.project_code) ||
                newProjectDetails?.project_code?.trim() === '')
        ) {
            newError.PROJECT_DETAILS['project_code'] = {
                error: 'Field is required',
            };
        } else if (
            newError.PROJECT_DETAILS &&
            newError.PROJECT_DETAILS['project_code']
        ) {
            delete newError.PROJECT_DETAILS['project_code'];
        }
    }

    if (field === CreateProjectErrorFieldEnum.project_name || field === 'ALL') {
        if (
            !Boolean(newProjectDetails?.project_name) ||
            newProjectDetails?.project_name?.trim() === ''
        ) {
            if (newError.PROJECT_DETAILS)
                newError.PROJECT_DETAILS['project_name'] = {
                    error: 'Field is required',
                };
        } else if (
            newError.PROJECT_DETAILS &&
            newError.PROJECT_DETAILS['project_name']
        ) {
            delete newError.PROJECT_DETAILS['project_name'];
        }
    }
    if (
        field === CreateProjectErrorFieldEnum.customer_entity_id ||
        field === 'ALL'
    ) {
        if (
            (templateDetails.fieldSettings.PROJECT_DETAILS.standardFields[
                ProjectTemplateFWSectionFieldsEnum.CUSTOMER_NAME
            ].buyerSettings.required &&
                templateDetails.fieldSettings.PROJECT_DETAILS.standardFields[
                    ProjectTemplateFWSectionFieldsEnum.CUSTOMER_NAME
                ].buyerSettings.showField &&
                !Boolean(newProjectDetails?.customer_entity_id)) ||
            newProjectDetails?.customer_entity_id?.trim() === ''
        ) {
            if (newError.PROJECT_DETAILS)
                newError.PROJECT_DETAILS['customer_entity_id'] = {
                    error: 'Field is required',
                };
        } else if (
            newError.PROJECT_DETAILS &&
            newError.PROJECT_DETAILS['customer_entity_id']
        ) {
            delete newError.PROJECT_DETAILS['customer_entity_id'];
        }
    }

    if (field === CreateProjectErrorFieldEnum.description || field === 'ALL') {
        if (
            templateDetails.fieldSettings.PROJECT_DETAILS.standardFields[
                ProjectTemplateFWSectionFieldsEnum.DESCRIPTION
            ].buyerSettings.required &&
            templateDetails.fieldSettings.PROJECT_DETAILS.standardFields[
                ProjectTemplateFWSectionFieldsEnum.DESCRIPTION
            ].buyerSettings.showField &&
            (!Boolean(newProjectDetails?.description) ||
                newProjectDetails?.description?.trim() === '')
        ) {
            if (newError.PROJECT_DETAILS)
                newError.PROJECT_DETAILS['description'] = {
                    error: 'Field is required',
                };
        } else if (
            newError.PROJECT_DETAILS &&
            newError.PROJECT_DETAILS['description']
        ) {
            delete newError.PROJECT_DETAILS['description'];
        }
    }

    if (
        field === CreateProjectErrorFieldEnum.validity_from ||
        field === 'ALL'
    ) {
        if (
            templateDetails.fieldSettings.PROJECT_DETAILS.standardFields[
                ProjectTemplateFWSectionFieldsEnum.START_DATE
            ].buyerSettings.required &&
            templateDetails.fieldSettings.PROJECT_DETAILS.standardFields[
                ProjectTemplateFWSectionFieldsEnum.START_DATE
            ].buyerSettings.showField &&
            (!Boolean(newProjectDetails?.validity_from) ||
                newProjectDetails?.validity_from?.trim() === '')
        ) {
            if (newError.PROJECT_DETAILS) {
                newError.PROJECT_DETAILS['validity_from'] = {
                    error: 'Field is required',
                };
            } else {
                newError.PROJECT_DETAILS = {
                    validity_from: {
                        error: 'Field is required',
                    },
                };
            }
        } else if (
            new Date(newProjectDetails?.validity_from ?? '') < new Date()
        ) {
            newError.PROJECT_DETAILS['validity_from'] = {
                error: 'Start date cannot be in the past',
            };
        } else if (
            newError.PROJECT_DETAILS &&
            new Date(newProjectDetails?.validity_from ?? '') >
                new Date(newProjectDetails?.validity_to ?? '')
        ) {
            newError.PROJECT_DETAILS['validity_from'] = {
                error: 'Start date cannot exceed end date',
            };
        } else if (
            newError.PROJECT_DETAILS &&
            newError.PROJECT_DETAILS['validity_from']
        ) {
            delete newError.PROJECT_DETAILS['validity_from'];
        }
    }

    if (field === CreateProjectErrorFieldEnum.validity_to || field === 'ALL') {
        if (
            templateDetails.fieldSettings.PROJECT_DETAILS.standardFields[
                ProjectTemplateFWSectionFieldsEnum.END_DATE
            ].buyerSettings.required &&
            templateDetails.fieldSettings.PROJECT_DETAILS.standardFields[
                ProjectTemplateFWSectionFieldsEnum.END_DATE
            ].buyerSettings.showField &&
            (!Boolean(newProjectDetails?.validity_to) ||
                newProjectDetails?.validity_to?.trim() === '')
        ) {
            if (newError.PROJECT_DETAILS)
                newError.PROJECT_DETAILS['validity_to'] = {
                    error: 'Field is required',
                };
        } else if (
            newError.PROJECT_DETAILS &&
            new Date(newProjectDetails?.validity_to ?? '') < new Date()
        ) {
            newError.PROJECT_DETAILS['validity_to'] = {
                error: 'End date cannot be in the past',
            };
        } else if (
            newError.PROJECT_DETAILS &&
            new Date(newProjectDetails?.validity_from ?? '') >
                new Date(newProjectDetails?.validity_to ?? '')
        ) {
            newError.PROJECT_DETAILS['validity_to'] = {
                error: 'Start date cannot exceed end date',
            };
        } else if (
            newError.PROJECT_DETAILS &&
            newError.PROJECT_DETAILS['validity_to']
        ) {
            delete newError.PROJECT_DETAILS['validity_to'];
        }
    }

    if (
        field === CreateProjectErrorFieldEnum.custom_fields ||
        field === 'ALL'
    ) {
        const sectionListInTemplate = Object.keys(templateDetails.sections);
        sectionListInTemplate.forEach((section) => {
            const fieldsInCurrentSection =
                newProjectDetails?.custom_sections?.find(
                    (customSection) =>
                        customSection.name ===
                        (section === 'PROJECT_DETAILS'
                            ? templateDetails.sections.PROJECT_DETAILS.label
                            : section)
                );
            if (!Boolean(newError[section])) {
                newError[section] = {};
            }

            if (fieldsInCurrentSection) {
                fieldsInCurrentSection?.custom_fields?.forEach(
                    (customField) => {
                        const isBuiltField =
                            templateDetails.sections[section].fieldList.find(
                                (field) =>
                                    field.field === customField.name ||
                                    field.field ===
                                        ProjectTemplateStandardFieldBackendNames[
                                            customField.name
                                        ]
                            )?.fieldType === 'STANDARD';

                        if (Boolean(newError[section])) {
                            delete newError[section][customField.name];
                        }

                        const fieldSettingFromTemplate = isBuiltField
                            ? templateDetails.fieldSettings[section]
                                  .standardFields[
                                  ProjectTemplateStandardFieldBackendNames[
                                      customField.name
                                  ]
                              ]
                            : templateDetails.fieldSettings[section]
                                  ?.customFields[customField.name];

                        if (fieldSettingFromTemplate) {
                            if (
                                fieldSettingFromTemplate.buyerSettings
                                    .required &&
                                fieldSettingFromTemplate.buyerSettings
                                    .showField &&
                                checkIfValueIsEmpty(
                                    fieldSettingFromTemplate,
                                    customField
                                )
                            ) {
                                newError[section][customField.name] = {
                                    error: 'Field is required',
                                };
                            }
                        }
                    }
                );
            }
            if (isEqual(newError[section], {})) {
                delete newError[section];
            }
        });
    }
    return newError;
};

const checkIfValueIsEmpty = (
    fieldInfoIntemplate:
        | IProjectTemplateStandardFieldOptions
        | IProjectTemplateCustomFieldOptions,
    fieldInfo: IProjectCustomField
) => {
    const otherCondition =
        fieldInfoIntemplate.buyerSettings.required &&
        fieldInfoIntemplate.buyerSettings.showField;
    switch (fieldInfo.type) {
        case CustomFieldType.BOOLEAN:
            return otherCondition && fieldInfo.boolean_value === null;
        case CustomFieldType.CHOICE:
        case CustomFieldType.SHORTTEXT:
        case CustomFieldType.LONGTEXT:
            return otherCondition && !Boolean(fieldInfo.text_value);
        case CustomFieldType.DATE:
            return otherCondition && !Boolean(fieldInfo.date_value);
        case CustomFieldType.CURRENCY:
            return (
                otherCondition &&
                (!Boolean(fieldInfo.currency) ||
                    !Boolean(fieldInfo.decimal_value))
            );
        case CustomFieldType.DATETIME:
            return otherCondition && !Boolean(fieldInfo.datetime_value);
        case CustomFieldType.INTEGER:
            return otherCondition && fieldInfo.integer_value === null;
        case CustomFieldType.FLOAT:
            return otherCondition && !Boolean(fieldInfo.decimal_value);
        case CustomFieldType.ATTACHMENT:
        case CustomFieldType.TEMPLATE:
            return otherCondition && fieldInfo.attachment_values?.length === 0;
        case CustomFieldType.MULTI_CHOICE:
            return otherCondition && fieldInfo.multi_choice_value?.length === 0;
    }
};

export const checkPermissionInTemplateList = (
    templates: IMultipleProjectTemplate[],
    permission: 'CREATE' | 'ASSIGN' | 'EDIT',
    userId: string,
    searchLevel: 'TEMPLATE' | 'SECTION'
) => {
    const listOfTemplateWithPermission = templates?.filter(
        (template) =>
            template.assigned_users?.length === 0 ||
            template?.assigned_users?.some(
                (user) =>
                    user.user_id === userId && user.permission === permission
            )
    );

    const listOfTemplates: TemplateData[] = listOfTemplateWithPermission.map(
        (temp) => ({
            template_id: temp.template_id,
            auto_reminders: temp.auto_reminders,
            created_at: temp.created_on,
            created_by: temp.creator_name,
            description: temp.description,
            is_default: temp.is_default,
            is_global: temp.is_global,
            last_modified_by: temp.last_edited_by_name,
            last_modified: temp.last_edited_on,
            deleted_datetime: temp.deleted_datetime,
            items: [],
            tags: temp.tags,
            template_type: temp.type as templateType,
            template_name: temp.name,
            template_status: temp.status as TemplateStatus,
            template_description: temp.description,
            template_entity_id: temp.entity_id,
            template_entity_name: temp.entity_name,
        })
    );

    return listOfTemplates;
};

export const getProjectStatusTextAndColor = (
    projectStatus: ProjectStatusEnum
) => {
    switch (projectStatus) {
        case ProjectStatusEnum.ASSIGNING_USERS:
            return {
                label: 'Pending',
                color: '',
            };
        case ProjectStatusEnum.REVISED:
            return {
                label: 'Revising',
                color: 'warning.main',
            };
        case ProjectStatusEnum.DRAFT:
            return {
                label: 'Draft',
                color: '',
            };
        case ProjectStatusEnum.ONGOING:
            return {
                label: 'Ongoing',
                color: 'success.main',
            };
        case ProjectStatusEnum.EXPIRED:
            return {
                label: 'Expired',
                color: 'error.main',
            };
        case ProjectStatusEnum.SUBMITTED:
            return {
                label: 'Submitted',
                color: 'success.main',
            };
        default:
            return {
                label: capitaliseFirstLetter(projectStatus?.toLowerCase()),
                color: '',
            };
    }
};

export const udpateProjectSectionMode = (
    projectDetailsResponse: IProjectPayload,
    customSectionName: string[],
    currentUserId: string,
    templateDetails: IProjectTemplate,
    isUserProjectManager: boolean,
    doesCurrentUserHaveProjectAssignPermissionInAdmin: boolean
) => {
    let projectSectionMode: IProjectSectionMode = {
        PROJECT_DETAILS: ProjectSectionMode.VIEW,
    };

    customSectionName.forEach((section) => {
        const sectionNameInResponse =
            section === 'PROJECT_DETAILS'
                ? templateDetails.sections.PROJECT_DETAILS.label
                : section;
        const currentSectionIndex =
            projectDetailsResponse.custom_sections.findIndex(
                (customSection) => customSection.name === sectionNameInResponse
            );

        if (currentSectionIndex !== -1) {
            let isSectionEditable = false;

            if (
                projectDetailsResponse.custom_sections[currentSectionIndex]
                    ?.name === templateDetails.sections.PROJECT_DETAILS.label
            ) {
                const sectionStatus =
                    projectDetailsResponse.custom_sections[currentSectionIndex]
                        .status;

                if (
                    sectionStatus === ProjectStatusEnum.DRAFT ||
                    sectionStatus === ProjectStatusEnum.REVISED ||
                    sectionStatus === ProjectStatusEnum.ASSIGNING_USERS
                ) {
                    isSectionEditable = true;
                }
            } else {
                const sectionStatus =
                    projectDetailsResponse.custom_sections[currentSectionIndex]
                        .status;
                if (
                    sectionStatus === ProjectStatusEnum.DRAFT ||
                    sectionStatus === ProjectStatusEnum.REVISED
                ) {
                    isSectionEditable = true;
                }

                const doesPreviousSectionExist = currentSectionIndex !== 0;

                if (doesPreviousSectionExist) {
                    const previousSectionState =
                        projectDetailsResponse.custom_sections[
                            currentSectionIndex - 1
                        ].status;
                    if (
                        sectionStatus === ProjectStatusEnum.ASSIGNING_USERS &&
                        [
                            ProjectStatusEnum.REVISED,
                            ProjectStatusEnum.SUBMITTED,
                        ].includes(previousSectionState as ProjectStatusEnum)
                    ) {
                        isSectionEditable = true;
                    }
                } else {
                    isSectionEditable = true;
                }
            }

            if (isSectionEditable) {
                const userShortListedInTemplate =
                    templateDetails.assigned_users[section]?.length === 0
                        ? doesCurrentUserHaveProjectAssignPermissionInAdmin
                        : templateDetails.assigned_users[section].some(
                              (user) =>
                                  user.user_id === currentUserId &&
                                  user.permission ===
                                      ProjectPermissions.PROJECT_ASSIGN_USERS
                          );

                const isUserAssignedToSection =
                    projectDetailsResponse.custom_sections[
                        currentSectionIndex
                    ].assigned_users.includes(currentUserId) ||
                    (templateDetails.assigned_users[section]?.length > 0
                        ? templateDetails.assigned_users[section].some(
                              (user) =>
                                  user.user_id === currentUserId &&
                                  user.permission ===
                                      ProjectPermissions.PROJECT_ASSIGN_USERS
                          ) ||
                          templateDetails.assigned_users.TEMPLATE_LEVEL.some(
                              (user) =>
                                  user.user_id === currentUserId &&
                                  user.permission ===
                                      ProjectPermissions.PROJECT_ASSIGN_MANAGERS
                          )
                        : doesCurrentUserHaveProjectAssignPermissionInAdmin);

                if (
                    userShortListedInTemplate ||
                    isUserProjectManager ||
                    isUserAssignedToSection
                ) {
                    const allowUserToEdit =
                        isUserAssignedToSection ||
                        isUserProjectManager ||
                        userShortListedInTemplate;

                    if (currentSectionIndex !== 0) {
                        const previousSectionState =
                            projectDetailsResponse.custom_sections[
                                currentSectionIndex - 1
                            ].status;

                        if (
                            previousSectionState ===
                            ProjectStatusEnum.ASSIGNING_USERS
                        ) {
                            projectSectionMode[section] =
                                ProjectSectionMode.VIEW;
                        } else if (
                            previousSectionState ===
                                ProjectStatusEnum.SUBMITTED ||
                            previousSectionState === ProjectStatusEnum.REVISED
                        ) {
                            if (allowUserToEdit) {
                            }
                            projectSectionMode[section] = allowUserToEdit
                                ? ProjectSectionMode.EDIT
                                : ProjectSectionMode.VIEW;
                        } else if (
                            previousSectionState === ProjectStatusEnum.DRAFT
                        ) {
                            projectSectionMode[section] =
                                ProjectSectionMode.VIEW;
                        }
                    } else {
                        if (allowUserToEdit) {
                        }
                        projectSectionMode[section] = allowUserToEdit
                            ? ProjectSectionMode.EDIT
                            : ProjectSectionMode.VIEW;
                    }
                } else {
                    if (isUserProjectManager) {
                        const previousSectionState =
                            projectDetailsResponse.custom_sections[
                                currentSectionIndex - 1
                            ].status;

                        if (
                            previousSectionState ===
                            ProjectStatusEnum.ASSIGNING_USERS
                        ) {
                            projectSectionMode[section] =
                                ProjectSectionMode.VIEW;
                        } else if (
                            previousSectionState ===
                                ProjectStatusEnum.SUBMITTED ||
                            previousSectionState === ProjectStatusEnum.REVISED
                        ) {
                            projectSectionMode[section] =
                                ProjectSectionMode.EDIT;
                        } else if (
                            previousSectionState === ProjectStatusEnum.DRAFT
                        ) {
                            projectSectionMode[section] =
                                ProjectSectionMode.VIEW;
                        }
                    }
                    // if the user is not shortlisted in the template but the user has assigned to permission
                    projectSectionMode[section] = ProjectSectionMode.VIEW;
                }
            } else {
                projectSectionMode[section] = ProjectSectionMode.VIEW;
            }
        } else {
            const areAllSectionsSubmitted =
                projectDetailsResponse.custom_sections.some(
                    (section) => section.status === ProjectStatusEnum.SUBMITTED
                );

            projectSectionMode[section] = ProjectSectionMode.VIEW;
            if (areAllSectionsSubmitted) {
                const userShortListedInTemplate =
                    templateDetails.assigned_users[section].some(
                        (user) => user.user_id === currentUserId
                    );
                if (userShortListedInTemplate) {
                }
                projectSectionMode[section] = userShortListedInTemplate
                    ? ProjectSectionMode.EDIT
                    : ProjectSectionMode.VIEW;
            }
        }
    });

    if (projectDetailsResponse.custom_sections?.length === 0) {
        projectSectionMode.PROJECT_DETAILS = ProjectSectionMode.EDIT;
    }

    return projectSectionMode;
};

export const findUserInSectionAssignedUsersList = (
    currentUserid: string,
    templateDetails: IProjectTemplate
) => {
    const templateSections = Object.keys(templateDetails.sections);

    let noUserShortlistedForAnySection = false;

    templateSections.forEach((section) => {
        if (templateDetails.assigned_users[section]?.length === 0) {
            noUserShortlistedForAnySection = true;
        }
    });

    let userShortlistedForAnySection = false;

    templateSections.forEach((section) => {
        const usersAssignedForCurrentSection =
            templateDetails.assigned_users[section];

        const isUserAssigned = usersAssignedForCurrentSection.find(
            (user) => user.user_id === currentUserid
        );

        if (isUserAssigned && isUserAssigned.permission === 'ASSIGN') {
            userShortlistedForAnySection = true;
        }
    });

    return {
        noUserShortlistedForAnySection,
        userShortlistedForAnySection,
    };
};

export const getUpdatedProjectStatus = (
    currentStatus: ProjectStatusEnum | null
) => {
    switch (currentStatus) {
        case ProjectStatusEnum.SUBMITTED:
            return ProjectStatusEnum.REVISED;
        case ProjectStatusEnum.DRAFT:
            return ProjectStatusEnum.DRAFT;
        case ProjectStatusEnum.ASSIGNING_USERS:
            return ProjectStatusEnum.DRAFT;
        case ProjectStatusEnum.REVISED:
            return ProjectStatusEnum.REVISED;

        default:
            return ProjectStatusEnum.DRAFT;
    }

    // if (
    //     !Boolean(
    //         newProjectDetails.custom_sections[projectDetailSectionIndex].status
    //     )
    // ) {
    //     newProjectDetails.custom_sections[projectDetailSectionIndex].status =
    //         'DRAFT';
    // } else if (
    //     newProjectDetails.custom_sections[projectDetailSectionIndex].status ===
    //     ProjectStatusEnum.SUBMITTED
    // ) {
    //     newProjectDetails.custom_sections[projectDetailSectionIndex].status =
    //         ProjectStatusEnum.REVISED;
    // }
};

export const getProjectAttachmentIdsFromCF = (
    projectDetails: IProjectPayload
) => {
    let attachmentIds: string[] = [];

    projectDetails.custom_sections.forEach((section) => {
        section.custom_fields.forEach((customField) => {
            if (
                customField.type === CustomFieldType.ATTACHMENT &&
                customField.attachment_values
            ) {
                attachmentIds = [
                    ...attachmentIds,
                    ...customField.attachment_values,
                ];
            }
        });
    });

    return attachmentIds;
};

export const getLocalISOTime = (twDate: string) => {
    var d = new Date(twDate);

    if (moment(d).isValid()) {
        var utcd = Date.UTC(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            d.getHours(),
            d.getMinutes(),
            d.getSeconds(),
            d.getMilliseconds()
        );

        // obtain local UTC offset and convert to msec
        const localOffset = d.getTimezoneOffset() * 60000;
        var newdate = new Date(utcd + localOffset);
        return newdate.toISOString().replace('.000', '');
    } else return '';
};

export const getISOTime = (twDate: string) => {
    var d = new Date(twDate);

    if (moment(d).isValid()) {
        var utcd = Date.UTC(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            d.getHours(),
            d.getMinutes(),
            d.getSeconds(),
            d.getMilliseconds()
        );
        var newdate = new Date(utcd);
        return newdate.toISOString().replace('.000', '');
    } else return '';
};

export const getLocalTimeSone = () => {
    var d = new Date();

    return d.getTimezoneOffset() * 60 * 1000 * -1;
};

import {
    ASModule,
    ASModuleSetting,
} from '../Interfaces/AdminSettingsInterface';

export const asModuleNameMap: { [key in ASModule]: string } = {
    // [ASModule.ADMIN]: 'Admin', removed this for prod
    [ASModule.EVENT]: 'Events',
    [ASModule.PURCHASE_ORDER]: 'Purchase Orders',
    [ASModule.INVOICE]: 'Invoices',
    [ASModule.GOODS_RECEIPT]: 'Goods Receipt',
    [ASModule.QUALITY_CHECK]: 'Quality Check',
    [ASModule.PAYMENT]: 'Payments',
    [ASModule.REQUISITION]: 'Requisitions',
    [ASModule.PROJECT]: 'Project',
    [ASModule.QUOTE_CALCULATOR]: 'Quote Calculator',
};

export const asModuleValuesMap: {
    [key in ASModuleSetting]: {
        text: string; // required
        tooltip: string; //empty string to not show the tooltip
        label: string; // empty string to not show the label
        hidden?: boolean; // true to hide the setting
    };
} = {
    // ADMIN
    // [ASModuleSetting.MAX_DECIMAL_PRECISION]: {
    //     text: 'allow userss to decide decimal number',
    //     tooltip: '',
    //     label: '',
    // }, removed this for prod
    // EVENT
    [ASModuleSetting.EVENT_ALLOW_CUSTOM_SPECS]: {
        text: 'Allow users to add custom specifications for items while creating events',
        tooltip: '',
        label: '',
    },
    [ASModuleSetting.EVENT_ALLOW_SHARE_TARGET_PRICE]: {
        text: 'While creating events, share target rate with vendors by default',
        tooltip: 'Can be overridden while creating event',
        label: '',
    },
    [ASModuleSetting.EVENT_ALLOW_ADDING_ITEMS]: {
        text: 'Allow users without Admin privileges to add items to Item Directory while creating events',
        tooltip: '',
        label: '',
    },
    [ASModuleSetting.EVENT_PRICE_LIMIT]: {
        text: 'Allow events up to a specified value limit to be submitted to vendors without approval',
        tooltip: '',
        label: 'Add limit',
        hidden: true,
    },
    [ASModuleSetting.EVENT_ALLOW_NON_PREFERRED_VENDORS]: {
        text: 'Allow users to add non-preferred vendors to events',
        tooltip: '',
        label: '',
    },
    [ASModuleSetting.EVENT_MANDATORY_EXCLUDE_ITEM_NOTE]: {
        text: 'Require vendors to provide note for excluded items in their bids.',
        tooltip: '',
        label: '',
    },
    [ASModuleSetting.EVENT_IS_SELLER_ALLOWED_TO_RESPOND_TO_EXCLUDED_ITEMS]: {
        text: 'Allow vendors to bid for buyer-excluded items.',
        tooltip: '',
        label: '',
    },
    [ASModuleSetting.COMBINE_BOM_ITEMS]: {
        text: 'Automatically combine identical items from different BOMs when creating events.',
        tooltip: '',
        label: '',
    },
    [ASModuleSetting.EVENT_SHOW_ALTERNATES_SELLER]: {
        text: 'Show alternates to seller for a BOM in event.',
        tooltip: '',
        label: '',
    },
    [ASModuleSetting.EVENT_ALLOW_INVITING_VENDORS]: {
        text: 'Allow users with Admin privileges to invite vendors who are not on the Vendor Directory to events',
        tooltip: '',
        label: '',
    },
    [ASModuleSetting.EVENT_ALLOW_ADDING_ADDRESS]: {
        text: 'Allow users without admin priviliges to add addresses for entities while creating events',
        tooltip: '',
        label: '',
        hidden: true,
    },
    [ASModuleSetting.EVENT_AWARD_TOLERANCE]: {
        text: 'Allow tolerances for quantities while creating RFQ Awards',
        tooltip: '',
        label: 'Tolerance percentage',
        hidden: true,
    },

    // PO
    [ASModuleSetting.PO_ISSUE_VALUE_LIMIT]: {
        text: 'Allow Purchase Orders up to a specified value limit to be issued without approval',
        tooltip: '',
        label: 'Add limit',
        hidden: true,
    },
    [ASModuleSetting.PO_TOLERANCE]: {
        text: 'Allow tolerances for quantities while creating Purchase Orders',
        tooltip: '',
        label: 'Tolerance percentage',
        hidden: true,
    },

    // INVOICE
    [ASModuleSetting.INVOICE_AUTO_HOLD]: {
        text: 'Impose auto-hold on invoices with rates higher than corresponding PO',
        tooltip:
            'If rate, shipping or taxes for any item have been increased by the invoice creator then the invoice will automatically be put on hold',
        label: '',
    },

    // GR
    [ASModuleSetting.GR_ALLOW_INV_CREATION]: {
        text: 'Allow users to create Goods Receipts against documents other than invoices, such as delivery challans or bills',
        tooltip: '',
        label: '',
    },
    [ASModuleSetting.GR_TOLERANCE]: {
        text: 'Allow tolerances for quantities while creating Goods Receipts',
        tooltip: '',
        label: 'Tolerance percentage',
        hidden: true,
    },

    // QC
    [ASModuleSetting.QC_ALLOW_MULTIPLE_SECONDARY_ROUNDS]: {
        text: 'Allow users to perform multiple rounds of Secondary QC for an item',
        tooltip: '',
        label: '',
    },
    [ASModuleSetting.QC_ALLOW_UNVERIFIED_INVOICE]: {
        text: 'Allow users to perform quality checks against unverified invoices',
        tooltip: '',
        label: '',
    },
    [ASModuleSetting.QC_PRIMARY_SAMPLE_PERCENTAGE]: {
        text: 'Allow primary QC for a sample',
        tooltip: '',
        label: 'Default sampling percentage',
    },
    [ASModuleSetting.QC_GENERATE_SEPARATE_ARN]: {
        text: 'Generate separate ARNs for QC acceptances and rejections',
        tooltip: '',
        label: '',
    },

    // PAYMENTS
    [ASModuleSetting.PAYMENT_ADVANCE_DAYS_COUNT]: {
        text: 'Allow payments to be made in advance of the payment due date',
        tooltip:
            'Minimum number of days before the payment due date for payments to be allowed',
        label: 'No. of days',
        hidden: true,
    },
    [ASModuleSetting.PAYMENT_ALLOW_PREPAYMENT_MORE_THAN_PO]: {
        text: 'Allow users to make prepayments with values exceeding the prepayment amount specified in the corresponding PO',
        tooltip: '',
        label: '',
    },
    [ASModuleSetting.PAYMENTS_ALLOW_BEFORE_QC]: {
        text: 'Allow users to make payments for items before quality checks have been completed',
        tooltip: '',
        label: '',
    },
    [ASModuleSetting.PAYMENT_ALLOW_UNVERIFIED_INVOICE]: {
        text: 'Allow users to make payments against unverified invoices',
        tooltip: '',
        label: '',
        hidden: true,
    },

    // REQUISITION
    [ASModuleSetting.REQUISITION_ALLOW_PRICING_ANALYTICS]: {
        text: 'Allows users to access past pricing analytics data',
        tooltip: '',
        label: '',
    },

    // PROJECT
    [ASModuleSetting.PROJECT_ALLOW_INVITING_CUSTOMERS]: {
        text: 'Allow users without Admin privileges to add a customer to the Customer Directory while creating projects',
        tooltip: '',
        label: '',
        hidden: undefined,
    },

    // QUOTE CALCULATOR
    [ASModuleSetting.COSTING_SHEET_ASSIGN_PROJECT_CREATOR]: {
        text: 'While creating the quote, give assign access to the project creators for the quote by default',
        tooltip: '',
        label: '',
        hidden: undefined,
    },
};

import { cloneDeep } from 'lodash';
import { templateType } from '../../BuyerTemplates/Interfaces/TemplatePageInterfaces';
import { getFWDateTime } from '../../Common/Utils/DateUtils';
import { getFieldTypeContraintsFromBackend } from '../../FieldTypes/helpers/fieldTypesHelpers';
import { blankProjectTemplate } from '../../Global/Helpers/templateHelpers';
import { ProjectPermissions } from '../../Organizations/Admin/Interfaces/UsersInterface';

import {
    IUserPermissionFEStructure,
    PermissionsPriorityList,
} from '../../BuyerTemplates/Component/ShareSectionPopup';
import { IEntityViewBuyerMaster } from '../../Organizations/SellerAdmin/BuyerMaster/Interfaces/SellerAdminBuyerInterfaces.model';
import { baseApiSlice } from '../../Redux/Slices/BaseApiSlice';
// import { CustomFieldType } from '../../SRM/Constants/Interfaces/documentTemplate';
import { CustomFieldType } from '../../Global/Interfaces/TemplateInterface';
import { del, get, post, put } from '../../Utils/api';
import IProject, {
    DashBoardFilter,
    ICreateProjectPayload,
    IMultipleProjectTemplateApiResponse,
    IProjectAssigenUserListResponse,
    IProjectBackendResponsePagiation,
    IProjectCustomSection,
    IProjectDetailsResponse,
    IProjectPayload,
    IProjectTemplate,
    IProjectTemplateCustomFieldOptions,
    IProjectTemplateNewStructureResponse,
    IProjectTemplateStandardFieldOptions,
    IUpdateProjectPermissionPayload,
    ProjectApiResponse,
    ProjectStatusEnum,
    ProjectTemplateCustomSectionFieldEnum,
    ProjectTemplateFWSectionFieldsEnum,
    ProjectTemplateStandardFieldBackendNames,
    TProjectUsersList,
} from '../interface/project.model';
import {
    IProjectEventSummary,
    IProjectQuoteSummaryDashboardResponse,
} from '../interface/projectSummary';

export const createProjectApi = async (project: ICreateProjectPayload) => {
    try {
        const { data } = await post('/organization/project/create/', project);
        return data as { project_id: string };
    } catch (error) {
        throw error;
    }
};

export const updateProjectApi = async (
    projectuid: string,
    project: IProjectPayload
) => {
    try {
        const { data } = await put(
            `/organization/project/${projectuid}/update/`,
            project
        );

        return data as IProjectDetailsResponse;
    } catch (error) {
        throw error;
    }
};

export const updateSectionStatus = async (
    projectId: string,
    sectionId: string,
    status: ProjectStatusEnum
) => {
    try {
        await put(
            `/organization/project/${projectId}/sections/${sectionId}/update/state/`,
            {
                status,
            }
        );
    } catch (error) {
        throw error;
    }
};

export const restoreProjectVersion = async (
    projectId: string,
    sectionId: string
) => {
    try {
        let res = await put<any, IProjectCustomSection>(
            `/organization/project/${projectId}/sections/${sectionId}/restore/`,
            {}
        );

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const updateProjectPermission = async (
    projectId: string,
    payload: IUpdateProjectPermissionPayload
) => {
    try {
        let res = await put<any, any>(
            `/organization/project/${projectId}/update/pms_and_assigned_users/`,
            payload
        );

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const projectApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        listProjects: builder.query<
            IProject[],
            {
                entity_id?: string;
                isActive?: boolean;
            }
        >({
            query: () => '/organization/project/',
            transformResponse: (
                response: ProjectApiResponse[],
                meta,
                { entity_id, isActive }
            ) => {
                return response
                    .filter((project) => {
                        if (entity_id !== undefined && isActive !== undefined) {
                            return (
                                project.buyer_entity === entity_id &&
                                (isActive
                                    ? project.status ===
                                      ProjectStatusEnum.ACTIVE
                                    : project.status ===
                                      ProjectStatusEnum.INACTIVE)
                            );
                        } else if (entity_id !== undefined) {
                            return project.buyer_entity === entity_id;
                        } else if (isActive !== undefined) {
                            return isActive
                                ? project.status === ProjectStatusEnum.ACTIVE
                                : project.status === ProjectStatusEnum.INACTIVE;
                        }

                        return true;
                    })
                    .map((project) => ({
                        projectUUid: project.project_id,
                        createdOn: getFWDateTime(project.created_datetime),
                        entityUid: project.buyer_entity,
                        entityName: project.buyer_entity_name,
                        description: project.description,
                        internalNotes: project.internal_notes,
                        projectCustomSections: project.custom_sections,
                        createdByUsername: project.created_by_user.name,
                        createdByUserId: project.created_by_user.user_id,
                        customerEntity:
                            project.customer_entity === null
                                ? null
                                : {
                                      entity_id: project?.customer_entity ?? '',
                                      entity_name:
                                          project?.customer_entity_name ?? '',
                                  },
                        linkedCostCenterCount: project.cost_centre_count,
                        templateId: project?.additional_details?.template_id,
                        submittedOn: project.modified_datetime,
                        projectCode: project.project_code,
                        projectManagers: project.project_managers,
                        attachments: project.attachments,
                        modifiedByUsername: project.modified_by_user.name,
                        projectStatus:
                            project.project_status as ProjectStatusEnum,
                        projectName: project.project_name,
                        status: project.status as ProjectStatusEnum,
                        listCostCenterNames:
                            project.cost_centres?.map(
                                (cc) => cc.cost_centre.cost_centre_name
                            ) ?? [],
                        validityFrom: project.validity_from,
                        validityTo: project.validity_to,
                        modifiedOn: project.modified_datetime,
                    }));
            },
        }),
        listOfBuyerEntities: builder.query<
            IEntityViewBuyerMaster[],
            { entityId: string }
        >({
            query: ({ entityId }) => ({
                url: `/organization/buyer_master/admin/entity/${entityId}/buyers/`,
            }),
        }),
        listProjectsViaDasboard: builder.mutation<
            {
                data: IProject[];
                metadata: {
                    current_page: number;
                    has_next: boolean;
                    has_previous: boolean;
                    total_pages: number;
                    page_range: {
                        start: number;
                        stop: number;
                    };
                    count: number;
                };
                counts: {
                    all: number;
                };
            },
            {
                search_text?: string;
                sort_fields?: { field: string; ascending: boolean }[];
                items_per_page?: number;
                page_number: number;
                entity_id?: string;
                tab: string;
                filters: DashBoardFilter | null;
                query_data: {
                    status?:
                        | ProjectStatusEnum.ACTIVE
                        | ProjectStatusEnum.INACTIVE;
                };
            }
        >({
            query: ({
                search_text = '',
                sort_fields = [],
                page_number = 1,
                items_per_page = 10,
                entity_id,
                tab,
                filters,
                query_data,
            }) => {
                return {
                    url: '/dashboard/',
                    method: 'POST',
                    body: {
                        dashboard_view: `${entity_id ? 'entity_' : ''}project`,
                        tab,
                        search_text,
                        sort_fields,
                        page_number,
                        items_per_page,
                        query_data,
                        ...(entity_id && {
                            query_data: {
                                entity_id,
                            },
                        }),
                        filters,
                    },
                };
            },
            transformResponse: (
                response: IProjectBackendResponsePagiation
            ) => ({
                metadata: response.metadata,
                counts: response.counts,
                data: response.data.map((project) => ({
                    projectUUid: project.project_id,
                    createdOn: getFWDateTime(project.created_datetime),
                    entityName: project.buyer_entity_name,
                    entityUid: project.buyer_entity,
                    description: project.description,
                    internalNotes: project.internal_notes,
                    createdByUserId: project.created_by_user.user_id,
                    customerEntity:
                        project.customer_entity === null
                            ? null
                            : {
                                  entity_id: project?.customer_entity ?? '',
                                  entity_name:
                                      project?.customer_entity_name ?? '',
                              },
                    projectCustomSections: project.custom_sections,
                    linkedCostCenterCount: project.cost_centre_count,
                    attachments: project.attachments,
                    modifiedByUsername: project.modified_by_user.name,
                    submittedOn: project.modified_datetime,
                    templateId: project?.additional_details?.template_id,
                    projectCode: project.project_code,
                    projectStatus: project.project_status as ProjectStatusEnum,
                    projectManagers: project.project_managers,
                    createdByUsername: project.created_by_user.name,
                    projectName: project.project_name,
                    status: project.status as ProjectStatusEnum,
                    listCostCenterNames:
                        project.cost_centres?.map(
                            (cc) => cc.cost_centre.cost_centre_name
                        ) ?? [],
                    validityFrom: project.validity_from,
                    validityTo: project.validity_to,
                    modifiedOn: project.modified_datetime,
                })),
            }),
        }),
        getProjectDetails: builder.query<IProjectDetailsResponse, string>({
            query: (projectId) => `/organization/project/${projectId}/`,
        }),
        getProjectEventSummary: builder.mutation<
            {
                data: IProjectEventSummary[];
                metadata: {
                    current_page: number;
                    has_next: boolean;
                    has_previous: boolean;
                    total_pages: number;
                    page_range: {
                        start: number;
                        stop: number;
                    };
                    count: number;
                };
                counts: {
                    all: number;
                };
            },
            {
                search_text: string;
                sort_fields: { field: string; ascending: boolean }[];
                items_per_page: number;
                page_number: number;
                project_id: string;
            }
        >({
            query: ({
                search_text,
                items_per_page,
                page_number,
                sort_fields,
                project_id,
            }) => ({
                url: '/dashboard/',
                method: 'POST',
                body: {
                    dashboard_view: 'project_event',
                    search_text,
                    sort_fields,
                    page_number,
                    tab: 'all',
                    items_per_page,
                    query_data: {
                        project_id,
                    },
                },
            }),
        }),
        getProjectQuoteSummary: builder.mutation<
            IProjectQuoteSummaryDashboardResponse,
            {
                search_text: string;
                sort_fields: { field: string; ascending: boolean }[];
                items_per_page: number;
                page_number: number;
                project_id: string;
            }
        >({
            query: ({
                search_text,
                items_per_page,
                page_number,
                sort_fields,
                project_id,
            }) => ({
                url: '/dashboard/',
                method: 'POST',
                body: {
                    dashboard_view: 'project_quote',
                    search_text,
                    sort_fields,
                    page_number,
                    tab: 'all',
                    items_per_page,
                    query_data: {
                        project_id,
                    },
                },
            }),
            transformResponse: (
                baseQueryReturnValue: IProjectQuoteSummaryDashboardResponse,
                meta,
                arg
            ): IProjectQuoteSummaryDashboardResponse => {
                let response = cloneDeep(baseQueryReturnValue);

                response.data.forEach((sheet) => {
                    sheet.costing_sheet_items =
                        sheet.costing_sheet_items.filter((item) =>
                            item.projects.some(
                                (project) =>
                                    project.project_id === arg.project_id
                            )
                        );
                });

                return response;
            },
        }),
        getProjectTabCount: builder.mutation<
            {
                tab_counts: {
                    in_progress: number;
                    ongoing: number;
                    finished: number;
                    all: number;
                };
            },
            {}
        >({
            query: () => ({
                url: '/dashboard/tab_counts/',
                method: 'POST',
                body: {
                    dashboard_view: 'project',
                },
            }),
        }),
        getProjectTemplateById: builder.query<
            IProjectTemplate,
            { templateId: string; entityId: string }
        >({
            query: ({ entityId, templateId }) =>
                `/module_templates/${entityId}/${templateId}`,
            transformResponse: (
                baseQueryReturnValue: IProjectTemplateNewStructureResponse,
                meta,
                arg
            ): IProjectTemplate => {
                const response: IProjectTemplate = JSON.parse(
                    JSON.stringify(blankProjectTemplate)
                );

                let userPermissionListFiltered: IUserPermissionFEStructure[] =
                    [];

                baseQueryReturnValue.assigned_users.forEach((user) => {
                    const currUserIndexInList =
                        userPermissionListFiltered.findIndex(
                            (userInList) =>
                                userInList.user_id === user.user.user_id
                        );

                    if (currUserIndexInList === -1) {
                        userPermissionListFiltered.push({
                            name: user.user.name,
                            user_id: user.user.user_id,
                            permissions: [user.permission],
                        });
                    } else {
                        userPermissionListFiltered[
                            currUserIndexInList
                        ].permissions = Array.from(
                            new Set([
                                ...(userPermissionListFiltered[
                                    currUserIndexInList
                                ]
                                    .permissions as (keyof typeof PermissionsPriorityList)[]),
                                user.permission as keyof typeof PermissionsPriorityList,
                            ])
                        ).sort(
                            (
                                a: keyof typeof PermissionsPriorityList,
                                b: keyof typeof PermissionsPriorityList
                            ) =>
                                PermissionsPriorityList[b] -
                                PermissionsPriorityList[a]
                        );
                    }
                });

                // const userList: { user_id: string; permission: string }[] = [];

                // baseQueryReturnValue.assigned_users?.forEach((user) => {
                //     const currUserIndexInList = userList.findIndex(
                //         (userInList) => userInList.user_id === user.user_id
                //     );

                //     if (currUserIndexInList === -1) {
                //         userList.push({
                //             permission:
                //                 user.permission === 'ASSIGN'
                //                     ? ProjectPermissions.PROJECT_ASSIGN_MANAGERS
                //                     : ProjectPermissions.PROJECT_CREATE,

                //             user_id: user.user_id,
                //         });
                //     } else {
                //         if (
                //             userList[currUserIndexInList].permission ===
                //                 ProjectPermissions.PROJECT_CREATE &&
                //             user.permission === 'ASSIGN'
                //         ) {
                //             userList[currUserIndexInList].permission =
                //                 ProjectPermissions.PROJECT_ASSIGN_MANAGERS;
                //         }
                //     }
                // });
                // response.assigned_users = userList;

                try {
                    const userList: {
                        TEMPLATE_LEVEL: {
                            user_id: string;
                            permission: string;
                        }[];
                        PROJECT_DETAILS: {
                            user_id: string;
                            permission: string;
                        }[];
                        [sectionName: string]: {
                            user_id: string;
                            permission: string;
                        }[];
                    } = { PROJECT_DETAILS: [], TEMPLATE_LEVEL: [] };

                    userPermissionListFiltered?.forEach((user) => {
                        const currUserIndexInList =
                            userList.TEMPLATE_LEVEL.findIndex(
                                (userInList) =>
                                    userInList.user_id === user.user_id
                            );

                        if (currUserIndexInList === -1) {
                            userList.TEMPLATE_LEVEL.push({
                                permission:
                                    user.permissions[0] === 'ASSIGN'
                                        ? ProjectPermissions.PROJECT_ASSIGN_MANAGERS
                                        : ProjectPermissions.PROJECT_CREATE,

                                user_id: user.user_id,
                            });
                        } else {
                            if (
                                userList.TEMPLATE_LEVEL[currUserIndexInList]
                                    .permission ===
                                    ProjectPermissions.PROJECT_CREATE &&
                                user.permissions[0] === 'ASSIGN'
                            ) {
                                userList.TEMPLATE_LEVEL[
                                    currUserIndexInList
                                ].permission =
                                    ProjectPermissions.PROJECT_ASSIGN_MANAGERS;
                            }
                        }
                    });

                    try {
                        baseQueryReturnValue.section_list.forEach((section) => {
                            const isFWSection =
                                section.name === 'Project details';

                            if (isFWSection) {
                                section.assigned_users?.forEach((user) => {
                                    const currUserIndexInList =
                                        userList.PROJECT_DETAILS.findIndex(
                                            (userInList) =>
                                                userInList.user_id ===
                                                user.user.user_id
                                        );

                                    if (currUserIndexInList === -1) {
                                        userList.PROJECT_DETAILS.push({
                                            permission:
                                                user.permission === 'ASSIGN'
                                                    ? ProjectPermissions.PROJECT_ASSIGN_USERS
                                                    : ProjectPermissions.PROJECT_EDIT,

                                            user_id: user.user.user_id,
                                        });
                                    } else {
                                        if (
                                            userList.PROJECT_DETAILS[
                                                currUserIndexInList
                                            ].permission ===
                                                ProjectPermissions.PROJECT_EDIT &&
                                            user.permission === 'ASSIGN'
                                        ) {
                                            userList.PROJECT_DETAILS[
                                                currUserIndexInList
                                            ].permission =
                                                ProjectPermissions.PROJECT_ASSIGN_USERS;
                                        }
                                    }
                                });
                            } else {
                                userList[section.alternate_name] = [];

                                section.assigned_users?.forEach((user) => {
                                    const currUserIndexInList = userList[
                                        section.alternate_name
                                    ].findIndex(
                                        (userInList) =>
                                            userInList.user_id ===
                                            user.user.user_id
                                    );

                                    if (currUserIndexInList === -1) {
                                        userList[section.alternate_name].push({
                                            permission:
                                                user.permission === 'ASSIGN'
                                                    ? ProjectPermissions.PROJECT_ASSIGN_USERS
                                                    : ProjectPermissions.PROJECT_EDIT,

                                            user_id: user.user.user_id,
                                        });
                                    } else {
                                        if (
                                            userList[section.alternate_name][
                                                currUserIndexInList
                                            ].permission ===
                                                ProjectPermissions.PROJECT_EDIT &&
                                            user.permission === 'ASSIGN'
                                        ) {
                                            userList[section.alternate_name][
                                                currUserIndexInList
                                            ].permission =
                                                ProjectPermissions.PROJECT_ASSIGN_USERS;
                                        }
                                    }
                                });
                            }
                        });
                    } catch (err) {}
                    if (
                        baseQueryReturnValue.template_id ===
                        'cd7f01a3-3424-4384-9db6-9261664b5104'
                    ) {
                    }
                    response.assigned_users = cloneDeep(userList);
                } catch (err) {}

                response.templateId = baseQueryReturnValue.template_id;
                response.templateName = baseQueryReturnValue.name;

                const templateSections = baseQueryReturnValue.section_list.map(
                    (section) => {
                        return {
                            ...section,
                            section_items: section.section_items.map(
                                (item: any) => {
                                    return {
                                        ...item,
                                        alternate_name:
                                            item.alternate_name.replace(
                                                '_',
                                                ''
                                            ),
                                        name: item.name.replace('_', ''),
                                    };
                                }
                            ),
                        };
                    }
                );

                baseQueryReturnValue = {
                    ...baseQueryReturnValue,
                    section_list: templateSections,
                };

                templateSections.forEach((section) => {
                    if (section.name === 'Project details') {
                        response.sections.PROJECT_DETAILS = {
                            label: section.alternate_name,
                            fieldList: [],
                        };
                    } else {
                        response.sections[section.alternate_name] = {
                            label: section.alternate_name,
                            fieldList: [],
                        };
                    }
                });

                baseQueryReturnValue.section_list.forEach((section) => {
                    if (section.name === 'Project details') {
                        section.section_items.forEach((sectionItem) => {
                            if (sectionItem.is_builtin_field) {
                                let formattedData: IProjectTemplateStandardFieldOptions =
                                    {
                                        label: sectionItem.alternate_name,
                                        description: sectionItem.description,
                                        constraints:
                                            getFieldTypeContraintsFromBackend({
                                                choices:
                                                    sectionItem.constraints
                                                        .choices ?? [],
                                                choice_type:
                                                    sectionItem.constraints
                                                        .choice_type,
                                                max_limit:
                                                    sectionItem.constraints
                                                        .field_type ===
                                                    CustomFieldType.LONGTEXT
                                                        ? 500
                                                        : sectionItem
                                                              .constraints
                                                              .max_limit,
                                                min_limit:
                                                    sectionItem.constraints
                                                        .min_limit,
                                                field_type:
                                                    sectionItem.constraints
                                                        .field_type,
                                            }),
                                        buyerSettings: {
                                            required: sectionItem.is_required,
                                            showField:
                                                !sectionItem
                                                    .additional_information
                                                    .is_hidden,
                                        },
                                        standardFieldCategory: 'STANDARD',
                                    };
                                const fieldNameForTemplate =
                                    ProjectTemplateStandardFieldBackendNames[
                                        sectionItem.name
                                    ];

                                if (
                                    !Boolean(
                                        response.fieldSettings.PROJECT_DETAILS
                                    )
                                ) {
                                    response.fieldSettings.PROJECT_DETAILS = {
                                        customFields: {},
                                        standardFields: {
                                            [ProjectTemplateCustomSectionFieldEnum.ASSIGNED_TO]:
                                                {} as any,
                                            [ProjectTemplateCustomSectionFieldEnum.ATTACHMENTS]:
                                                {} as any,
                                            [ProjectTemplateCustomSectionFieldEnum.DEADLINE_DATE]:
                                                {} as any,
                                            [ProjectTemplateCustomSectionFieldEnum.STATUS]:
                                                {} as any,
                                            [ProjectTemplateFWSectionFieldsEnum.PROJECT_CODE]:
                                                {} as any,
                                            [ProjectTemplateFWSectionFieldsEnum.PROJECT_NAME]:
                                                {} as any,
                                            [ProjectTemplateFWSectionFieldsEnum.PROJECT_MANAGER]:
                                                {} as any,
                                            [ProjectTemplateFWSectionFieldsEnum.CUSTOMER_NAME]:
                                                {} as any,
                                            [ProjectTemplateFWSectionFieldsEnum.DESCRIPTION]:
                                                {} as any,
                                            [ProjectTemplateFWSectionFieldsEnum.INTERNAL_NOTES]:
                                                {} as any,
                                            [ProjectTemplateFWSectionFieldsEnum.START_DATE]:
                                                {} as any,
                                            [ProjectTemplateFWSectionFieldsEnum.END_DATE]:
                                                {} as any,
                                        },
                                    };
                                }

                                response.fieldSettings.PROJECT_DETAILS.standardFields[
                                    fieldNameForTemplate
                                ] = formattedData;

                                response.sections.PROJECT_DETAILS.fieldList.push(
                                    {
                                        field: fieldNameForTemplate,
                                        fieldType: 'STANDARD',
                                    }
                                );
                            } else {
                                let formattedData: IProjectTemplateCustomFieldOptions =
                                    {
                                        label: sectionItem.alternate_name,
                                        description: sectionItem.description,
                                        buyerSettings: {
                                            required: sectionItem.is_required,
                                            showField:
                                                !sectionItem
                                                    .additional_information
                                                    .is_hidden,
                                        },
                                        constraints:
                                            getFieldTypeContraintsFromBackend({
                                                choices:
                                                    sectionItem.constraints
                                                        .choices ?? [],
                                                choice_type:
                                                    sectionItem.constraints
                                                        .choice_type,
                                                max_limit:
                                                    sectionItem.constraints
                                                        .max_limit,
                                                min_limit:
                                                    sectionItem.constraints
                                                        .min_limit,
                                                field_type:
                                                    sectionItem.constraints
                                                        .field_type,
                                            }),
                                        type:
                                            sectionItem.constraints
                                                .field_type ===
                                                CustomFieldType.CHOICE &&
                                            sectionItem.constraints
                                                .choice_type === 'MULTI_SELECT'
                                                ? ('MULTI_CHOICE' as CustomFieldType)
                                                : (sectionItem.constraints
                                                      .field_type as CustomFieldType),
                                    };

                                response.fieldSettings.PROJECT_DETAILS.customFields =
                                    {
                                        ...response.fieldSettings
                                            .PROJECT_DETAILS.customFields,
                                        [sectionItem.name]: formattedData,
                                    };

                                response.sections.PROJECT_DETAILS.fieldList.push(
                                    {
                                        field: sectionItem.name,
                                        fieldType: 'CUSTOM',
                                    }
                                );
                            }
                        });
                    } else {
                        section.section_items.forEach((sectionItem) => {
                            if (
                                !Boolean(
                                    response.fieldSettings[
                                        section.alternate_name
                                    ]
                                )
                            ) {
                                response.fieldSettings[section.alternate_name] =
                                    {
                                        customFields: {},
                                        standardFields: {
                                            [ProjectTemplateCustomSectionFieldEnum.ASSIGNED_TO]:
                                                {} as any,

                                            [ProjectTemplateCustomSectionFieldEnum.ATTACHMENTS]:
                                                {} as any,
                                            [ProjectTemplateCustomSectionFieldEnum.DEADLINE_DATE]:
                                                {} as any,
                                            [ProjectTemplateCustomSectionFieldEnum.STATUS]:
                                                {} as any,
                                            [ProjectTemplateFWSectionFieldsEnum.PROJECT_CODE]:
                                                {} as any,
                                            [ProjectTemplateFWSectionFieldsEnum.CUSTOMER_NAME]:
                                                {} as any,
                                            [ProjectTemplateFWSectionFieldsEnum.PROJECT_NAME]:
                                                {} as any,
                                            [ProjectTemplateFWSectionFieldsEnum.PROJECT_MANAGER]:
                                                {} as any,
                                            [ProjectTemplateFWSectionFieldsEnum.DESCRIPTION]:
                                                {} as any,
                                            [ProjectTemplateFWSectionFieldsEnum.INTERNAL_NOTES]:
                                                {} as any,
                                            [ProjectTemplateFWSectionFieldsEnum.START_DATE]:
                                                {} as any,
                                            [ProjectTemplateFWSectionFieldsEnum.END_DATE]:
                                                {} as any,
                                        },
                                    };
                            }
                            if (sectionItem.is_builtin_field) {
                                let formattedData: IProjectTemplateStandardFieldOptions =
                                    {
                                        label: sectionItem.alternate_name,
                                        description: sectionItem.description,
                                        constraints:
                                            getFieldTypeContraintsFromBackend({
                                                choices:
                                                    sectionItem.constraints
                                                        .choices ?? [],
                                                choice_type:
                                                    sectionItem.constraints
                                                        .choice_type,
                                                max_limit:
                                                    sectionItem.constraints
                                                        .max_limit,
                                                min_limit:
                                                    sectionItem.constraints
                                                        .min_limit,
                                                field_type:
                                                    sectionItem.constraints
                                                        .field_type,
                                            }),
                                        buyerSettings: {
                                            required: sectionItem.is_required,
                                            showField:
                                                !sectionItem
                                                    .additional_information
                                                    .is_hidden,
                                        },
                                        standardFieldCategory: 'STANDARD',
                                    };

                                const fieldNameForTemplate =
                                    ProjectTemplateStandardFieldBackendNames[
                                        sectionItem.name
                                    ];

                                response.fieldSettings[
                                    section.alternate_name
                                ].standardFields[fieldNameForTemplate] =
                                    formattedData;
                                response.sections[
                                    section.alternate_name
                                ].fieldList.push({
                                    field: fieldNameForTemplate,
                                    fieldType: 'STANDARD',
                                });
                            } else {
                                let formattedData: IProjectTemplateCustomFieldOptions =
                                    {
                                        label: sectionItem.alternate_name,
                                        description: sectionItem.description,
                                        buyerSettings: {
                                            required: sectionItem.is_required,
                                            showField:
                                                !sectionItem
                                                    .additional_information
                                                    .is_hidden,
                                        },
                                        constraints:
                                            getFieldTypeContraintsFromBackend({
                                                choices:
                                                    sectionItem.constraints
                                                        .choices ?? [],
                                                choice_type:
                                                    sectionItem.constraints
                                                        .choice_type,
                                                max_limit:
                                                    sectionItem.constraints
                                                        .max_limit,
                                                min_limit:
                                                    sectionItem.constraints
                                                        .min_limit,
                                                field_type:
                                                    sectionItem.constraints
                                                        .field_type,
                                            }),
                                        type:
                                            sectionItem.constraints
                                                .field_type === 'CHOICE' &&
                                            sectionItem.constraints
                                                .choice_type === 'MULTI_SELECT'
                                                ? ('MULTI_CHOICE' as CustomFieldType)
                                                : (sectionItem.constraints
                                                      .field_type as CustomFieldType),
                                    };

                                response.fieldSettings[
                                    section.alternate_name
                                ].customFields = {
                                    ...response.fieldSettings[
                                        section.alternate_name
                                    ].customFields,
                                    [sectionItem.name]: formattedData,
                                };

                                response.sections[
                                    section.alternate_name
                                ].fieldList.push({
                                    field: sectionItem.name,
                                    fieldType: 'CUSTOM',
                                });
                            }
                        });
                    }
                });

                return response;
            },
        }),
        getAssignUserList: builder.query<
            IProjectAssigenUserListResponse[],
            { permissionList: string[]; entityid: string; allUsers: boolean }
        >({
            query: ({ permissionList, entityid, allUsers }) => ({
                url: `/organization/user_permission/?all_users=${allUsers}&${permissionList
                    .map((permission) => `action_api_group[]=${permission}&`)
                    .join('')}${
                    entityid !== '' ? `entity_id[]=${entityid}` : ''
                }`,
            }),
            transformResponse: (
                baseQueryReturnValue: {
                    [userId: string]: IProjectAssigenUserListResponse[];
                },
                meta,
                arg
            ): IProjectAssigenUserListResponse[] => {
                let listOfUserPermission: IProjectAssigenUserListResponse[] =
                    [];

                Object.keys(baseQueryReturnValue).forEach((userId) => {
                    listOfUserPermission.push(...baseQueryReturnValue[userId]);
                });

                return listOfUserPermission;
            },
        }),
        getListOfAllProjectTemplates: builder.query<
            IMultipleProjectTemplateApiResponse[],
            { templateType: templateType }
        >({
            query: ({ templateType }) => ({
                url: `/module_templates/?template_type=${templateType}`,
            }),
        }),
        getListOfAllUserList: builder.query<TProjectUsersList, {}>({
            query: () => ({
                url: '/organization/project/columns/',
            }),
        }),
    }),
});

export const {
    useListProjectsQuery,
    useListProjectsViaDasboardMutation,
    useGetProjectDetailsQuery,
    useGetProjectTabCountMutation,
    useGetProjectTemplateByIdQuery,
    useGetAssignUserListQuery,
    useGetListOfAllProjectTemplatesQuery,
    useListOfBuyerEntitiesQuery,
    useGetProjectEventSummaryMutation,
    useGetProjectQuoteSummaryMutation,
    useGetListOfAllUserListQuery,
} = projectApiSlice;

export const updateStatusOfProject = async (
    projectUid: string,
    status: 'ACTIVE' | 'INACTIVE'
) => {
    try {
        const response = await put(
            `/organization/project/${projectUid}/update/state/`,
            {
                status,
            }
        );
        return response;
    } catch (err) {}
};

export const deleteProject = async (projectUid: string) => {
    try {
        const response = await del(
            `/organization/project/${projectUid}/delete/`
        );
        return response;
    } catch (err) {
        throw new Error('Failed to delete project!');
    }
};

export const getProjectHistoryData = (projectId: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let projectHistory = await get(
                `/organization/project/${projectId}/history/changes`
            );

            resolve(projectHistory.data);
        } catch (e) {
            reject();
        }
    });
};

export const getProjectSubmissionDateTimeHistoryList = (
    projectId: string
): Promise<{
    [sectionId: string]: string[];
}> => {
    return new Promise(async (resolve, reject) => {
        try {
            let projectHistoryResp = await get(
                `/organization/project/${projectId}/sections/history/?first_submission=true`
            );

            let projectHistory: {
                custom_section_id: string;
                modified_datetime: string;
                modified_by_user_id: string;
                modified_by_user_name: string;
                changes: {
                    field: string;
                    old_value: string;
                    new_value: string;
                }[];
                custom_fields: any[];
            }[] = projectHistoryResp.data as any;

            let submissionTimeList: {
                [sectionId: string]: string[];
            } = {};
            for (let i = 0; i < projectHistory.length; i++) {
                let submissionTime = projectHistory[i].changes.find(
                    (change) => change.field === 'submission_time'
                );

                if (submissionTime) {
                    if (
                        !submissionTimeList[projectHistory[i].custom_section_id]
                    ) {
                        submissionTimeList[
                            projectHistory[i].custom_section_id
                        ] = [];
                    }
                    submissionTimeList[
                        projectHistory[i].custom_section_id
                    ].push(submissionTime.new_value);
                }
            }
            resolve(submissionTimeList);
        } catch (e) {
            reject();
        }
    });
};

export const getPreviousProjectVersion = (projectId: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let prevProjectHistory = await get(
                `/organization/project/${projectId}/history/`
            );

            resolve(prevProjectHistory.data);
        } catch (e) {
            reject();
        }
    });
};

// request: http://localhost:8000/module_templates/sections/?template_type=PROJECT
// resp: [
//     {
//         "name": "Project details",
//         "alternate_name": "Project details"
//     }
// ]
export const getSectionsNamesList = (): Promise<
    {
        name: string;
        alternate_name: string;
    }[]
> => {
    return new Promise<{ name: string; alternate_name: string }[]>(
        async (resolve, reject) => {
            try {
                let sections = await get(
                    `/module_templates/sections/?template_type=PROJECT`
                );
                resolve(
                    sections.data as { name: string; alternate_name: string }[]
                );
                //
            } catch (e) {
                reject();
            }
        }
    );
};

// src/services/apiService.js

import { Box, IconButton } from '@mui/material';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import { planUpgradeTooltipText } from '../../FeatureAccess/Utils/planUpgradeTooltipText';
import { useGetAdminSettings } from '../../Organizations/Admin/Hooks/AdminSettingsHook';
import { ASModuleSetting } from '../../Organizations/Admin/Interfaces/AdminSettingsInterface';
import { useFeatureAccess } from '../../FeatureAccess/Hooks/FeatureAccessHook';
import AddSpecificationPopup from './AddSpecificaionPopup';
import { ICustomAttributeNew } from '../../Models/Attributes.model';

interface IAddAttributeButtonProps {
    item_attributes: ICustomAttributeNew[];
    updateAttribute(
        newAttribute: ICustomAttributeNew,
        isNewAttribute: boolean
    ): void;
    deleteAttribute: (idx: string) => void;
    openAttributePopup: {
        isOpen: boolean;
        currentAttribute: ICustomAttributeNew | null;
    };
    setOpenAttributePopup: React.Dispatch<
        React.SetStateAction<{
            isOpen: boolean;
            currentAttribute: ICustomAttributeNew | null;
        }>
    >;
    entity_id: string;
}

const AddAttributeButton = (props: IAddAttributeButtonProps) => {
    const [customSpecSettings] = useGetAdminSettings(
        props.entity_id,
        ASModuleSetting.EVENT_ALLOW_CUSTOM_SPECS
    );
    const { isFeatureAvailable } = useFeatureAccess();

    return (
        <>
            {customSpecSettings.enabled || true ? (
                <>
                    &nbsp;
                    <FWTooltip
                        title={
                            isFeatureAvailable(
                                'feature-event-customattributes-enabled'
                            )
                                ? 'Add specification'
                                : planUpgradeTooltipText.featureUnavailableText
                        }
                    >
                        <Box component="span">
                            <IconButton
                                disabled={
                                    !isFeatureAvailable(
                                        'feature-event-customattributes-enabled'
                                    )
                                }
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    props.setOpenAttributePopup({
                                        isOpen: true,
                                        currentAttribute: null,
                                    });
                                }}
                                sx={{
                                    color: 'primary.main',
                                    fontSize: '1.4rem',
                                }}
                                id="CreateEvent_addSpecificationsIcon"
                            >
                                <i className="bi bi-plus-circle"></i>
                            </IconButton>
                        </Box>
                    </FWTooltip>
                </>
            ) : (
                <span style={{ width: '1rem' }}></span>
            )}
            <AddSpecificationPopup
                currentAttribute={props.openAttributePopup.currentAttribute}
                updateAttribute={(...args) => {
                    props.updateAttribute(
                        ...args,
                        props.openAttributePopup.currentAttribute === null
                            ? true
                            : false
                    );
                    props.setOpenAttributePopup({
                        ...props.openAttributePopup,
                        isOpen: false,
                    });
                }}
                open={props.openAttributePopup.isOpen}
                setOpen={props.setOpenAttributePopup}
                all_attributes={props.item_attributes}
                deleteAttribute={props.deleteAttribute} // recom_attributes={recom_attributes}
            />
        </>
    );
};

export default AddAttributeButton;

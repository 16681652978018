import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import {
    // checkForDuplicates,
    useGetOrderDashboardDataMutation,
} from '../../../ItemAnalytics/Services/ItemAnalyticsCartService';
import { GridApi } from 'ag-grid-community';
import { RequisitionDetails } from '../../../ItemAnalytics/Interfaces/OrderInterfaces';
import { useDispatch } from 'react-redux';
import { updateCartDataStore } from '../../../ItemAnalytics/Slices/ItemAnalyticsCartSlice';
import { CurrencyDetails } from '../../../Historical/Interfaces/IHistoricalAnalyticsDropdowns.model';
import { cloneDeep, debounce } from 'lodash';
import {
    useGetInboundRequisitionItemsQuery,
    useGetRequisitionDashboardDataMutation,
} from '../../../Requisitions/services/RequisitionServices';
import {
    fetchCurrencies,
    // isDuplicateItem,
} from '../../RFQAnalytics/Helpers/EventIntegrationHelper';
import { batch } from 'react-redux';
import { updateEventIntegrationStore } from '../../../ItemAnalytics/Slices/EventIntegrationSlice';
import { useGetTemplateByIdQuery } from '../../../Global/Helpers/templateApiSlice';
const useOrderToEventIntegrationHook = (
    defaultCurrency: string,
    cartId: string,
    selectedReqId: string,
    open: boolean,
    integrationType: 'CART_ORDERS' | 'REQUISITIONS'
    // templateId?: string
) => {
    const [page, setPage] = useState(1);
    const [selectedOrders, setSelectedOrder] = useState<string[]>([]);
    const [ordersForEvent, setOrdersForEvent] = useState<RequisitionDetails[]>(
        []
    );
    const [itemsPage, setItemsPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    // **NO NEED FOR THIS** IF SELECTEDORDERS CAN CONTAIN THE ENTIRE REQUISITION/CARTORDER
    const [ordersForDisplay, setOrdersForDisplay] = useState<any>(); //A MUST HAVE (TO PROPERLY SHOW LOADER)
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    // const [itemsAvailable, setItemsAvailable] = useState<any[]>([]); //  IF SELECTEDITEMS CAN CONTAIN THE ENTIRE ITEM
    const [showItemPopup, setShowItemPopup] = useState(cartId === '');
    const [itemsForEvent, setItemsForEvent] = useState<any[]>([]); //CAN BE CREATED AT ONCE WHEN THE BUTTON IS CLICKED(WILL REMOVE ONE USEEFFECT) //
    const [showCombinePopup, setShowCombinePopup] = useState(false);
    const [showLoader] = useState(false);
    // const [eventId, setEventId] = useState<string | null>(''); // REQUIRED SINCE THE EVENT CREATION AND THE COMBINING API HAS A DELAY IN BETWEEN
    const [gridApi, setGridApi] = useState<GridApi | undefined>();
    const [shippingAddressId, setShippingAddressId] = useState(''); //USED TO DYNAMICALLY DISABLE THE ITEMS THAT DO NOT HAVE THIS ID
    const [selectedEntity, setSelectedEntity] = useState('');
    const [items, setItems] = useState<{
        [key: string]: { items: string[]; item_enterprise_id: string };
    }>({}); //SENT TO COMBINEITEMSFOREVENT (USED TO GET COMBINNG DETAILS)
    const [defaultCurrencyDetails, setDefaultCurrencyDetails] = useState<
        CurrencyDetails | undefined
    >();
    const [templateId, setTemplateId] = useState<string | undefined>();
    const [entityId, setEntityId] = useState<string | undefined>();
    const [duplicateArray, setDuplicateArray] = useState<any>([]);
    const [warningForShippingAddress, setWarning] = useState<Set<string>>(
        new Set()
    );
    const dispatch = useDispatch();
    const [fetchOrders, { data: orders }] = useGetOrderDashboardDataMutation({
        fixedCacheKey: 'orders',
    });
    const [fetchRequisitions, { data: requisitions }] =
        useGetRequisitionDashboardDataMutation({
            fixedCacheKey: 'orders',
        });

    const { data: itemsAvailable, refetch: refetchItems } =
        useGetInboundRequisitionItemsQuery({
            dashboard_view: 'inbound_requisition_items',
            tab: 'all',
            sort_fields: [],
            search_text: searchText,
            items_per_page: 10,
            page_number: itemsPage,
            query_data: {
                requisition_id_list: selectedOrders,
            },
        });
    useLayoutEffect(() => {
        if (gridApi) gridApi.onFilterChanged();
    }, [orders?.data.length, gridApi]);

    const { data: selectedtemplateData } = useGetTemplateByIdQuery(
        {
            entityId: entityId ?? '',
            templateId: templateId ?? '',
        },
        {
            skip: !Boolean(entityId) || !Boolean(templateId),
        }
    );

    useEffect(() => {
        if (selectedtemplateData) {
            dispatch(
                updateEventIntegrationStore({
                    type: 'UPDATE_TEMPLATES',
                    value: selectedtemplateData,
                })
            );
        }
    }, [selectedtemplateData, dispatch]);

    //FETCHING ORDERS HERE ONLY IF THE POPUP IS OPENED, NOT ADDING EXTRA API CALLS TO THE CREATE EVENT PAGE
    useEffect(() => {
        if (open) {
            fetchCurrencies().then((response: any) => {
                let fetchedCurrencyDetails = response.find(
                    (cid: any) => cid.entry_id === defaultCurrency
                );
                setDefaultCurrencyDetails(fetchedCurrencyDetails);
            });
            //DEPENDING ON THE INTEGRATION TYPE FETCH THE REQUIRED VALUES
            if (integrationType === 'CART_ORDERS') {
                fetchOrders({
                    page_number: page,
                    search_text: '',
                    tab: 'all',
                }).then((response: any) => {
                    if (selectedReqId !== '' && selectedOrders.length === 0) {
                        setSelectedOrder([selectedReqId]);
                    }
                });
            } else {
                fetchRequisitions({
                    dashboard_view: 'inbound_requisition',
                    page_number: page,
                    search_text: '',
                    tab: 'all',
                });
            }
            //WHEN THE POPUP IS CLOSED,RESET ALL THE STATES
        } else {
            setSelectedOrder([]);
            setSelectedItems([]);
            setOrdersForEvent([]);
            setShowItemPopup(false);
            // setItemsAvailable([]);
            setItemsForEvent([]);
            setShowCombinePopup(false);
            setDuplicateArray([]);
            setPage(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, page]);

    //The Orders for display is used to render the data on the DataGrid, if the response from the mutation is used
    //directly for rendering, the data is lost on every refetch
    useEffect(() => {
        if (orders) {
            dispatch(
                updateEventIntegrationStore({
                    type: 'UPDATE_ALL_ORDERS',
                    value: orders.data,
                })
            );
            setOrdersForDisplay(orders);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orders]);

    useEffect(() => {
        if (requisitions) {
            setOrdersForDisplay(requisitions);
        }
    }, [requisitions]);
    //IF CREATING EVENT FROM CART PAGE, NEED TO AUTO SELECT THE NEWLY CREATED REQUISITION, THIS IS THE USEEFFECT FOR THAT
    //SELECTEDREQID IS COMING FROM PROPS, (CREATED ON THE CREATEPAGEMASTERHOOK AND SENT VIA PROPS)
    useEffect(() => {
        let temp = [];
        if (open && integrationType === 'CART_ORDERS' && selectedReqId) {
            let tempOrder = orders?.data.filter(
                (order) => order.requisition_id === selectedReqId
            );
            if (tempOrder) {
                temp.push(tempOrder[0].requisition_id ?? '');
                setOrdersForEvent(tempOrder);
            }
        }
        setSelectedOrder(temp);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //DUE TO ADDING PAGINATION, CANT USE THE ORDERS TO KEEP TRACK OF THE SELECTED ORDERS,
    //SETTING THE ORDERSFOREVENT STATE HERE FOR KEEPING TRACK
    useEffect(() => {
        let ids = ordersForEvent.map((data) => data.requisition_id);
        orders?.data.forEach((order) => {
            if (
                selectedOrders.includes(order.requisition_id) &&
                !ids.includes(order.requisition_id)
            ) {
                setOrdersForEvent([...ordersForEvent, order]);
            } else if (
                !selectedOrders.includes(order.requisition_id) &&
                ids.includes(order.requisition_id)
            ) {
                let index = ordersForEvent.findIndex(
                    (data) => data.requisition_id === order.requisition_id
                );
                let newOrderForEvents = cloneDeep(ordersForEvent);
                newOrderForEvents.splice(index, 1);
                setOrdersForEvent(newOrderForEvents);
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrders]);

    //USEEFFCT TO SET THE ITEMS AVAILABLE FOR SELECTION
    useEffect(() => {
        if (ordersForEvent.length > 0) {
            setTemplateId(
                ordersForEvent[ordersForEvent.length - 1].additional_details
                    .template_id
            );
            setEntityId(ordersForEvent[ordersForEvent.length - 1].buyer_entity);
        }
        // if (ordersForEvent.length === 0) {
        //     setItemsAvailable([]);
        // } else setItemsAvailable(getItemsAvailable());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ordersForEvent]);

    const refetchItemsMemoized = useCallback(() => {
        refetchItems();
    }, [refetchItems]);

    const refetchItemsDebounced = useMemo(() => {
        return debounce(() => {
            refetchItemsMemoized();
        }, 1000);
    }, [refetchItemsMemoized]);

    //FUNCTION USED TO SET THE ITEMS AVAILABLE FOR SELECTION BASED ON THE ORDERS SELECTED BY THE USER
    // const getItemsAvailable = () => {
    //     let temp: any = [];
    //     ordersForEvent.forEach((order) => {
    //         if (selectedOrders.includes(order.requisition_id)) {
    //             temp = temp.concat(order.requisition_items);
    //         }
    //     });
    //     return temp;
    // };

    useEffect(() => {
        return () => {
            refetchItemsDebounced.cancel();
        };
    }, [refetchItemsDebounced]);

    useEffect(() => {
        if (integrationType === 'REQUISITIONS') {
            if (ordersForEvent.length === 1) {
                setSelectedEntity(ordersForEvent[0].buyer_entity);
            } else if (ordersForEvent.length === 0) setSelectedEntity('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrders]);

    //USEEFFECT TO SET THE ITEMS SELECTED ON THE SECOND POPUP
    useEffect(() => {
        let newItems: any = [];
        let temp: any = [];
        if (selectedItems.length === 0) setItemsForEvent([]);
        else {
            itemsAvailable?.data.forEach((item) => {
                if (selectedItems.includes(item.requisition_item_id))
                    newItems.push({
                        requisition_item_id: item.requisition_item_id,
                        enterprise_item_id: item.item_information.item_id,
                    });
                temp.push(item);
                if (selectedItems.length === 1) {
                    setWarning((prev) => {
                        let newprev = new Set<string>();
                        newprev.add(
                            item.shipping_address.shipping_address_id ?? ''
                        );
                        return newprev;
                    });
                } else if (selectedItems.length > 1) {
                    setWarning((prev) => {
                        let newprev = cloneDeep(prev);
                        newprev.add(
                            item.shipping_address.shipping_address_id ?? ''
                        );
                        return newprev;
                    });
                }
            });
            setItemsForEvent(newItems);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItems]);

    //USED TO SET SHIPPING ADDRESS, ALSO THE STORE, TO USE ON THE EDIT EVENT PAGE
    useEffect(() => {
        if (
            (itemsForEvent.length > 1 && shippingAddressId === null) ||
            itemsForEvent.length === 1
        ) {
            itemsAvailable?.data.forEach((item) => {
                if (
                    item.requisition_item_id ===
                    itemsForEvent[itemsForEvent.length - 1].requisition_item_id
                ) {
                    dispatch(
                        updateCartDataStore({
                            type: 'UPDATE_SHIPPING_ADDRESS',
                            value: item.shipping_address.shipping_address_id,
                        })
                    );
                    setShippingAddressId(
                        item.shipping_address.shipping_address_id
                    );
                }
            });
        } else if (itemsForEvent.length === 0) setShippingAddressId('');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsForEvent]);

    const dispatchRequiredValues = () => {
        let temp = itemsAvailable?.data.filter((item) =>
            selectedItems.includes(item.requisition_item_id)
        );
        let requisitions = temp?.map((value) => value.requisition);
        let selectedRequisitions = ordersForEvent.filter((order) =>
            requisitions?.includes(order.requisition_id)
        );
        batch(() => {
            dispatch(
                updateEventIntegrationStore({
                    type: 'UPDATE_SELECTED_ORDERS',
                    value: selectedRequisitions,
                })
            );
            dispatch(
                updateEventIntegrationStore({
                    type: 'UPDATE_ITEMS_AVAILABLE',
                    value: temp,
                })
            );
            dispatch(
                updateEventIntegrationStore({
                    type: 'UPDATE_ITEMS_FOR_EVENTS',
                    value: itemsForEvent,
                })
            );
            dispatch(
                updateEventIntegrationStore({
                    type: 'UPDATE_SOURCE',
                    value: integrationType,
                })
            );
        });
    };

    //FUNCTION WHERE WE CHECK FOR DUPLICATES AND HANDLE BOTH CASES.
    // const handleEventCreation = async () => {
    //     try {
    //         setShowLoader(true);
    //         const response: any = await checkForDuplicates(itemsForEvent);
    //         if (!isDuplicateItem(response)) {
    //             handleEventCreationWithCombination(false);
    //             setShowLoader(false);
    //         } else {
    //             let duplicates: any = {};
    //             Object.keys(response).forEach((item: any) => {
    //                 if (response[item].length > 1) {
    //                     if (Object.keys(duplicates).length === 0) {
    //                         duplicates = {
    //                             [item]: response[item],
    //                         };
    //                     } else {
    //                         duplicates = {
    //                             ...duplicates,
    //                             [item]: response[item],
    //                         };
    //                     }
    //                 }
    //             });
    //             setShowLoader(false);
    //             setEventId(eventId ?? '');
    //             setDuplicateArray(duplicates);
    //             setShowCombinePopup(true);
    //         }
    //     } catch (e) {
    //         setShowLoader(false);
    //     }
    // };

    //THIS USEEFFECT RUNS ONLY WHEN THE CREATE CUSTOM CONVERSION API HAS FINSIHED EXECUTING, REQUIRED SINCE COMBINE API NEEDS TO BE CALLED AFTER THAT
    // useEffect(() => {
    //     if (currency !== undefined) {
    //         handleEventCreationWithCombination(bool);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [currency]);

    //FUNCTION TO CREATE THE PAYLOAD AND INITIATE THE API FOR THE API CALL TO CREATE THE EVENT
    // const handleEventCreationWithCombination = async (bool: boolean) => {
    //     try {
    //         //DUPLICATES WHERE FOUND
    //         if (bool) {
    //             let itemsForEventCreation: any = {};
    //             itemsForEvent.forEach((item) => {
    //                 if (checkForEventCreation(item.requisition_item_id)) {
    //                     if (Object.keys(itemsForEventCreation).length === 0) {
    //                         itemsForEventCreation = {
    //                             [item.requisition_item_id]: [
    //                                 item.requisition_item_id,
    //                             ],
    //                         };
    //                     } else {
    //                         itemsForEventCreation = {
    //                             ...itemsForEventCreation,
    //                             [item.requisition_item_id]: [
    //                                 item.requisition_item_id,
    //                             ],
    //                         };
    //                     }
    //                 }
    //             });
    //             Object.keys(items).forEach((item) => {
    //                 itemsForEventCreation = {
    //                     ...itemsForEventCreation,
    //                     [item]: items[item].items,
    //                 };
    //             });
    //             // const payload = createPayloadForIntegration(
    //             //     itemsForEventCreation,
    //             //     fieldsToExport,
    //             //     selectedReqId,
    //             //     itemsAvailable
    //             // );
    //             //SAVE PAYLOAD TO STORE
    //             dispatch(
    //                 updateCartDataStore({
    //                     type: 'UPDATE_SOURCE',
    //                     value: 'CART_ORDERS',
    //                 })
    //             );
    //             dispatch(
    //                 updateCartDataStore({
    //                     type: 'UPDATE_PAYLOAD_FOR_EVENT_CREATION',
    //                     value: itemsForEventCreation,
    //                 })
    //             );
    //             // createEventWithCombinations(payload, eventId).then(() => {
    //             //     history.replace(`/buyer/events/rfq/${eventId}`);
    //             // });

    //             //NO DUPLICATES WHERE FOUND
    //         } else {
    //             let itemsForEventCreation: any = {};
    //             Object.values(itemsForEvent).forEach((item) => {
    //                 if (Object.keys(itemsForEventCreation).length === 0) {
    //                     itemsForEventCreation = {
    //                         [item.requisition_item_id]: [
    //                             item.requisition_item_id,
    //                         ],
    //                     };
    //                 } else {
    //                     itemsForEventCreation = {
    //                         ...itemsForEventCreation,
    //                         [item.requisition_item_id]: [
    //                             item.requisition_item_id,
    //                         ],
    //                     };
    //                 }
    //             });
    //             // const payload = createPayloadForIntegration(
    //             //     itemsForEventCreation,
    //             //     fieldsToExport,
    //             //     selectedReqId,
    //             //     itemsAvailable
    //             // );
    //             dispatch(
    //                 updateCartDataStore({
    //                     type: 'UPDATE_SOURCE',
    //                     value: 'CART_ORDERS',
    //                 })
    //             );
    //             dispatch(
    //                 updateCartDataStore({
    //                     type: 'UPDATE_PAYLOAD_FOR_EVENT_CREATION',
    //                     value: itemsForEventCreation,
    //                 })
    //             );
    //             //SAVE PAYLOAD TO STORE

    //             // createEventWithCombinations(payload, eventId).then(() => {
    //             //     history.replace(`/buyer/events/rfq/${eventId}`);
    //             // });
    //         }
    //     } catch (e) {
    //         setShowLoader(false);
    //     }
    // };

    // //THIS FUNCTION CHECKS IF THE ITEM WAS A DUPLICATE
    // const checkForEventCreation = (id: string) => {
    //     let bool = true;
    //     Object.values(items).forEach((duplicates) => {
    //         if (duplicates.items.includes(id)) {
    //             bool = false;
    //         }
    //     });
    //     return bool;
    // };

    const getCustomRequisitionId = (id: string) => {
        try {
            if (
                ordersForEvent.filter((order) => order.requisition_id === id)[0]
            ) {
                return ordersForEvent.filter(
                    (order) => order.requisition_id === id
                )[0].custom_requisition_id;
            } else {
                return null;
            }
        } catch (err) {
            return null;
        }
    };
    const onSearchChange = useCallback((search: string) => {
        setSearchText(search);
    }, []);

    return {
        orders,
        selectedOrders,
        setSelectedOrder,
        selectedItems,
        setSelectedItems,
        itemsAvailable,
        showItemPopup,
        setShowItemPopup,
        setGridApi,
        shippingAddressId,
        itemsForEvent,
        setItemsForEvent,
        showCombinePopup,
        setShowCombinePopup,
        duplicateArray,
        page,
        setPage,
        getCustomRequisitionId,
        items, //THIS IS THE ITEMS WHICH IS USED TO GET WHICH ITEM TO COMBINE IN DUPLICATES
        setItems,
        showLoader,
        ordersForEvent,
        ordersForDisplay,
        defaultCurrencyDetails,
        selectedEntity,
        dispatchRequiredValues,
        warningForShippingAddress,
        itemsPage,
        setItemsPage,
        onSearchChange,
    };
};

export default useOrderToEventIntegrationHook;

import { Box, Checkbox, Chip } from '@mui/material';
import {
    IAttribute,
    IAttributeValue,
} from '../../../../Specifications/Models/SpecificationInterfaces';
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import FWAutocomplete from '../../../../Common/FWAutocomplete';
import { FWInput } from '../../../../Common/FWInput';
import { FWMenuItem } from '../../../../Common/FWCustomMenu';
import { useGetAllCurrencyOptionsQuery } from '../../../../AdditionalCost/Services/additionalCosts.services';
import { useGetMeasurementUnitListQuery } from '../../../../UOM&Currency/Services/UOM.service';
import {
    ICustomAttributePayloadNew,
    ICustomAttributeValue,
} from '../../../../Models/Attributes.model';
import { FWTooltip } from '../../../../Common/FWCustomTooltip';
import { useGetSpecificationsListQueryQuery } from '../../../../Specifications/Services/specifications.service';

const SpecValueItem = ({
    currentSpec,
    setTransformedData,
    index,
}: {
    currentSpec: IAttribute;
    setTransformedData: Dispatch<SetStateAction<ICustomAttributePayloadNew[]>>;
    index: number;
}) => {
    const { data: allCurrencies } = useGetAllCurrencyOptionsQuery({});
    const { data: measurementUnitList } = useGetMeasurementUnitListQuery({});
    const { data: myOptionsForSpecs } = useGetSpecificationsListQueryQuery({});

    const [selectedSpecValues, setSelectedSpecValues] = useState<
        IAttributeValue[]
    >([]);
    const handleSelectChange = (
        event: React.ChangeEvent<{}>,
        value: IAttributeValue[]
    ) => {
        setSelectedSpecValues(value);
    };
    const getCurrencyName = useCallback(
        (text: string | null) => {
            if (!allCurrencies || !text) return '';

            const currency = allCurrencies.filter(
                (currency) => currency.entry_id === text
            );

            return currency.length > 0 ? currency[0].currency_symbol : '';
        },
        [allCurrencies]
    );

    const getMeasurementUnit = useCallback(
        (text: string | null) => {
            if (!measurementUnitList || !text) return '';

            const measurement_unit = measurementUnitList.filter(
                (val) => val.measurement_unit.measurement_unit_id === text
            );
            return measurement_unit.length > 0
                ? measurement_unit[0].measurement_unit
                      .measurement_unit_primary_name
                : '';
        },
        [measurementUnitList]
    );
    useEffect(() => {
        setSelectedSpecValues(currentSpec.attribute_values);
    }, [currentSpec.attribute_values]);

    const getOptionValueRender = (option: IAttributeValue) => {
        switch (currentSpec.attribute_type) {
            case 'NUMBER':
                return option?.value;
            case 'TEXT':
                return option?.value;
            case 'CURRENCY':
                return `${option.value} ${getCurrencyName(
                    option?.currency ?? ''
                )}`;
            case 'UOM':
                return `${option.value} ${getMeasurementUnit(
                    option?.measurement_unit
                )}`;
            // const getCurrencyName
            // return { optip };
            default:
                return option?.value;
        }
    };

    const handlePayloadData = (newSelectedValues: IAttributeValue[]) => {
        const selectedValueIds = newSelectedValues.map(
            (value) => value.attribute_value_id
        );

        // Filter the attribute_values based on selectedValueIds
        const updatedAttributeValues: ICustomAttributeValue[] =
            currentSpec.attribute_values
                .filter((attributeVal) =>
                    selectedValueIds.includes(attributeVal.attribute_value_id)
                )
                .map((attributeVal) => ({
                    attribute_value_linkage_id:
                        attributeVal.attribute_value_linkage_id ?? null,
                    attribute_value_id: attributeVal.attribute_value_id,
                    value: attributeVal.value,
                    measurement_unit_id: attributeVal.measurement_unit ?? null,
                    currency_id: attributeVal.currency ?? null,
                }));

        // Create the updatedSpec object of type ICustomAttributePayloadNew
        const updatedSpec: ICustomAttributePayloadNew = {
            attribute_linkage_id: currentSpec.attribute_linkage_id,
            attribute_id: currentSpec.attribute_id,
            attribute_name: currentSpec.attribute_name,
            attribute_type: currentSpec.attribute_type,
            attribute_values: updatedAttributeValues,
        };

        setTransformedData((prev) => {
            const updatedSpecs = [...prev];

            const customOptions = updatedSpecs[index].attribute_values.filter(
                (val) =>
                    val.attribute_value_id === null ||
                    val.attribute_value_id === ''
            );
            updatedSpecs[index] = updatedSpec;
            updatedSpecs[index].attribute_values = [
                ...updatedAttributeValues,
                ...customOptions,
            ];
            return updatedSpecs;
        });
    };

    const getOptions = useCallback(
        (id: string | null) => {
            const attributeOptions = myOptionsForSpecs?.find(
                (spec: { attribute_id: string | null }) =>
                    spec.attribute_id === id
            );
            return attributeOptions?.attribute_values;
        },
        [myOptionsForSpecs]
    );

    const otherOptions = useMemo(() => {
        return getOptions(currentSpec.attribute_id);
    }, [currentSpec.attribute_id, getOptions]);

    const optionsWithSelectAll = useMemo(() => {
        const optionsWithSelectAll = [
            { value: 'Select All', attribute_value_id: 'select_all' },
            ...(otherOptions ?? []),
        ];

        return optionsWithSelectAll;
    }, [otherOptions]);

    // const optionsWithSelectAll = [
    //     { value: 'Select All', attribute_value_id: 'select_all' },
    //     ...currentSpec.attribute_values,
    // ];

    const handleSelectAll = (isSelected: boolean) => {
        if (isSelected) {
            setSelectedSpecValues(otherOptions ?? []);
            handlePayloadData(otherOptions ?? []);
        } else {
            setSelectedSpecValues([]);
            handlePayloadData([]);
        }
    };

    return (
        <Box>
            <FWTooltip
                title={
                    currentSpec.attribute_id === ''
                        ? 'Please select the Specification name'
                        : selectedSpecValues.map((val) => val.value).join(', ')
                }
            >
                <Box>
                    <FWAutocomplete
                        disabled={currentSpec.attribute_id === ''}
                        popupIcon={
                            <i
                                className="bi bi-chevron-down"
                                style={{
                                    fontSize: '20px',
                                }}
                            />
                        }
                        limitTags={1}
                        options={optionsWithSelectAll}
                        getOptionLabel={(option: IAttributeValue) =>
                            option?.value
                        }
                        renderInput={(params) => <FWInput {...params} />}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    label={getOptionValueRender(option)}
                                    {...getTagProps({ index })}
                                    style={{ margin: '2px' }}
                                    onDelete={() => {
                                        const newSelectedValues =
                                            selectedSpecValues.filter(
                                                (selectedOption) =>
                                                    selectedOption.value !==
                                                    option?.value
                                            );
                                        setSelectedSpecValues(
                                            newSelectedValues
                                        );
                                        handlePayloadData(newSelectedValues); // Update payload after deselect
                                    }}
                                />
                            ))
                        }
                        multiple
                        value={selectedSpecValues}
                        // Hides the clear "X" icon
                        clearIcon={null}
                        clearOnEscape={false}
                        onChange={handleSelectChange}
                        renderOption={(props, option: IAttributeValue) => {
                            const isSelected = selectedSpecValues.some(
                                (selectedOption) =>
                                    selectedOption.value === option?.value
                            );

                            if (optionsWithSelectAll.length === 1) {
                                return (
                                    <FWMenuItem disabled>
                                        No options available
                                    </FWMenuItem>
                                );
                            }
                            if (option?.attribute_value_id === 'select_all') {
                                const isAllSelected =
                                    selectedSpecValues.length ===
                                    otherOptions?.length;

                                return (
                                    <FWMenuItem
                                        {...props}
                                        onClick={() =>
                                            handleSelectAll(!isAllSelected)
                                        }
                                    >
                                        <Checkbox
                                            style={{ marginRight: 8 }}
                                            checked={isAllSelected}
                                        />
                                        Select All
                                    </FWMenuItem>
                                );
                            }

                            return (
                                <FWMenuItem
                                    {...props}
                                    onClick={() => {
                                        const newSelectedValues = isSelected
                                            ? selectedSpecValues.filter(
                                                  (selectedOption) =>
                                                      selectedOption.value !==
                                                      option?.value
                                              )
                                            : [...selectedSpecValues, option];

                                        setSelectedSpecValues(
                                            newSelectedValues
                                        );
                                        handlePayloadData(newSelectedValues);
                                    }}
                                >
                                    <Checkbox
                                        style={{ marginRight: 8 }}
                                        checked={isSelected}
                                    />
                                    {/* {option.} */}
                                    {getOptionValueRender(option)}
                                </FWMenuItem>
                            );
                        }}
                    />
                </Box>
            </FWTooltip>
        </Box>
    );
};

export default SpecValueItem;

import { FormGroup } from '@material-ui/core';
import {
    IAdminSettingModule,
    IASSettings,
} from '../../../Interfaces/AdminSettingsInterface';
import { ICurrencyDetails } from '../../../../../Models/Currency.model';
import { Dispatch, Fragment, SetStateAction } from 'react';
import { asModuleValuesMap } from '../../../Constants/AdminSettingsConstants';
import ModuleSetting from '../ModuleSetting';
import { Spacer } from '../../../Pages/Settings/AdminSettingsHomePage';

interface IEventSettings {
    settings: IASSettings[];
    currencies: ICurrencyDetails[];
    setModuleValues: Dispatch<SetStateAction<IAdminSettingModule>>;
}

const ProjectSettings = ({
    settings,
    currencies,
    setModuleValues,
}: IEventSettings) => {
    return (
        <>
            <FormGroup>
                {settings.map((settingItem, index) => {
                    return settingItem.option in asModuleValuesMap &&
                        !asModuleValuesMap[settingItem.option].hidden ? (
                        <Fragment key={settingItem.option}>
                            <ModuleSetting
                                setting={settingItem}
                                currencies={currencies}
                                setModuleValues={setModuleValues}
                                childIndex={index}
                            />
                            {settings.length - 1 !== index && <Spacer />}
                        </Fragment>
                    ) : (
                        <Fragment key={settingItem.option}></Fragment>
                    );
                })}
            </FormGroup>
        </>
    );
};

export default ProjectSettings;

import { createSlice } from '@reduxjs/toolkit';
import { PeriodEnum } from '../../Global/Interfaces/PeriodInterface';
import { ICLMNewTemplate } from '../Interface/NewCLMTemplateInterface';
import {
    IBuyerIdentifications,
    IContractCustomFieldSections,
    IContractItemDetailsNew,
    ICreateContract,
} from '../Interface/ContractInterfaces';
import { ICurrencyDetails } from '../../Models/Currency.model';
import {
    ContractDetailsErrors,
    ItemDetailsNewErrors,
    VendorDetailsErrors,
} from '../Interface/CLMErrorsInterface';
import { cloneDeep } from 'lodash';
import { ICustomAttributeNew } from '../../Models/Attributes.model';

export type updateCLMItemDetailsActionType =
    | keyof IContractItemDetailsNew
    | 'price'
    | 'paymentTerms_invoiceItem_terms'
    | 'paymentTerms_invoiceItem_period'
    | 'paymentTerms_invoiceItem_appliedFrom'
    | 'paymentTerms_poItem_allocationRows'
    | 'paymentTerms_prepaymentPercentage';

export interface ICLMStore {
    allItems: { [key: string]: IContractItemDetailsNew };
    itemCount: number;
    templateId: string;
    templateDetails: ICLMNewTemplate | null;
    buyerDetails: IBuyerDetails;
    project: {
        projectCode: string;
        projectName: string;
        projectId: string;
    };
    contractDetails: IContractDetails;
    itemDetails: IContractItemDetailsNew[];
    vendorDetails: IVendorDetails;
    buyerIdentificationList: IBuyerIdentifications[];
    allCurrencies: ICurrencyDetails[];
    customFields: IContractCustomFieldSections;
    contractErrors: ContractDetailsErrors;
    vendorErrors: VendorDetailsErrors;
    itemErrors: { [key: string]: ItemDetailsNewErrors };
    termsAndConditions: ITermsAndConditionsListItem | null;
    pageNumber: number;
    errorPointer: number;
    totalPages: number;
    resetPageNumber: boolean;
    viewMode: boolean;
}

export interface ITermsAndConditionsListItem {
    terms_and_conditions: ITermsAndConditionsDetails;
}

export interface ITermsAndConditionsDetails {
    data: string;
    name: string;
    terms_and_conditions_id: string | null;
}

export interface IContractDetails {
    contractAttachments: any[];
    contractEndDate: string;
    contractStartDate: string;
    contractName: string;
    contractId: string;
    contractStatus: string;
    contractUUID: string;
}

export interface IBuyerDetails {
    buyerAddressId: string;
    buyerAddressName: string;
    buyerContactId: string;
    buyerContactName: string;
    buyerEntityId: string;
    buyerEntityName: string;
    buyerIdentificationId: string;
    buyerIdentificationValue: string;
    buyerIdentificationName: string;
}
export interface IVendorDetails {
    vendorAddressId: string;
    vendorAddressName: string;
    vendorAttachments: any[];
    vendorContactId: string;
    vendorContactName: string;
    vendorEnterpriseId: string;
    vendorEntityId: string;
    vendorEntityName: string;
    vendorIdentificationId: string;
    vendorIdentificationValue: string;
    vendorIdentificationName: string;
}

export interface CLMItem {
    isDisabled: boolean;
    isItemCreated: boolean;
    itemAttachments: any[];
    itemAttributes: ICustomAttributeNew[];
    itemBuyerSkus: any[];
    itemBuyerName: string;
    itemCode: string;
    itemCurrencyCode: string;
    itemCustomFields: {
        name: string;
        fields: any[];
    }[];
    itemDescriptions: string;
    itemId: string;
    measurementUnitDetails: {
        category: string;
        id: string;
        primaryName: string;
        valueTypes: string;
        measurementUnits: {
            abbreviation: string;
            category: string;
            id: string;
            isVerifiedUnit: boolean;
            name: string;
            type: string;
        };
    };
    itemName: string;
    paymentTerms: {
        appliedFrom: {
            referenceDateCategory: string;
            referenceDateLabel: string;
        };
        paymentType: string;
        period: PeriodEnum;
        prepaymentPercentage: string;
        terms: string;
    };
    leadTime: number;
    leadTimePeriod: PeriodEnum;
    pricingValue: number;
    quantity: number | null;
    tags: string[];
    uuid: string;
}

export interface IUpdateCLM {
    type: string;
    payload:
        | {
              type: 'INITIALISE_CONTRACT_DETAILS';
              value: ICreateContract;
          }
        | {
              type: 'SET_ALL_CURRENCIES';
              value: ICurrencyDetails[];
          }
        | {
              type: 'UPDATE_ITEM_DETAILS';
              value: IContractItemDetailsNew[];
          }
        | {
              type: 'UPDATE_SINGLE_ITEM_DETAILS';
              value: {
                  item: IContractItemDetailsNew;
                  idx: string;
              };
          }
        | {
              type: 'UPDATE_CONTRACT_DETAILS';
              value: IContractDetails;
          }
        | {
              type: 'UPDATE_BUYER_DETAILS';
              value: IBuyerDetails;
          }
        | {
              type: 'UPDATE_VENDOR_DETAILS';
              value: any;
          }
        | {
              type: 'UPDATE_VENDOR_CONTACT';
              value: any;
          }
        | {
              type: 'UPDATE_VENDOR_ADDRESS';
              value: any;
          }
        | {
              type: 'UPDATE_VENDOR_IDENTIFICATIONS';
              value: any;
          }
        | {
              type: 'UPDATE_VENDOR_ENTITY';
              value: any;
          }
        | {
              type: 'UPDATE_CUSTOM_FIELDS';
              value: any;
          }
        | {
              type: 'UPDATE_TOTAL_PAGES';
              value: number;
          }
        | {
              type: 'UPDATE_CONTRACT_ERRORS';
              value: ContractDetailsErrors;
          }
        | {
              type: 'ADD_SINGLE_ITEM';
              value: IContractItemDetailsNew;
          }
        | {
              type: 'UPDATE_CONTRACT_NAME';
              value: string;
          }
        | {
              type: 'UPDATE_CONTRACT_START_DATE';
              value: string;
          }
        | {
              type: 'UPDATE_CONTRACT_END_DATE';
              value: string;
          }
        | {
              type: 'UPDATE_BUYER_IDENTIFICATION';
              value: {
                  id: string;
                  name: string;
              };
          }
        | {
              type: 'UPDATE_BUYER_CONTACT';
              value: {
                  id: string;
                  name: string;
              };
          }
        | {
              type: 'UPDATE_BUYER_ADDRESS';
              value: {
                  id: string;
                  name: string;
              };
          }
        | {
              type: 'UPDATE_PROJECT';
              value: {
                  id: string;
                  name: string;
                  code: string;
              };
          }
        | {
              type: 'UPDATE_TEMPLATE_DETAILS';
              value: ICLMNewTemplate;
          }
        | {
              type: 'RESET_PAGE_NUMBER';
              value: boolean;
          }
        | {
              type: 'UPDATE_ITEM_COUNT';
              value: number;
          }
        | {
              type: 'UPDATE_ALL_ITEMS';
              value: IContractItemDetailsNew[];
          }
        | {
              type: 'UPDATE_ITEMS_ON_PAGE_CHANGE';
              value: {
                  page: number;
                  itemsPerPage: number;
              };
          }
        | {
              type: 'ADD_ITEM_ERROR';
              value: {
                  error: ItemDetailsNewErrors;
                  id: string;
              };
          }
        | {
              type: 'UPDATE_ITEM_ERRORS';
              value: {
                  error: ItemDetailsNewErrors;
                  idx: string;
              };
          }
        | {
              type: 'UPDATE_ITEM_COUNT_ON_AD';
              value: number;
          }
        | {
              type: 'UPDATE_VENDOR_ERRORS';
              value: VendorDetailsErrors;
          }
        | {
              type: 'UPDATE_TNC';
              value: ITermsAndConditionsListItem;
          }
        | {
              type: 'DELETE_ITEM_IN_CONTRACT';
              value: {
                  contractId: string;
                  page: number;
                  itemsPerPage: number;
              };
          }
        | {
              type: 'UPDATE_SEARCH_ITEM_RESULTS';
              value: string[];
          }
        | {
              type: 'RESET_CLM_STORE';
              value: null;
          }
        | {
              type: 'RESET_CLM_STORE_ON_CLONE';
              value: null;
          }
        | {
              type: 'UPDATE_ITEMS_BASED_ON_SEARCH';
              value: string[];
          }
        | {
              type: 'UPDATE_PAGE_NUMBER';
              value: number;
          }
        | {
              type: 'DELETE_ERROR_ON_ITEM';
              value: string;
          }
        | {
              type: 'UPDATE_VIEW_MODE';
              value: boolean;
          };
}

export const CLMSlice = createSlice<
    ICLMStore,
    {
        updateCLMStore: (state: ICLMStore, action: IUpdateCLM) => void;
    },
    string
>({
    name: 'CLMStore',
    initialState: {
        viewMode: false,
        totalPages: 0,
        resetPageNumber: false,
        errorPointer: 0,
        pageNumber: 1,
        termsAndConditions: null,
        allItems: {},
        itemCount: 0,
        customFields: {
            section_list: [
                { name: 'contract_details', fields: [] },
                { name: 'vendor_details', fields: [] },
                { name: 'terms_and_conditions', fields: [] },
            ],
        },
        allCurrencies: [],
        templateId: '',
        templateDetails: null,
        buyerDetails: {
            buyerAddressId: '',
            buyerAddressName: '',
            buyerContactId: '',
            buyerContactName: '',
            buyerEntityId: '',
            buyerEntityName: '',
            buyerIdentificationId: '',
            buyerIdentificationValue: '',
            buyerIdentificationName: '',
        },
        project: {
            projectCode: '',
            projectId: '',
            projectName: '',
        },
        contractDetails: {
            contractAttachments: [],
            contractEndDate: '',
            contractStartDate: '',
            contractName: '',
            contractId: '',
            contractStatus: '',
            contractUUID: '',
        },
        itemDetails: [],
        vendorDetails: {
            vendorAddressId: '',
            vendorAddressName: '',
            vendorAttachments: [],
            vendorContactId: '',
            vendorContactName: '',
            vendorEnterpriseId: '',
            vendorEntityId: '',
            vendorEntityName: '',
            vendorIdentificationId: '',
            vendorIdentificationValue: '',
            vendorIdentificationName: '',
        },
        contractErrors: {
            contract_name: null,
            contract_start_date: null,
            contract_end_date: null,
            buyer_identifications_id: null,
            buyer_contact_id: null,
            buyer_address_id: null,
            project_id: null,
            custom_fields: {},
        },
        vendorErrors: {
            vendor_entity_id: null,
            vendor_contact_id: null,
            vendor_identifications_name: null,
            vendor_address_id: null,
            custom_fields: {},
        },
        itemErrors: {},
        buyerIdentificationList: [],
    },
    reducers: {
        updateCLMStore: (state: ICLMStore, action: IUpdateCLM) => {
            try {
                switch (action.payload.type) {
                    //INITIALIZATION
                    case 'INITIALISE_CONTRACT_DETAILS':
                        state.contractDetails.contractStatus =
                            action.payload.value.contract_status;
                        state.contractDetails.contractAttachments =
                            action.payload.value.contract_attachments;
                        state.contractDetails.contractUUID =
                            action.payload.value.contract_uuid;
                        state.contractDetails.contractId =
                            action.payload.value.contract_id;
                        state.contractDetails.contractStartDate =
                            action.payload.value.contract_start_date;
                        state.contractDetails.contractEndDate =
                            action.payload.value.contract_end_date;
                        state.contractDetails.contractName =
                            action.payload.value.contract_name;

                        state.buyerDetails.buyerIdentificationId =
                            action.payload.value.buyer_identifications_id;
                        state.buyerDetails.buyerIdentificationName =
                            action.payload.value.buyer_identifications_name;
                        state.buyerDetails.buyerIdentificationValue =
                            action.payload.value.buyer_identifications_value;
                        state.buyerDetails.buyerContactId =
                            action.payload.value.buyer_contact_id;
                        state.buyerDetails.buyerContactName =
                            action.payload.value.buyer_contact_name;
                        state.buyerDetails.buyerAddressId =
                            action.payload.value.buyer_address_id;
                        state.buyerDetails.buyerAddressName =
                            action.payload.value.buyer_address_name;
                        state.buyerDetails.buyerEntityId =
                            action.payload.value.buyer_entity_id;
                        state.buyerDetails.buyerEntityName =
                            action.payload.value.buyer_entity_name;

                        state.vendorDetails.vendorAttachments =
                            action.payload.value.vendor_attachments;
                        state.vendorDetails.vendorEntityName =
                            action.payload.value.vendor_entity_name;
                        state.vendorDetails.vendorIdentificationName =
                            action.payload.value.vendor_identifications_name;
                        state.vendorDetails.vendorEntityId =
                            action.payload.value.vendor_entity_id;
                        state.vendorDetails.vendorIdentificationValue =
                            action.payload.value.vendor_identifications_value;
                        state.vendorDetails.vendorContactId =
                            action.payload.value.vendor_contact_id;
                        state.vendorDetails.vendorContactName =
                            action.payload.value.vendor_contact_name;
                        state.vendorDetails.vendorEnterpriseId =
                            action.payload.value.vendor_enterprise_id;
                        state.vendorDetails.vendorAddressId =
                            action.payload.value.vendor_address_id;
                        state.vendorDetails.vendorAddressName =
                            action.payload.value.vendor_address_name;

                        state.project.projectId =
                            action.payload.value.project_id;
                        state.project.projectCode =
                            action.payload.value.project_code;
                        state.project.projectName =
                            action.payload.value.project_name;

                        state.customFields = action.payload.value.custom_fields;

                        state.termsAndConditions = {
                            terms_and_conditions: {
                                data: action.payload.value.terms_and_conditions
                                    .data,
                                name:
                                    action.payload.value.terms_and_conditions
                                        .name ?? '',
                                terms_and_conditions_id:
                                    action.payload.value.terms_and_conditions
                                        .terms_and_conditions_id,
                            },
                        };
                        break;
                    case 'UPDATE_ITEM_DETAILS':
                        state.itemDetails = action.payload.value;
                        break;
                    case 'UPDATE_CONTRACT_DETAILS':
                        state.contractDetails = action.payload.value;
                        break;
                    case 'UPDATE_BUYER_DETAILS':
                        state.buyerDetails = action.payload.value;
                        break;
                    case 'UPDATE_VENDOR_DETAILS':
                        state.vendorDetails = action.payload.value;
                        break;
                    case 'SET_ALL_CURRENCIES':
                        state.allCurrencies = action.payload.value;
                        break;
                    case 'UPDATE_PROJECT':
                        state.project.projectId = action.payload.value.id;
                        state.project.projectName = action.payload.value.name;
                        state.project.projectCode = action.payload.value.code;
                        break;
                    case 'UPDATE_TEMPLATE_DETAILS':
                        state.templateDetails = action.payload.value;
                        break;
                    case 'UPDATE_CONTRACT_ERRORS':
                        state.contractErrors = action.payload.value;
                        break;
                    case 'UPDATE_VENDOR_ERRORS':
                        state.vendorErrors = action.payload.value;
                        break;
                    case 'UPDATE_VENDOR_IDENTIFICATIONS':
                        state.vendorDetails.vendorIdentificationId =
                            action.payload.value.id;
                        state.vendorDetails.vendorIdentificationName =
                            action.payload.value.name;
                        state.vendorDetails.vendorIdentificationValue =
                            action.payload.value.value;
                        break;
                    case 'UPDATE_VENDOR_ADDRESS':
                        state.vendorDetails.vendorAddressId =
                            action.payload.value.id;
                        state.vendorDetails.vendorAddressName =
                            action.payload.value.name;
                        break;
                    case 'RESET_PAGE_NUMBER':
                        state.resetPageNumber = action.payload.value;
                        break;
                    case 'UPDATE_VENDOR_CONTACT':
                        state.vendorDetails.vendorContactId =
                            action.payload.value.id;
                        state.vendorDetails.vendorContactName =
                            action.payload.value.name;
                        break;
                    case 'UPDATE_VENDOR_ENTITY':
                        state.vendorDetails.vendorEntityId =
                            action.payload.value.id;
                        state.vendorDetails.vendorEntityName =
                            action.payload.value.name;
                        state.vendorDetails.vendorEnterpriseId =
                            action.payload.value.enterpriseId;
                        break;

                    case 'UPDATE_CUSTOM_FIELDS':
                        state.customFields = action.payload.value;
                        break;
                    case 'UPDATE_SINGLE_ITEM_DETAILS':
                        state.allItems[action.payload.value.idx] =
                            action.payload.value.item;
                        break;
                    case 'ADD_SINGLE_ITEM':
                        state.itemDetails.push(action.payload.value);
                        break;
                    case 'UPDATE_ITEM_COUNT':
                        state.itemCount = action.payload.value;
                        break;
                    case 'UPDATE_ALL_ITEMS':
                        let allItems = cloneDeep(state.allItems);
                        action.payload.value.forEach((val) => {
                            allItems[val.item_uuid] = val;
                        });
                        state.allItems = allItems;
                        break;
                    case 'UPDATE_ITEMS_ON_PAGE_CHANGE':
                        // let page = action.payload.value.page;
                        // let itemsPerPage = action.payload.value.itemsPerPage;
                        // let items = cloneDeep(state.allItems);
                        // state.itemDetails = items.slice(
                        //     (page - 1) * itemsPerPage,
                        //     page * itemsPerPage
                        // );
                        // state.errorPointer = (page - 1) * itemsPerPage;
                        break;
                    case 'ADD_ITEM_ERROR':
                        let itemErrors = cloneDeep(state.itemErrors);
                        itemErrors[action.payload.value.id] =
                            action.payload.value.error;
                        state.itemErrors = itemErrors;
                        // state.itemErrors.push(action.payload.value);
                        break;
                    case 'UPDATE_ITEM_ERRORS':
                        let errors = cloneDeep(state.itemErrors);
                        errors[action.payload.value.idx] =
                            action.payload.value.error;
                        state.itemErrors = errors;
                        break;
                    case 'UPDATE_ITEM_COUNT_ON_AD':
                        state.itemCount =
                            state.itemCount + action.payload.value;
                        break;
                    case 'UPDATE_TOTAL_PAGES':
                        state.totalPages = action.payload.value;
                        break;
                    case 'UPDATE_TNC':
                        state.termsAndConditions = action.payload.value;
                        break;
                    case 'DELETE_ITEM_IN_CONTRACT':
                        delete state.allItems[action.payload.value.contractId];
                        // if (state.allItems.length % 10 === 0) {
                        //     state.pageNumber = state.pageNumber - 1;
                        // }
                        break;
                    case 'UPDATE_ITEMS_BASED_ON_SEARCH':
                        // state.itemDetails = state.allItems.filter((item) =>
                        //     action.payload.value.includes(item.item_uuid)
                        // );
                        break;
                    case 'UPDATE_PAGE_NUMBER':
                        state.pageNumber = action.payload.value;
                        break;
                    case 'DELETE_ERROR_ON_ITEM':
                        delete state.itemErrors[action.payload.value];
                        break;
                    case 'UPDATE_VIEW_MODE':
                        state.viewMode = action.payload.value;
                        break;
                    case 'RESET_CLM_STORE':
                        state.termsAndConditions = null;
                        state.allItems = {};
                        state.itemCount = 0;
                        state.customFields = {
                            section_list: [
                                { name: 'contract_details', fields: [] },
                                { name: 'vendor_details', fields: [] },
                                {
                                    name: 'terms_and_conditions',
                                    fields: [],
                                },
                            ],
                        };
                        state.allCurrencies = [];
                        state.templateId = '';
                        state.templateDetails = null;
                        state.buyerDetails = {
                            buyerAddressId: '',
                            buyerAddressName: '',
                            buyerContactId: '',
                            buyerContactName: '',
                            buyerEntityId: '',
                            buyerEntityName: '',
                            buyerIdentificationId: '',
                            buyerIdentificationValue: '',
                            buyerIdentificationName: '',
                        };
                        state.project = {
                            projectCode: '',
                            projectId: '',
                            projectName: '',
                        };
                        state.contractDetails = {
                            contractAttachments: [],
                            contractEndDate: '',
                            contractStartDate: '',
                            contractName: '',
                            contractId: '',
                            contractStatus: '',
                            contractUUID: '',
                        };
                        state.itemDetails = [];
                        state.vendorDetails = {
                            vendorAddressId: '',
                            vendorAddressName: '',
                            vendorAttachments: [],
                            vendorContactId: '',
                            vendorContactName: '',
                            vendorEnterpriseId: '',
                            vendorEntityId: '',
                            vendorEntityName: '',
                            vendorIdentificationId: '',
                            vendorIdentificationValue: '',
                            vendorIdentificationName: '',
                        };
                        state.contractErrors = {
                            contract_name: null,
                            contract_start_date: null,
                            contract_end_date: null,
                            buyer_identifications_id: null,
                            buyer_contact_id: null,
                            buyer_address_id: null,
                            project_id: null,
                            custom_fields: {},
                        };
                        state.vendorErrors = {
                            vendor_entity_id: null,
                            vendor_contact_id: null,
                            vendor_identifications_name: null,
                            vendor_address_id: null,
                            custom_fields: {},
                        };
                        state.itemErrors = {};
                        state.buyerIdentificationList = [];
                        break;
                    case 'RESET_CLM_STORE_ON_CLONE':
                        state.termsAndConditions = null;
                        state.allItems = {};
                        state.itemCount = 0;
                        state.customFields = {
                            section_list: [
                                { name: 'contract_details', fields: [] },
                                { name: 'vendor_details', fields: [] },
                                {
                                    name: 'terms_and_conditions',
                                    fields: [],
                                },
                            ],
                        };
                        state.buyerDetails = {
                            buyerAddressId: '',
                            buyerAddressName: '',
                            buyerContactId: '',
                            buyerContactName: '',
                            buyerEntityId: '',
                            buyerEntityName: '',
                            buyerIdentificationId: '',
                            buyerIdentificationValue: '',
                            buyerIdentificationName: '',
                        };
                        state.project = {
                            projectCode: '',
                            projectId: '',
                            projectName: '',
                        };
                        state.contractDetails = {
                            contractAttachments: [],
                            contractEndDate: '',
                            contractStartDate: '',
                            contractName: '',
                            contractId: '',
                            contractStatus: '',
                            contractUUID: '',
                        };
                        state.itemDetails = [];
                        state.vendorDetails = {
                            vendorAddressId: '',
                            vendorAddressName: '',
                            vendorAttachments: [],
                            vendorContactId: '',
                            vendorContactName: '',
                            vendorEnterpriseId: '',
                            vendorEntityId: '',
                            vendorEntityName: '',
                            vendorIdentificationId: '',
                            vendorIdentificationValue: '',
                            vendorIdentificationName: '',
                        };
                        state.contractErrors = {
                            contract_name: null,
                            contract_start_date: null,
                            contract_end_date: null,
                            buyer_identifications_id: null,
                            buyer_contact_id: null,
                            buyer_address_id: null,
                            project_id: null,
                            custom_fields: {},
                        };
                        state.vendorErrors = {
                            vendor_entity_id: null,
                            vendor_contact_id: null,
                            vendor_identifications_name: null,
                            vendor_address_id: null,
                            custom_fields: {},
                        };
                        state.itemErrors = {};
                        state.buyerIdentificationList = [];
                        break;
                }
            } catch (err) {
                console.error('Factwise', err);
            }
        },
    },
});

export const { updateCLMStore } = CLMSlice.actions;

const CLMStore = CLMSlice.reducer;

export default CLMStore;

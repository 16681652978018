import { Box, Grid, styled } from '@mui/material';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { batch, shallowEqual, useDispatch } from 'react-redux';
import {
    calculateAddtionalCost,
    calculateTotalAdditionalCostAndDeviation,
    convertAdditionalCostBackendToFrontend,
} from '../../../AdditionalCost/helpers';
import {
    AllocationTypeEnum,
    CostSourceEnum,
    CostTypeEnum,
    IAdditionalCost,
    IAdditionalCostInformation,
} from '../../../AdditionalCost/models/AdditionalCost.model';
import { IGetContractDetailsForItem } from '../../../CLM/Services/CLM.service';
import { FWIconButton } from '../../../Common/FWButton';
import { FWLink } from '../../../Common/FWLink';
import { FWTypography } from '../../../Common/FWTypograhy';
import { getFWDateTime } from '../../../Common/Utils/DateUtils';
import { leadTimeSurpassed } from '../../../Components/Shared/Utils';
import { isValueValidForStringArrNull } from '../../../Global/Helpers/helpers';
import {
    DELIVERABLE_LENGTH_LIMIT,
    validatePaymentTerms,
} from '../../../Global/Helpers/paymentTermsHelpers';
import { translateBackendCustomFieldSectionsViaTemplateToFrontEnd } from '../../../Global/Helpers/templateHelpers';
import { CustomTemplateItemTypes } from '../../../Global/Interfaces/CustomTemplate.interface';
import {
    IRfqTemplate,
    TemplateStandardFieldsEnum,
} from '../../../Global/Interfaces/TemplateInterface';
// import { IAddresses } from '../../../Models/Address.model';
import { useSelector } from 'react-redux';
import { IAddresses } from '../../../Models/Address.model';
import { IGetRFQDetails } from '../../../Models/RFQ.model';
import { IPreSelectedAdditionalCostFromApi } from '../../../Organizations/Admin/Hooks/ItemDirectory/ItemAdditionalCostHook';
import { IGlobalAppStore, store } from '../../../Redux/Store';
import {
    IBomItemDeliverScheduleStructure,
    IBuyerEnterpriseItemDetails,
    ICustomEventDetails,
    ICustomEventDetailsErrors,
    ICustomEventItemDetails,
    IEventDetails,
    IEventItemCustomFieldSections,
    IEventItemDetails,
    IEventItemFieldValidation,
    IEventItemValidation,
    custom_field_backend_section_names,
    updateCustomEventDetailsActionType,
    updateEventItemDetailsActionType,
} from '../../Interfaces/Buyer/RFQ.model';
import {
    ArgumentsForPaginationApiCall,
    RFQPaginatedApiSlice,
    RFQ_ITEM_PER_PAGE,
    getCurrencyConversion,
    useGetListOfAllSellersForAllD2POItemsQuery,
    useGetListOfAllSellersForAllRfqItemsQuery,
    // useGetListOfRfqItemPaginatedQueryQuery,
    useLazyGetListOfRfqItemPaginatedQueryQuery,
} from '../../Services/Buyer/RFQ.service';
import {
    updateEventBoomDataStore,
    updateEventDataStore,
} from '../../Slices/EventCreationSlice';
import { translateBackendCustomFieldSectionsToFrontEnd } from '../CustomFieldHelpers';
import {
    calculateNewItemSubtotalWithDecimal,
    calculateNewItemTotalWithDecimalPlaces,
} from '../../../Components/Shared/totalCalculator';
import {
    IRfqAnalyticsStoreListOfBomStruct,
    IRfqAnalyticsStoreListOfBomStructWIthHeiriacy,
} from '../../Slices/RfqAnalyticsSlice';
import {
    getBomHierarachyEntryIdFromBoms,
    getBomHierarachyLabelFromBoms,
} from '../../RFQAnalytics/Helpers/BomDsHelper';
import {
    removeIndexFromEveryWhere,
    removeQuantityFromEveryWhere,
} from '../../../Components/Buyer/Events/QuoteComparison/DsAllocationPopup/SingleDsAllocationBidItemrenderer';
// import { ICustomAttributeNew } from '../../../Models/Attributes.model';

export const validateRFQDetails = (
    field: updateCustomEventDetailsActionType | 'ALL',
    data: ICustomEventDetails,
    errors: ICustomEventDetailsErrors,
    allExistingEventNames: Set<string>,
    type: 'DIRECT' | 'FROM_EVENT',
    billing_address?: IAddresses[],
    shipping_address?: IAddresses[]
): ICustomEventDetailsErrors => {
    let newErrors = cloneDeep(errors);
    if (field === 'event_name' || field === 'ALL') {
        if (data.event_name.trim() === '') {
            newErrors.event_name = {
                type: 'ERROR',
                message: 'Please enter a valid event name',
            };
        } else if (data.event_name.trim().length > 50) {
            newErrors.event_name = {
                type: 'ERROR',
                message: 'Cannot exceed 50 characters',
            };
        } else if (
            allExistingEventNames.has(data.event_name.trim().toUpperCase())
        ) {
            newErrors.event_name = {
                type: 'ERROR',
                message: 'Duplicate event name',
            };
        } else {
            delete newErrors['event_name'];
        }
    }
    if (field === 'event_deadline' || field === 'ALL') {
        if (
            type === 'FROM_EVENT' &&
            !moment(data.event_deadline, moment.ISO_8601, true).isValid()
        ) {
            newErrors.event_deadline = {
                type: 'ERROR',
                message: 'Please enter a valid deadline',
            };
        } else if (
            type === 'FROM_EVENT' &&
            !moment(data.event_deadline).isAfter(moment().add(1, 'hours'))
        ) {
            newErrors.event_deadline = {
                type: 'ERROR',
                message: `Must be after ${getFWDateTime(
                    moment().add(1, 'hours').format('YYYY-MM-DDTHH:mm:ss')
                )}`,
            };
        } else {
            delete newErrors.event_deadline;
        }
    }
    if (field === 'billing_id' || field === 'ALL') {
        if (
            data.billing_id === '' ||
            data.billing_id === null ||
            (billing_address &&
                billing_address.find(
                    (addres) => addres.address.address_id === data.billing_id
                ) === undefined)
        ) {
            newErrors.billing_id = {
                type: 'ERROR',
                message: 'Please select a valid billing address',
            };
        } else {
            delete newErrors['billing_id'];
        }
    }
    if (field === 'shipping_id' || field === 'ALL') {
        if (
            data.shipping_id === '' ||
            data.shipping_id === null ||
            (shipping_address &&
                shipping_address.find(
                    (addres) => addres.address.address_id === data.shipping_id
                ) === undefined)
        ) {
            newErrors.shipping_id = {
                type: 'ERROR',
                message: 'Please select a valid shipping address',
            };
        } else {
            delete newErrors['shipping_id'];
        }
    }
    if (field === 'currency_id' || field === 'ALL') {
        if (data.currency_id === '') {
            newErrors.currency_id = {
                type: 'ERROR',
                message: 'Please select a valid currency',
            };
        } else {
            delete newErrors['currency_id'];
        }
    }
    return newErrors;
};

export const translateRFQDetailsToCustomDetails = (
    rfq_details: IEventDetails | IGetRFQDetails,
    type: 'DIRECT' | 'FROM_EVENT'
): ICustomEventDetails => {
    let details = rfq_details;
    let obj: ICustomEventDetails = {
        custom_fields: rfq_details.custom_fields,
        customer_entity_id: rfq_details.customer_entity_id,
        attachments: rfq_details.attachments,
        default_customer_entity_id:
            rfq_details.rfq_default_information.default_customer_entity_id ??
            null,
        default_event_item_additional_information:
            rfq_details.rfq_default_information
                .default_event_item_additional_information ?? '',
        default_event_quantity_tolerance_percentage:
            rfq_details.rfq_default_information
                .default_event_quantity_tolerance_percentage,
        custom_fields_negotiate: rfq_details.custom_fields_negotiate,
        default_item_terms: 'NONE',
        entity_id: details.buyer_entity.buyer_entity_id,
        entity_name: details.buyer_entity.buyer_entity_name,
        event_name: details.event_name,
        event_deadline: moment(details.event_end_datetime).format(
            'YYYY-MM-DDTHH:mm'
        ),
        project_code: details?.project?.project_code ?? '',
        project_name: details?.project?.project_name ?? '',
        currency_id: details.rfq_default_information.default_currency_id,
        event_custom_ID: details.custom_event_id,
        shipping_id: details.address_information.shipping_address_id ?? '',
        default_initial_reminder_hours:
            rfq_details.rfq_default_information.default_initial_reminder_hours,
        default_max_reminders:
            rfq_details.rfq_default_information.default_max_reminders,
        default_reminder_interval:
            rfq_details.rfq_default_information.default_reminder_interval,
        default_reminder_module:
            rfq_details.rfq_default_information.default_reminder_module,
        default_reminder_required:
            rfq_details.rfq_default_information.default_reminder_required,
        event_type: details.event_type as any,
        sub_event_id: details.rfq_entry_id!,
        event_id: details.event_id,
        event_status: details.event_status,
        sub_event_status: details.status,
        event_creator_id: details.event_details.original_event_creator,
        billing_id: details.address_information.billing_address_id ?? '',
        is_event_confidential:
            details.event_additional_details !== null
                ? details.event_additional_details.access ===
                  'ACCESS_RESTRICTED'
                : true,
        paymentTerms: details.rfq_default_information.defaultPaymentTerms,
        incoterm_id:
            type === 'DIRECT'
                ? details.incoterm_id ?? ''
                : details.rfq_default_information.default_event_incoterm_id,
        // default_initial_reminder_hours : rfq_details.def
        lead_time:
            type === 'DIRECT'
                ? details.lead_time ?? ''
                : details.rfq_default_information.default_event_lead_time,
        lead_time_period:
            type === 'DIRECT'
                ? details.lead_time_period ?? ''
                : details.rfq_default_information
                      .default_event_lead_time_period,
        custom_additional_information:
            details.custom_additional_information ?? '',
        delivery_date: details.rfq_default_information.default_delivery_date,
        gr_tolerance: details.gr_tolerance ?? '',
        import_from_requisition: false,
        requisition_no:
            details.requisition_information.length > 0
                ? details.requisition_information[0].requisition_number
                : '',
        requisition_approved:
            details.requisition_information.length > 0
                ? details.requisition_information[0].requisition_approved
                : false,
        event_creator_name: details.original_event_creator_name,
        currency_symbol:
            details.rfq_default_information.default_currency_symbol,
        currency_code_abbreviation:
            details.rfq_default_information.default_currency_abbreviation,
        billing_address_name: details.address_information.billing_address,
        shipping_address_name: details.address_information.shipping_address,
        last_action_notes:
            'last_action_information' in details.additional_details
                ? details.additional_details.last_action_information.notes ?? ''
                : '',
        parent_sub_event_id: details.additional_details
            ? details.additional_details.parent_rfq_id ?? null
            : null,
        template_id: details.event_additional_details?.template_id ?? '',
        default_event_project_id:
            details.rfq_default_information.default_event_project_id ?? '',
        default_event_cost_centre_id:
            details.rfq_default_information.default_event_cost_centre_id ?? '',
        default_event_gl_id:
            details.rfq_default_information.default_event_gl_id ?? '',
        cost_centre_id: type === 'DIRECT' ? details.cost_centre_id ?? '' : '',
        project_id:
            type === 'DIRECT'
                ? details.project_id ?? ''
                : details.project_id ?? '',
        gl_id: type === 'DIRECT' ? details.gl_id ?? '' : '',
        showWarnings:
            window.localStorage.getItem(`showWarnings ${details.event_id}`) ===
            'true',
        additional_costs: convertAdditionalCostBackendToFrontend(
            details.additional_costs
        ),
        taxes: convertAdditionalCostBackendToFrontend(details.taxes),
        discounts: convertAdditionalCostBackendToFrontend(details.discounts),
        total: details.pricing_information.total_price ?? 0,
        item_total: details.pricing_information.item_total ?? 0,
        item_subtotal: details.pricing_information.item_subtotal ?? 0,
        item_additional_costs_total:
            details.pricing_information.item_additional_costs_total ?? 0,
        item_taxes_total: details.pricing_information.item_taxes_total ?? 0,
        item_discounts_total:
            details.pricing_information.item_discounts_total ?? 0,
        vendor_item_total: details.pricing_information.vendor_item_total ?? 0,
        vendor_item_subtotal:
            details.pricing_information.vendor_item_subtotal ?? 0,
        vendor_item_additional_costs_total:
            details.pricing_information.vendor_item_additional_costs_total ?? 0,
        vendor_item_taxes_total:
            details.pricing_information.vendor_item_taxes_total ?? 0,
        vendor_item_discounts_total:
            details.pricing_information.vendor_item_discounts_total ?? 0,
    };
    if (details.rfq_default_information.standard_terms_type === 'LOAD_FROM_PO')
        obj['default_item_terms'] = 'FROM_PO';
    else if (details.rfq_default_information.standard_terms_type === 'CUSTOM')
        obj['default_item_terms'] = 'CUSTOM';
    else obj['default_item_terms'] = 'NONE';
    return obj;
};

// export const translateCustomToRFQDetails = (
//     rfq_details: ICustomEventDetails,
//     prev_details: IEventDetails
// ): IEventDetails => {
//     let newDeetails: IEventDetails = {
//         rfq_entry_id: rfq_details.sub_event_id,
//         custom_fields: rfq_details.custom_fields,
//         custom_fields_negotiate: rfq_details.custom_fields_negotiate,
//         custom_event_id: rfq_details.event_custom_ID,
//         live_event_start_datetime: prev_details.live_event_start_datetime,
//         version: prev_details.version,
//         event_name: rfq_details.event_name,
//         event_end_datetime: rfq_details.event_deadline,
//         rfq_event_creator: rfq_details.event_creator_name,
//         original_event_creator_name: prev_details.original_event_creator_name,
//         event_type: prev_details.event_type,
//         pause_information: prev_details.pause_information,
//         termination_information: prev_details.termination_information,
//         status: rfq_details.sub_event_status,
//         event_status: prev_details.event_status,
//         event_id: rfq_details.event_id,
//         event_additional_details: {
//             access: rfq_details.is_event_confidential
//                 ? 'ACCESS_RESTRICTED'
//                 : 'ACCESS_PUBLIC',
//             template_id:
//                 prev_details.event_additional_details?.template_id ?? '',
//         },
//         address_information: {
//             billing_address: '',
//             shipping_address: '',
//             billing_address_id: rfq_details.billing_id,
//             shipping_address_id: rfq_details.shipping_id,
//         },
//         requisition_information: prev_details.requisition_information,
//         rfq_default_information: prev_details.rfq_default_information,
//         buyer_entity: prev_details.buyer_entity,
//         additional_details: prev_details.additional_details,
//         event_details: prev_details.event_details,
//         final_approver: null,
//         lead_time: rfq_details.lead_time,
//         lead_time_period: rfq_details.lead_time_period,
//         incoterm_id: rfq_details.incoterm_id,
//         event_cost_centre_id: rfq_details.event_cost_centre_id,
//         event_project_id: rfq_details.event_project_id,
//         event_gl_id: rfq_details.event_gl_id,
//         event_custom_additional_information:
//             rfq_details.event_custom_additional_information,
//         event_gr_tolerance: rfq_details.event_gr_tolerance,
//         additional_costs: convertAdditionalCostFrontendToBackend(
//             rfq_details.additional_costs
//         ),
//         discounts: convertAdditionalCostFrontendToBackend(
//             rfq_details.discounts
//         ),
//         taxes: convertAdditionalCostFrontendToBackend(rfq_details.taxes),
//     };
//     return newDeetails;
// };

export const autoFillAdditionalCost = async (
    additionalCost: IPreSelectedAdditionalCostFromApi[],
    additionalCostInformation: IAdditionalCostInformation[],
    itemCurrency: string,
    eventCurrency: string,
    templateDetails: IRfqTemplate,
    currencyConversions: {
        [currency_id: string]: number;
    }
) => {
    try {
        let costsInRFQTemplate =
            templateDetails.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.ADDITIONAL_COSTS
            ]?.additionalCostOptions;

        let currencyConversionRate = (await getCurrencyConversion(
            itemCurrency,
            eventCurrency
        )) ?? {
            rate: 1,
        };

        // if cost is there in admin, then only autofill.
        let res2: IPreSelectedAdditionalCostFromApi[] = costsInRFQTemplate
            ?.filter((cost) =>
                additionalCostInformation?.some(
                    (adminAC) =>
                        adminAC.additional_cost_id === cost.additional_cost_id
                )
            )
            .map((costInRFQ) => {
                const costInfoInAdmin = additionalCostInformation.find(
                    (c) => c.additional_cost_id === costInRFQ.additional_cost_id
                );

                let additionalCostFinalValue: number = 0;

                if (
                    costInfoInAdmin?.cost_source === CostSourceEnum.NONE ||
                    costInfoInAdmin?.cost_source ===
                        CostSourceEnum.VENDOR_MASTER
                ) {
                    if (costInfoInAdmin.cost_type === CostTypeEnum.PERCENTAGE) {
                        additionalCostFinalValue = costInfoInAdmin.cost_value;
                    } else {
                        if (
                            costInfoInAdmin.currency &&
                            currencyConversions[costInfoInAdmin.currency]
                        ) {
                            additionalCostFinalValue =
                                costInfoInAdmin.cost_value *
                                currencyConversions[costInfoInAdmin.currency];
                        }
                    }
                } else if (
                    costInfoInAdmin?.cost_source === CostSourceEnum.ITEM_MASTER
                ) {
                    const costInfoInItemDir = additionalCost.find(
                        (itemAC) =>
                            itemAC.additional_cost.additional_cost_id ===
                            costInfoInAdmin?.additional_cost_id
                    );

                    if (costInfoInItemDir) {
                        const costValueInItemDir = costInfoInItemDir.cost_value;

                        if (Boolean(costValueInItemDir?.toString())) {
                            if (
                                costInfoInAdmin.cost_type ===
                                CostTypeEnum.ABSOLUTE_VALUE
                            ) {
                                additionalCostFinalValue =
                                    costValueInItemDir *
                                    currencyConversionRate.rate;
                            } else {
                                additionalCostFinalValue = costValueInItemDir;
                            }
                        }
                    } else {
                        additionalCostFinalValue = costInfoInAdmin.cost_value;
                    }
                }
                return {
                    additional_cost: {
                        additional_cost_id:
                            costInfoInAdmin?.additional_cost_id ??
                            costInRFQ.additional_cost_id,
                        allocation_type:
                            costInfoInAdmin?.allocation_type ??
                            costInRFQ.allocationType,
                        cost_name:
                            costInfoInAdmin?.cost_name ?? costInRFQ?.costName,
                        cost_type:
                            costInfoInAdmin?.cost_type ?? costInRFQ?.costType,
                        cost_value: additionalCostFinalValue,
                    },
                    cost_source:
                        costInfoInAdmin?.cost_source ?? costInRFQ?.cost_source,
                    cost_value: additionalCostFinalValue,
                };
            });

        // let res: IPreSelectedAdditionalCostFromApi[] = additionalCost
        //     ?.filter((cost) =>
        //         additionalCostInformation?.some(
        //             (adminAC) =>
        //                 adminAC.additional_cost_id ===
        //                 cost.additional_cost.additional_cost_id
        //         )
        //     )
        //     .map((cost) => {
        //         let additionalCostFinalValue: number = 0;

        //         let costInfo = additionalCostInformation.find(
        //             (c) =>
        //                 c.additional_cost_id ===
        //                 cost.additional_cost.additional_cost_id
        //         );

        //         // cost in admin
        //         if (costInfo) {
        //             // cost source in admin
        //             switch (costInfo.cost_source) {
        //                 case CostSourceEnum.ITEM_MASTER:
        //                     additionalCostFinalValue =
        //                         cost.cost_value * currencyConversionRate.rate;
        //                     break;
        //                 case CostSourceEnum.NONE:
        //                     additionalCostFinalValue =
        //                         cost.additional_cost.cost_value;
        //                     break;
        //             }
        //         }

        //         let indexOfDecimalPoint = additionalCostFinalValue
        //             .toString()
        //             .indexOf('');

        //         additionalCostFinalValue =
        //             +additionalCostFinalValue.toPrecision(
        //                 indexOfDecimalPoint + 9
        //             );

        //         return {
        //             additional_cost: {
        //                 additional_cost_id:
        //                     cost.additional_cost.additional_cost_id,
        //                 allocation_type: cost.additional_cost.allocation_type,
        //                 cost_name: cost.additional_cost.cost_name,
        //                 cost_type: cost.additional_cost.cost_type,
        //                 cost_value: additionalCostFinalValue,
        //             },
        //             cost_source: cost.cost_source,
        //             cost_value: additionalCostFinalValue,
        //         };
        //     });
        // res = res.filter((cost) =>
        //     templateDetails.fieldSettings.standardFields[
        //         TemplateStandardFieldsEnum.ADDITIONAL_COSTS
        //     ].additionalCostOptions.some(
        //         (tempCost) =>
        //             tempCost.additional_cost_id ===
        //             cost.additional_cost.additional_cost_id
        //     )
        // );

        return res2;
    } catch (err) {
        return additionalCost;
    }
};

export const translateEnterpriseItemToRFQItem = (
    enterprise_item: IBuyerEnterpriseItemDetails
): IEventItemDetails => {
    return {
        // NOTE: IN THE START RFQ_ITEM_ENTRY_ID SHOULD NOT BE A EMPTY STRING because the item box
        // logic uses this assumption to load data correctly
        rfq_item_entry_id: '',
        rfq_item_id: '',
        additional_details: {
            offer_count: '0',
            purchase_order_average_rate: '0',
            purchase_order_count: '0',
            purchase_order_total_amount: '0',
            purchase_order_vendor_count: '0',
            vendor_count: '0',
        },
        item_information: {
            buyer_item_id: enterprise_item.code,
            global_item_id: '',
            buyer_item_name: enterprise_item.name,
            custom_item_name: '',
            buyer_item_description: enterprise_item.description,
            item_additional_details: enterprise_item.notes,
            custom_ids: enterprise_item.custom_ids.custom_ids,
        },
        attribute_information: {
            buyer_item_attributes: [],
            custom_item_attributes: [],
        },
        attributes: enterprise_item.attributes,
        incoterm_abbreviation: '',
        fulfilment_information: {
            awarded_quantity: '0',
            po_item_approval_pending_quantity: '0',
            po_item_issued_quantity: '0',
            po_item_accepted_quantity: '0',
        },
        property_information: [],
        pricing_information: {
            currency_name: '',
            desired_price: '',
            currency_symbol: '',
            currency_code_id: '',
            currency_code_abbreviation: '',
            additional_charges: [],
            shipping_per_unit: '',
            additional_costs: enterprise_item.buyer_additional_costs.map(
                (cost) => ({
                    ...cost.additional_cost,
                    cost_source: cost.cost_source,
                })
            ),
            discounts: [],
            taxes: [],
            total_price: '0',
        },
        quantity_information: {
            quantity: '',
            quantity_tolerance_percentage: '0',
            measurement_unit_id: '',
            measurement_unit_name: '',
            max_awardable_quantity: '0',
        },
        delivery_information: [
            {
                bom_item: null,
                delivery_date: null,
                quantity: '',
                cost_centre_id: null,
                cost_centre_name: '',
                general_ledger_id: null,
                gl_code: '',
                project_code: '',
                project_id: null,
            },
        ],
        delivery_schedule: [
            {
                bom_item: null,
                delivery_date: null,
                quantity: '',
                cost_centre_id: null,
                cost_centre_name: '',
                general_ledger_id: null,
                gl_code: '',
                customer_entity_id: null,
                project_code: '',
                project_id: null,
                parent_delivery_schedule_item: null,
            },
        ],
        enterprise_item: enterprise_item.enterprise_item_id,
        procurement_information: {
            lead_time: '',
            lead_time_period: '',
            allow_substitutes: false,
            is_price_confidential: false,
            post_to_global_marketplace: false,
        },
        paymentTerms: {
            paymentType: 'poItemPercentages',
            prepaymentPercentage: '',
            list: [],
        },
        deliverables_payment_terms: [],
        payment_terms: null,
        prepayment_percentage: '',

        requisition_information: [],
        rfq_event: '',
        item: '',
        incoterm: '',
        attachments: [],
        custom_fields: {
            section_list: [],
        },
        custom_fields_negotiate: {
            section_list: [],
        },
        purchase_order_status: 'PURCHASE_ORDER_PENDING',
        tags: [],
        buyer_delivery_schedule: [],
    };
};

export const translateRFQItemDetailsToCustomDetails = (
    rfq_item_details: IEventItemDetails,
    custom_fields_from_template: IEventItemCustomFieldSections,
    itemTags: string[]
): ICustomEventItemDetails => {
    let custom_field_sections = cloneDeep(
        translateBackendCustomFieldSectionsToFrontEnd(
            rfq_item_details.custom_fields,
            rfq_item_details.custom_fields_negotiate
        )
    );
    if (
        +rfq_item_details.fulfilment_information.po_item_accepted_quantity ===
            0 &&
        +rfq_item_details.fulfilment_information
            .po_item_approval_pending_quantity === 0 &&
        +rfq_item_details.fulfilment_information.po_item_issued_quantity === 0
    ) {
        for (let field of custom_fields_from_template.existingSections
            .additional_details) {
            let customFieldNamesArray =
                custom_field_sections.existingSections.additional_details.map(
                    (f) => f.name
                );
            let indexOfFoundCustomField = customFieldNamesArray.indexOf(
                field.name
            );
            if (indexOfFoundCustomField !== -1) {
                custom_field_sections.existingSections.additional_details[
                    indexOfFoundCustomField
                ] = {
                    ...field,
                    value: custom_field_sections.existingSections
                        .additional_details[indexOfFoundCustomField].value,
                };
            } else {
                custom_field_sections.existingSections.additional_details.push(
                    field
                );
            }
        }

        for (let field of custom_fields_from_template.existingSections
            .payment_and_delivery_terms) {
            let customFieldNamesArray =
                custom_field_sections.existingSections.payment_and_delivery_terms.map(
                    (f) => f.name
                );
            let indexOfFoundCustomField = customFieldNamesArray.indexOf(
                field.name
            );
            if (indexOfFoundCustomField !== -1) {
                custom_field_sections.existingSections.payment_and_delivery_terms[
                    indexOfFoundCustomField
                ] = {
                    ...field,
                    value: custom_field_sections.existingSections
                        .payment_and_delivery_terms[indexOfFoundCustomField]
                        .value,
                };
            } else {
                custom_field_sections.existingSections.payment_and_delivery_terms.push(
                    field
                );
            }
        }

        for (let field of custom_fields_from_template.existingSections
            .essential_terms) {
            let customFieldNamesArray =
                custom_field_sections.existingSections.essential_terms.map(
                    (f) => f.name
                );
            let indexOfFoundCustomField = customFieldNamesArray.indexOf(
                field.name
            );
            if (indexOfFoundCustomField !== -1) {
                custom_field_sections.existingSections.essential_terms[
                    indexOfFoundCustomField
                ] = {
                    ...field,
                    value: custom_field_sections.existingSections
                        .essential_terms[indexOfFoundCustomField].value,
                };
            } else {
                custom_field_sections.existingSections.essential_terms.push(
                    field
                );
            }
        }
    }

    let itemTotal = calculateNewItemTotalWithDecimalPlaces({
        itemDS:
            rfq_item_details.delivery_information.length === 0
                ? [
                      {
                          quantity:
                              rfq_item_details.quantity_information.quantity,
                      },
                  ]
                : rfq_item_details.delivery_information,
        item_price: rfq_item_details.pricing_information.desired_price,
        decimalPlaces: 9,
        additional_cost: convertAdditionalCostBackendToFrontend(
            rfq_item_details.pricing_information.additional_costs ?? []
        ),
        discount: convertAdditionalCostBackendToFrontend(
            rfq_item_details.pricing_information.discounts ?? []
        ),
        tax: convertAdditionalCostBackendToFrontend(
            rfq_item_details.pricing_information.taxes ?? []
        ),
    });
    let itemSubtotal = calculateNewItemSubtotalWithDecimal({
        item_price: rfq_item_details.pricing_information.desired_price,
        decimalPlaces: 9,
        discount: convertAdditionalCostBackendToFrontend(
            rfq_item_details.pricing_information.discounts ?? []
        ),
        itemDS:
            rfq_item_details.delivery_information.length === 0
                ? [
                      {
                          quantity:
                              rfq_item_details.quantity_information.quantity,
                      },
                  ]
                : rfq_item_details.delivery_information,
    });
    let itemAdditionalCostTotal = calculateAddtionalCost({
        additionalCost: convertAdditionalCostBackendToFrontend(
            rfq_item_details.pricing_information.additional_costs ?? []
        ),
        baseQuantity: rfq_item_details.quantity_information.quantity,
        baseRate: rfq_item_details.pricing_information.desired_price,
        type: 'ADDITIONAL_COST',
    }).additionalCostValue;
    let itemDiscountsTotal = calculateAddtionalCost({
        additionalCost: convertAdditionalCostBackendToFrontend(
            rfq_item_details.pricing_information.discounts ?? []
        ),
        baseRate: rfq_item_details.pricing_information.desired_price,
        baseQuantity: rfq_item_details.quantity_information.quantity,
        type: 'DISCOUNT',
    }).additionalCostValue;
    let itemTaxesTotal = calculateAddtionalCost({
        additionalCost: convertAdditionalCostBackendToFrontend(
            rfq_item_details.pricing_information.taxes ?? []
        ),
        baseRate: rfq_item_details.pricing_information.desired_price,
        baseQuantity: rfq_item_details.quantity_information.quantity,
        type: 'TAX',
        discountAdditionalCost: convertAdditionalCostBackendToFrontend(
            rfq_item_details.pricing_information.discounts ?? []
        ),
    }).additionalCostValue;

    return {
        rfq_item_id: rfq_item_details.rfq_item_id,
        attachments: rfq_item_details.attachments,
        currency_code_symbol:
            rfq_item_details.pricing_information.currency_symbol,
        enterprise_item_id: rfq_item_details.enterprise_item,
        buyer_item_name: rfq_item_details.item_information.buyer_item_name,
        buyer_item_code: rfq_item_details.item_information.buyer_item_id,
        event_item_entry_id: rfq_item_details.rfq_item_entry_id,
        shipping: rfq_item_details.pricing_information.shipping_per_unit ?? '',
        additional_charges:
            rfq_item_details.pricing_information.additional_charges,
        price: rfq_item_details.pricing_information.desired_price ?? '',
        currency_code_id: rfq_item_details.pricing_information.currency_code_id,
        currency_code_abbreviation:
            rfq_item_details.pricing_information.currency_code_abbreviation,
        specifications: rfq_item_details.attribute_information,
        attributes: rfq_item_details.attributes,
        quantity: rfq_item_details.quantity_information.quantity ?? '',
        quantity_tolerance_percentage:
            rfq_item_details.quantity_information.quantity_tolerance_percentage,
        measurement_unit_id:
            rfq_item_details.quantity_information.measurement_unit_id,
        item_description:
            rfq_item_details.item_information.buyer_item_description,
        item_additional_information:
            rfq_item_details.item_information.item_additional_details,
        is_price_confidential:
            rfq_item_details.procurement_information.is_price_confidential,
        delivery_schedule:
            rfq_item_details.delivery_information.length === 0
                ? [
                      {
                          bom_item: null,

                          delivery_date: null,
                          quantity:
                              rfq_item_details.quantity_information.quantity,
                          cost_centre_id: null,
                          customer_entity: null,
                          general_ledger_id: null,
                          project_id: null,
                          parent_delivery_schedule_item_id: null,
                      },
                  ]
                : rfq_item_details.delivery_information.map((delivery) => ({
                      bom_item: delivery.bom_item,
                      delivery_date: delivery.delivery_date,
                      quantity: delivery.quantity ?? '',
                      cost_centre_id: delivery.cost_centre_id,
                      general_ledger_id: delivery.general_ledger_id,
                      project_id: delivery.project_id,
                      customer_entity: null,
                      parent_delivery_schedule_item_id:
                          delivery.parent_delivery_schedule_item
                              ?.delivery_schedule_item_id,
                  })),
        custom_ids: rfq_item_details.item_information.custom_ids,
        requisition_number:
            rfq_item_details.requisition_information.length > 0
                ? rfq_item_details.requisition_information[0].requisition_number
                : '',
        requisition_approved:
            rfq_item_details.requisition_information.length > 0
                ? rfq_item_details.requisition_information[0]
                      .requisition_approved
                : false,
        incoterm_id: rfq_item_details.incoterm,
        lead_time:
            rfq_item_details.procurement_information.lead_time !== null
                ? rfq_item_details.procurement_information.lead_time.toString()
                : '',
        lead_time_period:
            rfq_item_details.procurement_information.lead_time_period,
        paymentTerms: rfq_item_details.paymentTerms,
        measurement_unit_name:
            rfq_item_details.quantity_information.measurement_unit_name,
        po_status: rfq_item_details.purchase_order_status
            ? rfq_item_details.purchase_order_status
            : null,
        incoterm_name: rfq_item_details.incoterm_abbreviation,
        custom_fields_sections: custom_field_sections,
        po_item_accepted_quantity:
            rfq_item_details.fulfilment_information.po_item_accepted_quantity,
        po_item_approval_pending_quantity:
            rfq_item_details.fulfilment_information
                .po_item_approval_pending_quantity,
        po_item_issued_quantity:
            rfq_item_details.fulfilment_information.po_item_issued_quantity,
        taxes: convertAdditionalCostBackendToFrontend(
            rfq_item_details.pricing_information.taxes ?? []
        ),
        additional_costs: convertAdditionalCostBackendToFrontend(
            rfq_item_details.pricing_information.additional_costs ?? []
        ),
        discounts: convertAdditionalCostBackendToFrontend(
            rfq_item_details.pricing_information.discounts ?? []
        ),
        tags: itemTags ?? [],
        total: rfq_item_details.pricing_information.total_price,
        item_total: itemTotal.toString(),
        item_subtotal: itemSubtotal.toString(),
        item_additional_costs_total: itemAdditionalCostTotal.toString(),
        item_discounts_total: itemDiscountsTotal.toString(),
        item_taxes_total: itemTaxesTotal.toString(),
        vendor_item_total: itemTotal.toString(),
        vendor_item_subtotal: itemSubtotal.toString(),
        vendor_item_additional_costs_total: itemAdditionalCostTotal.toString(),
        vendor_item_discounts_total: itemDiscountsTotal.toString(),
        vendor_item_taxes_total: itemTaxesTotal.toString(),
    };
};

// export const translateRFQItemDetailsToCustomDetailsWithTemplates = (
//     rfq_item_details: IEventItemDetails,
//     custom_fields_from_template: IRfqTemplate,
//     itemTags: string[]
// ): ICustomEventItemDetails => {
//     let custom_field_sections = cloneDeep(
//         translateBackendCustomFieldSectionsViaTemplateToFrontEnd(
//             rfq_item_details.custom_fields,
//             rfq_item_details.custom_fields_negotiate
//         )
//     );

//     if (
//         +rfq_item_details.fulfilment_information.po_item_accepted_quantity ===
//             0 &&
//         +rfq_item_details.fulfilment_information
//             .po_item_approval_pending_quantity === 0 &&
//         +rfq_item_details.fulfilment_information.po_item_issued_quantity === 0
//     ) {
//         const listOfCustomFieldsOfAdditionalDetails =
//             custom_fields_from_template.sections.ADDITIONAL_DETAILS.fieldList.filter(
//                 (field) => field.fieldType === 'CUSTOM'
//             );
//         for (let field of listOfCustomFieldsOfAdditionalDetails) {
//             let customFieldNamesArray =
//                 custom_field_sections.existingSections.additional_details.map(
//                     (f) => f.name
//                 );

//             let indexOfFoundCustomField = customFieldNamesArray.indexOf(
//                 custom_fields_from_template.fieldSettings.customFields[
//                     field.field as string
//                 ]?.label
//             );
//             if (indexOfFoundCustomField !== -1) {
//                 custom_field_sections.existingSections.additional_details[
//                     indexOfFoundCustomField
//                 ] = {
//                     is_locked: true,
//                     is_negotiable: true,
//                     name: custom_fields_from_template.fieldSettings
//                         .customFields[field.field].label,
//                     is_required:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field as string
//                         ].buyerSettings.required,
//                     is_visible:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field as string
//                         ].buyerSettings.showField,
//                     type: CustomTemplateItemTypes.SHORTTEXT,
//                     value: custom_field_sections.existingSections
//                         .additional_details[indexOfFoundCustomField].value,
//                 };
//             } else {
//                 custom_field_sections.existingSections.additional_details.push({
//                     is_locked: false,
//                     is_negotiable: true,
//                     name: custom_fields_from_template.fieldSettings
//                         .customFields[field.field].label,
//                     is_required:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field as string
//                         ].buyerSettings.required,
//                     is_visible:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field as string
//                         ].buyerSettings.showField,
//                     type: CustomTemplateItemTypes.SHORTTEXT,
//                     value: '',
//                 });
//             }
//         }

//         const listOfCusomFieldsOfPaymentsDeliveryDetails =
//             custom_fields_from_template.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.filter(
//                 (field) => field.fieldType === 'CUSTOM'
//             );

//

//         for (let field of listOfCusomFieldsOfPaymentsDeliveryDetails) {
//             let customFieldNamesArray =
//                 custom_field_sections.existingSections.payment_and_delivery_terms.map(
//                     (f) => f.name
//                 );

//             let indexOfFoundCustomField = customFieldNamesArray.indexOf(
//                 custom_fields_from_template.fieldSettings.customFields[
//                     field.field as string
//                 ]?.label
//             );
//

//             if (indexOfFoundCustomField !== -1) {
//                 custom_field_sections.existingSections.payment_and_delivery_terms[
//                     indexOfFoundCustomField
//                 ] = {
//                     is_locked: true,
//                     is_negotiable: true,
//                     name: custom_fields_from_template.fieldSettings
//                         .customFields[field.field].label,
//                     is_required:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field as string
//                         ].buyerSettings.required,
//                     is_visible:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field as string
//                         ].buyerSettings.showField,
//                     type: CustomTemplateItemTypes.SHORTTEXT,
//                     value:
//                         custom_field_sections.existingSections
//                             .additional_details[indexOfFoundCustomField]
//                             ?.value ?? '',
//                 };
//             } else {
//
//                 custom_field_sections.existingSections.payment_and_delivery_terms.push(
//                     {
//                         is_locked: false,
//                         is_negotiable: true,
//                         name: field.field,
//                         is_required:
//                             custom_fields_from_template.fieldSettings
//                                 .customFields[field.field as string]
//                                 .buyerSettings.required,
//                         is_visible:
//                             custom_fields_from_template.fieldSettings
//                                 .customFields[field.field as string]
//                                 .buyerSettings.showField,
//                         type: CustomTemplateItemTypes.SHORTTEXT,
//                         value: '',
//                     }
//                 );
//             }
//         }

//         const listOfCustomFieldsOfEssentialTerms =
//             custom_fields_from_template.sections.ESSENTIAL_TERMS.fieldList.filter(
//                 (field) => field.fieldType === 'CUSTOM'
//             );

//         for (let field of listOfCustomFieldsOfEssentialTerms) {
//             let customFieldNamesArray =
//                 custom_field_sections.existingSections.essential_terms.map(
//                     (f) => f.name
//                 );

//             let indexOfFoundCustomField = customFieldNamesArray.indexOf(
//                 custom_fields_from_template.fieldSettings.customFields[
//                     field.field
//                 ]?.label
//             );
//             if (indexOfFoundCustomField !== -1) {
//                 custom_field_sections.existingSections.essential_terms[
//                     indexOfFoundCustomField
//                 ] = {
//                     is_locked: true,
//                     is_negotiable: true,
//                     name: custom_fields_from_template.fieldSettings
//                         .customFields[field.field].label,
//                     is_required:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field
//                         ].buyerSettings.required,
//                     is_visible:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field
//                         ].buyerSettings.showField,
//                     type: CustomTemplateItemTypes.SHORTTEXT,
//                     value:
//                         custom_field_sections.existingSections.essential_terms[
//                             indexOfFoundCustomField
//                         ]?.value ?? '',
//                 };
//             } else {
//                 custom_field_sections.existingSections.essential_terms.push({
//                     is_locked: false,
//                     is_negotiable: true,
//                     name: custom_fields_from_template.fieldSettings
//                         .customFields[field.field].label,
//                     is_required:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field
//                         ].buyerSettings.required,
//                     is_visible:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field
//                         ].buyerSettings.showField,
//                     type: CustomTemplateItemTypes.SHORTTEXT,
//                     value: '',
//                 });
//             }
//         }
//     }

//     return {
//         attachments: rfq_item_details.attachments,
//         currency_code_symbol:
//             rfq_item_details.pricing_information.currency_symbol,
//         enterprise_item_id: rfq_item_details.enterprise_item,
//         buyer_item_name: rfq_item_details.item_information.buyer_item_name,
//         buyer_item_code: rfq_item_details.item_information.buyer_item_id,
//         event_item_entry_id: rfq_item_details.rfq_item_entry_id,
//         shipping: rfq_item_details.pricing_information.shipping_per_unit ?? '',
//         additional_charges:
//             rfq_item_details.pricing_information.additional_charges,
//         price: rfq_item_details.pricing_information.desired_price ?? '',
//         currency_code_id: rfq_item_details.pricing_information.currency_code_id,
//         currency_code_abbreviation:
//             rfq_item_details.pricing_information.currency_code_abbreviation,
//         specifications: rfq_item_details.attribute_information,
//         quantity: rfq_item_details.quantity_information.quantity ?? '',
//         quantity_tolerance_percentage:
//             rfq_item_details.quantity_information.quantity_tolerance_percentage,
//         measurement_unit_id:
//             rfq_item_details.quantity_information.measurement_unit_id,
//         item_description:
//             rfq_item_details.item_information.buyer_item_description,
//         item_additional_information:
//             rfq_item_details.item_information.item_additional_details,
//         is_price_confidential:
//             rfq_item_details.procurement_information.is_price_confidential,
//         delivery_schedule:
//             rfq_item_details.delivery_information.length === 0
//                 ? [
//                       {
//                           delivery_date: null,
//                           quantity:
//                               rfq_item_details.quantity_information.quantity,
//                       },
//                   ]
//                 : rfq_item_details.delivery_information.map((delivery) => ({
//                       delivery_date: delivery.delivery_date,
//                       quantity: delivery.quantity ?? '',
//                   })),
//         custom_ids: rfq_item_details.item_information.custom_ids,
//         requisition_number:
//             rfq_item_details.requisition_information.length > 0
//                 ? rfq_item_details.requisition_information[0].requisition_number
//                 : '',
//         requisition_approved:
//             rfq_item_details.requisition_information.length > 0
//                 ? rfq_item_details.requisition_information[0]
//                       .requisition_approved
//                 : false,
//         incoterm_id: rfq_item_details.incoterm,
//         lead_time:
//             rfq_item_details.procurement_information.lead_time !== null
//                 ? rfq_item_details.procurement_information.lead_time.toString()
//                 : '',
//         lead_time_period:
//             rfq_item_details.procurement_information.lead_time_period,
//         prepayment_percentage:
//             rfq_item_details.procurement_information.prepayment_percentage ??
//             '',
//         payment_terms: rfq_item_details.procurement_information.payment_terms,
//         payment_terms_period:
//             rfq_item_details.procurement_information.payment_terms_period ??
//             'DAYS',
//         payment_terms_applied_from:
//             rfq_item_details.procurement_information.payment_terms_applied_from,
//         measurement_unit_name:
//             rfq_item_details.quantity_information.measurement_unit_name,
//         po_status: rfq_item_details.purchase_order_status
//             ? rfq_item_details.purchase_order_status
//             : null,
//         incoterm_name: rfq_item_details.incoterm_abbreviation,
//         custom_fields_sections: custom_field_sections,
//         po_item_accepted_quantity:
//             rfq_item_details.fulfilment_information.po_item_accepted_quantity,
//         po_item_approval_pending_quantity:
//             rfq_item_details.fulfilment_information
//                 .po_item_approval_pending_quantity,
//         po_item_issued_quantity:
//             rfq_item_details.fulfilment_information.po_item_issued_quantity,
//         tags: itemTags ?? [],
//     };
// };

// export const translateRFQItemDetailsToCustomDetailsWithTemplates = (
//     rfq_item_details: IEventItemDetails,
//     custom_fields_from_template: IRfqTemplate,
//     itemTags: string[]
// ): ICustomEventItemDetails => {
//     let custom_field_sections = cloneDeep(
//         translateBackendCustomFieldSectionsViaTemplateToFrontEnd(
//             rfq_item_details.custom_fields,
//             rfq_item_details.custom_fields_negotiate
//         )
//     );
//     if (
//         +rfq_item_details.fulfilment_information.po_item_accepted_quantity ===
//             0 &&
//         +rfq_item_details.fulfilment_information
//             .po_item_approval_pending_quantity === 0 &&
//         +rfq_item_details.fulfilment_information.po_item_issued_quantity === 0
//     ) {
//         let listOfCustomFieldsFromTemplateOfEssentialTerms =
//             custom_fields_from_template.sections.ESSENTIAL_TERMS.fieldList.filter(
//                 (f) => f.fieldType === 'CUSTOM'
//             );

//         let listOfCustomFieldsFromTemplateOfPaymentsDelivery =
//             custom_fields_from_template.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.filter(
//                 (f) => f.fieldType === 'CUSTOM'
//             );

//         let listOfCustomFieldsFromTemplateOfAdditionalDetails =
//             custom_fields_from_template.sections.ADDITIONAL_DETAILS.fieldList.filter(
//                 (f) => f.fieldType === 'CUSTOM'
//             );

//         for (let field of listOfCustomFieldsFromTemplateOfEssentialTerms) {
//             const listOfCustomNames =
//                 custom_field_sections.existingSections.essential_terms.map(
//                     (f) => f.name
//                 );

//             const foundIndex = listOfCustomNames.findIndex(
//                 (f) => field.field === f
//             );

//             if (foundIndex !== -1) {
//                 custom_field_sections.existingSections.essential_terms[
//                     foundIndex
//                 ] = {
//                     ...custom_field_sections.existingSections.essential_terms[
//                         foundIndex
//                     ],
//                     name: custom_fields_from_template.fieldSettings
//                         .customFields[field.field].label,
//                     description:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field
//                         ].description,
//                     is_required:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field
//                         ].buyerSettings.required,
//                     is_visible:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field
//                         ].buyerSettings.showField,
//                 };
//             } else {
//                 custom_field_sections.existingSections.essential_terms.push({
//                     is_locked: true,
//                     is_negotiable:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field
//                         ].sellerSettings.negotiable,
//                     is_required:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field
//                         ].buyerSettings.required,
//                     is_visible:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field
//                         ].buyerSettings.showField,
//                     name: custom_fields_from_template.fieldSettings
//                         .customFields[field.field].label,
//                     description:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field
//                         ].description,
//                     type: CustomTemplateItemTypes.SHORTTEXT,
//                     value: '',
//                 });
//             }
//         }
//         for (let field of listOfCustomFieldsFromTemplateOfPaymentsDelivery) {
//             const listOfCustomNames =
//                 custom_field_sections.existingSections.payment_and_delivery_terms.map(
//                     (f) => f.name
//                 );

//             const foundIndex = listOfCustomNames.findIndex(
//                 (f) => field.field === f
//             );

//             if (foundIndex !== -1) {
//                 custom_field_sections.existingSections.payment_and_delivery_terms[
//                     foundIndex
//                 ] = {
//                     ...custom_field_sections.existingSections
//                         .payment_and_delivery_terms[foundIndex],
//                     name: custom_fields_from_template.fieldSettings
//                         .customFields[field.field].label,
//                     description:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field
//                         ].description,
//                     is_required:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field
//                         ].buyerSettings.required,
//                     is_visible:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field
//                         ].buyerSettings.showField,
//                 };
//             } else {
//                 custom_field_sections.existingSections.payment_and_delivery_terms.push(
//                     {
//                         is_locked: true,
//                         is_negotiable:
//                             custom_fields_from_template.fieldSettings
//                                 .customFields[field.field].sellerSettings
//                                 .negotiable,
//                         is_required:
//                             custom_fields_from_template.fieldSettings
//                                 .customFields[field.field].buyerSettings
//                                 .required,
//                         is_visible:
//                             custom_fields_from_template.fieldSettings
//                                 .customFields[field.field].buyerSettings
//                                 .showField,
//                         name: custom_fields_from_template.fieldSettings
//                             .customFields[field.field].label,
//                         description:
//                             custom_fields_from_template.fieldSettings
//                                 .customFields[field.field].description,
//                         type: CustomTemplateItemTypes.SHORTTEXT,
//                         value: '',
//                     }
//                 );
//             }
//         }
//         for (let field of listOfCustomFieldsFromTemplateOfAdditionalDetails) {
//             const listOfCustomNames =
//                 custom_field_sections.existingSections.additional_details.map(
//                     (f) => f.name
//                 );

//             const foundIndex = listOfCustomNames.findIndex(
//                 (f) => field.field === f
//             );

//             if (foundIndex !== -1) {
//                 custom_field_sections.existingSections.additional_details[
//                     foundIndex
//                 ] = {
//                     ...custom_field_sections.existingSections
//                         .additional_details[foundIndex],
//                     name: custom_fields_from_template.fieldSettings
//                         .customFields[field.field].label,
//                     description:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field
//                         ].description,
//                     is_required:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field
//                         ].buyerSettings.required,
//                     is_visible:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field
//                         ].buyerSettings.showField,
//                 };
//             } else {
//                 //
//                 custom_field_sections.existingSections.additional_details.push({
//                     is_locked: true,
//                     is_negotiable:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field
//                         ].sellerSettings.negotiable,
//                     is_required:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field
//                         ].buyerSettings.required,
//                     is_visible:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field
//                         ].buyerSettings.showField,
//                     name: custom_fields_from_template.fieldSettings
//                         .customFields[field.field].label,
//                     description:
//                         custom_fields_from_template.fieldSettings.customFields[
//                             field.field
//                         ].description,
//                     type: CustomTemplateItemTypes.SHORTTEXT,
//                     value: '',
//                 });
//             }
//         }
//     }

//     return {
//         attachments: rfq_item_details.attachments,
//         currency_code_symbol:
//             rfq_item_details.pricing_information.currency_symbol,
//         enterprise_item_id: rfq_item_details.enterprise_item,
//         buyer_item_name: rfq_item_details.item_information.buyer_item_name,
//         buyer_item_code: rfq_item_details.item_information.buyer_item_id,
//         event_item_entry_id: rfq_item_details.rfq_item_entry_id,
//         shipping: rfq_item_details.pricing_information.shipping_per_unit ?? '',
//         additional_charges:
//             rfq_item_details.pricing_information.additional_charges,
//         price: rfq_item_details.pricing_information.desired_price ?? '',
//         currency_code_id: rfq_item_details.pricing_information.currency_code_id,
//         currency_code_abbreviation:
//             rfq_item_details.pricing_information.currency_code_abbreviation,
//         specifications: rfq_item_details.attribute_information,
//         quantity: rfq_item_details.quantity_information.quantity ?? '',
//         quantity_tolerance_percentage:
//             rfq_item_details.quantity_information.quantity_tolerance_percentage,
//         measurement_unit_id:
//             rfq_item_details.quantity_information.measurement_unit_id,
//         item_description:
//             rfq_item_details.item_information.buyer_item_description,
//         item_additional_information:
//             rfq_item_details.item_information.item_additional_details,
//         is_price_confidential:
//             rfq_item_details.procurement_information.is_price_confidential,
//         delivery_schedule:
//             rfq_item_details.delivery_information.length === 0
//                 ? [
//                       {
//                           delivery_date: null,
//                           quantity:
//                               rfq_item_details.quantity_information.quantity,
//                           cost_centre_id: null,
//                           general_ledger_id: null,
//                           project_id: null,
//                       },
//                   ]
//                 : rfq_item_details.delivery_information.map((delivery) => ({
//                       delivery_date: delivery.delivery_date,
//                       quantity: delivery.quantity ?? '',
//                       cost_centre_id: delivery.cost_centre_id,
//                       general_ledger_id: delivery.general_ledger_id,
//                       project_id: delivery.project_id,
//                   })),
//         custom_ids: rfq_item_details.item_information.custom_ids,
//         requisition_number:
//             rfq_item_details.requisition_information.length > 0
//                 ? rfq_item_details.requisition_information[0].requisition_number
//                 : '',
//         requisition_approved:
//             rfq_item_details.requisition_information.length > 0
//                 ? rfq_item_details.requisition_information[0]
//                       .requisition_approved
//                 : false,
//         incoterm_id: rfq_item_details.incoterm,
//         lead_time:
//             rfq_item_details.procurement_information.lead_time !== null
//                 ? rfq_item_details.procurement_information.lead_time.toString()
//                 : '',
//         lead_time_period:
//             rfq_item_details.procurement_information.lead_time_period,
//         paymentTerms: rfq_item_details.paymentTerms,
//         measurement_unit_name:
//             rfq_item_details.quantity_information.measurement_unit_name,
//         po_status: rfq_item_details.purchase_order_status
//             ? rfq_item_details.purchase_order_status
//             : null,
//         incoterm_name: rfq_item_details.incoterm_abbreviation,
//         custom_fields_sections: custom_field_sections,
//         po_item_accepted_quantity:
//             rfq_item_details.fulfilment_information.po_item_accepted_quantity,
//         po_item_approval_pending_quantity:
//             rfq_item_details.fulfilment_information
//                 .po_item_approval_pending_quantity,
//         po_item_issued_quantity:
//             rfq_item_details.fulfilment_information.po_item_issued_quantity,
//         tags: itemTags ?? [],
//     };
// };

export const translateRFQItemDetailsToCustomDetailsWithTemplates = (
    rfq_item_details: IEventItemDetails,
    custom_fields_from_template: IRfqTemplate,
    itemTags: string[]
): ICustomEventItemDetails => {
    let custom_field_sections = cloneDeep(
        translateBackendCustomFieldSectionsViaTemplateToFrontEnd(
            rfq_item_details.custom_fields,
            rfq_item_details.custom_fields_negotiate
        )
    );
    if (
        +rfq_item_details.fulfilment_information.po_item_accepted_quantity ===
            0 &&
        +rfq_item_details.fulfilment_information
            .po_item_approval_pending_quantity === 0 &&
        +rfq_item_details.fulfilment_information.po_item_issued_quantity === 0
    ) {
        let listOfCustomFieldsFromTemplateOfEssentialTerms =
            custom_fields_from_template.sections.ESSENTIAL_TERMS.fieldList.filter(
                (f) => f.fieldType === 'CUSTOM'
            );

        let listOfCustomFieldsFromTemplateOfPaymentsDelivery =
            custom_fields_from_template.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.filter(
                (f) => f.fieldType === 'CUSTOM'
            );

        let listOfCustomFieldsFromTemplateOfAdditionalDetails =
            custom_fields_from_template.sections.ADDITIONAL_DETAILS.fieldList.filter(
                (f) => f.fieldType === 'CUSTOM'
            );

        for (let field of listOfCustomFieldsFromTemplateOfEssentialTerms) {
            const listOfCustomNames =
                custom_field_sections.existingSections.essential_terms.map(
                    (f) => f.name
                );

            const foundIndex = listOfCustomNames.findIndex(
                (f) => field.field === f
            );

            if (foundIndex !== -1) {
                custom_field_sections.existingSections.essential_terms[
                    foundIndex
                ] = {
                    ...custom_field_sections.existingSections.essential_terms[
                        foundIndex
                    ],
                    name: custom_fields_from_template.fieldSettings
                        .customFields[field.field].label,
                    description:
                        custom_fields_from_template.fieldSettings.customFields[
                            field.field
                        ].description,
                    is_required:
                        custom_fields_from_template.fieldSettings.customFields[
                            field.field
                        ].buyerSettings.required,
                    is_visible:
                        custom_fields_from_template.fieldSettings.customFields[
                            field.field
                        ].sellerSettings.showField,
                };
            } else {
                custom_field_sections.existingSections.essential_terms.push({
                    is_locked: true,
                    is_negotiable:
                        custom_fields_from_template.fieldSettings.customFields[
                            field.field
                        ].sellerSettings.negotiable,
                    is_required:
                        custom_fields_from_template.fieldSettings.customFields[
                            field.field
                        ].buyerSettings.required,
                    is_visible:
                        custom_fields_from_template.fieldSettings.customFields[
                            field.field
                        ].sellerSettings.showField,
                    name: custom_fields_from_template.fieldSettings
                        .customFields[field.field].label,
                    description:
                        custom_fields_from_template.fieldSettings.customFields[
                            field.field
                        ].description,
                    type: CustomTemplateItemTypes.SHORTTEXT,
                    value: '',
                });
            }
        }
        for (let field of listOfCustomFieldsFromTemplateOfPaymentsDelivery) {
            const listOfCustomNames =
                custom_field_sections.existingSections.payment_and_delivery_terms.map(
                    (f) => f.name
                );

            const foundIndex = listOfCustomNames.findIndex(
                (f) => field.field === f
            );

            if (foundIndex !== -1) {
                custom_field_sections.existingSections.payment_and_delivery_terms[
                    foundIndex
                ] = {
                    ...custom_field_sections.existingSections
                        .payment_and_delivery_terms[foundIndex],
                    name: custom_fields_from_template.fieldSettings
                        .customFields[field.field].label,
                    description:
                        custom_fields_from_template.fieldSettings.customFields[
                            field.field
                        ].description,
                    is_required:
                        custom_fields_from_template.fieldSettings.customFields[
                            field.field
                        ].buyerSettings.required,
                    is_visible:
                        custom_fields_from_template.fieldSettings.customFields[
                            field.field
                        ].sellerSettings.showField,
                };
            } else {
                custom_field_sections.existingSections.payment_and_delivery_terms.push(
                    {
                        is_locked: true,
                        is_negotiable:
                            custom_fields_from_template.fieldSettings
                                .customFields[field.field].sellerSettings
                                .negotiable,
                        is_required:
                            custom_fields_from_template.fieldSettings
                                .customFields[field.field].buyerSettings
                                .required,
                        is_visible:
                            custom_fields_from_template.fieldSettings
                                .customFields[field.field].sellerSettings
                                .showField,
                        name: custom_fields_from_template.fieldSettings
                            .customFields[field.field].label,
                        description:
                            custom_fields_from_template.fieldSettings
                                .customFields[field.field].description,
                        type: CustomTemplateItemTypes.SHORTTEXT,
                        value: '',
                    }
                );
            }
        }
        for (let field of listOfCustomFieldsFromTemplateOfAdditionalDetails) {
            const listOfCustomNames =
                custom_field_sections.existingSections.additional_details.map(
                    (f) => f.name
                );

            const foundIndex = listOfCustomNames.findIndex(
                (f) => field.field === f
            );

            if (foundIndex !== -1) {
                custom_field_sections.existingSections.additional_details[
                    foundIndex
                ] = {
                    ...custom_field_sections.existingSections
                        .additional_details[foundIndex],
                    name: custom_fields_from_template.fieldSettings
                        .customFields[field.field].label,
                    description:
                        custom_fields_from_template.fieldSettings.customFields[
                            field.field
                        ].description,
                    is_required:
                        custom_fields_from_template.fieldSettings.customFields[
                            field.field
                        ].buyerSettings.required,
                    is_visible:
                        custom_fields_from_template.fieldSettings.customFields[
                            field.field
                        ].sellerSettings.showField,
                };
            } else {
                //
                custom_field_sections.existingSections.additional_details.push({
                    is_locked: true,
                    is_negotiable:
                        custom_fields_from_template.fieldSettings.customFields[
                            field.field
                        ].sellerSettings.negotiable,
                    is_required:
                        custom_fields_from_template.fieldSettings.customFields[
                            field.field
                        ].buyerSettings.required,
                    is_visible:
                        custom_fields_from_template.fieldSettings.customFields[
                            field.field
                        ].sellerSettings.showField,
                    name: custom_fields_from_template.fieldSettings
                        .customFields[field.field].label,
                    description:
                        custom_fields_from_template.fieldSettings.customFields[
                            field.field
                        ].description,
                    type: CustomTemplateItemTypes.SHORTTEXT,
                    value: '',
                });
            }
        }
    }

    let itemTotal = calculateNewItemTotalWithDecimalPlaces({
        itemDS:
            rfq_item_details.delivery_information.length === 0
                ? [
                      {
                          quantity:
                              rfq_item_details.quantity_information.quantity,
                      },
                  ]
                : rfq_item_details.delivery_information,
        item_price: rfq_item_details.pricing_information.desired_price,
        decimalPlaces: 9,
        additional_cost: convertAdditionalCostBackendToFrontend(
            rfq_item_details.pricing_information.additional_costs ?? []
        ),
        discount: convertAdditionalCostBackendToFrontend(
            rfq_item_details.pricing_information.discounts ?? []
        ),
        tax: convertAdditionalCostBackendToFrontend(
            rfq_item_details.pricing_information.taxes ?? []
        ),
    });
    let itemSubtotal = calculateNewItemSubtotalWithDecimal({
        item_price: rfq_item_details.pricing_information.desired_price,
        decimalPlaces: 9,
        discount: convertAdditionalCostBackendToFrontend(
            rfq_item_details.pricing_information.discounts ?? []
        ),
        itemDS:
            rfq_item_details.delivery_information.length === 0
                ? [
                      {
                          quantity:
                              rfq_item_details.quantity_information.quantity,
                      },
                  ]
                : rfq_item_details.delivery_information,
    });
    let itemAdditionalCostTotal = calculateAddtionalCost({
        additionalCost: convertAdditionalCostBackendToFrontend(
            rfq_item_details.pricing_information.additional_costs ?? []
        ),
        baseQuantity: rfq_item_details.quantity_information.quantity,
        baseRate: rfq_item_details.pricing_information.desired_price,
        type: 'ADDITIONAL_COST',
    }).additionalCostValue;
    let itemDiscountsTotal = calculateAddtionalCost({
        additionalCost: convertAdditionalCostBackendToFrontend(
            rfq_item_details.pricing_information.discounts ?? []
        ),
        baseRate: rfq_item_details.pricing_information.desired_price,
        baseQuantity: rfq_item_details.quantity_information.quantity,
        type: 'DISCOUNT',
    }).additionalCostValue;
    let itemTaxesTotal = calculateAddtionalCost({
        additionalCost: convertAdditionalCostBackendToFrontend(
            rfq_item_details.pricing_information.taxes ?? []
        ),
        baseRate: rfq_item_details.pricing_information.desired_price,
        baseQuantity: rfq_item_details.quantity_information.quantity,
        type: 'TAX',
        discountAdditionalCost: convertAdditionalCostBackendToFrontend(
            rfq_item_details.pricing_information.discounts ?? []
        ),
    }).additionalCostValue;

    return {
        rfq_item_id: rfq_item_details.rfq_item_id,
        attachments: rfq_item_details.attachments,
        currency_code_symbol:
            rfq_item_details.pricing_information.currency_symbol,
        enterprise_item_id: rfq_item_details.enterprise_item,
        buyer_item_name: rfq_item_details.item_information.buyer_item_name,
        buyer_item_code: rfq_item_details.item_information.buyer_item_id,
        event_item_entry_id: rfq_item_details.rfq_item_entry_id,
        shipping: rfq_item_details.pricing_information.shipping_per_unit ?? '',
        additional_charges:
            rfq_item_details.pricing_information.additional_charges,
        price: rfq_item_details.pricing_information.desired_price
            ? rfq_item_details.pricing_information.desired_price
            : '',
        currency_code_id: rfq_item_details.pricing_information.currency_code_id,
        currency_code_abbreviation:
            rfq_item_details.pricing_information.currency_code_abbreviation,
        specifications: rfq_item_details.attribute_information,
        attributes: rfq_item_details.attributes,
        quantity: rfq_item_details.quantity_information.quantity
            ? rfq_item_details.quantity_information.quantity
            : '',
        quantity_tolerance_percentage:
            rfq_item_details.quantity_information.quantity_tolerance_percentage,
        measurement_unit_id:
            rfq_item_details.quantity_information.measurement_unit_id,
        item_description:
            rfq_item_details.item_information.buyer_item_description,
        item_additional_information:
            rfq_item_details.item_information.item_additional_details,
        is_price_confidential:
            rfq_item_details.procurement_information.is_price_confidential,
        delivery_schedule:
            (
                rfq_item_details.delivery_schedule ??
                rfq_item_details.delivery_information
            ).length === 0
                ? [
                      {
                          bom_item: null,
                          delivery_date: null,
                          quantity:
                              rfq_item_details.quantity_information.quantity,
                          cost_centre_id: null,
                          general_ledger_id: null,
                          project_id: null,
                          customer_entity: null,
                          parent_delivery_schedule_item: null,
                      },
                  ]
                : (
                      rfq_item_details.delivery_schedule ??
                      rfq_item_details.delivery_information
                  ).map((delivery) => ({
                      bom_item: delivery.bom_item,
                      delivery_date: delivery.delivery_date,
                      quantity: delivery.quantity ?? '',
                      cost_centre_id: delivery.cost_centre_id,
                      general_ledger_id: delivery.general_ledger_id,
                      project_id: delivery.project_id,
                      customer_entity: delivery.customer_entity_id,
                      parent_delivery_schedule_item: {
                          delivery_schedule_item_id:
                              delivery.parent_delivery_schedule_item
                                  ?.delivery_schedule_item_id,
                          parent_delivery_schedule_item:
                              delivery.parent_delivery_schedule_item
                                  ?.parent_delivery_schedule_item,
                          quantity:
                              delivery.parent_delivery_schedule_item?.quantity,
                          requisition_item: {
                              requisition_id:
                                  delivery.parent_delivery_schedule_item
                                      ?.requisition_item.requisition_id,
                              requisition_source_type:
                                  delivery.parent_delivery_schedule_item
                                      ?.requisition_item
                                      .requisition_source_type,
                              custom_requisition_id:
                                  delivery.parent_delivery_schedule_item
                                      ?.requisition_item.custom_requisition_id,
                              shipping_address: {
                                  shipping_address_id:
                                      delivery.parent_delivery_schedule_item
                                          ?.requisition_item.shipping_address
                                          .shipping_address_id,
                                  shipping_full_address:
                                      delivery.parent_delivery_schedule_item
                                          ?.requisition_item.shipping_address
                                          .shipping_full_address,
                              },
                          },
                      },
                  })),
        custom_ids: rfq_item_details.item_information.custom_ids,
        requisition_number:
            rfq_item_details.requisition_information.length > 0
                ? rfq_item_details.requisition_information[0].requisition_number
                : '',
        requisition_approved:
            rfq_item_details.requisition_information.length > 0
                ? rfq_item_details.requisition_information[0]
                      .requisition_approved
                : false,
        incoterm_id: rfq_item_details.incoterm,
        lead_time:
            rfq_item_details.procurement_information.lead_time !== null
                ? rfq_item_details.procurement_information.lead_time.toString()
                : '',
        lead_time_period:
            rfq_item_details.procurement_information.lead_time_period,
        paymentTerms: rfq_item_details.paymentTerms,
        measurement_unit_name:
            rfq_item_details.quantity_information.measurement_unit_name,
        po_status: rfq_item_details.purchase_order_status
            ? rfq_item_details.purchase_order_status
            : null,
        incoterm_name: rfq_item_details.incoterm_abbreviation,
        custom_fields_sections: custom_field_sections,
        po_item_accepted_quantity:
            rfq_item_details.fulfilment_information.po_item_accepted_quantity,
        po_item_approval_pending_quantity:
            rfq_item_details.fulfilment_information
                .po_item_approval_pending_quantity,
        po_item_issued_quantity:
            rfq_item_details.fulfilment_information.po_item_issued_quantity,
        // TODO: remove this dummy
        taxes: convertAdditionalCostBackendToFrontend(
            rfq_item_details.pricing_information.taxes
        ),
        additional_costs: convertAdditionalCostBackendToFrontend(
            rfq_item_details.pricing_information.additional_costs
        ),
        discounts: convertAdditionalCostBackendToFrontend(
            rfq_item_details.pricing_information.discounts
        ),
        tags: itemTags ?? [],
        total: rfq_item_details.pricing_information.total_price,
        item_total: itemTotal.toString(),
        item_subtotal: itemSubtotal.toString(),
        item_additional_costs_total: itemAdditionalCostTotal.toString(),
        item_discounts_total: itemDiscountsTotal.toString(),
        item_taxes_total: itemTaxesTotal.toString(),
        vendor_item_total: itemTotal.toString(),
        vendor_item_subtotal: itemSubtotal.toString(),
        vendor_item_additional_costs_total: itemAdditionalCostTotal.toString(),
        vendor_item_discounts_total: itemDiscountsTotal.toString(),
        vendor_item_taxes_total: itemTaxesTotal.toString(),
    };
};

export const translateCustomToRFQItemDetails = (
    prev_rfq_item_details: IEventItemDetails,
    new_rfq_item_details: ICustomEventItemDetails
): IEventItemDetails => {
    let newDetails = cloneDeep(prev_rfq_item_details);
    newDetails.rfq_item_entry_id = new_rfq_item_details.event_item_entry_id;
    newDetails.pricing_information.desired_price =
        new_rfq_item_details.price.toString();
    newDetails.pricing_information.shipping_per_unit =
        new_rfq_item_details.shipping;
    newDetails.pricing_information.additional_charges =
        new_rfq_item_details.additional_charges;
    newDetails.quantity_information.quantity =
        new_rfq_item_details.quantity.toString();
    newDetails.quantity_information.measurement_unit_id =
        new_rfq_item_details.measurement_unit_id;
    newDetails.attribute_information = new_rfq_item_details.specifications;
    newDetails.item_information.item_additional_details =
        new_rfq_item_details.item_additional_information;
    newDetails.procurement_information.is_price_confidential =
        new_rfq_item_details.is_price_confidential;
    newDetails.delivery_information =
        new_rfq_item_details.delivery_schedule.map((delivery) => ({
            bom_item: delivery.bom_item,
            delivery_date: delivery.delivery_date,
            quantity: delivery.quantity.toString(),
            cost_centre_id:
                delivery.cost_centre_id !== '' ? delivery.cost_centre_id : null,
            general_ledger_id:
                delivery.general_ledger_id !== ''
                    ? delivery.general_ledger_id
                    : null,
            project_id: delivery.project_id !== '' ? delivery.project_id : null,
            cost_centre_name: '',
            gl_code: '',
            project_code: '',
            customer_entity_id: delivery.customer_entity ?? null,
        }));
    newDetails.requisition_information = [
        {
            requisition_number: new_rfq_item_details.requisition_number,
            requisition_approved: new_rfq_item_details.requisition_approved,
        },
    ];
    newDetails.incoterm = new_rfq_item_details.incoterm_id;
    newDetails.procurement_information.lead_time =
        new_rfq_item_details.lead_time.toString();
    newDetails.procurement_information.lead_time_period =
        new_rfq_item_details.lead_time_period ?? '';
    newDetails.paymentTerms = new_rfq_item_details.paymentTerms;

    return newDetails;
};

const validateSpecificCustomField = ({
    customFieldSections,
    sectionType,
    sectionName,
    fieldName,
    errorObj,
    suppressErrors,
    templateDetails,
}: {
    customFieldSections: IEventItemCustomFieldSections;
    sectionType: 'existingSections' | 'newSections';
    sectionName: string;
    fieldName: string;
    errorObj: IEventItemValidation;
    suppressErrors: boolean;
    templateDetails: IRfqTemplate;
}): void => {
    const updatedSectionParentObj =
        sectionType === 'existingSections'
            ? customFieldSections.existingSections
            : customFieldSections.newSections;
    const updatedSection =
        updatedSectionParentObj[
            sectionName as keyof typeof updatedSectionParentObj
        ];
    const fieldToCheck = updatedSection?.find((f) => f.name === fieldName);
    if (!errorObj.customFields[sectionName]) {
        errorObj.customFields[sectionName] = {};
    }
    delete errorObj.customFields[sectionName][fieldName];

    // fieldToCheck will be null if the field was deleted.
    // In that case, we delete any prev error, and dont validate again
    const customfieldSettingFromTemplate =
        templateDetails.fieldSettings.customFields[fieldName];

    if (
        fieldToCheck &&
        fieldToCheck.is_required &&
        !isValueValidForStringArrNull(fieldToCheck.value)
    ) {
        errorObj.customFields[sectionName][fieldName] = {
            type: 'ERROR',
            fieldMessageText: 'Input required',
            popupMessageText: `${fieldName} input required`,
            suppress: suppressErrors,
        };
    } else if (
        fieldToCheck &&
        customfieldSettingFromTemplate &&
        customfieldSettingFromTemplate.buyerSettings.required &&
        !isValueValidForStringArrNull(fieldToCheck.value)
    ) {
        errorObj.customFields[sectionName][fieldName] = {
            type: 'ERROR',
            fieldMessageText: 'Input required',
            popupMessageText: `${fieldName} input required`,
            suppress: suppressErrors,
        };
    }
};

export const validateRFQItemCustomFields = ({
    customFieldInfo,
    itemDetails,
    currentErrors,
    suppressErrors,
    templateDetails,
}: {
    customFieldInfo:
        | {
              sectionType: 'existingSections' | 'newSections';
              sectionName: string;
              fieldName: string;
          }
        | 'ALL';
    itemDetails: ICustomEventItemDetails;
    currentErrors: IEventItemValidation;
    suppressErrors: boolean;
    templateDetails: IRfqTemplate;
}): IEventItemValidation => {
    let newErrors = cloneDeep(currentErrors);

    if (customFieldInfo === 'ALL') {
        //Validating existing sections
        for (let sectionName of [
            custom_field_backend_section_names.payment_and_delivery_terms_template_name,
            custom_field_backend_section_names.additional_information_template_name,
            custom_field_backend_section_names.essential_terms_template_name,
        ]) {
            let curSectionFields =
                sectionName ===
                custom_field_backend_section_names.payment_and_delivery_terms_template_name
                    ? itemDetails.custom_fields_sections.existingSections
                          .payment_and_delivery_terms
                    : sectionName ===
                      custom_field_backend_section_names.additional_information_template_name
                    ? itemDetails.custom_fields_sections.existingSections
                          .additional_details
                    : itemDetails.custom_fields_sections.existingSections
                          .essential_terms;
            for (let curField of curSectionFields) {
                validateSpecificCustomField({
                    customFieldSections: itemDetails.custom_fields_sections,
                    errorObj: newErrors,
                    fieldName: curField.name,
                    sectionName,
                    sectionType: 'existingSections',
                    suppressErrors,
                    templateDetails,
                });
            }
        }
        //TODO: Add handling for new sections when implementing it
    } else {
        validateSpecificCustomField({
            customFieldSections: itemDetails.custom_fields_sections,
            errorObj: newErrors,
            fieldName: customFieldInfo.fieldName,
            sectionName: customFieldInfo.sectionName,
            sectionType: customFieldInfo.sectionType,
            suppressErrors,
            templateDetails,
        });
    }
    return newErrors;
};

export const getItemValidationKey = (
    updatedFieldName: updateEventItemDetailsActionType
): keyof IEventItemValidation | null => {
    switch (updatedFieldName) {
        case 'quantity':
            return 'quantity';
        case 'delivery_schedule':
            return 'deliveryScheduleRows';
        case 'deliveryScheduleOverall':
            return 'deliveryScheduleOverall';
        case 'price':
            return 'price';
        case 'additional_charges':
            return 'additional_charges';
        case 'shipping':
            return 'shipping';
        case 'incoterm_id':
            return 'incoterm_id';
        case 'item_additional_information':
            return 'item_additional_information';
        case 'lead_time':
            return 'lead_time';
        case 'lead_time_period':
            return 'lead_time_period';
        case 'measurement_unit_id':
            return 'measurementUnit';
        case 'custom_fields_sections':
            return 'customFields';
        case 'paymentTerms_prepaymentPercentage':
            return 'paymentTerms_prepayment';
        case 'paymentTerms_invoiceItem_terms':
            return 'paymentTerms_invoiceItem_terms';
        case 'paymentTerms_invoiceItem_period':
            return 'paymentTerms_invoiceItem_period';
        case 'paymentTerms_invoiceItem_appliedFrom':
            return 'paymentTerms_invoiceItem_appliedFrom';
        case 'paymentTerms_poItem_allocationRows':
            return 'paymentTerms_poItem_allocationRows';
        case 'quantity_tolerance_percentage':
            return 'quantity_tolerance_percentage';
        case 'requisition_approved':
            return 'requisition_approval';
        case 'requisition_number':
            return 'requisition_number';
        case 'specifications':
            return 'specifications';
        case 'additional_costs':
            return 'additional_cost';
        case 'taxes':
            return 'taxes';
        case 'discounts':
            return 'discounts';
        default:
            return null;
    }
};

export const getErrorObjectUnsuppressed = (
    errorObj: IEventItemValidation
): IEventItemValidation => {
    let newErrorObj = cloneDeep(errorObj);
    for (let section of Object.keys(newErrorObj.customFields)) {
        for (let field of Object.keys(newErrorObj.customFields[section])) {
            newErrorObj.customFields[section][field].suppress = false;
        }
    }
    for (let row of newErrorObj.deliveryScheduleRows) {
        if (row.date) {
            row.date.suppress = false;
        }
        if (row.quantity) {
            row.quantity.suppress = false;
        }
    }
    for (let row of newErrorObj.paymentTerms_poItem_allocationRows) {
        if (row.rowAllocationPercentage) {
            row.rowAllocationPercentage.suppress = false;
        }
        if (row.rowDate) {
            row.rowDate.suppress = false;
        }
        if (row.rowDeliverableName) {
            row.rowDeliverableName.suppress = false;
        }
        if (row.rowPeriod) {
            row.rowPeriod.suppress = false;
        }
        if (row.rowTerms) {
            row.rowTerms.suppress = false;
        }
    }

    for (let key of Object.keys(newErrorObj)) {
        let typedKey = key as keyof IEventItemValidation;
        if (
            typedKey === 'customFields' ||
            typedKey === 'deliveryScheduleRows' ||
            typedKey === 'paymentTerms_poItem_allocationRows'
        ) {
            continue;
        }
        try {
            newErrorObj = {
                ...newErrorObj,
                [typedKey]: { ...newErrorObj[typedKey], suppress: false },
            };
        } catch (e) {}
    }

    return newErrorObj;
};

export const eventItemErrorExists = (
    errorObj: IEventItemValidation
): boolean => {
    if (
        errorObj.additional_charges?.type === 'ERROR' ||
        errorObj.deliveryScheduleOverall?.type === 'ERROR' ||
        errorObj.excessive_lead_time?.type === 'ERROR' ||
        errorObj.incoterm_id?.type === 'ERROR' ||
        errorObj.item_additional_information?.type === 'ERROR' ||
        errorObj.lead_time?.type === 'ERROR' ||
        errorObj.lead_time_period?.type === 'ERROR' ||
        errorObj.measurementUnit?.type === 'ERROR' ||
        errorObj.paymentTerms_invoiceItem_appliedFrom?.type === 'ERROR' ||
        errorObj.paymentTerms_invoiceItem_period?.type === 'ERROR' ||
        errorObj.paymentTerms_invoiceItem_terms?.type === 'ERROR' ||
        errorObj.paymentTerms_poItem_overallPercentage?.type === 'ERROR' ||
        errorObj.paymentTerms_prepayment?.type === 'ERROR' ||
        errorObj.price?.type === 'ERROR' ||
        errorObj.quantity?.type === 'ERROR' ||
        errorObj.quantity_tolerance_percentage?.type === 'ERROR' ||
        errorObj.requisition_number?.type === 'ERROR' ||
        errorObj.requisition_approval?.type === 'ERROR' ||
        errorObj.shipping?.type === 'ERROR' ||
        errorObj.specifications?.type === 'ERROR' ||
        errorObj.vendor_count?.type === 'ERROR' ||
        errorObj.additional_cost?.type === 'ERROR' ||
        errorObj.taxes?.type === 'ERROR' ||
        errorObj.discounts?.type === 'ERROR'
    ) {
        return true;
    }
    for (let section of Object.keys(errorObj.customFields)) {
        for (let field of Object.keys(errorObj.customFields[section])) {
            if (errorObj.customFields[section][field].type === 'ERROR') {
                return true;
            }
        }
    }

    for (let row of errorObj.deliveryScheduleRows) {
        if (row.date?.type === 'ERROR' || row.quantity?.type === 'ERROR') {
            return true;
        }
    }

    for (let row of errorObj.paymentTerms_poItem_allocationRows) {
        if (
            row.rowAllocationPercentage?.type === 'ERROR' ||
            row.rowDate?.type === 'ERROR' ||
            row.rowDeliverableName?.type === 'ERROR' ||
            row.rowPeriod?.type === 'ERROR' ||
            row.rowTerms?.type === 'ERROR'
        ) {
            return true;
        }
    }
    return false;
};

export const validateRFQItemDetails = ({
    field,
    itemDetails,
    currentErrors,
    preferredVendorCount,
    nonpreferredVendorCount,
    eventType,
    suppressErrors,
    templateDetails,
}: {
    field: keyof IEventItemValidation | 'ALL';
    itemDetails: ICustomEventItemDetails;
    currentErrors: IEventItemValidation;
    preferredVendorCount: number | null;
    nonpreferredVendorCount: number | null;
    eventType: 'DIRECT' | 'FROM_EVENT';
    suppressErrors: boolean;
    templateDetails: IRfqTemplate;
}): IEventItemValidation => {
    if (
        +itemDetails.po_item_approval_pending_quantity > 0 ||
        (itemDetails.po_status &&
            itemDetails.po_status !== 'PURCHASE_ORDER_PENDING')
    ) {
        return {
            deliveryScheduleRows: [],
            paymentTerms_poItem_allocationRows: [],
            customFields: {},
        };
    }

    let newErrors = cloneDeep(currentErrors);

    if (field === 'ALL') {
        newErrors = validateRFQItemCustomFields({
            currentErrors: newErrors,
            customFieldInfo: 'ALL',
            itemDetails,
            suppressErrors,
            templateDetails,
        });
    }

    if (
        field === 'ALL' ||
        field === 'quantity' ||
        field === 'deliveryScheduleRows' ||
        field === 'deliveryScheduleOverall' ||
        field === 'lead_time' ||
        field === 'lead_time_period'
    ) {
        if (
            typeof itemDetails.quantity !== 'number' &&
            isEmpty(itemDetails.quantity)
        ) {
            //Quantity is blank
            newErrors.quantity = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Quantity input required',
                suppress: suppressErrors,
            };
        } else if (+itemDetails.quantity <= 0) {
            newErrors.quantity = {
                //Quantity is 0
                type: 'ERROR',
                fieldMessageText: 'Must be greater than 0',
                popupMessageText: 'Invalid quantity',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['quantity'];
        }
        if (isEmpty(itemDetails.measurement_unit_id)) {
            //Measurement unit is blank
            newErrors.measurementUnit = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Measurement unit input required',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['measurementUnit'];
        }
        let totalDeliveryQuantity = 0;
        delete newErrors['deliveryScheduleOverall'];
        newErrors['deliveryScheduleRows'] = [];
        if (+itemDetails.quantity > 0) {
            for (let delivery of itemDetails.delivery_schedule) {
                let errorRow: {
                    quantity?: IEventItemFieldValidation;
                    date?: IEventItemFieldValidation;
                    cost_centre_id?: IEventItemFieldValidation;
                    project_id?: IEventItemFieldValidation;
                    general_ledger_id?: IEventItemFieldValidation;
                } = {};
                if (
                    typeof delivery.quantity !== 'number' &&
                    isEmpty(delivery.quantity)
                ) {
                    //Delivery quantity is blank
                    errorRow.quantity = {
                        type: 'ERROR',
                        fieldMessageText: 'Input required',
                        popupMessageText: '',
                        suppress: suppressErrors,
                    };
                    newErrors.deliveryScheduleOverall = {
                        type: 'ERROR',
                        fieldMessageText: '',
                        popupMessageText: 'Invalid delivery schedule',
                        suppress: suppressErrors,
                    };
                } else if (+delivery.quantity <= 0) {
                    //Delivery quantity is 0
                    errorRow.quantity = {
                        type: 'ERROR',
                        fieldMessageText: 'Must be greater than 0',
                        popupMessageText: '',
                        suppress: suppressErrors,
                    };
                    newErrors.deliveryScheduleOverall = {
                        type: 'ERROR',
                        fieldMessageText: '',
                        popupMessageText: 'Invalid delivery schedule',
                        suppress: suppressErrors,
                    };
                }
                if (
                    delivery.delivery_date !== null &&
                    !moment(
                        delivery.delivery_date,
                        'yyyy-MM-DD',
                        true
                    ).isValid()
                ) {
                    //Delivery date is blank or invalid (cannot differentiate these two cases in code)
                    errorRow.date = {
                        type: 'ERROR',
                        fieldMessageText: 'Invalid date',
                        popupMessageText: '',
                        suppress: suppressErrors,
                    };
                    newErrors.deliveryScheduleOverall = {
                        type: 'ERROR',
                        fieldMessageText: '',
                        popupMessageText: 'Invalid delivery schedule',
                        suppress: suppressErrors,
                    };
                } else if (
                    moment(delivery.delivery_date).isBefore(
                        moment().format('YYYY-MM-DD')
                    )
                ) {
                    errorRow.date = {
                        type: 'ERROR',
                        fieldMessageText: 'Past date cannot be entered',
                        popupMessageText: '',
                        suppress: suppressErrors,
                    };
                    newErrors.deliveryScheduleOverall = {
                        type: 'ERROR',
                        fieldMessageText: '',
                        popupMessageText: 'Invalid delivery schedule',
                        suppress: suppressErrors,
                    };
                } else if (
                    templateDetails.fieldSettings.standardFields.DELIVERY_DATE
                        .buyerSettings.required &&
                    !delivery.delivery_date
                ) {
                    errorRow.date = {
                        type: 'ERROR',
                        fieldMessageText: 'Input is required',
                        popupMessageText: '',
                        suppress: suppressErrors,
                    };
                }

                if (
                    templateDetails.fieldSettings.standardFields.COST_CENTER
                        .buyerSettings.required &&
                    templateDetails.fieldSettings.standardFields.COST_CENTER
                        .buyerSettings.showField &&
                    templateDetails.sections.ESSENTIAL_TERMS.fieldList.some(
                        (field) =>
                            field.field ===
                            TemplateStandardFieldsEnum.COST_CENTER
                    )
                ) {
                    if (
                        delivery.cost_centre_id === null ||
                        delivery.cost_centre_id === ''
                    ) {
                        errorRow.cost_centre_id = {
                            type: 'ERROR',
                            fieldMessageText: 'Input required',
                            popupMessageText: 'Cost centre is required',
                            suppress: suppressErrors,
                        };
                        newErrors.deliveryScheduleOverall = {
                            type: 'ERROR',
                            fieldMessageText: '',
                            popupMessageText: 'Cost centre is required',
                            suppress: suppressErrors,
                        };
                    }
                }
                if (
                    templateDetails.fieldSettings.standardFields.GL
                        .buyerSettings.required &&
                    templateDetails.fieldSettings.standardFields.GL
                        .buyerSettings.showField &&
                    templateDetails.sections.ESSENTIAL_TERMS.fieldList.some(
                        (field) => field.field === TemplateStandardFieldsEnum.GL
                    )
                ) {
                    if (
                        delivery.general_ledger_id === null ||
                        delivery.general_ledger_id === ''
                    ) {
                        errorRow.general_ledger_id = {
                            type: 'ERROR',
                            fieldMessageText: 'Input required',
                            popupMessageText: 'General ledger is required',
                            suppress: suppressErrors,
                        };
                        newErrors.deliveryScheduleOverall = {
                            type: 'ERROR',
                            fieldMessageText: '',
                            popupMessageText: 'General ledger is required',
                            suppress: suppressErrors,
                        };
                    }
                }
                if (
                    templateDetails.fieldSettings.standardFields.PROJECT
                        .buyerSettings.required &&
                    templateDetails.fieldSettings.standardFields.PROJECT
                        .buyerSettings.showField &&
                    templateDetails.fieldSettings.standardFields[
                        TemplateStandardFieldsEnum.PROJECT
                    ].fieldLevel === 'ITEM'
                ) {
                    if (
                        delivery.project_id === null ||
                        delivery.project_id === ''
                    ) {
                        errorRow.project_id = {
                            type: 'ERROR',
                            fieldMessageText: 'Input required',
                            popupMessageText: 'Project is required',
                            suppress: suppressErrors,
                        };
                        newErrors.deliveryScheduleOverall = {
                            type: 'ERROR',
                            fieldMessageText: '',
                            popupMessageText: 'Project is required',
                            suppress: suppressErrors,
                        };
                    }
                }

                totalDeliveryQuantity += isNaN(+delivery.quantity)
                    ? 0
                    : +delivery.quantity;

                newErrors.deliveryScheduleRows.push(errorRow);
            }
        }
        if (
            templateDetails.fieldSettings.standardFields.DELIVERY_DATE
                .buyerSettings.required
        ) {
            if (field === 'ALL' || field === 'deliveryScheduleOverall') {
                if (
                    itemDetails.delivery_schedule.length === 0 ||
                    itemDetails.delivery_schedule.some(
                        (delivery) => delivery.delivery_date === null
                    )
                ) {
                    newErrors.deliveryScheduleOverall = {
                        type: 'ERROR',
                        fieldMessageText: 'Input required',
                        popupMessageText: 'Delivery date input required',
                        suppress: suppressErrors,
                    };
                }
            }
        }
        if (
            // Only perform this validation if delivery schedule is valid but quantity mismatches.
            !newErrors.deliveryScheduleOverall &&
            !isEmpty(itemDetails.quantity) &&
            totalDeliveryQuantity !== +itemDetails.quantity
        ) {
            //Total quantity does not match sum of delivery quantities
            newErrors.deliveryScheduleOverall = {
                type: 'ERROR',
                fieldMessageText: '',
                popupMessageText:
                    'Delivery schedule quantity does not match total desired quantity',
                suppress: suppressErrors,
            };
        }
    }
    if (field === 'ALL' || field === 'price') {
        if (
            typeof itemDetails.price !== 'number' &&
            isEmpty(itemDetails.price)
        ) {
            //Price is blank
            newErrors.price = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Target rate input required',
                suppress: suppressErrors,
            };
        } else if (+itemDetails.price <= 0) {
            //Price is 0
            newErrors.price = {
                type: 'ERROR',
                fieldMessageText: 'Must be greater than 0',
                popupMessageText: 'Invalid target rate',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['price'];
        }
    }
    if (
        templateDetails.fieldSettings.standardFields.GR_TOLERANCE.buyerSettings
            .showField &&
        templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.some(
            (field) => field.field === TemplateStandardFieldsEnum.GR_TOLERANCE
        ) &&
        (field === 'ALL' || field === 'quantity_tolerance_percentage')
    ) {
        if (+itemDetails.quantity_tolerance_percentage > 10) {
            newErrors.quantity_tolerance_percentage = {
                type: 'ERROR',
                fieldMessageText: 'Cannot exceed 10%',
                popupMessageText: 'GR tolerance cannot exceed 10%',
                suppress: suppressErrors,
            };
        } else if (
            templateDetails.fieldSettings.standardFields.GR_TOLERANCE
                .buyerSettings.required &&
            templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.some(
                (field) =>
                    field.field === TemplateStandardFieldsEnum.GR_TOLERANCE
            )
        ) {
            if (itemDetails.quantity_tolerance_percentage === '') {
                newErrors['quantity_tolerance_percentage'] = {
                    type: 'ERROR',
                    fieldMessageText: 'GR tolerance is required',
                    popupMessageText: 'GR tolerance is required',
                    suppress: suppressErrors,
                };
            } else {
                delete newErrors['quantity_tolerance_percentage'];
            }
        } else {
            delete newErrors['quantity_tolerance_percentage'];
        }
    } else {
        delete newErrors['quantity_tolerance_percentage'];
    }

    if (
        templateDetails.fieldSettings.standardFields.SHIPPING_RATE.buyerSettings
            .showField &&
        (field === 'ALL' || field === 'shipping')
    ) {
        if (
            typeof itemDetails.quantity !== 'number' &&
            !isEmpty(itemDetails.shipping) &&
            isNaN(+itemDetails.shipping)
        ) {
            //Shipping is not a number
            newErrors.shipping = {
                type: 'ERROR',
                fieldMessageText: 'Invalid shipping rate',
                popupMessageText: 'Invalid shipping rate',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['shipping'];
        }
    } else {
        delete newErrors['shipping'];
    }

    const templatePaymentTermsOptions =
        templateDetails.fieldSettings.standardFields.PAYMENT_TERMS;
    const ptValidation = validatePaymentTerms({
        paymentTerms: itemDetails.paymentTerms!,
        prepaymentAllowed:
            templatePaymentTermsOptions.paymentTermOptions.prepaymentAllowed,
    });

    const paymentTermsErrorKeys: (keyof IEventItemValidation)[] = [
        'paymentTerms_prepayment',
        'paymentTerms_invoiceItem_terms',
        'paymentTerms_invoiceItem_period',
        'paymentTerms_invoiceItem_appliedFrom',
        'paymentTerms_poItem_overallPercentage',
        'paymentTerms_poItem_allocationRows',
    ];

    if (
        templateDetails.fieldSettings.standardFields[
            TemplateStandardFieldsEnum.PAYMENT_TERMS
        ].buyerSettings.showField &&
        templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.some(
            (field) => field.field === TemplateStandardFieldsEnum.PAYMENT_TERMS
        ) &&
        (field === 'ALL' || paymentTermsErrorKeys.includes(field))
    ) {
        paymentTermsErrorKeys.forEach((key) => {
            delete newErrors[key];
        });

        newErrors.paymentTerms_poItem_allocationRows = [];

        if (ptValidation.overallErrors.prepaymentBlank) {
            newErrors.paymentTerms_prepayment = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Prepayment input required',
                suppress: suppressErrors,
            };
        } else if (ptValidation.overallErrors.prepaymentAbove100) {
            newErrors.paymentTerms_prepayment = {
                type: 'ERROR',
                fieldMessageText: 'Cannot exceed 100%',
                popupMessageText: 'Invalid prepayment percentage',
                suppress: suppressErrors,
            };
        }
        if (ptValidation.overallErrors.termsBlank) {
            newErrors.paymentTerms_invoiceItem_terms = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Payment terms input required',
                suppress: suppressErrors,
            };
        }
        if (ptValidation.overallErrors.periodBlank) {
            newErrors.paymentTerms_invoiceItem_period = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Payment terms period input required',
                suppress: suppressErrors,
            };
        }
        if (ptValidation.overallErrors.appliedFromBlank) {
            newErrors.paymentTerms_invoiceItem_appliedFrom = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Payment terms reference date required',
                suppress: suppressErrors,
            };
        }
        if (ptValidation.overallErrors.allocationNot100) {
            const msg =
                +(itemDetails.paymentTerms?.prepaymentPercentage ?? '0') > 0
                    ? 'Allocations and prepayment must add up to 100%'
                    : 'Allocations must add up to 100%';
            newErrors.paymentTerms_poItem_overallPercentage = {
                type: 'ERROR',
                fieldMessageText: msg,
                popupMessageText: msg,
                suppress: suppressErrors,
            };
        }
        for (let rowIdx = 0; rowIdx < ptValidation.rowErrors.length; rowIdx++) {
            const curRowErrors: {
                rowAllocationPercentage?: IEventItemFieldValidation;
                rowTerms?: IEventItemFieldValidation;
                rowPeriod?: IEventItemFieldValidation;
                rowDeliverableName?: IEventItemFieldValidation;
                rowDate?: IEventItemFieldValidation;
            } = {};
            if (ptValidation.rowErrors[rowIdx].allocationBlank) {
                curRowErrors.rowAllocationPercentage = {
                    fieldMessageText: 'Input required',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            } else if (ptValidation.rowErrors[rowIdx].allocationOver100) {
                curRowErrors.rowAllocationPercentage = {
                    fieldMessageText: 'Cannot exceed 100',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            }
            if (ptValidation.rowErrors[rowIdx].dateBlank) {
                curRowErrors.rowDate = {
                    fieldMessageText: 'Input required',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            } else if (ptValidation.rowErrors[rowIdx].datePast) {
                curRowErrors.rowDate = {
                    fieldMessageText: 'Input invalid',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            }
            if (ptValidation.rowErrors[rowIdx].termsBlank) {
                curRowErrors.rowTerms = {
                    fieldMessageText: 'Input required',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            }
            if (ptValidation.rowErrors[rowIdx].periodBlank) {
                curRowErrors.rowPeriod = {
                    fieldMessageText: 'Input required',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            }
            if (ptValidation.rowErrors[rowIdx].deliverableBlank) {
                curRowErrors.rowDeliverableName = {
                    fieldMessageText: 'Input required',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            } else if (ptValidation.rowErrors[rowIdx].deliverableTooLong) {
                curRowErrors.rowDeliverableName = {
                    fieldMessageText: `Cannot exceed ${DELIVERABLE_LENGTH_LIMIT} characters`,
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            } else if (ptValidation.rowErrors[rowIdx].deliverableInvalid) {
                curRowErrors.rowDeliverableName = {
                    fieldMessageText: 'Input invalid',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            }

            newErrors.paymentTerms_poItem_allocationRows.push(curRowErrors);
        }
    }

    if (
        templateDetails.fieldSettings.standardFields.LEAD_TIME.buyerSettings
            .showField &&
        templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.some(
            (field) => field.field === TemplateStandardFieldsEnum.LEAD_TIME
        ) &&
        (field === 'ALL' ||
            field === 'lead_time_period' ||
            field === 'lead_time')
    ) {
        if (
            templateDetails.fieldSettings.standardFields.LEAD_TIME.buyerSettings
                .showField &&
            templateDetails.fieldSettings.standardFields.LEAD_TIME.buyerSettings
                .required &&
            typeof itemDetails.quantity !== 'number' &&
            isEmpty(itemDetails.lead_time)
        ) {
            newErrors.lead_time = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Lead time input required',
                suppress: suppressErrors,
            };
        } else if (isNaN(+itemDetails.lead_time)) {
            //Lead time is not a number
            newErrors.lead_time = {
                type: 'ERROR',
                fieldMessageText: 'Invalid lead time',
                popupMessageText: 'Invalid lead time',
                suppress: suppressErrors,
            };
        } else if (
            templateDetails.fieldSettings.standardFields.LEAD_TIME.buyerSettings
                .showField &&
            templateDetails.fieldSettings.standardFields.LEAD_TIME.buyerSettings
                .required &&
            +itemDetails.lead_time < 0
        ) {
            newErrors.lead_time = {
                type: 'ERROR',
                fieldMessageText: 'Lead time need to be greater then 0',
                popupMessageText: 'Lead time need to be greater then 0',
                suppress: suppressErrors,
            };
        } else if (
            templateDetails.fieldSettings.standardFields.LEAD_TIME.buyerSettings
                .showField &&
            itemDetails.lead_time_period === '' &&
            templateDetails.fieldSettings.standardFields.LEAD_TIME.buyerSettings
                .required
        ) {
            //Lead time period is blank
            newErrors.lead_time = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Lead time period input required',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['lead_time'];
        }
        if (
            itemDetails.lead_time_period !== '' &&
            !isNaN(+itemDetails.lead_time) &&
            leadTimeSurpassed(
                itemDetails.lead_time,
                itemDetails.lead_time_period
            )
        ) {
            newErrors.excessive_lead_time = {
                type: 'WARNING',
                fieldMessageText: '',
                popupMessageText: 'Lead time exceeds one year',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['excessive_lead_time'];
        }
    } else {
        delete newErrors['lead_time'];
    }
    if (
        templateDetails.fieldSettings.standardFields.INCOTERMS.buyerSettings
            .showField &&
        templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.some(
            (field) => field.field === TemplateStandardFieldsEnum.INCOTERMS
        ) &&
        (field === 'ALL' || field === 'incoterm_id')
    ) {
        if (
            itemDetails.incoterm_id === '' &&
            templateDetails.fieldSettings.standardFields.INCOTERMS.buyerSettings
                .required
        ) {
            //Incoterm is blank
            newErrors.incoterm_id = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Incoterms input required',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['incoterm_id'];
        }
    } else {
        delete newErrors['incoterm_id'];
    }
    if (
        (field === 'ALL' || field === 'vendor_count') &&
        preferredVendorCount !== null &&
        nonpreferredVendorCount !== null
    ) {
        if (preferredVendorCount + nonpreferredVendorCount === 0) {
            //No of vendors is 0
            newErrors.vendor_count = {
                type: 'ERROR',
                fieldMessageText: '',
                popupMessageText: 'No vendors added',
                suppress: suppressErrors,
            };
        } else if (nonpreferredVendorCount) {
            //No of vendors is 0
            newErrors.vendor_count = {
                type: 'WARNING',
                fieldMessageText: '',
                popupMessageText: 'Non-preferred vendors added',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['vendor_count'];
        }
    }
    // specifications
    if (field === 'ALL' || field === 'specifications') {
        if (itemDetails.specifications.custom_item_attributes.length > 0) {
            // non standard specification(s) added
            newErrors.specifications = {
                type: 'WARNING',
                fieldMessageText: '',
                popupMessageText:
                    itemDetails.specifications.custom_item_attributes.length > 1
                        ? `Item contains ${itemDetails.specifications.custom_item_attributes.length} non-standard specifications`
                        : `Item contains a non-standard specification`,

                suppress: suppressErrors,
            };
        } else {
            delete newErrors['specifications'];
        }
    }

    // requisition
    if (
        templateDetails.fieldSettings.standardFields.REQUISITION.buyerSettings
            .showField &&
        templateDetails.sections.ADDITIONAL_DETAILS.fieldList.some(
            (field) => field.field === TemplateStandardFieldsEnum.REQUISITION
        ) &&
        (field === 'ALL' ||
            field === 'requisition_number' ||
            field === 'requisition_approval')
    ) {
        if (
            itemDetails.requisition_number.length > 0 &&
            templateDetails.fieldSettings.standardFields.REQUISITION
                .buyerSettings.required &&
            !itemDetails.requisition_approved
        ) {
            // requisition is not approved
            newErrors.requisition_approval = {
                type: 'WARNING',
                fieldMessageText: 'Requisition not approved',
                popupMessageText: 'Requisition not approved',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['requisition_approval'];
        }

        if (
            templateDetails.fieldSettings.standardFields.REQUISITION
                .buyerSettings.required &&
            itemDetails.requisition_number?.trim() === ''
        ) {
            newErrors['requisition_number'] = {
                type: 'ERROR',
                fieldMessageText: 'Requisition is required',
                popupMessageText: 'Requisition is required',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['requisition_number'];
        }
    }

    if (
        templateDetails.fieldSettings.standardFields.ADDITIONAL_INFORMATION
            .buyerSettings.showField &&
        templateDetails.fieldSettings.standardFields.ADDITIONAL_INFORMATION
            .buyerSettings.required &&
        templateDetails.sections.ADDITIONAL_DETAILS.fieldList.some(
            (field) =>
                field.field ===
                TemplateStandardFieldsEnum.ADDITIONAL_INFORMATION
        ) &&
        itemDetails.item_additional_information === ''
    ) {
        newErrors.item_additional_information = {
            type: 'ERROR',
            fieldMessageText: 'Input required',
            popupMessageText: 'Input required',
            suppress: suppressErrors,
        };
    } else {
        delete newErrors['item_additional_information'];
    }

    if (
        templateDetails.fieldSettings.standardFields.TAX.buyerSettings
            .showField &&
        (field === 'ALL' || field === 'additional_charges')
    ) {
        const taxNames = itemDetails.additional_charges.map((charge) =>
            charge.charge_name.trim()
        );
        const duplicateNamesExist = new Set(taxNames).size !== taxNames.length;
        if (duplicateNamesExist) {
            //Duplicate tax names (warning)
            newErrors.additional_charges = {
                type: 'WARNING',
                fieldMessageText: 'Taxes may contain duplicates',
                popupMessageText: 'Taxes may contain duplicates',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['additional_charges'];
        }
    }

    if (
        templateDetails.fieldSettings.standardFields.ADDITIONAL_COSTS
            .buyerSettings.showField &&
        templateDetails.sections.ESSENTIAL_TERMS.fieldList.some(
            (field) =>
                field.field === TemplateStandardFieldsEnum.ADDITIONAL_COSTS
        ) &&
        (field === 'ALL' || field === 'additional_cost')
    ) {
        const requiredAdditionalCost =
            templateDetails.fieldSettings.standardFields.ADDITIONAL_COSTS.additionalCostOptions.filter(
                (opt) => opt.is_required
            );
        if (requiredAdditionalCost.length > 0) {
            const isThereError = requiredAdditionalCost.reduce(
                (acc, additionalCostopt) => {
                    const foundItemAdditionalCost =
                        itemDetails.additional_costs.find(
                            (costAdditional) =>
                                costAdditional.costName.trim() ===
                                additionalCostopt.costName.trim()
                        );

                    if (foundItemAdditionalCost) {
                        if (
                            typeof foundItemAdditionalCost.value === 'string' &&
                            foundItemAdditionalCost.value.trim() === ''
                        ) {
                            return acc || true;
                        } else {
                            return acc || false;
                        }
                    } else {
                        return acc || true;
                    }
                },
                false
            );
            if (isThereError) {
                newErrors.additional_cost = {
                    type: 'ERROR',
                    fieldMessageText: 'Additional cost is required',
                    popupMessageText: 'Additional cost is required',
                    suppress: suppressErrors,
                };
            } else {
                delete newErrors['additional_cost'];
            }
        } else {
            delete newErrors['additional_cost'];
        }
    }
    if (
        templateDetails.fieldSettings.standardFields.TAX.buyerSettings
            .showField &&
        templateDetails.sections.ESSENTIAL_TERMS.fieldList.some(
            (field) => field.field === TemplateStandardFieldsEnum.DISCOUNT
        ) &&
        (field === 'ALL' || field === 'taxes')
    ) {
        const requiredAdditionalCost =
            templateDetails.fieldSettings.standardFields.TAX.taxesOptions.filter(
                (opt) => opt.is_required
            );
        if (requiredAdditionalCost.length > 0) {
            const isThereError = requiredAdditionalCost.reduce(
                (acc, additionalCostTaxopt) => {
                    const foundItemTaxes = itemDetails.taxes.find(
                        (cost) =>
                            cost.costName.trim() ===
                            additionalCostTaxopt.costName.trim()
                    );

                    if (foundItemTaxes) {
                        if (
                            typeof foundItemTaxes.value === 'string' &&
                            foundItemTaxes.value.trim() === ''
                        ) {
                            return acc || true;
                        } else {
                            return acc || false;
                        }
                    } else {
                        return acc || true;
                    }
                },
                false
            );
            if (isThereError) {
                newErrors.taxes = {
                    type: 'ERROR',
                    fieldMessageText: 'Taxes is required',
                    popupMessageText: 'Taxes is required',
                    suppress: suppressErrors,
                };
            } else {
                delete newErrors['taxes'];
            }
        } else {
            delete newErrors['taxes'];
        }
    }

    if (
        templateDetails.fieldSettings.standardFields.DISCOUNT.buyerSettings
            .showField &&
        templateDetails.sections.ESSENTIAL_TERMS.fieldList.some(
            (field) => field.field === TemplateStandardFieldsEnum.DISCOUNT
        ) &&
        (field === 'ALL' || field === 'discounts' || field === 'price')
    ) {
        const requiredDiscount =
            templateDetails.fieldSettings.standardFields.DISCOUNT.discountOptions.filter(
                (opt) => opt.is_required
            );
        if (requiredDiscount.length > 0) {
            const isThereError = requiredDiscount.reduce(
                (acc, additionalCostDiscountopt) => {
                    const foundItemDiscount = itemDetails.discounts.find(
                        (costDiscount) =>
                            costDiscount.costName.trim() ===
                            additionalCostDiscountopt.costName.trim()
                    );

                    if (foundItemDiscount) {
                        if (
                            typeof foundItemDiscount.value === 'string' &&
                            foundItemDiscount.value.trim() === ''
                        ) {
                            return acc || true;
                        } else {
                            return acc || false;
                        }
                    } else {
                        return acc || true;
                    }
                },
                false
            );

            if (isThereError) {
                newErrors.discounts = {
                    type: 'ERROR',
                    fieldMessageText: 'Discount is required',
                    popupMessageText: 'Discount is required',
                    suppress: suppressErrors,
                };
            } else {
                delete newErrors['discounts'];
            }
        } else {
            delete newErrors['discounts'];
        }

        // if (
        //     itemDetails.price &&
        //     calculateTotalAdditionalCostAndDeviation({
        //         additionalCost: itemDetails.discounts[0] ?? [],
        //         baseQuantity: itemDetails.quantity,
        //         baseRate: itemDetails.price,
        //     }).effectiveRateDeviation >= +itemDetails.price
        // ) {
        //     newErrors.discounts = {
        //         type: 'ERROR',
        //         fieldMessageText: 'Discount cannot exceed target rate',
        //         popupMessageText: 'Discount cannot exceed target rate',
        //         suppress: suppressErrors,
        //     };
        // } else {
        //     delete newErrors['discounts'];
        // }

        if (
            templateDetails.fieldSettings.standardFields[
                TemplateStandardFieldsEnum.DISCOUNT
            ].buyerSettings.required &&
            itemDetails.discounts[0].value === ''
        ) {
            newErrors.discounts = {
                type: 'ERROR',
                fieldMessageText: '',
                popupMessageText: 'Discount input required',
                suppress: suppressErrors,
            };
        } else if (
            itemDetails.price &&
            calculateTotalAdditionalCostAndDeviation({
                additionalCost: itemDetails.discounts[0] ?? [],
                baseQuantity: itemDetails.quantity,
                baseRate: itemDetails.price,
            }).effectiveRateDeviation >= +itemDetails.price
        ) {
            newErrors.discounts = {
                type: 'ERROR',
                fieldMessageText: 'Discount cannot exceed target rate',
                popupMessageText: 'Discount cannot exceed target rate',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['discounts'];
        }
    }
    if (itemDetails.paymentTerms.paymentType === 'poItemPercentages') {
        const list = itemDetails.paymentTerms.list;
        const seen: { [key: string]: number } = {};

        for (let i = 0; i < list.length; i++) {
            const temp = list[i];
            if (temp.milestoneType === 'DELIVERABLE') {
                const { deliverableName, terms, period } = temp;
                const itemKey = JSON.stringify({
                    deliverableName,
                    terms,
                    period,
                });

                if (seen[itemKey] !== undefined) {
                    newErrors.paymentTerms_poItem_allocationRows[i] = {
                        rowAllocationPercentage: {
                            type: 'ERROR',
                            fieldMessageText: 'error-terms',
                            popupMessageText:
                                'Same terms cannot be added. Combine or change terms',
                            suppress: suppressErrors,
                        },
                    };
                } else {
                    seen[itemKey] = i;
                }
            }
        }
        newErrors.paymentTerms_poItem_allocationRows =
            newErrors.paymentTerms_poItem_allocationRows.filter(
                (a) => Object.keys(a).length > 0
            );

        // return false;
    }
    return newErrors;
};

export const validateRFQItemDetailsForPO = ({
    field,
    itemDetails,
    currentErrors,
    preferredVendorCount,
    nonpreferredVendorCount,
    eventType,
    suppressErrors,
    templateDetails,
}: {
    field: keyof IEventItemValidation | 'ALL';
    itemDetails: ICustomEventItemDetails;
    currentErrors: IEventItemValidation;
    preferredVendorCount: number | null;
    nonpreferredVendorCount: number | null;
    eventType: 'DIRECT' | 'FROM_EVENT';
    suppressErrors: boolean;
    templateDetails: IRfqTemplate;
}): IEventItemValidation => {
    if (
        +itemDetails.po_item_approval_pending_quantity > 0 ||
        (itemDetails.po_status &&
            itemDetails.po_status !== 'PURCHASE_ORDER_PENDING')
    ) {
        return {
            deliveryScheduleRows: [],
            paymentTerms_poItem_allocationRows: [],
            customFields: {},
        };
    }

    let newErrors = cloneDeep(currentErrors);

    if (field === 'ALL') {
        newErrors = validateRFQItemCustomFields({
            currentErrors: newErrors,
            customFieldInfo: 'ALL',
            itemDetails,
            suppressErrors,
            templateDetails,
        });
    }

    if (
        field === 'ALL' ||
        field === 'quantity' ||
        field === 'deliveryScheduleRows' ||
        field === 'deliveryScheduleOverall' ||
        field === 'lead_time' ||
        field === 'lead_time_period'
    ) {
        if (
            typeof itemDetails.quantity !== 'number' &&
            isEmpty(itemDetails.quantity)
        ) {
            //Quantity is blank
            newErrors.quantity = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Quantity input required',
                suppress: suppressErrors,
            };
        } else if (+itemDetails.quantity <= 0) {
            newErrors.quantity = {
                //Quantity is 0
                type: 'ERROR',
                fieldMessageText: 'Must be greater than 0',
                popupMessageText: 'Invalid quantity',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['quantity'];
        }
        if (isEmpty(itemDetails.measurement_unit_id)) {
            //Measurement unit is blank
            newErrors.measurementUnit = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Measurement unit input required',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['measurementUnit'];
        }
        let totalDeliveryQuantity = 0;
        delete newErrors['deliveryScheduleOverall'];
        newErrors['deliveryScheduleRows'] = [];
        if (+itemDetails.quantity > 0) {
            for (let delivery of itemDetails.delivery_schedule) {
                let errorRow: {
                    quantity?: IEventItemFieldValidation;
                    date?: IEventItemFieldValidation;
                    deliveryScheduleRows?: IEventItemFieldValidation;
                    customerEntityId?: IEventItemFieldValidation;
                    cost_centre_id?: IEventItemFieldValidation;
                    project_id?: IEventItemFieldValidation;
                    general_ledger_id?: IEventItemFieldValidation;
                } = {};
                if (
                    typeof itemDetails.quantity !== 'number' &&
                    isEmpty(delivery.quantity)
                ) {
                    //Delivery quantity is blank
                    errorRow.quantity = {
                        type: 'ERROR',
                        fieldMessageText: 'Input required',
                        popupMessageText: '',
                        suppress: suppressErrors,
                    };
                    newErrors.deliveryScheduleOverall = {
                        type: 'ERROR',
                        fieldMessageText: '',
                        popupMessageText: 'Invalid delivery schedule',
                        suppress: suppressErrors,
                    };
                } else if (+delivery.quantity <= 0) {
                    //Delivery quantity is 0
                    errorRow.quantity = {
                        type: 'ERROR',
                        fieldMessageText: 'Must be greater than 0',
                        popupMessageText: '',
                        suppress: suppressErrors,
                    };
                    newErrors.deliveryScheduleOverall = {
                        type: 'ERROR',
                        fieldMessageText: '',
                        popupMessageText: 'Invalid delivery schedule',
                        suppress: suppressErrors,
                    };
                }

                if (
                    delivery.delivery_date !== null &&
                    !moment(
                        delivery.delivery_date,
                        'yyyy-MM-DD',
                        true
                    ).isValid()
                ) {
                    //Delivery date is blank or invalid (cannot differentiate these two cases in code)
                    errorRow.date = {
                        type: 'ERROR',
                        fieldMessageText: 'Invalid date',
                        popupMessageText: '',
                        suppress: suppressErrors,
                    };
                    newErrors.deliveryScheduleOverall = {
                        type: 'ERROR',
                        fieldMessageText: '',
                        popupMessageText: 'Invalid delivery schedule',
                        suppress: suppressErrors,
                    };
                } else if (
                    moment(delivery.delivery_date).isBefore(
                        moment().format('YYYY-MM-DD')
                    )
                ) {
                    errorRow.date = {
                        type: 'ERROR',
                        fieldMessageText: 'Past date cannot be entered',
                        popupMessageText: '',
                        suppress: suppressErrors,
                    };
                    newErrors.deliveryScheduleOverall = {
                        type: 'ERROR',
                        fieldMessageText: '',
                        popupMessageText: 'Invalid delivery schedule',
                        suppress: suppressErrors,
                    };
                }

                if (
                    templateDetails.fieldSettings.standardFields[
                        TemplateStandardFieldsEnum.PROJECT
                    ].buyerSettings.required &&
                    delivery.project_id === null
                ) {
                    errorRow.project_id = {
                        type: 'ERROR',
                        fieldMessageText: 'Project is required',
                        popupMessageText: 'Project is required',
                        suppress: suppressErrors,
                    };
                } else {
                    // if(errorRow.project_id){
                    //     delete errorRow['project_id']
                    // }
                }

                if (
                    templateDetails.fieldSettings.standardFields[
                        TemplateStandardFieldsEnum.PROJECT
                    ].buyerSettings.required &&
                    delivery.project_id === null
                ) {
                    errorRow.cost_centre_id = {
                        type: 'ERROR',
                        fieldMessageText: 'Cost-centre is required',
                        popupMessageText: 'Cost-centre is required',
                        suppress: suppressErrors,
                    };
                } else {
                    // if(errorRow.project_id){
                    //     delete errorRow['project_id']
                    // }
                }

                if (
                    templateDetails.fieldSettings.standardFields[
                        TemplateStandardFieldsEnum.PROJECT
                    ].buyerSettings.required &&
                    delivery.project_id === null
                ) {
                    errorRow.general_ledger_id = {
                        type: 'ERROR',
                        fieldMessageText: 'GL is required',
                        popupMessageText: 'GL is required',
                        suppress: suppressErrors,
                    };
                } else {
                    // if(errorRow.project_id){
                    //     delete errorRow['project_id']
                    // }
                }

                totalDeliveryQuantity += isNaN(+delivery.quantity)
                    ? 0
                    : +delivery.quantity;

                newErrors.deliveryScheduleRows.push(errorRow);
            }
        }
        if (
            templateDetails.fieldSettings.standardFields.DELIVERY_DATE
                .buyerSettings.required
        ) {
            if (field === 'ALL' || field === 'deliveryScheduleOverall') {
                if (
                    itemDetails.delivery_schedule.length === 0 ||
                    itemDetails.delivery_schedule.some(
                        (delivery) => delivery.delivery_date === null
                    )
                ) {
                    newErrors.deliveryScheduleOverall = {
                        type: 'ERROR',
                        fieldMessageText: 'Input required',
                        popupMessageText: 'Delivery date input required',
                        suppress: suppressErrors,
                    };
                }
            }
        }
        if (
            // Only perform this validation if delivery schedule is valid but quantity mismatches.
            !newErrors.deliveryScheduleOverall &&
            !isEmpty(itemDetails.quantity) &&
            totalDeliveryQuantity !== +itemDetails.quantity
        ) {
            //Total quantity does not match sum of delivery quantities
            newErrors.deliveryScheduleOverall = {
                type: 'ERROR',
                fieldMessageText: '',
                popupMessageText:
                    'Delivery schedule quantity does not match total desired quantity',
                suppress: suppressErrors,
            };
        }
    }
    if (field === 'ALL' || field === 'price') {
        if (
            typeof itemDetails.price !== 'number' &&
            isEmpty(itemDetails.price)
        ) {
            //Price is blank
            newErrors.price = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Target rate input required',
                suppress: suppressErrors,
            };
        } else if (+itemDetails.price <= 0) {
            //Price is 0
            newErrors.price = {
                type: 'ERROR',
                fieldMessageText: 'Must be greater than 0',
                popupMessageText: 'Invalid target rate',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['price'];
        }
    }
    if (
        templateDetails.fieldSettings.standardFields.GR_TOLERANCE.buyerSettings
            .showField &&
        templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.some(
            (field) => field.field === TemplateStandardFieldsEnum.GR_TOLERANCE
        ) &&
        (field === 'ALL' || field === 'quantity_tolerance_percentage')
    ) {
        if (+itemDetails.quantity_tolerance_percentage > 10) {
            newErrors.quantity_tolerance_percentage = {
                type: 'ERROR',
                fieldMessageText: 'Cannot exceed 10%',
                popupMessageText: 'GR tolerance cannot exceed 10%',
                suppress: suppressErrors,
            };
        } else if (
            templateDetails.fieldSettings.standardFields.GR_TOLERANCE
                .buyerSettings.required &&
            templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.some(
                (field) =>
                    field.field === TemplateStandardFieldsEnum.GR_TOLERANCE
            )
        ) {
            newErrors['quantity_tolerance_percentage'] = {
                type: 'ERROR',
                fieldMessageText: 'GR tolerance is required',
                popupMessageText: 'GR tolerance is required',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['quantity_tolerance_percentage'];
        }
    } else {
        delete newErrors['quantity_tolerance_percentage'];
    }
    if (
        templateDetails.fieldSettings.standardFields.SHIPPING_RATE.buyerSettings
            .showField &&
        (field === 'ALL' || field === 'shipping')
    ) {
        if (
            typeof itemDetails.quantity !== 'number' &&
            !isEmpty(itemDetails.shipping) &&
            isNaN(+itemDetails.shipping)
        ) {
            //Shipping is not a number
            newErrors.shipping = {
                type: 'ERROR',
                fieldMessageText: 'Invalid shipping rate',
                popupMessageText: 'Invalid shipping rate',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['shipping'];
        }
    } else {
        delete newErrors['shipping'];
    }

    const templatePaymentTermsOptions =
        templateDetails.fieldSettings.standardFields.PAYMENT_TERMS;
    const ptValidation = validatePaymentTerms({
        paymentTerms: itemDetails.paymentTerms!,
        prepaymentAllowed:
            templatePaymentTermsOptions.paymentTermOptions.prepaymentAllowed,
    });

    const paymentTermsErrorKeys: (keyof IEventItemValidation)[] = [
        'paymentTerms_prepayment',
        'paymentTerms_invoiceItem_terms',
        'paymentTerms_invoiceItem_period',
        'paymentTerms_invoiceItem_appliedFrom',
        'paymentTerms_poItem_overallPercentage',
        'paymentTerms_poItem_allocationRows',
    ];

    if (
        templateDetails.fieldSettings.standardFields[
            TemplateStandardFieldsEnum.PAYMENT_TERMS
        ].buyerSettings.showField &&
        templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.some(
            (field) => field.field === TemplateStandardFieldsEnum.PAYMENT_TERMS
        ) &&
        (field === 'ALL' || paymentTermsErrorKeys.includes(field))
    ) {
        paymentTermsErrorKeys.forEach((key) => {
            delete newErrors[key];
        });

        newErrors.paymentTerms_poItem_allocationRows = [];

        if (ptValidation.overallErrors.prepaymentBlank) {
            newErrors.paymentTerms_prepayment = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Prepayment input required',
                suppress: suppressErrors,
            };
        } else if (ptValidation.overallErrors.prepaymentAbove100) {
            newErrors.paymentTerms_prepayment = {
                type: 'ERROR',
                fieldMessageText: 'Cannot exceed 100%',
                popupMessageText: 'Invalid prepayment percentage',
                suppress: suppressErrors,
            };
        }
        if (ptValidation.overallErrors.termsBlank) {
            newErrors.paymentTerms_invoiceItem_terms = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Payment terms input required',
                suppress: suppressErrors,
            };
        }
        if (ptValidation.overallErrors.periodBlank) {
            newErrors.paymentTerms_invoiceItem_period = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Payment terms period input required',
                suppress: suppressErrors,
            };
        }
        if (ptValidation.overallErrors.appliedFromBlank) {
            newErrors.paymentTerms_invoiceItem_appliedFrom = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Payment terms reference date required',
                suppress: suppressErrors,
            };
        }
        if (ptValidation.overallErrors.allocationNot100) {
            const msg =
                +(itemDetails.paymentTerms?.prepaymentPercentage ?? '0') > 0
                    ? 'Allocations and prepayment must add up to 100%'
                    : 'Allocations must add up to 100%';
            newErrors.paymentTerms_poItem_overallPercentage = {
                type: 'ERROR',
                fieldMessageText: msg,
                popupMessageText: msg,
                suppress: suppressErrors,
            };
        }
        for (let rowIdx = 0; rowIdx < ptValidation.rowErrors.length; rowIdx++) {
            const curRowErrors: {
                rowAllocationPercentage?: IEventItemFieldValidation;
                rowTerms?: IEventItemFieldValidation;
                rowPeriod?: IEventItemFieldValidation;
                rowDeliverableName?: IEventItemFieldValidation;
                rowDate?: IEventItemFieldValidation;
            } = {};
            if (ptValidation.rowErrors[rowIdx].allocationBlank) {
                curRowErrors.rowAllocationPercentage = {
                    fieldMessageText: 'Input required',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            } else if (ptValidation.rowErrors[rowIdx].allocationOver100) {
                curRowErrors.rowAllocationPercentage = {
                    fieldMessageText: 'Cannot exceed 100',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            }
            if (ptValidation.rowErrors[rowIdx].dateBlank) {
                curRowErrors.rowDate = {
                    fieldMessageText: 'Input required',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            } else if (ptValidation.rowErrors[rowIdx].datePast) {
                curRowErrors.rowDate = {
                    fieldMessageText: 'Input invalid',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            }
            if (ptValidation.rowErrors[rowIdx].termsBlank) {
                curRowErrors.rowTerms = {
                    fieldMessageText: 'Input required',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            }
            if (ptValidation.rowErrors[rowIdx].periodBlank) {
                curRowErrors.rowPeriod = {
                    fieldMessageText: 'Input required',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            }
            if (ptValidation.rowErrors[rowIdx].deliverableBlank) {
                curRowErrors.rowDeliverableName = {
                    fieldMessageText: 'Input required',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            } else if (ptValidation.rowErrors[rowIdx].deliverableTooLong) {
                curRowErrors.rowDeliverableName = {
                    fieldMessageText: `Cannot exceed ${DELIVERABLE_LENGTH_LIMIT} characters`,
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            } else if (ptValidation.rowErrors[rowIdx].deliverableInvalid) {
                curRowErrors.rowDeliverableName = {
                    fieldMessageText: 'Input invalid',
                    popupMessageText: 'Payment terms invalid',
                    suppress: suppressErrors,
                    type: 'ERROR',
                };
            }

            newErrors.paymentTerms_poItem_allocationRows.push(curRowErrors);
        }
    }

    if (
        templateDetails.fieldSettings.standardFields.LEAD_TIME.buyerSettings
            .showField &&
        templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.some(
            (field) => field.field === TemplateStandardFieldsEnum.LEAD_TIME
        ) &&
        (field === 'ALL' ||
            field === 'lead_time_period' ||
            field === 'lead_time')
    ) {
        if (
            templateDetails.fieldSettings.standardFields.LEAD_TIME.buyerSettings
                .showField &&
            templateDetails.fieldSettings.standardFields.LEAD_TIME.buyerSettings
                .required &&
            typeof itemDetails.quantity !== 'number' &&
            isEmpty(itemDetails.lead_time)
        ) {
            newErrors.lead_time = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Lead time input required',
                suppress: suppressErrors,
            };
        } else if (isNaN(+itemDetails.lead_time)) {
            //Lead time is not a number
            newErrors.lead_time = {
                type: 'ERROR',
                fieldMessageText: 'Invalid lead time',
                popupMessageText: 'Invalid lead time',
                suppress: suppressErrors,
            };
        } else if (
            templateDetails.fieldSettings.standardFields.LEAD_TIME.buyerSettings
                .showField &&
            templateDetails.fieldSettings.standardFields.LEAD_TIME.buyerSettings
                .required &&
            +itemDetails.lead_time < 0
        ) {
            newErrors.lead_time = {
                type: 'ERROR',
                fieldMessageText: 'Lead time need to be greater then 0',
                popupMessageText: 'Lead time need to be greater then 0',
                suppress: suppressErrors,
            };
        } else if (
            templateDetails.fieldSettings.standardFields.LEAD_TIME.buyerSettings
                .showField &&
            itemDetails.lead_time_period === '' &&
            templateDetails.fieldSettings.standardFields.LEAD_TIME.buyerSettings
                .required
        ) {
            //Lead time period is blank
            newErrors.lead_time = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Lead time period input required',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['lead_time'];
        }
        if (
            itemDetails.lead_time_period !== '' &&
            !isNaN(+itemDetails.lead_time) &&
            leadTimeSurpassed(
                itemDetails.lead_time,
                itemDetails.lead_time_period
            )
        ) {
            newErrors.excessive_lead_time = {
                type: 'WARNING',
                fieldMessageText: '',
                popupMessageText: 'Lead time exceeds one year',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['excessive_lead_time'];
        }
    } else {
        delete newErrors['lead_time'];
    }
    if (
        templateDetails.fieldSettings.standardFields.INCOTERMS.buyerSettings
            .showField &&
        templateDetails.sections.PAYMENT_AND_DELIVERY_TERMS.fieldList.some(
            (field) => field.field === TemplateStandardFieldsEnum.INCOTERMS
        ) &&
        (field === 'ALL' || field === 'incoterm_id')
    ) {
        if (
            itemDetails.incoterm_id === '' &&
            templateDetails.fieldSettings.standardFields.INCOTERMS.buyerSettings
                .required
        ) {
            //Incoterm is blank
            newErrors.incoterm_id = {
                type: 'ERROR',
                fieldMessageText: 'Input required',
                popupMessageText: 'Incoterms input required',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['incoterm_id'];
        }
    } else {
        delete newErrors['incoterm_id'];
    }
    if (
        (field === 'ALL' || field === 'vendor_count') &&
        preferredVendorCount !== null &&
        nonpreferredVendorCount !== null
    ) {
        if (preferredVendorCount + nonpreferredVendorCount === 0) {
            //No of vendors is 0
            newErrors.vendor_count = {
                type: 'ERROR',
                fieldMessageText: '',
                popupMessageText: 'No vendors added',
                suppress: suppressErrors,
            };
        } else if (nonpreferredVendorCount) {
            //No of vendors is 0
            newErrors.vendor_count = {
                type: 'WARNING',
                fieldMessageText: '',
                popupMessageText: 'Non-preferred vendors added',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['vendor_count'];
        }
    }
    // specifications
    if (field === 'ALL' || field === 'specifications') {
        if (itemDetails.specifications.custom_item_attributes.length > 0) {
            // non standard specification(s) added
            newErrors.specifications = {
                type: 'WARNING',
                fieldMessageText: '',
                popupMessageText:
                    itemDetails.specifications.custom_item_attributes.length > 1
                        ? `Item contains ${itemDetails.specifications.custom_item_attributes.length} non-standard specifications`
                        : `Item contains a non-standard specification`,

                suppress: suppressErrors,
            };
        } else {
            delete newErrors['specifications'];
        }
    }

    // requisition
    if (
        templateDetails.fieldSettings.standardFields.REQUISITION.buyerSettings
            .showField &&
        templateDetails.sections.ADDITIONAL_DETAILS.fieldList.some(
            (field) => field.field === TemplateStandardFieldsEnum.REQUISITION
        ) &&
        (field === 'ALL' ||
            field === 'requisition_number' ||
            field === 'requisition_approval')
    ) {
        if (
            itemDetails.requisition_number.length > 0 &&
            templateDetails.fieldSettings.standardFields.REQUISITION
                .buyerSettings.required &&
            !itemDetails.requisition_approved
        ) {
            // requisition is not approved
            newErrors.requisition_approval = {
                type: 'WARNING',
                fieldMessageText: 'Requisition not approved',
                popupMessageText: 'Requisition not approved',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['requisition_approval'];
        }

        if (
            templateDetails.fieldSettings.standardFields.REQUISITION
                .buyerSettings.required &&
            itemDetails.requisition_number?.trim() === ''
        ) {
            newErrors['requisition_number'] = {
                type: 'ERROR',
                fieldMessageText: 'Requisition is required',
                popupMessageText: 'Requisition is required',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['requisition_number'];
        }
    }

    if (
        templateDetails.fieldSettings.standardFields.ADDITIONAL_INFORMATION
            .buyerSettings.showField &&
        templateDetails.fieldSettings.standardFields.ADDITIONAL_INFORMATION
            .buyerSettings.required &&
        templateDetails.sections.ADDITIONAL_DETAILS.fieldList.some(
            (field) =>
                field.field ===
                TemplateStandardFieldsEnum.ADDITIONAL_INFORMATION
        ) &&
        itemDetails.item_additional_information === ''
    ) {
        newErrors.item_additional_information = {
            type: 'ERROR',
            fieldMessageText: 'Input required',
            popupMessageText: 'Input required',
            suppress: suppressErrors,
        };
    } else {
        delete newErrors['item_additional_information'];
    }

    if (
        templateDetails.fieldSettings.standardFields.TAX.buyerSettings
            .showField &&
        (field === 'ALL' || field === 'additional_charges')
    ) {
        const taxNames = itemDetails.additional_charges.map((charge) =>
            charge.charge_name.trim()
        );
        const duplicateNamesExist = new Set(taxNames).size !== taxNames.length;
        if (duplicateNamesExist) {
            //Duplicate tax names (warning)
            newErrors.additional_charges = {
                type: 'WARNING',
                fieldMessageText: 'Taxes may contain duplicates',
                popupMessageText: 'Taxes may contain duplicates',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['additional_charges'];
        }
    }

    if (
        templateDetails.fieldSettings.standardFields.ADDITIONAL_COSTS
            .buyerSettings.showField &&
        templateDetails.sections.ESSENTIAL_TERMS.fieldList.some(
            (field) =>
                field.field === TemplateStandardFieldsEnum.ADDITIONAL_COSTS
        ) &&
        (field === 'ALL' || field === 'additional_cost')
    ) {
        const requiredAdditionalCost =
            templateDetails.fieldSettings.standardFields.ADDITIONAL_COSTS.additionalCostOptions.filter(
                (opt) => opt.is_required
            );
        if (requiredAdditionalCost.length > 0) {
            const isThereError = requiredAdditionalCost.reduce(
                (acc, additionalCostopt) => {
                    const foundItemAdditionalCost =
                        itemDetails.additional_costs.find(
                            (costAdditional) =>
                                costAdditional.costName.trim() ===
                                additionalCostopt.costName.trim()
                        );

                    if (foundItemAdditionalCost) {
                        if (
                            typeof foundItemAdditionalCost.value === 'string' &&
                            foundItemAdditionalCost.value.trim() === ''
                        ) {
                            return acc || true;
                        } else {
                            return acc || false;
                        }
                    } else {
                        return acc || true;
                    }
                },
                false
            );
            if (isThereError) {
                newErrors.additional_cost = {
                    type: 'ERROR',
                    fieldMessageText: 'Additional cost is required',
                    popupMessageText: 'Additional cost is required',
                    suppress: suppressErrors,
                };
            } else {
                delete newErrors['additional_cost'];
            }
        } else {
            delete newErrors['additional_cost'];
        }
    }
    if (
        templateDetails.fieldSettings.standardFields.TAX.buyerSettings
            .showField &&
        templateDetails.sections.ESSENTIAL_TERMS.fieldList.some(
            (field) => field.field === TemplateStandardFieldsEnum.DISCOUNT
        ) &&
        (field === 'ALL' || field === 'taxes')
    ) {
        const requiredAdditionalCost =
            templateDetails.fieldSettings.standardFields.TAX.taxesOptions.filter(
                (opt) => opt.is_required
            );
        if (requiredAdditionalCost.length > 0) {
            const isThereError = requiredAdditionalCost.reduce(
                (acc, additionalCostTaxopt) => {
                    const foundItemTaxes = itemDetails.taxes.find(
                        (cost) =>
                            cost.costName.trim() ===
                            additionalCostTaxopt.costName.trim()
                    );

                    if (foundItemTaxes) {
                        if (
                            typeof foundItemTaxes.value === 'string' &&
                            foundItemTaxes.value.trim() === ''
                        ) {
                            return acc || true;
                        } else {
                            return acc || false;
                        }
                    } else {
                        return acc || true;
                    }
                },
                false
            );
            if (isThereError) {
                newErrors.taxes = {
                    type: 'ERROR',
                    fieldMessageText: 'Taxes is required',
                    popupMessageText: 'Taxes is required',
                    suppress: suppressErrors,
                };
            } else {
                delete newErrors['taxes'];
            }
        } else {
            delete newErrors['taxes'];
        }
    }

    if (
        templateDetails.fieldSettings.standardFields.DISCOUNT.buyerSettings
            .showField &&
        templateDetails.sections.ESSENTIAL_TERMS.fieldList.some(
            (field) => field.field === TemplateStandardFieldsEnum.DISCOUNT
        ) &&
        (field === 'ALL' || field === 'discounts' || field === 'price')
    ) {
        const requiredDiscount =
            templateDetails.fieldSettings.standardFields.DISCOUNT.discountOptions.filter(
                (opt) => opt.is_required
            );
        if (requiredDiscount.length > 0) {
            const isThereError = requiredDiscount.reduce(
                (acc, additionalCostDiscountopt) => {
                    const foundItemDiscount = itemDetails.discounts.find(
                        (costDiscount) =>
                            costDiscount.costName.trim() ===
                            additionalCostDiscountopt.costName.trim()
                    );
                    if (foundItemDiscount) {
                        if (
                            typeof foundItemDiscount.value === 'string' &&
                            foundItemDiscount.value.trim() === ''
                        ) {
                            return acc || true;
                        } else {
                            return acc || false;
                        }
                    } else {
                        return acc || true;
                    }
                },
                false
            );
            if (isThereError) {
                newErrors.discounts = {
                    type: 'ERROR',
                    fieldMessageText: 'Discount is required',
                    popupMessageText: 'Discount is required',
                    suppress: suppressErrors,
                };
            } else {
                delete newErrors['discounts'];
            }
        } else {
            delete newErrors['discounts'];
        }

        if (
            itemDetails.price &&
            calculateTotalAdditionalCostAndDeviation({
                additionalCost: itemDetails.discounts[0] ?? [],
                baseQuantity: itemDetails.quantity,
                baseRate: itemDetails.price,
            }).effectiveRateDeviation >= +itemDetails.price
        ) {
            newErrors.discounts = {
                type: 'ERROR',
                fieldMessageText: 'Discount cannot exceed target rate',
                popupMessageText: 'Discount cannot exceed target rate',
                suppress: suppressErrors,
            };
        } else {
            delete newErrors['discounts'];
        }
    }

    return newErrors;
};

export const findWarningForContract = ({
    data,
    errors,
    handleSelectionContractPopUp,
    listOfContracts,
    noOfSelectedVendorWithContract,
    listOfVendorContracts,
    selectNewContract,
}: {
    data: ICustomEventItemDetails;
    errors: IEventItemValidation;
    listOfContracts: IGetContractDetailsForItem[];
    handleSelectionContractPopUp: (contractUid: string) => void;
    selectNewContract?: (contractUid: string) => void;
    noOfSelectedVendorWithContract: number;
    listOfVendorContracts: IGetContractDetailsForItem[];
}): IEventItemValidation => {
    const newErrors = { ...errors };

    listOfContracts.sort((a, b) => {
        return +a.contract_items[0].rate - +b.contract_items[0].rate;
    });

    let listOfCheapestContractThenPrise = listOfContracts
        .filter((contract) => +contract.contract_items[0].rate > 0)
        .filter((contract) => +data.price > +contract.contract_items[0].rate);

    if (listOfCheapestContractThenPrise.length > 0) {
        const HelperComponent: () => React.ReactElement = () => {
            return (
                <Grid container>
                    {listOfCheapestContractThenPrise.map((contract) => (
                        <Grid item xs={12}>
                            <div className="flex flex--aic">
                                <FWLink
                                    onClick={() =>
                                        handleSelectionContractPopUp(
                                            contract.contract_id
                                        )
                                    }
                                >
                                    <FWTypography>
                                        {contract.contract_name +
                                            ` (${
                                                contract.seller_entity_details
                                                    .seller_entity_name ?? ''
                                            })`}
                                    </FWTypography>
                                </FWLink>
                                <FWIconButton
                                    sx={{
                                        height: '16px',
                                    }}
                                    onClick={() => {
                                        window.open(
                                            `${window.origin}/buyer/CLM/template/${contract.additional_details.template_id}/contract/${contract.contract_id}/review`,
                                            '_blank',
                                            'noreferrer'
                                        );
                                    }}
                                >
                                    <i
                                        style={{
                                            fontSize: '16px',
                                            marginBottom: '-8px',
                                        }}
                                        className="bi bi-box-arrow-up-right"
                                    />
                                </FWIconButton>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            );
        };

        newErrors.cheaperContractAvailable = {
            type: 'WARNING',
            fieldMessageText: 'Cheaper contract available',
            popupMessageText: 'Cheaper contract available',
            suppress: false,
            HelperComponent,
        };
    } else {
        delete newErrors['cheaperContractAvailable'];
    }

    if (
        listOfVendorContracts.length > 0 &&
        noOfSelectedVendorWithContract < 1
    ) {
        const HelperComponent = () => {
            return (
                <Grid container xs={12}>
                    {listOfVendorContracts.map((contract) => (
                        <Grid item xs={12}>
                            {/* <ContractDetailPopup
                                    contractDetails={contract}
                                    currencyCodeSymbol="$"
                                    handleUseContract={() => {
                                        if (selectNewContract)
                                            selectNewContract(
                                                contract.contract_id
                                            );
                                    }}
                                    text={
                                        contract.contract_name +
                                        ` (${
                                            contract
                                                .contract.seller_entity_details
                                        .seller_entity_name ??
                                            ''
                                        })`
                                    }
                                /> */}
                            <div className="flex flex--aic">
                                <FWLink
                                    onClick={() =>
                                        handleSelectionContractPopUp(
                                            contract.contract_id
                                        )
                                    }
                                >
                                    <FWTypography>
                                        {contract.contract_name +
                                            ` (${
                                                contract.seller_entity_details
                                                    .seller_entity_name ?? ''
                                            })`}
                                    </FWTypography>
                                </FWLink>
                                <FWIconButton
                                    sx={{
                                        height: '16px',
                                    }}
                                    onClick={() => {
                                        window.open(
                                            `${window.origin}/buyer/CLM/template/${contract.additional_details.template_id}/contract/${contract.contract_id}/review`,
                                            '_blank',
                                            'noreferrer'
                                        );
                                    }}
                                >
                                    <Box>
                                        <i
                                            style={{
                                                fontSize: '16px',
                                                // marginBottom:
                                                //     '-8px',
                                            }}
                                            className="bi bi-box-arrow-up-right"
                                        />
                                    </Box>
                                </FWIconButton>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            );
        };

        newErrors.noContractVendorSelected = {
            type: 'WARNING',
            fieldMessageText: 'Vendor without a contract is selected',
            popupMessageText: 'Vendor without a contract is selected',
            suppress: false,
            HelperComponent,
        };
    } else {
        delete newErrors['noContractVendorSelected'];
    }

    return newErrors;
};

export const StyledRFQItemNumberDiv = styled(Box)({
    '&': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    '& .itemBoxHeaderNumber': {
        position: 'absolute',
        fontSize: '1.5rem',
        top: '-2rem',
        backgroundColor: 'white',
    },
    '& .itemBoxHeaderName': {
        fontWeight: '500',
        fontSize: '24px',
        zIndex: 1,
    },
});

export const rfqDetailsErrorExists = (
    errors: ICustomEventDetailsErrors
): boolean => {
    if (
        errors.event_name?.type === 'ERROR' ||
        errors.event_deadline?.type === 'ERROR' ||
        errors.entity_id?.type === 'ERROR' ||
        errors.template_id?.type === 'ERROR' ||
        errors.currency_id?.type === 'ERROR' ||
        errors.billing_id?.type === 'ERROR' ||
        errors.shipping_id?.type === 'ERROR' ||
        errors.lead_time?.type === 'ERROR' ||
        errors.lead_time_period?.type === 'ERROR' ||
        errors.delivery_date?.type === 'ERROR' ||
        errors.deliveryScheduleOverall?.type === 'ERROR' ||
        errors.paymentTerms_prepayment?.type === 'ERROR' ||
        errors.paymentTerms_invoiceItem_terms?.type === 'ERROR' ||
        errors.paymentTerms_invoiceItem_period?.type === 'ERROR' ||
        errors.paymentTerms_invoiceItem_appliedFrom?.type === 'ERROR' ||
        errors.paymentTerms_poItem_overallPercentage?.type === 'ERROR'
    ) {
        return true;
    }
    for (let row of errors.paymentTerms_poItem_allocationRows) {
        if (
            row.rowAllocationPercentage?.type === 'ERROR' ||
            row.rowTerms?.type === 'ERROR' ||
            row.rowPeriod?.type === 'ERROR' ||
            row.rowDeliverableName?.type === 'ERROR' ||
            row.rowDate?.type === 'ERROR'
        ) {
            return true;
        }
    }
    return false;
};

const NO_ITEM_TO_BE_CALLED_IN_BACKGROUND = RFQ_ITEM_PER_PAGE * 2;

export const useCacheAllThePagesForEvents = (
    rfq_id: string,
    entity_id: string,
    type: 'DIRECT' | 'FROM_EVENT',
    rfqTemplate: IRfqTemplate | null
) => {
    const dipatch = useDispatch();

    const currentPage = useSelector(
        (state: IGlobalAppStore) =>
            state.EventCreationStore.cacheSetting.curentPage
    );

    const setCurrentPage = useCallback(
        (callback: number | ((prev: number) => number)) => {
            dipatch(
                updateEventDataStore({
                    type: 'UPDATE_CACHING_SETTING_PAGE',
                    value:
                        typeof callback === 'number'
                            ? callback
                            : callback(currentPage),
                })
            );
        },
        [currentPage, dipatch]
    );

    const arePoAvailable = useSelector(
        (store: IGlobalAppStore) => store.EventCreationStore.arePoAvailable,
        shallowEqual
    );
    const showWarnings = useSelector(
        (store: IGlobalAppStore) =>
            store.EventCreationStore.eventDetails?.showWarnings ?? false,
        shallowEqual
    );

    // const { data, fulfilledTimeStamp } = useGetListOfRfqItemPaginatedQueryQuery(
    // {
    //     ...(type === 'FROM_EVENT'
    //         ? {
    //               body: {
    //                   dashboard_view: 'rfq_event_item_buyer',
    //                   items_per_page: NO_ITEM_TO_BE_CALLED_IN_BACKGROUND,
    //                   page_number: currentPage,
    //                   query_data: {
    //                       rfq_event_id: rfq_id,
    //                   },
    //                   search_text: '',
    //                   sort_fields: [],
    //                   tab: 'all',
    //               },
    //               otherInfoRequired: {
    //                   entity_id: entity_id ?? '',
    //                   type,
    //                   poCount: arePoAvailable && showWarnings ? 1 : 0,
    //                   searchQueryType: 'ITEMS',
    //               },
    //           }
    //         : {
    //               body: {
    //                   dashboard_view: 'po_group_item',
    //                   items_per_page: NO_ITEM_TO_BE_CALLED_IN_BACKGROUND,
    //                   page_number: currentPage,
    //                   query_data: {
    //                       po_group_id: rfq_id,
    //                   },
    //                   search_text: '',
    //                   sort_fields: [],
    //                   tab: 'all',
    //               },
    //               otherInfoRequired: {
    //                   entity_id: entity_id ?? '',
    //                   type,
    //                   poCount: arePoAvailable && showWarnings ? 1 : 0,
    //                   searchQueryType: 'ITEMS',
    //               },
    //           }),
    // },
    //     {
    //         skip:
    //             rfq_id.trim() === '' ||
    //             entity_id.trim() === '' ||
    //             rfqTemplate === null,
    //         // refetchOnFocus: false,

    //         refetchOnMountOrArgChange: 0,
    //     }
    // );

    const [fetchData] = useLazyGetListOfRfqItemPaginatedQueryQuery();

    const {
        data: listOfSellerForAllItemsRFQ,
        isFetching: isFetchingRfqSeller,
    } = useGetListOfAllSellersForAllRfqItemsQuery(
        {
            rfq_id: rfq_id ? rfq_id : '',
        },
        {
            skip: rfq_id === null || rfq_id === '' || type === 'DIRECT',
        }
    );

    const {
        data: listOfSellerForAllItemD2PO,
        isFetching: isFetchingD2OPSeller,
    } = useGetListOfAllSellersForAllD2POItemsQuery(
        {
            po_group_id: rfq_id ? rfq_id : '',
        },
        {
            skip: rfq_id === null || rfq_id === '' || type === 'FROM_EVENT',
        }
    );

    const listOfSellerForAllItems = useMemo(() => {
        if (type === 'FROM_EVENT') return listOfSellerForAllItemsRFQ;
        else return listOfSellerForAllItemD2PO;
    }, [listOfSellerForAllItemD2PO, listOfSellerForAllItemsRFQ, type]);

    useEffect(() => {
        // if (data && rfqTemplate && listOfSellerForAllItems) {
        //     const itemDetails = data.data.listOfItems.map((item) => {
        //         return {
        //             ...translateRFQItemDetailsToCustomDetailsWithTemplates(
        //                 item,
        //                 rfqTemplate,
        //                 data.data.allItemTags[item.rfq_item_entry_id]
        //             ),
        //             rfq_item_entry_id: item.rfq_item_entry_id,
        //         };
        //     });

        //     const errors = itemDetails.map((item, idx) => ({
        //         id: item.rfq_item_entry_id,
        //         item,
        //         error: validateRFQItemDetails({
        //             currentErrors: {
        //                 customFields: {},
        //                 deliveryScheduleRows: [],
        //                 paymentTerms_poItem_allocationRows: [],
        //             },
        //             eventType: type,
        //             field: 'ALL',
        //             itemDetails: item,
        //             nonpreferredVendorCount: 0,
        //             preferredVendorCount:
        //                 listOfSellerForAllItems[item.rfq_item_entry_id]
        //                     ?.length ?? 0,
        //             suppressErrors: false,
        //             templateDetails: rfqTemplate,
        //         }),
        //         itemNo:
        //             (data.metadata.current_page - 1) * RFQ_ITEM_PER_PAGE +
        //             (idx + 1),
        //     }));

        //     const dictOfBoms: {
        //         [itemUid: string]: IBomItemDeliverScheduleStructure[];
        //     } = itemDetails.reduce(
        //         (acc, next) => {
        //             console.log(
        //                 'next.delivery_schedulenext.delivery_schedulenext.delivery_schedule',
        //                 next.delivery_schedule
        //             );

        //             if (
        //                 next.delivery_schedule.some(
        //                     (ds) => ds.bom_item !== null
        //                 )
        //             ) {
        //                 return {
        //                     ...acc,
        //                     [next.rfq_item_entry_id]: next.delivery_schedule
        //                         .filter((ds) => ds.bom_item !== null)
        //                         .map(
        //                             (ds) =>
        //                                 ds.bom_item as IBomItemDeliverScheduleStructure
        //                         ),
        //                 };
        //             }

        //             return acc;
        //         },
        //         {} as {
        //             [itemUid: string]: IBomItemDeliverScheduleStructure[];
        //         }
        //     );

        //

        //     // update current event item reedux store information
        //     batch(() => {
        //         dipatch(
        //             updateEventDataStore({
        //                 type: 'UPDATE_CACHE_ERRORS',
        //                 value: {
        //                     type_of_action: 'UPDATE_MULTIPLE_ITEM',
        //                     listOfItems: errors.map((error, idx) => ({
        //                         rfq_item_entery_id: error.id,
        //                         noOfVendorsSelected:
        //                             listOfSellerForAllItems[error.id]?.length ??
        //                             0,
        //                         pageNoItem: data.metadata.current_page,
        //                         validity: eventItemErrorExists(error.error)
        //                             ? 'INVALID'
        //                             : 'VALID',
        //                         itemNumber:
        //                             (data.metadata.current_page - 1) *
        //                                 RFQ_ITEM_PER_PAGE +
        //                             (idx + 1),
        //                         itemDetails: error.item,
        //                         itemNo: error.itemNo,
        //                     })),
        //                 },
        //             })
        //         );
        //         dipatch(
        //             updateEventDataStore({
        //                 type: 'UPDATE_ALL_PO_ITEMS_ARE_FULL_fILLED',
        //                 value: data.data.all_po_items_are_fullfilled,
        //             })
        //         );
        //         dipatch(
        //             updateEventDataStore({
        //                 type: 'UPDATE_DICT_OF_BOM',
        //                 value: dictOfBoms,
        //             })
        //         );
        //         dipatch(
        //             updateEventDataStore({
        //                 type: 'UPDATE_PO_ITEMS_GROUPED_BY_ITEM_ENTRY',
        //                 value: { ...data.data.poItemsGroupedByItemEntry },
        //             })
        //         );
        //         dipatch(
        //             updateEventDataStore({
        //                 type: 'UPDATE_LAST_POS_FOR_ITEMS',
        //                 value: { ...data.data.lastPoItems },
        //             })
        //         );
        //     });
        //     // update the current page details

        //     let argfor: ArgumentsForPaginationApiCall;
        //     let argfor2: ArgumentsForPaginationApiCall;

        //     if (type === 'FROM_EVENT') {
        //         argfor = {
        //             body: {
        //                 dashboard_view: 'rfq_event_item_buyer',
        //                 items_per_page: RFQ_ITEM_PER_PAGE,
        //                 page_number: data.metadata.current_page * 2 - 1,
        //                 query_data: {
        //                     rfq_event_id: rfq_id,
        //                 },
        //                 search_text: '',
        //                 sort_fields: [],
        //                 tab: 'all',
        //             },
        //             otherInfoRequired: {
        //                 entity_id: entity_id,
        //                 poCount:
        //                     store.getState().EventCreationStore
        //                         .arePoAvailable && showWarnings
        //                         ? 1
        //                         : 0,
        //                 type: 'FROM_EVENT',
        //                 searchQueryType: 'ITEMS',
        //             },
        //         };
        //         argfor2 = {
        //             body: {
        //                 dashboard_view: 'rfq_event_item_buyer',
        //                 items_per_page: RFQ_ITEM_PER_PAGE,
        //                 page_number: data.metadata.current_page * 2,
        //                 query_data: {
        //                     rfq_event_id: rfq_id,
        //                 },
        //                 search_text: '',
        //                 sort_fields: [],
        //                 tab: 'all',
        //             },
        //             otherInfoRequired: {
        //                 entity_id: entity_id,
        //                 poCount:
        //                     store.getState().EventCreationStore
        //                         .arePoAvailable && showWarnings
        //                         ? 1
        //                         : 0,
        //                 type: 'FROM_EVENT',
        //                 searchQueryType: 'ITEMS',
        //             },
        //         };
        //     } else {
        //         argfor = {
        //             body: {
        //                 dashboard_view: 'po_group_item',
        //                 items_per_page: RFQ_ITEM_PER_PAGE,
        //                 page_number: data.metadata.current_page * 2 - 1,
        //                 query_data: {
        //                     po_group_id: rfq_id,
        //                 },
        //                 search_text: '',
        //                 sort_fields: [],
        //                 tab: 'all',
        //             },
        //             otherInfoRequired: {
        //                 entity_id: entity_id,
        //                 poCount:
        //                     store.getState().EventCreationStore
        //                         .arePoAvailable && showWarnings
        //                         ? 1
        //                         : 0,
        //                 type: 'DIRECT',
        //                 searchQueryType: 'ITEMS',
        //             },
        //         };
        //         argfor2 = {
        //             body: {
        //                 dashboard_view: 'po_group_item',
        //                 items_per_page: RFQ_ITEM_PER_PAGE,
        //                 page_number: data.metadata.current_page * 2,
        //                 query_data: {
        //                     po_group_id: rfq_id,
        //                 },
        //                 search_text: '',
        //                 sort_fields: [],
        //                 tab: 'all',
        //             },
        //             otherInfoRequired: {
        //                 entity_id: entity_id,
        //                 poCount:
        //                     store.getState().EventCreationStore
        //                         .arePoAvailable && showWarnings
        //                         ? 1
        //                         : 0,
        //                 type: 'DIRECT',
        //                 searchQueryType: 'ITEMS',
        //             },
        //         };
        //     }

        //     const patches1 = RFQPaginatedApiSlice.util.upsertQueryData(
        //         'getListOfRfqItemPaginatedQuery',
        //         argfor,
        //         {
        //             counts: {
        //                 all: data.counts.all,
        //             },
        //             data: {
        //                 all_po_items_are_fullfilled:
        //                     data.data.all_po_items_are_fullfilled,
        //                 allItemTags: data.data.allItemTags,
        //                 lastPoItems: data.data.lastPoItems,
        //                 listOfItems: cloneDeep(data.data.listOfItems).splice(
        //                     0,
        //                     10
        //                 ),
        //                 measurementUnits: data.data.measurementUnits,
        //                 poItemsGroupedByItemEntry:
        //                     data.data.poItemsGroupedByItemEntry,
        //             },
        //             metadata: {
        //                 count: data.metadata.count,
        //                 current_page: data.metadata.current_page * 2 - 1,
        //                 has_next:
        //                     data.metadata.has_next ||
        //                     data.data.listOfItems.length > 10,
        //                 has_previous: data.metadata.has_previous,
        //                 page_range: {
        //                     start: data.metadata.page_range.start,
        //                     stop: data.metadata.page_range.stop * 2,
        //                 },
        //                 total_pages: Math.ceil(
        //                     data.counts.all / RFQ_ITEM_PER_PAGE
        //                 ),
        //             },
        //         }
        //     );
        //     const patches2 = RFQPaginatedApiSlice.util.upsertQueryData(
        //         'getListOfRfqItemPaginatedQuery',
        //         argfor2,
        //         {
        //             counts: {
        //                 all: data.counts.all,
        //             },
        //             data: {
        //                 all_po_items_are_fullfilled:
        //                     data.data.all_po_items_are_fullfilled,
        //                 allItemTags: data.data.allItemTags,
        //                 lastPoItems: data.data.lastPoItems,
        //                 listOfItems: cloneDeep(data.data.listOfItems).splice(
        //                     10,
        //                     20
        //                 ),
        //                 measurementUnits: data.data.measurementUnits,
        //                 poItemsGroupedByItemEntry:
        //                     data.data.poItemsGroupedByItemEntry,
        //             },
        //             metadata: {
        //                 count: data.metadata.count,
        //                 current_page: data.metadata.current_page * 2,
        //                 has_next:
        //                     data.metadata.has_next ||
        //                     data.data.listOfItems.length === 20,
        //                 has_previous: data.metadata.has_previous,
        //                 page_range: {
        //                     start: data.metadata.page_range.start,
        //                     stop: data.metadata.page_range.stop * 2,
        //                 },
        //                 total_pages: Math.ceil(
        //                     data.counts.all / RFQ_ITEM_PER_PAGE
        //                 ),
        //             },
        //         }
        //     );

        //     batch(() => {
        //         dipatch(patches1 as any);
        //         dipatch(patches2 as any);
        //     });

        //     setCurrentPage((prev) => {
        //         if (prev <= data.metadata.total_pages) {
        //             prev += 1;
        //         } else {
        //             dipatch(
        //                 updateEventDataStore({
        //                     type: 'UPDATE_CACHING_SETTING_LOADING',
        //                     value: false,
        //                 })
        //             );
        //         }

        //         return prev;
        //     });
        // }

        fetchData({
            ...(type === 'FROM_EVENT'
                ? {
                      body: {
                          dashboard_view: 'rfq_event_item_buyer',
                          items_per_page: NO_ITEM_TO_BE_CALLED_IN_BACKGROUND,
                          page_number: currentPage,
                          query_data: {
                              rfq_event_id: rfq_id,
                          },
                          search_text: '',
                          sort_fields: [],
                          tab: 'all',
                      },
                      otherInfoRequired: {
                          entity_id: entity_id ?? '',
                          type,
                          poCount: arePoAvailable && showWarnings ? 1 : 0,
                          searchQueryType: 'ITEMS',
                      },
                  }
                : {
                      body: {
                          dashboard_view: 'po_group_item',
                          items_per_page: NO_ITEM_TO_BE_CALLED_IN_BACKGROUND,
                          page_number: currentPage,
                          query_data: {
                              po_group_id: rfq_id,
                          },
                          search_text: '',
                          sort_fields: [],
                          tab: 'all',
                      },
                      otherInfoRequired: {
                          entity_id: entity_id ?? '',
                          type,
                          poCount: arePoAvailable && showWarnings ? 1 : 0,
                          searchQueryType: 'ITEMS',
                      },
                  }),
        })
            .unwrap()
            .then((data) => {
                if (data && rfqTemplate && listOfSellerForAllItems) {
                    const itemDetails = data.data.listOfItems.map((item) => {
                        return {
                            ...translateRFQItemDetailsToCustomDetailsWithTemplates(
                                item,
                                rfqTemplate,
                                data.data.allItemTags[item.rfq_item_entry_id]
                            ),
                            rfq_item_entry_id: item.rfq_item_entry_id,
                        };
                    });

                    const errors = itemDetails.map((item, idx) => ({
                        id: item.rfq_item_entry_id,
                        item,
                        error: validateRFQItemDetails({
                            currentErrors: {
                                customFields: {},
                                deliveryScheduleRows: [],
                                paymentTerms_poItem_allocationRows: [],
                            },
                            eventType: type,
                            field: 'ALL',
                            itemDetails: item,
                            nonpreferredVendorCount: 0,
                            preferredVendorCount:
                                listOfSellerForAllItems[item.rfq_item_entry_id]
                                    ?.length ?? 0,
                            suppressErrors: false,
                            templateDetails: rfqTemplate,
                        }),
                        itemNo:
                            (data.metadata.current_page - 1) *
                                RFQ_ITEM_PER_PAGE +
                            (idx + 1),
                    }));

                    const dictOfBoms: {
                        [itemUid: string]: IBomItemDeliverScheduleStructure[];
                    } = itemDetails.reduce(
                        (acc, next) => {
                            if (
                                next.delivery_schedule.some(
                                    (ds) =>
                                        ds.bom_item !== null &&
                                        ds.bom_item !== undefined
                                )
                            ) {
                                return {
                                    ...acc,
                                    [next.rfq_item_entry_id]:
                                        next.delivery_schedule
                                            .filter(
                                                (ds) =>
                                                    ds.bom_item !== null &&
                                                    ds.bom_item !== undefined
                                            )
                                            .map(
                                                (ds) =>
                                                    ds.bom_item as IBomItemDeliverScheduleStructure
                                            ),
                                };
                            }

                            return acc;
                        },
                        {} as {
                            [
                                itemUid: string
                            ]: IBomItemDeliverScheduleStructure[];
                        }
                    );

                    const bomMap: {
                        [bomid: string]: IRfqAnalyticsStoreListOfBomStruct;
                    } = {};

                    const obj2: IRfqAnalyticsStoreListOfBomStructWIthHeiriacy[] =
                        cloneDeep(
                            store.getState().EventCreationStore
                                .listOfBomsOfCurrentEventsWithSubBomOptions
                        );

                    const bomInfo: (IBomItemDeliverScheduleStructure & {
                        listOfRfqItemIds: string[];
                    })[] = [];

                    for (let item of itemDetails) {
                        for (let ds of item.delivery_schedule) {
                            if (ds.bom_item?.enterprise_bom?.entry_id) {
                                bomMap[ds.bom_item.enterprise_bom.entry_id] = {
                                    bom_code:
                                        ds.bom_item.enterprise_bom.bom_code +
                                        ` (${
                                            ds.bom_item.enterprise_bom
                                                ?.quantity ?? 0
                                        } ${
                                            ds.bom_item.enterprise_bom
                                                ?.measurement_unit_abbreviation ??
                                            ''
                                        })`,
                                    enterprise_bom_id:
                                        ds.bom_item.enterprise_bom.entry_id,
                                    listOfRfqItemIds: [item.rfq_item_id],
                                };
                            }
                            if (
                                ds.bom_item &&
                                bomInfo.find((bom) => {
                                    const temp1: any = cloneDeep(bom);
                                    const temp2: any = cloneDeep(ds.bom_item);

                                    delete temp1['listOfRfqItemIds'];

                                    delete temp1['entry_id'];
                                    delete temp2['entry_id'];

                                    removeIndexFromEveryWhere(temp1);
                                    removeIndexFromEveryWhere(temp2);

                                    removeQuantityFromEveryWhere(temp1);
                                    removeQuantityFromEveryWhere(temp2);

                                    return isEqual(temp1, temp2);
                                }) === undefined
                            ) {
                                bomInfo.push({
                                    ...ds.bom_item,
                                    listOfRfqItemIds: [item.rfq_item_id],
                                });
                            } else if (ds.bom_item) {
                                const foundBomInfo = bomInfo.find((bom) => {
                                    const temp1: any = cloneDeep(bom);
                                    const temp2: any = cloneDeep(ds.bom_item);

                                    delete temp1['listOfRfqItemIds'];

                                    delete temp1['entry_id'];
                                    delete temp2['entry_id'];

                                    removeIndexFromEveryWhere(temp1);
                                    removeIndexFromEveryWhere(temp2);

                                    removeQuantityFromEveryWhere(temp1);
                                    removeQuantityFromEveryWhere(temp2);

                                    return isEqual(temp1, temp2);
                                });

                                if (foundBomInfo) {
                                    foundBomInfo.listOfRfqItemIds.push(
                                        item.rfq_item_id
                                    );
                                }
                            }
                        }
                    }

                    for (let bom of bomInfo) {
                        bom.listOfRfqItemIds = Array.from(
                            new Set(bom.listOfRfqItemIds)
                        );
                    }

                    for (let bom of bomInfo) {
                        const foundObj2 = obj2.find(
                            (o) =>
                                o.enterprise_bom_id ===
                                    bom.enterprise_bom?.entry_id &&
                                o.sub_bom_id ===
                                    (bom.parent_sub_bom_item
                                        ? getBomHierarachyEntryIdFromBoms(bom)
                                        : undefined)
                        );

                        if (foundObj2) {
                            foundObj2.listOfRfqItemIds.push(
                                ...bom.listOfRfqItemIds
                            );
                        } else {
                            const label =
                                getBomHierarachyLabelFromBoms(bom) +
                                ` (${bom.enterprise_bom?.quantity ?? 0} ${
                                    bom.enterprise_bom
                                        ?.measurement_unit_abbreviation ?? ''
                                })`;
                            obj2.push({
                                label: label,
                                enterprise_bom_id: bom.enterprise_bom?.entry_id,
                                sub_bom_id: bom.parent_sub_bom_item
                                    ? getBomHierarachyEntryIdFromBoms(bom)
                                    : undefined,
                                listOfRfqItemIds: bom.listOfRfqItemIds,
                            });
                        }
                    }

                    for (let bom of Object.values(bomMap)) {
                        // const temp21 = obj2.find(
                        //     (o) => o.enterprise_bom_id === bom.enterprise_bom_id
                        // );

                        if (
                            obj2.find(
                                (o) =>
                                    o.enterprise_bom_id ===
                                        bom.enterprise_bom_id && !o.sub_bom_id
                            ) === undefined
                        ) {
                            obj2.push({
                                label: bom.bom_code,
                                enterprise_bom_id: bom.enterprise_bom_id,
                                listOfRfqItemIds: bom.listOfRfqItemIds,
                            });
                        }
                    }
                    for (let bom of bomInfo) {
                        bom.listOfRfqItemIds = Array.from(
                            new Set(bom.listOfRfqItemIds)
                        );
                    }

                    // update current event item redux store information
                    batch(() => {
                        dipatch(
                            updateEventDataStore({
                                type: 'UPDATE_CACHE_ERRORS',
                                value: {
                                    type_of_action: 'UPDATE_MULTIPLE_ITEM',
                                    listOfItems: errors.map((error, idx) => ({
                                        rfq_item_entery_id: error.id,
                                        noOfVendorsSelected:
                                            listOfSellerForAllItems[error.id]
                                                ?.length ?? 0,
                                        pageNoItem: data.metadata.current_page,
                                        validity: eventItemErrorExists(
                                            error.error
                                        )
                                            ? 'INVALID'
                                            : 'VALID',
                                        itemNumber:
                                            (data.metadata.current_page - 1) *
                                                RFQ_ITEM_PER_PAGE +
                                            (idx + 1),
                                        itemDetails: error.item,
                                        itemNo: error.itemNo,
                                    })),
                                },
                            })
                        );
                        dipatch(
                            updateEventDataStore({
                                type: 'UPDATE_ALL_PO_ITEMS_ARE_FULL_fILLED',
                                value: data.data.all_po_items_are_fullfilled,
                            })
                        );
                        dipatch(
                            updateEventDataStore({
                                type: 'UPDATE_DICT_OF_BOM',
                                value: dictOfBoms,
                            })
                        );
                        dipatch(
                            updateEventBoomDataStore({
                                type: 'UPDATE_LIST_OF_BOMS_OF_CURRENT_EVENT_WITH_HEIRARY',
                                value: obj2,
                            })
                        );
                        dipatch(
                            updateEventDataStore({
                                type: 'UPDATE_PO_ITEMS_GROUPED_BY_ITEM_ENTRY',
                                value: {
                                    ...data.data.poItemsGroupedByItemEntry,
                                },
                            })
                        );
                        dipatch(
                            updateEventDataStore({
                                type: 'UPDATE_LAST_POS_FOR_ITEMS',
                                value: { ...data.data.lastPoItems },
                            })
                        );
                    });
                    // update the current page details

                    let argfor: ArgumentsForPaginationApiCall;
                    let argfor2: ArgumentsForPaginationApiCall;

                    if (type === 'FROM_EVENT') {
                        argfor = {
                            body: {
                                dashboard_view: 'rfq_event_item_buyer',
                                items_per_page: RFQ_ITEM_PER_PAGE,
                                page_number: data.metadata.current_page * 2 - 1,
                                query_data: {
                                    rfq_event_id: rfq_id ? rfq_id : '',
                                    seller_search:
                                        store.getState().EventCreationStore
                                            .searchQueryType === 'VENDORS'
                                            ? store.getState()
                                                  .EventCreationStore
                                                  .searchQuery
                                            : undefined,
                                    enterprise_bom_id: store.getState()
                                        .EventCreationStore
                                        .selectedBomForFiltering
                                        ? store.getState().EventCreationStore
                                              .selectedBomForFiltering!
                                        : undefined,
                                },
                                search_text:
                                    store.getState().EventCreationStore
                                        .searchQueryType === 'VENDORS'
                                        ? ''
                                        : store.getState().EventCreationStore
                                              .searchQuery,
                                sort_fields: [],
                                tab: 'all',
                            },
                            otherInfoRequired: {
                                entity_id: entity_id ?? '',
                                type: 'FROM_EVENT',
                                poCount: arePoAvailable && showWarnings ? 1 : 0,
                                searchQueryType:
                                    store.getState().EventCreationStore
                                        .searchQueryType,
                            },
                        };
                        argfor2 = {
                            body: {
                                dashboard_view: 'rfq_event_item_buyer',
                                items_per_page: RFQ_ITEM_PER_PAGE,
                                page_number: data.metadata.current_page * 2,
                                query_data: {
                                    rfq_event_id: rfq_id ? rfq_id : '',
                                    seller_search:
                                        store.getState().EventCreationStore
                                            .searchQueryType === 'VENDORS'
                                            ? store.getState()
                                                  .EventCreationStore
                                                  .searchQuery
                                            : undefined,
                                    enterprise_bom_id: store.getState()
                                        .EventCreationStore
                                        .selectedBomForFiltering
                                        ? store.getState().EventCreationStore
                                              .selectedBomForFiltering!
                                        : undefined,
                                },
                                search_text:
                                    store.getState().EventCreationStore
                                        .searchQueryType === 'VENDORS'
                                        ? ''
                                        : store.getState().EventCreationStore
                                              .searchQuery,
                                sort_fields: [],
                                tab: 'all',
                            },
                            otherInfoRequired: {
                                entity_id: entity_id ?? '',
                                type: 'FROM_EVENT',
                                poCount: arePoAvailable && showWarnings ? 1 : 0,
                                searchQueryType:
                                    store.getState().EventCreationStore
                                        .searchQueryType,
                            },
                        };
                    } else {
                        argfor = {
                            body: {
                                dashboard_view: 'po_group_item',
                                items_per_page: RFQ_ITEM_PER_PAGE,
                                page_number: data.metadata.current_page * 2 - 1,
                                query_data: {
                                    po_group_id: rfq_id,
                                },
                                search_text: '',
                                sort_fields: [],
                                tab: 'all',
                            },
                            otherInfoRequired: {
                                entity_id: entity_id,
                                poCount:
                                    store.getState().EventCreationStore
                                        .arePoAvailable && showWarnings
                                        ? 1
                                        : 0,
                                type: 'DIRECT',
                                searchQueryType: 'ITEMS',
                            },
                        };
                        argfor2 = {
                            body: {
                                dashboard_view: 'po_group_item',
                                items_per_page: RFQ_ITEM_PER_PAGE,
                                page_number: data.metadata.current_page * 2,
                                query_data: {
                                    po_group_id: rfq_id,
                                },
                                search_text: '',
                                sort_fields: [],
                                tab: 'all',
                            },
                            otherInfoRequired: {
                                entity_id: entity_id,
                                poCount:
                                    store.getState().EventCreationStore
                                        .arePoAvailable && showWarnings
                                        ? 1
                                        : 0,
                                type: 'DIRECT',
                                searchQueryType: 'ITEMS',
                            },
                        };
                    }

                    const patches1 = RFQPaginatedApiSlice.util.upsertQueryData(
                        'getListOfRfqItemPaginatedQuery',
                        argfor,
                        {
                            counts: {
                                all: data.counts.all,
                            },
                            data: {
                                all_po_items_are_fullfilled:
                                    data.data.all_po_items_are_fullfilled,
                                allItemTags: data.data.allItemTags,
                                lastPoItems: data.data.lastPoItems,
                                listOfItems: cloneDeep(
                                    data.data.listOfItems
                                ).splice(0, 10),
                                measurementUnits: data.data.measurementUnits,
                                poItemsGroupedByItemEntry:
                                    data.data.poItemsGroupedByItemEntry,
                            },
                            metadata: {
                                count: data.metadata.count,
                                current_page:
                                    data.metadata.current_page * 2 - 1,
                                has_next:
                                    data.metadata.has_next ||
                                    data.data.listOfItems.length > 10,
                                has_previous: data.metadata.has_previous,
                                page_range: {
                                    start: data.metadata.page_range.start,
                                    stop: data.metadata.page_range.stop * 2,
                                },
                                total_pages: Math.ceil(
                                    data.counts.all / RFQ_ITEM_PER_PAGE
                                ),
                            },
                        }
                    );
                    const patches2 = RFQPaginatedApiSlice.util.upsertQueryData(
                        'getListOfRfqItemPaginatedQuery',
                        argfor2,
                        {
                            counts: {
                                all: data.counts.all,
                            },
                            data: {
                                all_po_items_are_fullfilled:
                                    data.data.all_po_items_are_fullfilled,
                                allItemTags: data.data.allItemTags,
                                lastPoItems: data.data.lastPoItems,
                                listOfItems: cloneDeep(
                                    data.data.listOfItems
                                ).splice(10, 20),
                                measurementUnits: data.data.measurementUnits,
                                poItemsGroupedByItemEntry:
                                    data.data.poItemsGroupedByItemEntry,
                            },
                            metadata: {
                                count: data.metadata.count,
                                current_page: data.metadata.current_page * 2,
                                has_next:
                                    data.metadata.has_next ||
                                    data.data.listOfItems.length === 20,
                                has_previous: data.metadata.has_previous,
                                page_range: {
                                    start: data.metadata.page_range.start,
                                    stop: data.metadata.page_range.stop * 2,
                                },
                                total_pages: Math.ceil(
                                    data.counts.all / RFQ_ITEM_PER_PAGE
                                ),
                            },
                        }
                    );

                    batch(() => {
                        dipatch(patches1 as any);
                        dipatch(patches2 as any);
                    });
                    dipatch(
                        updateEventDataStore({
                            type: 'UPATE_IS_LOADING',
                            value: false,
                        })
                    );

                    setCurrentPage((prev) => {
                        if (prev <= data.metadata.total_pages) {
                            prev += 1;
                        } else {
                            dipatch(
                                updateEventDataStore({
                                    type: 'UPDATE_CACHING_SETTING_LOADING',
                                    value: false,
                                })
                            );
                        }

                        return prev;
                    });
                }
            })
            .catch((err) => console.error(err));
    }, [
        dipatch,
        entity_id,
        listOfSellerForAllItems,
        rfqTemplate,
        rfq_id,
        showWarnings,
        type,
        setCurrentPage,
        fetchData,
        currentPage,
        arePoAvailable,
        isFetchingRfqSeller,
        isFetchingD2OPSeller,
    ]);

    const updateSpecificPageData = useCallback((pageNo: number) => {}, []);

    const updateCurrentPage = useCallback((pageNo: number) => {}, []);

    const updateCurrentPageAndPagesAfterThis = useCallback(
        (pageNo: number) => {
            setCurrentPage(pageNo);
        },
        [setCurrentPage]
    );

    return {
        updateSpecificPageData,
        updateCurrentPage,
        updateCurrentPageAndPagesAfterThis,
    };
};

export const ComponentThatCallsCachingPages = memo(
    (props: {
        rfq_id: string;
        entity_id: string;
        type: 'DIRECT' | 'FROM_EVENT';
        rfqTemplate: IRfqTemplate | null;
    }) => {
        useCacheAllThePagesForEvents(
            props.rfq_id,
            props.entity_id,
            props.type,
            props.rfqTemplate
        );
        return null;
    }
);

export const getInfoIconTextForDiscountType = (
    discount: ({
        is_required: boolean;
    } & IAdditionalCost)[]
): string => {
    if (discount.length > 0) {
        if (discount[0].costType === CostTypeEnum.PERCENTAGE) {
            return 'Percentage';
        } else {
            if (discount[0].allocationType === AllocationTypeEnum.PER_UNIT) {
                return 'flat rate, per unit';
            } else {
                return 'flat rate, overall quantity';
            }
        }
    }

    return '';
};

import {
    Box,
    // ButtonGroup,
    Divider,
    Grid,
    InputAdornment,
    MenuItem,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import { FWCurrencyTypoGraphyWithCurrencyUid } from '../../Common/CurrencyUtilsComponent';
import { FWIconButton } from '../../Common/FWButton';
import { FWTooltip } from '../../Common/FWCustomTooltip';
import FWIcon from '../../Common/FWIcon';
import { FWInput } from '../../Common/FWInput';
import FWSelect from '../../Common/FWSelect';
import { FWTypography } from '../../Common/FWTypograhy';
import {
    calculateTotalAdditionalCostAndDeviation,
    calculateTotalAdditionalCostAndDeviationEventLevel,
} from '../helpers';
import {
    AllocationTypeEnum,
    AllocationTypeEnumToLabelMap,
    CostSourceEnum,
    CostSourceEnumToLabelMap,
    CostTypeEnum,
    CostTypeEnumToLabelMap,
    IAdditionalCost,
    IAdditionalCostWithIsRequired,
    IAdditionalCostsWithValue,
} from '../models/AdditionalCost.model';
// import { useEffect, useState } from 'react';

export const AdditionalCostListPopUp = ({
    localCopyOfAdditionalCost,
    updateSingleAdditionalCost,
    updateSingleAdditionalCostThresholdValue,
    updateSingleAdditionalCostThresholdAppliedOn,
    updateSingleAdditionalCostPerUnit,
    updateSingleAdditionalCostTotal,
    updateSingleAdditionalCostAgreedValue,
    updateSingleAdditionalCostAgreedCostPerUnit,
    updateSingleAdditionalCostAgreedTotal,
    currency_code_abbreviation,
    currency_code_symbol,
    measurementUnitName,
    baseQuantity,
    baseRate,
    removeSingleAdditionalCost,
    availableAdditionalCostTemplateOptions,
    addNewAdditionalCost,
    selectedAdditionalCostTemplateOptions,
    totalAdditionalCost,
    totalAgreedAdditionalCost,
    additionalCostTemplateOptions,
    conversionRate,
    conversionPrecision,
    rfqCurrencyAbbreviation,
    displayIconNextToRate,
    module,
    isEventLevel,
    isBOMLevel,
    isAgreedCost,
    showThreshold,
    eventSubtotal,
    eventTotal,
}: {
    localCopyOfAdditionalCost: IAdditionalCostsWithValue[];
    updateSingleAdditionalCost: (costValue: string, idx: number) => void;
    updateSingleAdditionalCostPerUnit: (value: string, idx: number) => void;
    updateSingleAdditionalCostTotal: (value: string, idx: number) => void;
    updateSingleAdditionalCostAgreedValue: (value: string, idx: number) => void;
    updateSingleAdditionalCostAgreedCostPerUnit: (
        value: string,
        idx: number
    ) => void;
    updateSingleAdditionalCostAgreedTotal: (value: string, idx: number) => void;
    updateSingleAdditionalCostThresholdValue: (
        thresholdValue: string,
        idx: number
    ) => void;
    updateSingleAdditionalCostThresholdAppliedOn: (
        thresholdAppliedOn: 'TOTAL' | 'SUBTOTAL',
        idx: number
    ) => void;
    currency_code_abbreviation: string;
    currency_code_symbol: string;
    measurementUnitName: string;
    baseRate: number | string;
    baseQuantity: number | string;
    removeSingleAdditionalCost: (idx: number) => void;
    availableAdditionalCostTemplateOptions: IAdditionalCost[];
    addNewAdditionalCost: () => void;
    selectedAdditionalCostTemplateOptions: (
        costName: string,
        idx: number
    ) => void;
    totalAdditionalCost: {
        additionalCostValue: number;
        effectiveRateDeviation: number;
    };
    totalAgreedAdditionalCost?: {
        additionalCostValue: number;
        effectiveRateDeviation: number;
    };
    additionalCostTemplateOptions: IAdditionalCostWithIsRequired[];
    conversionRate: number;
    conversionPrecision: number;
    rfqCurrencyAbbreviation: string;
    displayIconNextToRate?: boolean;
    iconToDisplayNextToRate?: React.ReactNode;
    module?: 'QUOTE_CALCULATOR';
    isEventLevel: boolean;
    isBOMLevel: boolean;
    isAgreedCost: boolean;
    showThreshold?: boolean;
    eventSubtotal: number | string;
    eventTotal: number | string;
}) => {
    // const [buttonGroupValue, setButtonGroupValue] = useState<
    //     'TOTAL' | 'SUBTOTAL'
    // >('TOTAL');

    //Created a key of all the columns present in popup (this should include keys of all modules)
    //if 2 modules
    type ColumnSizeKeys =
        | 'name'
        | 'type'
        | 'allocation'
        | 'source'
        | 'rate'
        | 'quotedRatePerUnit'
        | 'threshold'
        | 'thresholdAppliedOn'
        | 'value'
        | 'agreedRate'
        | 'agreedRatePerUnit'
        | 'agreedAmount';

    // This creates a type where each key (ColumnSizeKeys) maps to a number, and all keys are optional (via Partial).
    type ColumnSizes = Partial<Record<ColumnSizeKeys, number>>;

    const visibleFields = [
        { condition: true, label: 'Name', key: 'name' }, //name
        { condition: !isBOMLevel, label: 'Type', key: 'type' }, //type
        { condition: !isBOMLevel, label: 'Allocation', key: 'allocation' }, //allocation
        { condition: true, label: 'Source', key: 'source' }, //used
        {
            condition: true,
            label:
                isBOMLevel && module === 'QUOTE_CALCULATOR'
                    ? 'Quoted rate'
                    : 'Rate',
            key: 'rate', //quoted rate
        },
        {
            condition: isBOMLevel,
            label: 'Quoted rate per unit',
            key: 'quotedRatePerUnit', //quoted cost per unit
        },
        {
            condition: isEventLevel && showThreshold,
            label: 'Threshold',
            key: 'threshold', //used
        },
        {
            condition: isEventLevel && showThreshold,
            label: 'Applied On',
            key: 'thresholdAppliedOn', //used
        },
        {
            condition: true,
            label:
                isBOMLevel && module === 'QUOTE_CALCULATOR'
                    ? 'Quoted amount'
                    : 'Amount',
            key: 'value', //quoted total
        },
        {
            condition: isBOMLevel && module === 'QUOTE_CALCULATOR',
            label: 'Agreed rate',
            key: 'agreedRate', // agreed rate
        },
        {
            condition: isBOMLevel && module === 'QUOTE_CALCULATOR',
            label: 'Agreed rate per unit',
            key: 'agreedRatePerUnit', //agreed cost per unit
        },
        {
            condition: isBOMLevel && module === 'QUOTE_CALCULATOR',
            label: 'Agreed amount',
            key: 'agreedAmount', //agreed total
        },
    ];

    // Filter visible fields for displaying header
    const fieldsToShow = visibleFields.filter((field) => field.condition);

    // Calculate dynamic width for each field for header
    const fieldWidth = 11 / fieldsToShow.length;

    // Dynamically generate columnSizes object for fields below header
    const columnSizes: ColumnSizes = fieldsToShow.reduce((acc, field) => {
        acc[field.key as ColumnSizeKeys] = fieldWidth;
        return acc;
    }, {} as ColumnSizes);

    return (
        <>
            {/* header of list of addition cost */}
            <Grid container rowSpacing={1} marginBottom={1}>
                <Grid item xs={12}>
                    <Grid container columnSpacing={1}>
                        {fieldsToShow.map((field, index) => (
                            <Grid item xs={fieldWidth} key={index}>
                                <FWTypography color="text.secondary">
                                    {field.label}
                                </FWTypography>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container xs={12} rowSpacing={1}>
                {localCopyOfAdditionalCost?.map((cost, idx) => {
                    if (isEventLevel) {
                        if (!Boolean(cost.threshold_applied_on)) {
                            cost.threshold_applied_on = 'TOTAL';
                            updateSingleAdditionalCostThresholdAppliedOn(
                                'TOTAL',
                                idx
                            );
                        }
                    }

                    let rateError = Boolean(
                        !cost.value && cost.threshold && isEventLevel
                    );
                    let thresholdError = Boolean(
                        !cost.threshold && cost.value && isEventLevel
                    );

                    return cost.costName?.trim() !== '' ? (
                        <Grid item xs={12} key={idx}>
                            <Grid
                                container
                                xs={12}
                                columnSpacing={2}
                                alignItems={'center'}
                                style={{
                                    marginBottom: '0.5rem',
                                }}
                            >
                                <Grid item xs={columnSizes.name}>
                                    <FWTypography>{cost.costName}</FWTypography>
                                </Grid>
                                {!isBOMLevel && (
                                    <Grid item xs={columnSizes.type}>
                                        <FWTypography>
                                            {
                                                CostTypeEnumToLabelMap[
                                                    cost.costType
                                                ]
                                            }
                                        </FWTypography>
                                    </Grid>
                                )}
                                {!isBOMLevel && (
                                    <Grid item xs={columnSizes.allocation}>
                                        <FWTypography>
                                            {cost.costType ===
                                            CostTypeEnum.ABSOLUTE_VALUE
                                                ? cost.allocationType
                                                    ? AllocationTypeEnumToLabelMap[
                                                          cost.allocationType
                                                      ]
                                                    : AllocationTypeEnumToLabelMap[
                                                          AllocationTypeEnum
                                                              .OVERALL_QUANTITY
                                                      ]
                                                : '-'}
                                        </FWTypography>
                                    </Grid>
                                )}
                                <Grid item xs={columnSizes.source}>
                                    <FWTypography color="text.secondary">
                                        {isEventLevel
                                            ? 'Default value'
                                            : CostSourceEnumToLabelMap[
                                                  cost.cost_source
                                                      ? cost.cost_source
                                                      : CostSourceEnum.NONE
                                              ]}
                                    </FWTypography>
                                </Grid>

                                {/* Rate */}
                                <Grid item xs={columnSizes.rate}>
                                    <Box
                                        sx={{
                                            // paddingRight: '1rem',
                                            position: 'relative',
                                        }}
                                    >
                                        <FWInput
                                            sx={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                position: 'absolute',
                                                top: -20,
                                            }}
                                            value={cost.value}
                                            onChange={(e) => {
                                                updateSingleAdditionalCost(
                                                    e.target.value,
                                                    idx
                                                );
                                            }}
                                            disabled={
                                                isBOMLevel && isAgreedCost
                                            }
                                            allowOnly={
                                                cost.costType ===
                                                CostTypeEnum.PERCENTAGE
                                                    ? 'PERCENT'
                                                    : 'DECIMAL_NUMBER'
                                            }
                                            maxNumberOfDecimal={
                                                cost.costType ===
                                                CostTypeEnum.PERCENTAGE
                                                    ? 4
                                                    : 10
                                            }
                                            InputProps={{
                                                ...(cost.costType ===
                                                CostTypeEnum.PERCENTAGE
                                                    ? {
                                                          startAdornment: (
                                                              <InputAdornment
                                                                  position={
                                                                      'start'
                                                                  }
                                                                  sx={{
                                                                      m: '0 0.75rem',
                                                                  }}
                                                              ></InputAdornment>
                                                          ),
                                                          endAdornment: (
                                                              <InputAdornment
                                                                  position={
                                                                      'end'
                                                                  }
                                                                  sx={{
                                                                      m: '0 0.75rem',
                                                                  }}
                                                              >
                                                                  %
                                                              </InputAdornment>
                                                          ),
                                                      }
                                                    : {
                                                          startAdornment: (
                                                              <InputAdornment
                                                                  position={
                                                                      'start'
                                                                  }
                                                                  sx={{
                                                                      m: '0 0.25rem 0 0.75rem',
                                                                  }}
                                                              >
                                                                  {
                                                                      currency_code_symbol
                                                                  }
                                                              </InputAdornment>
                                                          ),
                                                          endAdornment: (
                                                              <InputAdornment
                                                                  position={
                                                                      'end'
                                                                  }
                                                                  sx={{
                                                                      m: '0 0.15rem',
                                                                  }}
                                                              >
                                                                  <FWTypography
                                                                      sx={{
                                                                          width:
                                                                              cost.allocationType ===
                                                                              AllocationTypeEnum.PER_UNIT
                                                                                  ? '45px'
                                                                                  : '0px',
                                                                      }}
                                                                  >
                                                                      {cost.allocationType ===
                                                                          AllocationTypeEnum.PER_UNIT &&
                                                                          `/${measurementUnitName}`}
                                                                  </FWTypography>
                                                              </InputAdornment>
                                                          ),
                                                      }),
                                            }}
                                            error={
                                                (additionalCostTemplateOptions.find(
                                                    (opt) =>
                                                        opt.costName?.trim() ===
                                                        cost.costName?.trim()
                                                )?.is_required &&
                                                    cost.value === '') ||
                                                rateError
                                            }
                                            helper={{
                                                text: rateError
                                                    ? 'Rate is required when threshold is applied'
                                                    : additionalCostTemplateOptions.find(
                                                          (opt) =>
                                                              opt.costName?.trim() ===
                                                              cost.costName?.trim()
                                                      )?.is_required &&
                                                      cost.value === ''
                                                    ? 'Input required'
                                                    : '',
                                            }}
                                        />
                                        {cost.value !== '' &&
                                            conversionRate !== 1 &&
                                            cost.costType !==
                                                CostTypeEnum.PERCENTAGE && (
                                                <FWTypography
                                                    // paddingY={
                                                    //     paddingForAllFields
                                                    // }
                                                    style={{
                                                        position: 'absolute',
                                                        // paddingTop: '1.5rem',
                                                    }}
                                                    sx={{ mt: '20px' }}
                                                    color={'orange'}
                                                >
                                                    {cost.allocationType ===
                                                    AllocationTypeEnum.PER_UNIT ? (
                                                        <FWCurrencyTypoGraphyWithCurrencyUid
                                                            currencyUidOrAbbrevaition={
                                                                rfqCurrencyAbbreviation
                                                            }
                                                            currency_code_abbreviation={
                                                                rfqCurrencyAbbreviation
                                                            }
                                                            value={
                                                                Number(
                                                                    cost.value
                                                                ) *
                                                                conversionRate
                                                            }
                                                            measurementUnitName={
                                                                measurementUnitName
                                                            }
                                                        />
                                                    ) : (
                                                        <FWCurrencyTypoGraphyWithCurrencyUid
                                                            currencyUidOrAbbrevaition={
                                                                rfqCurrencyAbbreviation
                                                            }
                                                            currency_code_abbreviation={
                                                                rfqCurrencyAbbreviation
                                                            }
                                                            value={
                                                                Number(
                                                                    cost.value
                                                                ) *
                                                                conversionRate
                                                            }
                                                        />
                                                    )}
                                                </FWTypography>
                                            )}
                                    </Box>
                                </Grid>

                                {displayIconNextToRate && (
                                    <Grid item xs={0.5}>
                                        {Boolean(cost.source_currency_id) && (
                                            <FWTooltip title={'test'}>
                                                <Box>
                                                    <FWIcon
                                                        name="bi bi-alarm"
                                                        color="primary.main"
                                                        size={16}
                                                    />
                                                </Box>
                                            </FWTooltip>
                                        )}
                                    </Grid>
                                )}

                                {isEventLevel && showThreshold && (
                                    <>
                                        <Grid item xs={columnSizes.threshold}>
                                            <Box
                                                sx={{
                                                    // paddingRight: '1rem',
                                                    position: 'relative',
                                                }}
                                            >
                                                <FWInput
                                                    // label="Threshold"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: -20,
                                                    }}
                                                    type="DECIMAL_NUMBER"
                                                    allowOnly="DECIMAL_NUMBER"
                                                    value={cost.threshold ?? ''}
                                                    error={thresholdError}
                                                    helper={{
                                                        text: thresholdError
                                                            ? 'Threshold is required when rate is applied'
                                                            : '',
                                                    }}
                                                    onChange={(e) => {
                                                        updateSingleAdditionalCostThresholdValue(
                                                            e.target.value,
                                                            idx
                                                        );
                                                    }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment
                                                                position={
                                                                    'start'
                                                                }
                                                                sx={{
                                                                    m: '0 0.25rem 0 0.75rem',
                                                                }}
                                                            >
                                                                {
                                                                    currency_code_symbol
                                                                }
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Box>

                                            {cost.threshold !== '' &&
                                                cost.threshold !== null &&
                                                cost.threshold !== undefined &&
                                                conversionRate !== 1 && (
                                                    <FWTypography
                                                        // paddingY={
                                                        //     paddingForAllFields
                                                        // }
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            paddingTop:
                                                                '1.5rem',
                                                        }}
                                                        color={'orange'}
                                                    >
                                                        <FWCurrencyTypoGraphyWithCurrencyUid
                                                            currencyUidOrAbbrevaition={
                                                                rfqCurrencyAbbreviation
                                                            }
                                                            currency_code_abbreviation={
                                                                rfqCurrencyAbbreviation
                                                            }
                                                            value={
                                                                Number(
                                                                    cost.threshold
                                                                ) *
                                                                conversionRate
                                                            }
                                                        />
                                                    </FWTypography>
                                                )}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={columnSizes.thresholdAppliedOn}
                                        >
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={
                                                    cost.threshold_applied_on
                                                }
                                                exclusive
                                                fullWidth
                                                onChange={(
                                                    event: React.MouseEvent<
                                                        HTMLElement,
                                                        MouseEvent
                                                    >,
                                                    value: 'TOTAL' | 'SUBTOTAL'
                                                ) => {
                                                    updateSingleAdditionalCostThresholdAppliedOn(
                                                        value,
                                                        idx
                                                    );
                                                    // value === 'TOTAL'
                                                    //     ? setButtonGroupValue(
                                                    //           'TOTAL'
                                                    //       )
                                                    //     : setButtonGroupValue(
                                                    //           'SUBTOTAL'
                                                    //       );
                                                }}
                                                aria-label="Platform"
                                            >
                                                <ToggleButton value="TOTAL">
                                                    <FWTypography>
                                                        Total
                                                    </FWTypography>
                                                </ToggleButton>
                                                <ToggleButton value="SUBTOTAL">
                                                    <FWTypography>
                                                        Subtotal
                                                    </FWTypography>
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </Grid>
                                    </>
                                )}

                                {/* Per unit */}
                                {isBOMLevel ? (
                                    <Grid
                                        item
                                        xs={columnSizes.quotedRatePerUnit}
                                    >
                                        <Box
                                            sx={{
                                                // paddingRight: '1rem',
                                                position: 'relative',
                                            }}
                                        >
                                            <FWInput
                                                sx={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    position: 'absolute',
                                                    top: -20,
                                                }}
                                                value={cost.cost_per_unit ?? ''}
                                                onChange={(e) => {
                                                    updateSingleAdditionalCostPerUnit(
                                                        e.target.value,
                                                        idx
                                                    );
                                                }}
                                                disabled={isAgreedCost}
                                                allowOnly="DECIMAL_NUMBER"
                                                maxNumberOfDecimal={
                                                    cost.costType ===
                                                    CostTypeEnum.PERCENTAGE
                                                        ? 4
                                                        : 10
                                                }
                                                InputProps={{
                                                    ...{
                                                        startAdornment: (
                                                            <InputAdornment
                                                                position={
                                                                    'start'
                                                                }
                                                                sx={{
                                                                    m: '0 0.25rem 0 0.75rem',
                                                                }}
                                                            >
                                                                {
                                                                    currency_code_symbol
                                                                }
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position={'end'}
                                                                sx={{
                                                                    m: '0 0.15rem',
                                                                }}
                                                            >
                                                                <FWTypography
                                                                    sx={{
                                                                        width: '45px',
                                                                    }}
                                                                >
                                                                    {`/${measurementUnitName}`}
                                                                </FWTypography>
                                                            </InputAdornment>
                                                        ),
                                                    },
                                                }}
                                                error={
                                                    (additionalCostTemplateOptions.find(
                                                        (opt) =>
                                                            opt.costName?.trim() ===
                                                            cost.costName?.trim()
                                                    )?.is_required &&
                                                        cost.value === '') ||
                                                    rateError
                                                }
                                                helper={{
                                                    text: rateError
                                                        ? 'Rate is required when threshold is applied'
                                                        : additionalCostTemplateOptions.find(
                                                              (opt) =>
                                                                  opt.costName?.trim() ===
                                                                  cost.costName?.trim()
                                                          )?.is_required &&
                                                          cost.value === ''
                                                        ? 'Input required'
                                                        : '',
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                ) : (
                                    <Grid
                                        item
                                        xs={columnSizes.value}
                                        paddingLeft={4}
                                    >
                                        <FWCurrencyTypoGraphyWithCurrencyUid
                                            currencyUidOrAbbrevaition={
                                                currency_code_abbreviation
                                            }
                                            currency_code_abbreviation={
                                                currency_code_abbreviation
                                            }
                                            value={
                                                isEventLevel
                                                    ? calculateTotalAdditionalCostAndDeviationEventLevel(
                                                          {
                                                              additionalCost:
                                                                  cost,
                                                              eventSubTotal:
                                                                  eventSubtotal,
                                                              eventTotal:
                                                                  eventTotal,
                                                              type: 'AdditionalCost',
                                                              applyThreshold:
                                                                  showThreshold,
                                                          }
                                                      ).additionalCostValue
                                                    : calculateTotalAdditionalCostAndDeviation(
                                                          {
                                                              additionalCost:
                                                                  cost,
                                                              baseRate:
                                                                  baseRate,
                                                              baseQuantity:
                                                                  baseQuantity,
                                                          }
                                                      ).additionalCostValue
                                            }
                                        />
                                        {conversionRate !== 1 && (
                                            <FWTypography
                                                // paddingY={
                                                //     paddingForAllFields
                                                // }
                                                style={{
                                                    position: 'absolute',
                                                    paddingTop: '0.5rem',
                                                }}
                                                color={'orange'}
                                            >
                                                <FWCurrencyTypoGraphyWithCurrencyUid
                                                    currencyUidOrAbbrevaition={
                                                        rfqCurrencyAbbreviation
                                                    }
                                                    currency_code_abbreviation={
                                                        rfqCurrencyAbbreviation
                                                    }
                                                    value={
                                                        isEventLevel
                                                            ? calculateTotalAdditionalCostAndDeviationEventLevel(
                                                                  {
                                                                      additionalCost:
                                                                          cost,
                                                                      eventSubTotal:
                                                                          eventSubtotal,
                                                                      eventTotal:
                                                                          eventTotal,
                                                                      type: 'AdditionalCost',
                                                                      applyThreshold:
                                                                          showThreshold,
                                                                  }
                                                              )
                                                                  .additionalCostValue *
                                                              conversionRate
                                                            : calculateTotalAdditionalCostAndDeviation(
                                                                  {
                                                                      additionalCost:
                                                                          cost,
                                                                      baseRate:
                                                                          baseRate,
                                                                      baseQuantity:
                                                                          baseQuantity,
                                                                  }
                                                              )
                                                                  .additionalCostValue *
                                                              conversionRate
                                                    }
                                                />
                                            </FWTypography>
                                        )}
                                    </Grid>
                                )}

                                {/* Total */}
                                {isBOMLevel && (
                                    <Grid item xs={columnSizes.value}>
                                        <Box
                                            sx={{
                                                // paddingRight: '1rem',
                                                position: 'relative',
                                            }}
                                        >
                                            <FWInput
                                                sx={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    position: 'absolute',
                                                    top: -20,
                                                }}
                                                value={cost.cost_total ?? ''}
                                                onChange={(e) => {
                                                    updateSingleAdditionalCostTotal(
                                                        e.target.value,
                                                        idx
                                                    );
                                                }}
                                                disabled={isAgreedCost}
                                                allowOnly="DECIMAL_NUMBER"
                                                maxNumberOfDecimal={
                                                    cost.costType ===
                                                    CostTypeEnum.PERCENTAGE
                                                        ? 4
                                                        : 10
                                                }
                                                InputProps={{
                                                    ...{
                                                        startAdornment: (
                                                            <InputAdornment
                                                                position={
                                                                    'start'
                                                                }
                                                                sx={{
                                                                    m: '0 0.25rem 0 0.75rem',
                                                                }}
                                                            >
                                                                {
                                                                    currency_code_symbol
                                                                }
                                                            </InputAdornment>
                                                        ),
                                                    },
                                                }}
                                                error={
                                                    (additionalCostTemplateOptions.find(
                                                        (opt) =>
                                                            opt.costName?.trim() ===
                                                            cost.costName?.trim()
                                                    )?.is_required &&
                                                        cost.value === '') ||
                                                    rateError
                                                }
                                                helper={{
                                                    text: rateError
                                                        ? 'Rate is required when threshold is applied'
                                                        : additionalCostTemplateOptions.find(
                                                              (opt) =>
                                                                  opt.costName?.trim() ===
                                                                  cost.costName?.trim()
                                                          )?.is_required &&
                                                          cost.value === ''
                                                        ? 'Input required'
                                                        : '',
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                )}

                                {/* Agreed rate */}
                                {isBOMLevel && (
                                    <Grid item xs={columnSizes.agreedRate}>
                                        <Box
                                            sx={{
                                                // paddingRight: '1rem',
                                                position: 'relative',
                                            }}
                                        >
                                            <FWInput
                                                sx={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    position: 'absolute',
                                                    top: -20,
                                                }}
                                                value={
                                                    cost.agreed_cost_value ?? ''
                                                }
                                                onChange={(e) => {
                                                    updateSingleAdditionalCostAgreedValue(
                                                        e.target.value,
                                                        idx
                                                    );
                                                }}
                                                disabled={!isAgreedCost}
                                                allowOnly={
                                                    cost.costType ===
                                                    CostTypeEnum.PERCENTAGE
                                                        ? 'PERCENT'
                                                        : 'DECIMAL_NUMBER'
                                                }
                                                maxNumberOfDecimal={
                                                    cost.costType ===
                                                    CostTypeEnum.PERCENTAGE
                                                        ? 4
                                                        : 10
                                                }
                                                InputProps={{
                                                    ...(cost.costType ===
                                                    CostTypeEnum.PERCENTAGE
                                                        ? {
                                                              startAdornment: (
                                                                  <InputAdornment
                                                                      position={
                                                                          'start'
                                                                      }
                                                                      sx={{
                                                                          m: '0 0.75rem',
                                                                      }}
                                                                  ></InputAdornment>
                                                              ),
                                                              endAdornment: (
                                                                  <InputAdornment
                                                                      position={
                                                                          'end'
                                                                      }
                                                                      sx={{
                                                                          m: '0 0.75rem',
                                                                      }}
                                                                  >
                                                                      %
                                                                  </InputAdornment>
                                                              ),
                                                          }
                                                        : {
                                                              startAdornment: (
                                                                  <InputAdornment
                                                                      position={
                                                                          'start'
                                                                      }
                                                                      sx={{
                                                                          m: '0 0.25rem 0 0.75rem',
                                                                      }}
                                                                  >
                                                                      {
                                                                          currency_code_symbol
                                                                      }
                                                                  </InputAdornment>
                                                              ),
                                                              endAdornment: (
                                                                  <InputAdornment
                                                                      position={
                                                                          'end'
                                                                      }
                                                                      sx={{
                                                                          m: '0 0.15rem',
                                                                      }}
                                                                  >
                                                                      <FWTypography
                                                                          sx={{
                                                                              width:
                                                                                  cost.allocationType ===
                                                                                  AllocationTypeEnum.PER_UNIT
                                                                                      ? '45px'
                                                                                      : '0px',
                                                                          }}
                                                                      >
                                                                          {cost.allocationType ===
                                                                              AllocationTypeEnum.PER_UNIT &&
                                                                              `/${measurementUnitName}`}
                                                                      </FWTypography>
                                                                  </InputAdornment>
                                                              ),
                                                          }),
                                                }}
                                                error={
                                                    (additionalCostTemplateOptions.find(
                                                        (opt) =>
                                                            opt.costName?.trim() ===
                                                            cost.costName?.trim()
                                                    )?.is_required &&
                                                        cost.value === '') ||
                                                    rateError
                                                }
                                                helper={{
                                                    text: rateError
                                                        ? 'Rate is required when threshold is applied'
                                                        : additionalCostTemplateOptions.find(
                                                              (opt) =>
                                                                  opt.costName?.trim() ===
                                                                  cost.costName?.trim()
                                                          )?.is_required &&
                                                          cost.value === ''
                                                        ? 'Input required'
                                                        : '',
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                )}

                                {/* Agreed per unit */}
                                {isBOMLevel && (
                                    <Grid
                                        item
                                        xs={columnSizes.agreedRatePerUnit}
                                    >
                                        <Box
                                            sx={{
                                                // paddingRight: '1rem',
                                                position: 'relative',
                                            }}
                                        >
                                            <FWInput
                                                sx={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    position: 'absolute',
                                                    top: -20,
                                                }}
                                                value={
                                                    cost.agreed_cost_per_unit ??
                                                    ''
                                                }
                                                onChange={(e) => {
                                                    updateSingleAdditionalCostAgreedCostPerUnit(
                                                        e.target.value,
                                                        idx
                                                    );
                                                }}
                                                disabled={!isAgreedCost}
                                                allowOnly="DECIMAL_NUMBER"
                                                maxNumberOfDecimal={
                                                    cost.costType ===
                                                    CostTypeEnum.PERCENTAGE
                                                        ? 4
                                                        : 10
                                                }
                                                InputProps={{
                                                    ...{
                                                        startAdornment: (
                                                            <InputAdornment
                                                                position={
                                                                    'start'
                                                                }
                                                                sx={{
                                                                    m: '0 0.25rem 0 0.75rem',
                                                                }}
                                                            >
                                                                {
                                                                    currency_code_symbol
                                                                }
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position={'end'}
                                                                sx={{
                                                                    m: '0 0.15rem',
                                                                }}
                                                            >
                                                                <FWTypography
                                                                    sx={{
                                                                        width: '45px',
                                                                    }}
                                                                >
                                                                    {`/${measurementUnitName}`}
                                                                </FWTypography>
                                                            </InputAdornment>
                                                        ),
                                                    },
                                                }}
                                                error={
                                                    (additionalCostTemplateOptions.find(
                                                        (opt) =>
                                                            opt.costName?.trim() ===
                                                            cost.costName?.trim()
                                                    )?.is_required &&
                                                        cost.value === '') ||
                                                    rateError
                                                }
                                                helper={{
                                                    text: rateError
                                                        ? 'Rate is required when threshold is applied'
                                                        : additionalCostTemplateOptions.find(
                                                              (opt) =>
                                                                  opt.costName?.trim() ===
                                                                  cost.costName?.trim()
                                                          )?.is_required &&
                                                          cost.value === ''
                                                        ? 'Input required'
                                                        : '',
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                )}

                                {/* Agreed total */}
                                {isBOMLevel && (
                                    <Grid item xs={columnSizes.agreedAmount}>
                                        <Box
                                            sx={{
                                                // paddingRight: '1rem',
                                                position: 'relative',
                                            }}
                                        >
                                            <FWInput
                                                sx={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    position: 'absolute',
                                                    top: -20,
                                                }}
                                                value={
                                                    cost.agreed_cost_total ?? ''
                                                }
                                                onChange={(e) => {
                                                    updateSingleAdditionalCostAgreedTotal(
                                                        e.target.value,
                                                        idx
                                                    );
                                                }}
                                                disabled={!isAgreedCost}
                                                allowOnly="DECIMAL_NUMBER"
                                                maxNumberOfDecimal={
                                                    cost.costType ===
                                                    CostTypeEnum.PERCENTAGE
                                                        ? 4
                                                        : 10
                                                }
                                                InputProps={{
                                                    ...{
                                                        startAdornment: (
                                                            <InputAdornment
                                                                position={
                                                                    'start'
                                                                }
                                                                sx={{
                                                                    m: '0 0.25rem 0 0.75rem',
                                                                }}
                                                            >
                                                                {
                                                                    currency_code_symbol
                                                                }
                                                            </InputAdornment>
                                                        ),
                                                    },
                                                }}
                                                error={
                                                    (additionalCostTemplateOptions.find(
                                                        (opt) =>
                                                            opt.costName?.trim() ===
                                                            cost.costName?.trim()
                                                    )?.is_required &&
                                                        cost.value === '') ||
                                                    rateError
                                                }
                                                helper={{
                                                    text: rateError
                                                        ? 'Rate is required when threshold is applied'
                                                        : additionalCostTemplateOptions.find(
                                                              (opt) =>
                                                                  opt.costName?.trim() ===
                                                                  cost.costName?.trim()
                                                          )?.is_required &&
                                                          cost.value === ''
                                                        ? 'Input required'
                                                        : '',
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                )}

                                <Grid item xs={0.5} display={'flex'}>
                                    <FWIconButton
                                        color="error"
                                        disabled={
                                            additionalCostTemplateOptions.find(
                                                (opt) =>
                                                    opt.costName?.trim() ===
                                                    cost.costName?.trim()
                                            )?.is_required
                                        }
                                        onClick={() => {
                                            removeSingleAdditionalCost(idx);
                                        }}
                                    >
                                        <i className="bi bi-dash-circle"></i>
                                    </FWIconButton>

                                    {localCopyOfAdditionalCost?.length - 1 ===
                                        idx &&
                                        availableAdditionalCostTemplateOptions.length >
                                            0 && (
                                            <FWIconButton
                                                color="primary"
                                                onClick={() => {
                                                    addNewAdditionalCost();
                                                }}
                                            >
                                                <i className="bi bi-plus-circle"></i>
                                            </FWIconButton>
                                        )}
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        // blank cost row
                        <Grid item xs={12} key={idx}>
                            <Grid
                                container
                                xs={12}
                                columnSpacing={2}
                                alignItems={'center'}
                            >
                                <Grid item xs={columnSizes.name}>
                                    <FWSelect
                                        fullWidth
                                        sx={{
                                            '&': {
                                                backgroundColor: 'white',
                                            },
                                        }}
                                    >
                                        {availableAdditionalCostTemplateOptions.map(
                                            (option, optionIdx) => (
                                                <MenuItem
                                                    key={optionIdx}
                                                    onClick={() => {
                                                        selectedAdditionalCostTemplateOptions(
                                                            option.costName,
                                                            idx
                                                        );
                                                    }}
                                                    value={option.costName}
                                                >
                                                    {option.costName}
                                                </MenuItem>
                                            )
                                        )}
                                    </FWSelect>
                                </Grid>
                                {!isBOMLevel && (
                                    <Grid item xs={columnSizes.type}>
                                        <FWTypography>-</FWTypography>
                                    </Grid>
                                )}
                                {!isBOMLevel && (
                                    <Grid item xs={columnSizes.allocation}>
                                        <FWTypography>-</FWTypography>
                                    </Grid>
                                )}
                                <Grid item xs={columnSizes.source}>
                                    <FWTypography>-</FWTypography>
                                </Grid>

                                {/* Rate */}
                                <Grid item xs={columnSizes.rate}>
                                    <Box
                                        sx={{
                                            paddingRight: '1rem',
                                        }}
                                    >
                                        <FWInput
                                            value={cost.value}
                                            onChange={(e) => {
                                                updateSingleAdditionalCost(
                                                    e.target.value,
                                                    idx
                                                );
                                            }}
                                            disabled
                                            allowOnly="DECIMAL_NUMBER"
                                            {...(cost.costType ===
                                            CostTypeEnum.PERCENTAGE
                                                ? {
                                                      maxNumberOfDecimal: 4,
                                                  }
                                                : {})}
                                        />
                                    </Box>
                                </Grid>

                                {isEventLevel && showThreshold && (
                                    <>
                                        <Grid item xs={columnSizes.threshold}>
                                            <FWTypography>-</FWTypography>
                                        </Grid>
                                        <Grid item xs={columnSizes.threshold}>
                                            <FWTypography>-</FWTypography>
                                        </Grid>
                                    </>
                                )}

                                {/* Per unit */}
                                {isBOMLevel ? (
                                    <Grid
                                        item
                                        xs={columnSizes.quotedRatePerUnit}
                                    >
                                        <Box
                                            sx={{
                                                paddingRight: '1rem',
                                            }}
                                        >
                                            <FWInput
                                                value={cost.value}
                                                onChange={(e) => {
                                                    updateSingleAdditionalCost(
                                                        e.target.value,
                                                        idx
                                                    );
                                                }}
                                                disabled
                                                allowOnly="DECIMAL_NUMBER"
                                                {...(cost.costType ===
                                                CostTypeEnum.PERCENTAGE
                                                    ? {
                                                          maxNumberOfDecimal: 4,
                                                      }
                                                    : {})}
                                            />
                                        </Box>
                                    </Grid>
                                ) : (
                                    <Grid
                                        item
                                        xs={columnSizes.value}
                                        // paddingLeft={4}
                                    >
                                        -
                                    </Grid>
                                )}

                                {/* Total */}
                                {isBOMLevel && (
                                    <Grid item xs={columnSizes.value}>
                                        <Box
                                            sx={{
                                                paddingRight: '1rem',
                                            }}
                                        >
                                            <FWInput
                                                value={cost.value}
                                                onChange={(e) => {
                                                    updateSingleAdditionalCost(
                                                        e.target.value,
                                                        idx
                                                    );
                                                }}
                                                disabled
                                                allowOnly="DECIMAL_NUMBER"
                                                {...(cost.costType ===
                                                CostTypeEnum.PERCENTAGE
                                                    ? {
                                                          maxNumberOfDecimal: 4,
                                                      }
                                                    : {})}
                                            />
                                        </Box>
                                    </Grid>
                                )}

                                {/* Agreed rate */}
                                {isBOMLevel && (
                                    <Grid item xs={columnSizes.agreedRate}>
                                        <Box
                                            sx={{
                                                paddingRight: '1rem',
                                            }}
                                        >
                                            <FWInput
                                                value={cost.value}
                                                onChange={(e) => {
                                                    updateSingleAdditionalCost(
                                                        e.target.value,
                                                        idx
                                                    );
                                                }}
                                                disabled
                                                allowOnly="DECIMAL_NUMBER"
                                                {...(cost.costType ===
                                                CostTypeEnum.PERCENTAGE
                                                    ? {
                                                          maxNumberOfDecimal: 4,
                                                      }
                                                    : {})}
                                            />
                                        </Box>
                                    </Grid>
                                )}

                                {/* Agreed per unit */}
                                {isBOMLevel && (
                                    <Grid
                                        item
                                        xs={columnSizes.agreedRatePerUnit}
                                    >
                                        <Box
                                            sx={{
                                                paddingRight: '1rem',
                                            }}
                                        >
                                            <FWInput
                                                value={cost.value}
                                                onChange={(e) => {
                                                    updateSingleAdditionalCost(
                                                        e.target.value,
                                                        idx
                                                    );
                                                }}
                                                disabled
                                                allowOnly="DECIMAL_NUMBER"
                                                {...(cost.costType ===
                                                CostTypeEnum.PERCENTAGE
                                                    ? {
                                                          maxNumberOfDecimal: 4,
                                                      }
                                                    : {})}
                                            />
                                        </Box>
                                    </Grid>
                                )}

                                {/* Agreed total */}
                                {isBOMLevel && (
                                    <Grid item xs={columnSizes.agreedAmount}>
                                        <Box
                                            sx={{
                                                paddingRight: '1rem',
                                            }}
                                        >
                                            <FWInput
                                                value={cost.value}
                                                onChange={(e) => {
                                                    updateSingleAdditionalCost(
                                                        e.target.value,
                                                        idx
                                                    );
                                                }}
                                                disabled
                                                allowOnly="DECIMAL_NUMBER"
                                                {...(cost.costType ===
                                                CostTypeEnum.PERCENTAGE
                                                    ? {
                                                          maxNumberOfDecimal: 4,
                                                      }
                                                    : {})}
                                            />
                                        </Box>
                                    </Grid>
                                )}

                                <Grid item xs={0.5} display={'flex'}>
                                    {localCopyOfAdditionalCost?.filter(
                                        (cost) =>
                                            cost.costName?.trim().length > 0
                                    ).length > 0 && (
                                        <FWIconButton
                                            color="error"
                                            onClick={() => {
                                                removeSingleAdditionalCost(idx);
                                            }}
                                        >
                                            <i className="bi bi-dash-circle"></i>
                                        </FWIconButton>
                                    )}
                                    {localCopyOfAdditionalCost?.length - 1 ===
                                        idx &&
                                        availableAdditionalCostTemplateOptions.length >
                                            0 &&
                                        localCopyOfAdditionalCost?.filter(
                                            (cost) =>
                                                cost.costName?.trim().length ===
                                                0
                                        ).length <
                                            availableAdditionalCostTemplateOptions.length && (
                                            <FWIconButton
                                                color="primary"
                                                onClick={() => {
                                                    addNewAdditionalCost();
                                                }}
                                                disabled={cost.costName === ''}
                                            >
                                                <i className="bi bi-plus-circle"></i>
                                            </FWIconButton>
                                        )}
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>

            <br />
            <Divider />
            <br />
            <Grid container xs={12} rowSpacing={1} marginBottom={1}>
                <Grid item xs={12}>
                    <Grid container xs={12} columnSpacing={1}>
                        <Grid item xs={columnSizes.name}>
                            <FWTypography fontWeight="500">Total</FWTypography>
                        </Grid>
                        {!isBOMLevel && (
                            <Grid item xs={columnSizes.type}>
                                -
                            </Grid>
                        )}
                        {!isBOMLevel && (
                            <Grid item xs={columnSizes.allocation}>
                                -
                            </Grid>
                        )}
                        <Grid item xs={columnSizes.source}>
                            -
                        </Grid>

                        {isEventLevel || isBOMLevel ? (
                            <Grid item xs={columnSizes.rate}>
                                -
                            </Grid>
                        ) : (
                            <Grid item xs={columnSizes.rate}>
                                <FWTypography
                                    style={{
                                        width: 'fit-content',
                                    }}
                                >
                                    <FWCurrencyTypoGraphyWithCurrencyUid
                                        currencyUidOrAbbrevaition={
                                            currency_code_abbreviation
                                        }
                                        currency_code_abbreviation={
                                            currency_code_abbreviation
                                        }
                                        value={
                                            totalAdditionalCost.effectiveRateDeviation
                                        }
                                        measurementUnitName={
                                            measurementUnitName
                                        }
                                    />
                                </FWTypography>
                                <FWTypography
                                    style={{
                                        position: 'absolute',
                                    }}
                                    color={'orange'}
                                >
                                    {conversionRate !== 1 && (
                                        <FWCurrencyTypoGraphyWithCurrencyUid
                                            currencyUidOrAbbrevaition={
                                                rfqCurrencyAbbreviation
                                            }
                                            currency_code_abbreviation={
                                                rfqCurrencyAbbreviation
                                            }
                                            value={
                                                Number(
                                                    totalAdditionalCost.effectiveRateDeviation
                                                ) * conversionRate
                                            }
                                            measurementUnitName={
                                                measurementUnitName
                                            }
                                        />
                                    )}
                                </FWTypography>
                            </Grid>
                        )}

                        {isEventLevel && showThreshold && (
                            <>
                                <Grid item xs={columnSizes.threshold}>
                                    <FWTypography>-</FWTypography>
                                </Grid>
                                <Grid item xs={columnSizes.threshold}>
                                    <FWTypography>-</FWTypography>
                                </Grid>
                            </>
                        )}

                        {/* Per unit */}
                        {isBOMLevel && (
                            <Grid item xs={columnSizes.rate}>
                                <FWTypography
                                    style={{
                                        width: 'fit-content',
                                    }}
                                >
                                    <FWCurrencyTypoGraphyWithCurrencyUid
                                        currencyUidOrAbbrevaition={
                                            currency_code_abbreviation
                                        }
                                        currency_code_abbreviation={
                                            currency_code_abbreviation
                                        }
                                        value={
                                            totalAdditionalCost.effectiveRateDeviation
                                        }
                                        measurementUnitName={
                                            measurementUnitName
                                        }
                                    />
                                </FWTypography>
                                <FWTypography
                                    style={{
                                        position: 'absolute',
                                    }}
                                    color={'orange'}
                                >
                                    {conversionRate !== 1 && (
                                        <FWCurrencyTypoGraphyWithCurrencyUid
                                            currencyUidOrAbbrevaition={
                                                rfqCurrencyAbbreviation
                                            }
                                            currency_code_abbreviation={
                                                rfqCurrencyAbbreviation
                                            }
                                            value={
                                                Number(
                                                    totalAdditionalCost.effectiveRateDeviation
                                                ) * conversionRate
                                            }
                                            measurementUnitName={
                                                measurementUnitName
                                            }
                                        />
                                    )}
                                </FWTypography>
                            </Grid>
                        )}

                        {/* Total */}
                        <Grid item xs={columnSizes.value}>
                            <FWTypography
                                fontWeight="500"
                                style={{
                                    width: 'fit-content',
                                }}
                            >
                                <FWCurrencyTypoGraphyWithCurrencyUid
                                    currencyUidOrAbbrevaition={
                                        currency_code_abbreviation
                                    }
                                    currency_code_abbreviation={
                                        currency_code_abbreviation
                                    }
                                    value={
                                        totalAdditionalCost.additionalCostValue
                                    }
                                />
                                <FWTypography
                                    style={{
                                        position: 'absolute',
                                    }}
                                    color={'orange'}
                                >
                                    {conversionRate !== 1 && (
                                        <FWCurrencyTypoGraphyWithCurrencyUid
                                            currencyUidOrAbbrevaition={
                                                rfqCurrencyAbbreviation
                                            }
                                            currency_code_abbreviation={
                                                rfqCurrencyAbbreviation
                                            }
                                            value={
                                                Number(
                                                    totalAdditionalCost.additionalCostValue
                                                ) * conversionRate
                                            }
                                        />
                                    )}
                                </FWTypography>
                            </FWTypography>
                        </Grid>

                        {/* Agreed rate */}
                        {isBOMLevel && (
                            <Grid item xs={columnSizes.rate}>
                                -
                            </Grid>
                        )}

                        {/* Agreed per unit */}
                        {isBOMLevel && (
                            <Grid item xs={columnSizes.rate}>
                                <FWTypography
                                    style={{
                                        width: 'fit-content',
                                    }}
                                >
                                    <FWCurrencyTypoGraphyWithCurrencyUid
                                        currencyUidOrAbbrevaition={
                                            currency_code_abbreviation
                                        }
                                        currency_code_abbreviation={
                                            currency_code_abbreviation
                                        }
                                        value={
                                            totalAgreedAdditionalCost?.effectiveRateDeviation ??
                                            0
                                        }
                                        measurementUnitName={
                                            measurementUnitName
                                        }
                                    />
                                </FWTypography>
                                <FWTypography
                                    style={{
                                        position: 'absolute',
                                    }}
                                    color={'orange'}
                                >
                                    {conversionRate !== 1 && (
                                        <FWCurrencyTypoGraphyWithCurrencyUid
                                            currencyUidOrAbbrevaition={
                                                rfqCurrencyAbbreviation
                                            }
                                            currency_code_abbreviation={
                                                rfqCurrencyAbbreviation
                                            }
                                            value={
                                                Number(
                                                    totalAgreedAdditionalCost?.effectiveRateDeviation ??
                                                        0
                                                ) * conversionRate
                                            }
                                            measurementUnitName={
                                                measurementUnitName
                                            }
                                        />
                                    )}
                                </FWTypography>
                            </Grid>
                        )}

                        {/* Agreed total */}
                        {isBOMLevel && (
                            <Grid item xs={columnSizes.value}>
                                <FWTypography
                                    fontWeight="500"
                                    style={{
                                        width: 'fit-content',
                                    }}
                                >
                                    <FWCurrencyTypoGraphyWithCurrencyUid
                                        currencyUidOrAbbrevaition={
                                            currency_code_abbreviation
                                        }
                                        currency_code_abbreviation={
                                            currency_code_abbreviation
                                        }
                                        value={
                                            totalAgreedAdditionalCost?.additionalCostValue ??
                                            0
                                        }
                                    />
                                    <FWTypography
                                        style={{
                                            position: 'absolute',
                                        }}
                                        color={'orange'}
                                    >
                                        {conversionRate !== 1 && (
                                            <FWCurrencyTypoGraphyWithCurrencyUid
                                                currencyUidOrAbbrevaition={
                                                    rfqCurrencyAbbreviation
                                                }
                                                currency_code_abbreviation={
                                                    rfqCurrencyAbbreviation
                                                }
                                                value={
                                                    Number(
                                                        totalAgreedAdditionalCost?.additionalCostValue ??
                                                            0
                                                    ) * conversionRate
                                                }
                                            />
                                        )}
                                    </FWTypography>
                                </FWTypography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default AdditionalCostListPopUp;

import { Box, TypographyProps } from '@mui/material';
import { useCallback } from 'react';
import { useGetSelectedCurrency } from '../Events/Services/Buyer/shared.service';
import { FWTooltip } from './FWCustomTooltip';
import { FWTypography } from './FWTypograhy';
import { FWNumberFormatter } from './Utils/CurrencyUtils';

export const FWCurrencyTypoGraphyWithCurrencyUid = ({
    currencyUidOrAbbrevaition = '',
    currency_code_abbreviation,
    value,
    measurementUnitName,
    hideCurrency = false,
    sx,
    textAlign,
    hideTooltip = false,
    fontWeight,
    dontshowSlash = false,
}: TypographyProps & {
    hideTooltip?: boolean;
    value: number | string;
    currencyUidOrAbbrevaition: string;
    currency_code_abbreviation: string | null;
    measurementUnitName?: string;
    hideCurrency?: boolean;
    dontshowSlash?: boolean;
}) => {
    const selectedCurrencyDetails = useGetSelectedCurrency(
        currencyUidOrAbbrevaition
    );
    let decimalPlaces = selectedCurrencyDetails
        ? +value === 0
            ? 0
            : +value < 2
            ? 4
            : selectedCurrencyDetails.decimals
        : 2;

    // const tooltipText = useCallback(() => {
    //     if (
    //         roundToNDecimalPlace(+value, 10) ===
    //         roundToNDecimalPlace(+value, decimalPlaces)
    //     )
    //         return '';
    //     else
    //         return (
    //             (selectedCurrencyDetails?.currency_symbol ?? '') +
    //             roundToNDecimalPlace(+value, 10)
    //         );
    // }, [decimalPlaces, selectedCurrencyDetails?.currency_symbol, value]);

    const tooltipText = useCallback(() => {
        if (!hideTooltip) {
            return `${selectedCurrencyDetails?.currency_symbol ?? ''}${+value}`;
        }
        return '';
    }, [hideTooltip, selectedCurrencyDetails?.currency_symbol, value]);

    return (
        <FWTooltip title={hideCurrency ? '' : tooltipText()}>
            <Box style={{ height: 'min-content' }}>
                <FWTypography
                    textAlign={textAlign}
                    fontWeight={fontWeight}
                    sx={sx}

                    // fontFamily={"PT Mono"} fontSize={14}
                >
                    {hideCurrency
                        ? value
                            ? FWNumberFormatter(+value)
                            : roundToNDecimalPlace(+value, decimalPlaces)
                        : isNaN(+value)
                        ? '-'
                        : FWNumberFormatter(
                              roundToNDecimalPlace(+value, decimalPlaces),
                              selectedCurrencyDetails?.currency_code_abbreviation,
                              decimalPlaces
                          )}

                    {measurementUnitName &&
                    measurementUnitName.trim().length > 0
                        ? ` ${dontshowSlash ? '' : '/'}${measurementUnitName}`
                        : ''}
                </FWTypography>
            </Box>
        </FWTooltip>
    );
};

export const FWCurrencyTypoGraphyWithDecimalPlaces = ({
    currency_code_abbreviation = null,
    decimalPlaces,
    value,
    measurementUnitName,
    sx,
    textAlign,
    hideTooltip = false,
}: TypographyProps & {
    hideTooltip?: boolean;
    value: number | string;
    decimalPlaces: number;
    currency_code_abbreviation?: string | null;
    measurementUnitName?: string;
}) => {
    return (
        <FWTooltip title={hideTooltip ? '' : value}>
            <Box
                sx={{
                    width: 'fit-content',
                }}
            >
                <FWTypography textAlign={textAlign} sx={sx}>
                    {FWNumberFormatter(
                        roundToNDecimalPlace(+value, decimalPlaces),
                        currency_code_abbreviation,
                        decimalPlaces
                    )}
                    {measurementUnitName &&
                    measurementUnitName.trim().length > 0
                        ? `/${measurementUnitName}`
                        : ''}
                </FWTypography>
            </Box>
        </FWTooltip>
    );
};

export const roundToNDecimalPlace = (number: number, decimalPlaces: number) =>
    Math.round(number * 10 ** decimalPlaces) / 10 ** decimalPlaces;

import { Box, Chip, FormControl, TextField, Typography } from '@mui/material';
import { memo, useMemo, useState } from 'react';
import FWAutocomplete from '../../../Common/FWAutocomplete';
import { FWIconButton } from '../../../Common/FWButton';
import { FWMenuItem } from '../../../Common/FWCustomMenu';
import { FWTooltip } from '../../../Common/FWCustomTooltip';
import FWIcon from '../../../Common/FWIcon';
import { FWInput } from '../../../Common/FWInput';
import { FWTypography } from '../../../Common/FWTypograhy';
import { CustomFieldType } from '../../../Global/Interfaces/TemplateInterface';
import {
    Condition,
    FieldTypeLabel,
    getConditions,
    TFilterUpdateParams,
} from '../../constants/projectDashboardConstants';
import { IFieldTypeLabel } from '../../helpers/projectDashboardHelper';
import { DashBoardFilter } from '../../interface/project.model';
import ProjectDashboardFilterInput from './ProjectDashboardFilterInput';

const ProjectDashboardFilterFieldRow = ({
    index,
    fieldTypeLabels,
    filterFieldToNameMap,
    disableRemoveRow,
    andFilter,
    updateFilterObject,
    resetField,
    removeSortRow,
    selectedFields,
}: {
    removeSortRow: (index: number) => void;
    disableRemoveRow: boolean;
    index: number;
    fieldTypeLabels: IFieldTypeLabel[];
    filterFieldToNameMap: Record<string, string>;
    andFilter: DashBoardFilter;
    resetField: (index: number, resetType: 'FIELD' | 'CONDITION') => void;
    updateFilterObject: (
        index: number,
        updatedField: TFilterUpdateParams
    ) => void;
    selectedFields: string[];
}) => {
    const filterDataInOptions = useMemo(() => {
        console.log('dadnwanjd', andFilter.single?.field);
        return fieldTypeLabels.find(
            (option) =>
                option.column ===
                    (andFilter.single?.field
                        ? filterFieldToNameMap[andFilter.single?.field] ??
                          andFilter.single?.field
                        : andFilter.single?.field) &&
                (option.is_built_in === false
                    ? true
                    : option.sectionName === 'Project details'
                    ? true
                    : option.sectionAlternameName === andFilter.single?.section)
        );
    }, [andFilter, fieldTypeLabels, filterFieldToNameMap]);

    const conditionOptions = useMemo(() => {
        return andFilter.single?.type
            ? getConditions(andFilter.single?.type)
            : [];
    }, [andFilter.single?.type]);

    const showBlankField = useMemo(() => {
        return (
            !Boolean(andFilter.single?.field) ||
            !Boolean(andFilter.single?.condition_type) ||
            (andFilter.single?.condition_type &&
                ['BLANK', 'NOT_BLANK'].includes(
                    andFilter.single?.condition_type
                ))
        );
    }, [andFilter.single?.condition_type, andFilter.single?.field]);

    return (
        <Box
            key={index}
            sx={{
                display: 'flex',
                // gap: 1,
                alignItems: 'center',
            }}
        >
            <FWTypography
                variant="h5"
                fontSize={15}
                sx={{ mr: 1, ml: 1.5, width: '50px' }}
            >
                {index === 0 ? 'Where' : 'and'}
            </FWTypography>
            <FormControl
                sx={{
                    m: 1,
                    minWidth: 300,
                    backgroundColor: 'white',
                    overflow: 'auto ',
                }}
                size="small"
            >
                <ProjectDaahboardFilter
                    fieldTypeLabels={fieldTypeLabels}
                    resetField={resetField}
                    updateFilterObject={updateFilterObject}
                    filterDataInOptions={filterDataInOptions}
                    andFilter={andFilter}
                    index={index}
                    selectedFields={selectedFields}
                />
            </FormControl>

            <FormControl
                sx={{
                    m: 1,
                    width: 200,
                    backgroundColor: 'white',
                }}
                size="small"
            >
                {console.log('conditionOptions', conditionOptions)}
                <FWAutocomplete
                    options={conditionOptions}
                    value={
                        conditionOptions.find(
                            (condition) =>
                                condition.id ===
                                andFilter.single?.condition_type
                        ) ?? null
                    }
                    popupIcon={
                        <Box fontSize={'16px'} paddingTop={0.5}>
                            <i className="bi bi-chevron-down EventsAddItemStage_DetailsArrowIconSave"></i>
                        </Box>
                    }
                    renderInput={(params) => <TextField {...params} />}
                    fullWidth
                    onChange={(e, value: Condition | null) => {
                        if (value) {
                            updateFilterObject(index, {
                                key: 'CONDITION',
                                value: value.id,
                            });
                        } else {
                            resetField(index, 'CONDITION');
                        }
                    }}
                    getOptionLabel={(option: Condition) => {
                        return option.label;
                    }}
                    renderOption={(props, option: Condition) => {
                        return (
                            <FWMenuItem
                                key={option.id}
                                {...props}
                                value={option.label}
                            >
                                <FWTypography>{option.label}</FWTypography>
                            </FWMenuItem>
                        );
                    }}
                />
            </FormControl>
            {showBlankField ? (
                <FWInput
                    sx={{
                        margin: 1,
                        width: 'max-content',
                    }}
                    disabled
                />
            ) : filterDataInOptions ? (
                <FormControl
                    sx={{
                        m: 1,
                        minWidth: 235,
                        backgroundColor: 'white',
                    }}
                    size="small"
                >
                    <ProjectDashboardFilterInput
                        filter={andFilter}
                        index={index}
                        filterDataInOptions={filterDataInOptions}
                        updateFilterObject={updateFilterObject}
                    />
                </FormControl>
            ) : (
                <FWInput
                    sx={{
                        margin: 1,
                        width: 'max-content',
                    }}
                    disabled
                />
            )}
            {/* {filterDataInOptions &&
                Boolean(andFilter.single?.condition_type) &&
                (andFilter.single?.condition_type
                    ? !['BLANK', 'NOT_BLANK'].includes(
                          andFilter.single?.condition_type
                      )
                    : true) && ( */}

            {/* )} */}
            <FWIconButton
                sx={{ color: 'black' }}
                disabled={disableRemoveRow}
                onClick={() => {
                    removeSortRow(index);
                }}
            >
                <FWIcon name="bi bi-x" size={20} />
            </FWIconButton>
        </Box>
    );
};

export default memo(ProjectDashboardFilterFieldRow);

const ProjectDaahboardFilter = memo(
    ({
        fieldTypeLabels,
        updateFilterObject,
        filterDataInOptions,
        andFilter,
        resetField,
        index,
        selectedFields,
    }: {
        fieldTypeLabels: IFieldTypeLabel[];
        resetField: (index: number, resetType: 'FIELD' | 'CONDITION') => void;
        updateFilterObject: (
            index: number,
            updatedField: TFilterUpdateParams
        ) => void;
        filterDataInOptions: FieldTypeLabel | undefined;
        andFilter: DashBoardFilter;
        index: number;
        selectedFields: string[];
    }) => {
        const [inputValue, setInputValue] = useState('');
        console.log('fieldTypeLabels', fieldTypeLabels);
        console.log('filterDataInOptions', filterDataInOptions);
        return (
            <FWAutocomplete
                options={fieldTypeLabels}
                popupIcon={
                    <Box fontSize={'16px'} paddingTop={0.5}>
                        <i className="bi bi-chevron-down EventsAddItemStage_DetailsArrowIconSave"></i>
                    </Box>
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                )}
                value={filterDataInOptions ? filterDataInOptions : null}
                getOptionLabel={(option: IFieldTypeLabel) =>
                    option.fieldLabelWithSectionName
                }
                fullWidth
                onChange={(e, value: IFieldTypeLabel | null) => {
                    if (!value) {
                        setInputValue('');
                        resetField(index, 'FIELD');
                    } else {
                        updateFilterObject(index, {
                            key: 'FIELD',
                            value: value.column as string,
                            isBuiltIn: value.is_built_in,
                            type: value.fieldType as CustomFieldType,
                            section: value.isFieldFWFieldInCustomSection
                                ? value.sectionAlternameName
                                : null,
                        });
                    }
                }}
                filterOptions={(option: IFieldTypeLabel[]) =>
                    option.filter(
                        (option) =>
                            (option.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase().trim()) ||
                                option.fieldType
                                    .toLowerCase()
                                    .includes(inputValue.toLowerCase())) &&
                            option.label.toLowerCase() !== 'type'
                    )
                }
                onClose={() => {
                    // Reset the input value when the dropdown is closed
                    setInputValue('');
                }}
                renderOption={(props, option: IFieldTypeLabel) => {
                    const isDisabled = selectedFields.includes(option.column);
                    return (
                        <FWTooltip
                            title={
                                isDisabled
                                    ? 'Field already selected'
                                    : `${option.fieldLabelWithSectionName} | ${option.fieldType}`
                            }
                        >
                            <div>
                                <FWMenuItem
                                    {...props}
                                    key={option.column + '123'}
                                    value={option.column}
                                    disabled={selectedFields.includes(
                                        option.column
                                    )}
                                >
                                    <Typography
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <p>
                                            {' '}
                                            {option.fieldLabelWithSectionName}
                                        </p>
                                        {option.is_built_in && (
                                            <img
                                                style={{
                                                    height: '1.5rem',
                                                    width: '1.5rem',
                                                    marginLeft: '0.7rem',
                                                }}
                                                src="/logo.png"
                                                alt="factwise logo"
                                            />
                                        )}
                                        <Chip
                                            sx={{
                                                backgroundColor: '#C7C8CC',
                                                borderRadius: '7px !important',
                                                fontSize: '0.7rem',
                                                marginLeft: '0.5rem',
                                                color: 'black',
                                            }}
                                            label={option.fieldType}
                                        />
                                    </Typography>
                                </FWMenuItem>
                            </div>
                        </FWTooltip>
                    );
                }}
                isOptionEqualToValue={(
                    option: IFieldTypeLabel,
                    value: IFieldTypeLabel
                ) => {
                    return (
                        option &&
                        value &&
                        option?.fieldLabelWithSectionName ===
                            value?.fieldLabelWithSectionName
                    );
                }}
            />
        );
    }
);

import { IAdditionalCostsBackend } from '../../AdditionalCost/models/AdditionalCost.model';
import {
    transformPaymentTermsFromBackendToFrontend,
    transformPaymentTermsFromFrontendToBackend,
} from '../../Global/Helpers/paymentTermsHelpers';
import { EntityStatus } from '../../Global/Interfaces/EntityInterface';
import { IPaymentTerms } from '../../Global/Interfaces/PaymentTermsInterface';
import {
    BackendDeliverablePaymentTerms,
    BackendInvoicePaymentTerms,
    IAttachment,
} from '../../Models/RFQ.model';
import { baseApiSlice } from '../../Redux/Slices/BaseApiSlice';
import { del, get, post, put } from '../../Utils/api';
import { convertBackendContractItemToFrontEnd } from '../HelperFunctions/EditContractHelpers';
import {
    ContractStatusEnum,
    IBuyerIdentifications,
    ICLMCustomField,
    IContractItemDetailsNew,
    IContractProject,
    IItemBuyerSKUsTable,
    IVendorContactsCLM,
} from '../Interface/ContractInterfaces';

export const getEntityIdentifications = async (
    entity_id: string
): Promise<IBuyerIdentifications[]> => {
    const response: any = await get<any>(
        `/organization/entity/${entity_id}/identifications/`
    );
    const data: any = response.data.filter(
        (identity: IBuyerIdentifications) => {
            return (
                identity.status === 'ACTIVE' &&
                identity.verification_status === 'VERIFIED'
            );
        }
    );

    return data;
};

export const getVendorContacts = async (
    buyer_entity_id: string,
    seller_entity_id: string
): Promise<IVendorContactsCLM[]> => {
    const response: any = await get<any>(
        `/organization/vendor_master/admin/entity/${buyer_entity_id}/contacts/?seller_entity_id=${seller_entity_id}`
    );
    const data: any = response.data;
    return data;
};

export type ICLMItemsSearchData = {
    data: Array<{
        entity_item_id: string;
        preferred_vendors_count: number;
        enterprise_item: {
            enterprise_item_id: string;
            entities: Array<{
                buyer_entity_name: string;
                buyer_entity_id: string;
            }>;
            created_datetime: string;
            modified_datetime: string;
            deleted_datetime: any;
            code: string;
            name: string;
            item_type: string;
            description: string;
            notes: string;
            internal_notes: any;
            measurement_units: {
                item_measurement_units: Array<{
                    measurement_unit_id: string;
                    measurement_unit_category: string;
                    measurement_unit_value_type: string;
                    measurement_unit_primary_name: string;
                }>;
            };
            attributes: {
                attributes: Array<any>;
            };
            custom_ids: {
                custom_ids: Array<any>;
            };
            pricing_information: {
                price: any;
                currency_name: string;
                currency_symbol: string;
                currency_code_id: string;
                currency_code_abbreviation: string;
            };
            status: string;
            preferred_vendor_status: string;
            tags: Array<string>;
            created_by_user: string;
            modified_by_user: string;
            deleted_by_user: any;
            buyer_enterprise: string;
        };
        created_datetime: string;
        modified_datetime: string;
        deleted_datetime: any;
        status: string;
        created_by_user: string;
        modified_by_user: string;
        deleted_by_user: any;
        buyer_enterprise: string;
        buyer_entity: string;
    }>;
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
    counts: {
        active: number;
        disabled: number;
        all: number;
    };
};

export const createCLMContract = async ({
    contract_attachments,
    contract_name,
    contract_start_date,
    contract_end_date,
    contract_entity_id,
    buyer_identifications,
    buyer_address_id,
    buyer_full_address,
    buyer_contact_id,
    template_id,
    custom_fields,
    project_id,
    terms_and_conditions,
}: {
    contract_attachments: string[];
    contract_name: string;
    contract_start_date: string;
    contract_end_date: string;
    contract_entity_id: string;
    buyer_identifications: string[];
    buyer_address_id: string | null;
    buyer_full_address: string;
    buyer_contact_id: string;
    template_id: string;
    custom_fields: {
        section_list: {
            name: string;
            fields: ICLMCustomField[];
        }[];
    };
    project_id: string | null;
    terms_and_conditions: {
        terms_and_conditions_id: string | null;
        name: string | null;
        data: string;
    } | null;
}): Promise<any> => {
    const response = await post<
        {
            attachment_ids: string[];
            contract_name: string;
            contract_start_date: string;
            contract_end_date: string;
            contract_entity_id: string;
            buyer_identifications: string[];
            buyer_address_information: {
                address_id: string | null;
                full_address: string;
            };
            buyer_contact_id: string;
            template_id: string;
            custom_fields: {
                section_list: {
                    name: string;
                    fields: ICLMCustomField[];
                }[];
            };
            project_id: string | null;
            terms_and_conditions: {
                terms_and_conditions: string | null;
                name: string | null;
                data: string;
            } | null;
        },
        any
    >('/contracts/create/', {
        attachment_ids: contract_attachments,
        contract_name: contract_name,
        contract_start_date: contract_start_date,
        contract_end_date: contract_end_date,
        contract_entity_id: contract_entity_id,
        buyer_identifications: buyer_identifications,
        buyer_address_information: {
            address_id: buyer_address_id,
            full_address: buyer_full_address,
        },
        buyer_contact_id: buyer_contact_id,
        template_id: template_id,
        custom_fields: custom_fields,
        project_id: project_id === 'NA' ? null : project_id,
        terms_and_conditions: terms_and_conditions,
    });

    return response.data;
};

export const getContractDetails = async (contract_id: string): Promise<any> => {
    const response: any = await get<any>(`/contracts/${contract_id}/`);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const ContractDetails = response.data;
    return response.data;
};

export type contractAutoSavePayload = {
    contract_name: string;
    contract_start_date: string;
    contract_end_date: string;
    contract_entity_id: string;
    buyer_identifications: Array<any>;
    seller_enterprise_id: any;
    seller_entity_id: any;
    seller_identifications: Array<any>;
    buyer_address_id: string;
    buyer_contact_id: string;
    template_id: string;
    project_id: string;
    terms_and_conditions: {
        terms_and_conditions_id: string;
        name: string;
        data: string;
    };
};

export const autoSaveContract = async ({
    contract_uuid,
    contract_name,
    contract_start_date,
    contract_end_date,
    contract_entity_id,
    buyer_identifications,
    buyer_address_id,
    buyer_full_address,
    buyer_contact_id,
    seller_enterprise_id,
    seller_entity_id,
    vendor_identifications,
    vendor_contact_id,
    custom_fields,
    template_id,
    terms_and_conditions,
    project_id,
    attachment_ids,
    vendor_address_id,
    vendor_full_address,
}: {
    contract_uuid: string;
    contract_name: string;
    contract_start_date: string;
    contract_end_date: string;
    contract_entity_id: string;
    buyer_identifications: string[];
    buyer_address_id: string | null;
    buyer_full_address: string;
    buyer_contact_id: string;
    seller_enterprise_id: string;
    seller_entity_id: string;
    vendor_identifications: any;
    vendor_contact_id: string;
    custom_fields: {
        section_list: {
            name: string;
            fields: ICLMCustomField[];
        }[];
    };
    template_id: string;
    terms_and_conditions: {
        terms_and_conditions_id: string | null;
        name: string | null;
        data: string;
    } | null;
    project_id: string | null;
    attachment_ids: string[];
    vendor_address_id: string | null;
    vendor_full_address: string;
}): Promise<any> => {
    //return true;
    const response = await put<contractAutoSavePayload, any>(
        `/contracts/${contract_uuid}/update/`,
        {
            contract_name: contract_name,
            contract_start_date: contract_start_date,
            contract_end_date: contract_end_date,
            contract_entity_id: contract_entity_id,
            buyer_identifications: buyer_identifications,
            buyer_address_information: {
                address_id: buyer_address_id,
                full_address: buyer_full_address,
            },
            buyer_contact_id: buyer_contact_id,
            seller_enterprise_id: seller_enterprise_id,
            seller_entity_id: seller_entity_id,
            vendor_identifications: vendor_identifications,
            vendor_contact_id: vendor_contact_id,
            custom_fields: custom_fields,
            template_id: template_id,
            terms_and_conditions: terms_and_conditions,
            project_id: project_id,
            attachment_ids: attachment_ids,
            vendor_address_information:
                !vendor_full_address || vendor_full_address.trim() === ''
                    ? null
                    : {
                          address_id: vendor_address_id,
                          full_address: vendor_full_address,
                      },
        }
    );

    return response.data;
};

export const createItem = async ({
    contract_uuid,
    enterprise_item_id,
    currency_code_id,
    measurement_unit_id,
    attributes,
    rate,
    paymentTerms,
    lead_time,
    lead_time_period,
    attachment_ids,
    custom_fields,
    buyer_skus,
    incoterm_id,
}: {
    contract_uuid: string;
    enterprise_item_id: string;
    currency_code_id: string;
    measurement_unit_id: string;
    attributes: [] | null;
    rate: string | null;
    paymentTerms: IPaymentTerms;
    incoterm_id: string;
    lead_time: string | null;
    lead_time_period: string | null;
    attachment_ids: [] | null;
    custom_fields: {
        section_list: {
            name: string;
            fields: ICLMCustomField[];
        }[];
    };
    buyer_skus: string[];
}): Promise<any> => {
    const {
        payment_type,
        prepayment_percentage,
        payment_terms,
        deliverables_payment_terms,
    } = transformPaymentTermsFromFrontendToBackend({ paymentTerms });
    const response = await post<string, any>(
        `/contracts/${contract_uuid}/items/create/`,
        {
            enterprise_item_id: enterprise_item_id,
            currency_code_id: currency_code_id,
            measurement_unit_id: measurement_unit_id,
            attributes: attributes,
            rate: rate,
            payment_type,
            prepayment_percentage,
            payment_terms,
            incoterm_id: 'bbb27fda-712f-4aa2-b42b-386fde9c2b50',
            deliverables_payment_terms,
            lead_time: lead_time,
            lead_time_period: lead_time_period,
            attachment_ids: attachment_ids,
            custom_fields: custom_fields,
            buyer_skus: buyer_skus,
        }
    );
    return response.data;
};

export interface itemAutoSavePayload {
    contract_uuid: string;
    contract_item_uuid: string;
    currency_code_id: string | null;
    measurement_unit_id: string | null;
    rate: string | null;
    quantity: string | null;
    paymentTerms: IPaymentTerms;
    lead_time: string | null;
    lead_time_period: string | null;
    attachment_ids: string[];
    custom_fields: {
        section_list: {
            name: string;
            fields: ICLMCustomField[];
        }[];
    };
    incoterm_id: string;
    buyer_skus: string[];
    additional_costs: IAdditionalCostsBackend[];
    discounts: IAdditionalCostsBackend[];
    taxes: IAdditionalCostsBackend[];
    total_price: number;
}

export const autoSaveItem = async ({
    contract_uuid,
    contract_item_uuid,
    currency_code_id,
    measurement_unit_id,
    rate,
    quantity,
    paymentTerms,
    incoterm_id,
    lead_time,
    lead_time_period,
    attachment_ids,
    custom_fields,
    buyer_skus,
    additional_costs,
    discounts,
    taxes,
    total_price = 0,
}: itemAutoSavePayload): Promise<any> => {
    const {
        payment_type,
        prepayment_percentage,
        payment_terms,
        deliverables_payment_terms,
    } = transformPaymentTermsFromFrontendToBackend({ paymentTerms });
    const response = await put<itemAutoSavePayload, any>(
        `/contracts/${contract_uuid}/items/${contract_item_uuid}/update/`,
        {
            currency_code_id: currency_code_id,
            measurement_unit_id: measurement_unit_id,
            rate: rate,
            quantity: quantity,
            payment_type,
            prepayment_percentage,
            payment_terms,
            deliverables_payment_terms,
            incoterm_id: incoterm_id,
            lead_time: lead_time,
            lead_time_period: lead_time_period,
            attachment_ids: attachment_ids,
            custom_fields: custom_fields,
            buyer_skus: buyer_skus,
            additional_costs: additional_costs,
            discounts: discounts,
            taxes: taxes,
            total_price: total_price,
        }
    );
    return response.data;
};

export const entityAPI = async (): Promise<any> => {
    const response = await get<any>('/organization/entity/');
    return response.data;
};

export type ListOFitemsFOrContractResponsoed = Array<{
    attachments: IAttachment[];
    attribute_information: {
        buyer_item_attributes: Array<{
            attribute_name: string;
            attribute_value: string[];
            attribute_exclude: boolean;
        }>;
        custom_item_attributes: Array<any>;
    };
    buyer_sku_details: SKU[];
    buyer_skus: string[];
    contract_item_id: string;
    contract: string;
    created_by_user: string;
    created_datetime: string;
    currency: string;
    custom_fields: {
        section_list: {
            name: string;
            fields: ICLMCustomField[];
        }[];
    };
    custom_fields_negotiate: {
        section_list: Array<any>;
    };
    deleted_by_user: any;
    deleted_datetime: any;
    enterprise_item: string;
    measurement_unit: string;
    modified_by_user: string;
    modified_datetime: string;
    quantity: any;
    rate: any;
    procurement_information: {
        lead_time: any;
        lead_time_period: any;
    };
    payment_terms: BackendInvoicePaymentTerms;
    payment_type: string;
    prepayment_percentage: string;
    deliverables_payment_terms: BackendDeliverablePaymentTerms;
}>;

export const getListOfItemsForCLMContract = async (
    contract_id: string
): Promise<ListOFitemsFOrContractResponsoed> => {
    const response = await get<ListOFitemsFOrContractResponsoed>(
        `/contracts/${contract_id}/items/`
    );

    return response.data;
};

export const deleteItem = async ({
    contract_uuid,
    contract_item_uuid,
}: {
    contract_uuid: string;
    contract_item_uuid: string;
}): Promise<boolean> => {
    try {
        await del<any>(
            `/contracts/${contract_uuid}/items/${contract_item_uuid}/delete/`
        );
        return true;
    } catch (e) {
        return false;
    }
};

export interface ICLMDetailsResponse {
    contract_id: string;
    contract_item_count: number;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    base_contract_id: string;
    custom_contract_id: string;
    contract_name: string;
    contract_start_date: string;
    contract_end_date: string;
    buyer_identifications: string[];
    buyer_contact_information: {
        buyer_contact_id: string;
        buyer_contact_name: string;
        buyer_contact_email: string;
    };
    buyer_address_information: {
        address_id: string;
        full_address: string;
    };
    vendor_identifications: {
        identification_name: string;
        identification_value: string;
    }[];
    vendor_contact_information: {
        vendor_contact_id: string;
        vendor_contact_name: string;
        vendor_contact_email: string;
    } | null;
    status: ContractStatusEnum;
    additional_details: {
        template_id: string;
    };
    project_information: {
        project_id: string;
        project_code: string;
        project_name: string;
    };
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: string | null;
    buyer_enterprise: string;
    buyer_entity: string;
    seller_enterprise: string | null;
    seller_entity: string | null;
    seller_entity_details: {
        seller_entity_name: string;
    };
    buyer_entity_details: {
        buyer_entity_name: string;
    };
    item_details: {
        item_name: string;
        item_code: string;
    }[];
}

export type IDashBoardDataCLM = {
    data: ICLMDetailsResponse[];
    metadata: {
        current_page: number;
        has_next: boolean;
        has_previous: boolean;
        total_pages: number;
        page_range: {
            start: number;
            stop: number;
        };
        count: number;
    };
    counts: {
        draft: number;
        ongoing: number;
        finished: number;
        all: number;
    };
};

export interface payloadGetDashboardForCLM {
    dashboard_view: string;
    items_per_page: number;
    page_number: number;
    search_text: string;
    sort_fields: {
        field: string;
        ascending: boolean;
    }[];
    tab: string;
}

export const getDashboardForCLM = async ({
    dashboard_view,
    items_per_page,
    page_number,
    search_text,
    sort_fields,
    tab,
    sellerEntityUuid,
    enterpriseItemUuid,
    buyerEntityid,
}: {
    dashboard_view: string;
    items_per_page: number;
    page_number: number;
    search_text: string;
    sort_fields: {
        field: string;
        ascending: boolean;
    }[];
    tab: string;
    sellerEntityUuid?: string;
    enterpriseItemUuid?: string;
    buyerEntityid?: string;
}): Promise<IDashBoardDataCLM> => {
    return new Promise(async (resolve, reject) => {
        try {
            let payload = {
                dashboard_view: dashboard_view,
                items_per_page: items_per_page,
                page_number: page_number,
                search_text: search_text,
                sort_fields: sort_fields,
                tab: tab,
                query_data: {
                    seller_entity_list: sellerEntityUuid
                        ? [sellerEntityUuid]
                        : [],
                    enterprise_item_id_list: enterpriseItemUuid
                        ? [enterpriseItemUuid]
                        : [],
                    buyer_entity_id: buyerEntityid ? buyerEntityid : null,
                },
            };

            const response = await post<any, any>(`/dashboard/`, payload);
            resolve(response.data);
        } catch (e) {
            reject(e);
        }
    });
};

export const changeStatusOfContract = async ({
    status,
    contract_uuid,
}: {
    status: string;
    contract_uuid: string;
}): Promise<void> => {
    try {
        await put<string, any>(`/contracts/${contract_uuid}/state/`, {
            status: status,
        });
    } catch (e) {}
};

export const getVendorEntityList = async ({
    search_term,
    entity_id,
}: {
    search_term: string;
    entity_id: string;
}): Promise<any> => {
    try {
        const response = await get<any>(
            `/organization/vendor_master/search/?search_term=${search_term}&entity_id=${entity_id}`
        );
        return response.data;
    } catch (e) {}
};

export const deleteContract = async ({
    contract_uuid,
}: {
    contract_uuid: string;
}): Promise<boolean> => {
    try {
        await del<string>(`/contracts/${contract_uuid}/delete/`);
        return true;
    } catch (e) {
        return false;
    }
};

export const cloneContract = async ({
    contract_uuid,
    contract_name,
}: {
    contract_uuid: string;
    contract_name: string;
}): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const respone = await post<
                {
                    contract_uuid: string;
                    contract_name: string;
                },
                string
            >(`/contracts/${contract_uuid}/clone/`, {
                contract_name: contract_name,
            });
            resolve(respone);
        } catch (e) {
            reject(e);
        }
    });
};

//get dashboard tab count

export function getTabCount(): Promise<{
    drafts: number;
    ongoing: number;
    finished: number;
    all: number;
}> {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await post<
                {
                    dashboard_view: string;
                },
                {
                    tab_counts: {
                        drafts: number;
                        ongoing: number;
                        all: number;
                        finished: number;
                    };
                }
            >('/dashboard/tab_counts/', {
                dashboard_view: 'contract_buyer',
            });
            resolve(resp.data.tab_counts);
        } catch (err) {
            reject();
        }
    });
}

export type SKU = {
    sku_entry_id: string;
    sku_linkages: Array<{
        sku_linkage_id: string;
        created_datetime: string;
        modified_datetime: string;
        deleted_datetime: any;
        seller_skus: {
            sku_id: string;
            sku_name: string;
        }[];
        created_by_user: string;
        modified_by_user: string;
        deleted_by_user: any;
        buyer_enterprise: string;
        buyer_sku: string;
        seller_entity: string;
    }>;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    sku_id: string;
    sku_name: string;
    attributes: {
        attributes: Array<any>;
    };
    status: string;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    enterprise_item: string;
    enterprise: string;
};

export const getAllItemSkus = async (
    item_id: string
): Promise<IItemBuyerSKUsTable[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<SKU[]>(`/organization/item/${item_id}/skus/`);
            const result: IItemBuyerSKUsTable[] = resp.data.map((sku) => ({
                id: sku.sku_entry_id,
                buyer_sku_uuid: sku.sku_entry_id,
                buyer_sku_id: sku.sku_id,
                buyer_sku_name: sku.sku_name,
                vendor_sku_ids: sku.sku_linkages
                    .map((sku_linkage) => sku_linkage.seller_skus)
                    .flat()
                    .map((sku) => sku.sku_id),
                vendor_sku_names: sku.sku_linkages
                    .map((sku_linkage) => sku_linkage.seller_skus)
                    .flat()
                    .map((sku) => sku.sku_name),
            }));
            resolve(result);
        } catch (e) {
            reject(e);
        }
    });
};

export type IProjects = {
    project_id: string;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    project_code: string;
    project_name: string;
    validity_from: any;
    validity_to: any;
    location: any;
    description: any;
    internal_notes: any;
    tags: Array<any>;
    status: string;
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    buyer_enterprise: string;
    buyer_entity: string;
    project_manager: string;
};

export const getAllProjects = async (): Promise<IContractProject[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            let resp = await get<IContractProject[]>(`/organization/project/`);
            const result: IContractProject[] = resp.data.map((project) => ({
                project_id: project.project_id,
                project_code: project.project_code,
                project_name: project.project_name,
                status: project.status,
                buyer_entity: project.buyer_entity,
            }));
            resolve(result);
        } catch (e) {
            reject(e);
        }
    });
};

export const getListOfContracts = async (): Promise<any> => {
    const response = await get<any>('/contracts/');
    return response.data;
};

export const checkIfContractNameExists = async (
    entityID: string,
    contractName: string
): Promise<boolean> => {
    const response = await get<any>(
        `/contracts/entity/${entityID}/exists/?contract_name=${contractName}`
    );
    return response.data.template_exists;
};

export interface IGetContractDetailsForItem {
    contract_id: string;
    item_details: Array<{
        item_name: string;
        item_code: string;
    }>;
    buyer_identifications_details: Array<{
        identification_id: string;
        identification_name: string;
    }>;
    seller_entity_details: {
        seller_entity_name: string;
    };
    buyer_entity_details: {
        buyer_entity_name: string;
    };
    attachments: Array<any>;
    contract_items: Array<{
        contract_item_id: string;
        buyer_sku_details: Array<any>;
        attachments: Array<any>;
        created_datetime: string;
        modified_datetime: string;
        deleted_datetime: any;
        buyer_skus: Array<any>;
        rate: string;
        quantity: any;
        attribute_information: {
            buyer_item_attributes: Array<{
                attribute_name: string;
                attribute_value: Array<string>;
                attribute_exclude: boolean;
            }>;
            custom_item_attributes: Array<any>;
        };
        paymentTerms: IPaymentTerms;
        payment_type: string;
        payment_terms: BackendInvoicePaymentTerms;
        deliverables_payment_terms: BackendDeliverablePaymentTerms;
        prepayment_percentage: string;
        procurement_information: {
            lead_time: number;
            lead_time_period: string;
        };
        custom_fields: {
            section_list: Array<{
                name: string;
                fields: Array<{
                    name: string;
                    type: string;
                    value: string;
                    is_locked: boolean;
                    is_visible: boolean;
                    is_required: boolean;
                }>;
            }>;
        };
        custom_fields_negotiate: {
            section_list: Array<any>;
        };
        created_by_user: string;
        modified_by_user: string;
        deleted_by_user: any;
        contract: string;
        enterprise_item: string;
        measurement_unit: string;
        currency: string;
        currency_details: {
            currency_code_abbreviation: string;
            currency_name: string;
            currency_symbol: string;
        };
    }>;
    created_datetime: string;
    modified_datetime: string;
    deleted_datetime: any;
    base_contract_id: string;
    custom_contract_id: string;
    contract_name: string;
    contract_start_date: string;
    contract_end_date: string;
    buyer_identifications: Array<string>;
    buyer_contact_information: {
        buyer_contact_id: string;
        buyer_contact_name: string;
        buyer_contact_email: string;
    };
    buyer_address_information: {
        address_id: string;
        full_address: string;
    };
    vendor_identifications: Array<{
        identification_name: string;
        identification_value: string;
    }>;
    vendor_contact_information?: {
        vendor_contact_id: string;
        vendor_contact_name: string;
        vendor_contact_email: string;
    };
    vendor_address_information: {
        address_id: string | null;
        full_address: string;
    } | null;
    status: string;
    additional_details: {
        template_id: string;
    };
    project_information: {
        project_id: string;
        project_name: string;
        project_code: string;
    } | null;
    terms_and_conditions: {
        data: string;
        name: string;
        terms_and_conditions_id: any;
    };
    custom_fields: {
        section_list: Array<{
            name: string;
            fields: Array<any>;
        }>;
    };
    custom_fields_negotiate: {
        section_list: Array<any>;
    };
    created_by_user: string;
    modified_by_user: string;
    deleted_by_user: any;
    buyer_enterprise: string;
    buyer_entity: string;
    seller_enterprise: string;
    seller_entity: string;
    vendorMasterDetails: IMasterVendorDetails[];
}

export const contractApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getContractDetailsForItem: builder.query<
            IGetContractDetailsForItem[],
            {
                entityUid: string;
                enterpriseItemUid: string;
            }
        >({
            query: ({ entityUid, enterpriseItemUid }) => ({
                url: `/contracts/entity/${entityUid}/item/${enterpriseItemUid}/`,
            }),
            transformResponse: (
                response: IGetContractDetailsForItem[],
                meta,
                args
            ) => {
                Promise.all(
                    response.map(async (contract) => {
                        const res = await get<IMasterVendorDetails[]>(
                            `/organization/vendor_master/admin/?seller_entity_id=${contract.seller_entity}`
                        );
                        // contract.vendorMasterDetails = res.data;
                        return {
                            ...contract,
                            contract_items: contract.contract_items.filter(
                                (contractItem) =>
                                    contractItem.contract_item_id ===
                                    args.enterpriseItemUid
                            ),
                            vendorMasterDetails: res.data,
                        };
                    })
                );
                return response;
            },
        }),
    }),
});

export const { useLazyGetContractDetailsForItemQuery } = contractApiSlice;

export const getContractDetailsForItem = async (
    entityUid: string,
    enterpriseItemUid: string
): Promise<IGetContractDetailsForItem[]> => {
    try {
        const response = await get<IGetContractDetailsForItem[]>(
            `/contracts/entity/${entityUid}/item/${enterpriseItemUid}/`
        );
        const contracts = response.data;
        contracts.forEach((contract) => {
            // contract.
            contract.contract_items.forEach((item) => {
                item.paymentTerms = transformPaymentTermsFromBackendToFrontend({
                    deliverables_payment_terms: item.deliverables_payment_terms,
                    payment_terms: item.payment_terms,
                    prepayment_percentage: item.prepayment_percentage,
                });
            });
        });
        await Promise.all(
            contracts.map(async (contract) => {
                const res = await get<IMasterVendorDetails[]>(
                    `/organization/vendor_master/admin/?seller_entity_id=${contract.seller_entity}`
                );
                contract.vendorMasterDetails = res.data;
            })
        );

        return response.data;
    } catch (err) {
        console.error('Factwise', err);

        throw err;
    }
};

export const { useGetContractDetailsForItemQuery } =
    baseApiSlice.injectEndpoints({
        endpoints: (build) => ({
            getContractDetailsForItem: build.query<
                IGetContractDetailsForItem[],
                { entityUid: string; enterpriseItemUid: string }
            >({
                queryFn: async ({ enterpriseItemUid, entityUid }) => {
                    try {
                        const response = await get<
                            IGetContractDetailsForItem[]
                        >(
                            `/contracts/entity/${entityUid}/item/${enterpriseItemUid}/`
                        );
                        const contracts = response.data;
                        contracts.forEach((contract) => {
                            // contract.
                            contract.contract_items.forEach((item) => {
                                item.paymentTerms =
                                    transformPaymentTermsFromBackendToFrontend({
                                        deliverables_payment_terms:
                                            item.deliverables_payment_terms,
                                        payment_terms: item.payment_terms,
                                        prepayment_percentage:
                                            item.prepayment_percentage,
                                    });
                            });
                        });
                        await Promise.all(
                            contracts.map(async (contract) => {
                                const res = await get<IMasterVendorDetails[]>(
                                    `/organization/vendor_master/admin/?seller_entity_id=${contract.seller_entity}`
                                );
                                contract.vendorMasterDetails = res.data;
                            })
                        );
                        return { data: response.data };
                    } catch (error: any) {
                        return { error };
                    }
                },
            }),
        }),
    });

export interface IMasterVendorDetails {
    enterprise_vendor_master_id: string;
    buyer_enterprise: string;
    seller_enterprise: string;
    seller_entity: {
        entity_id: string;
        enterprise: string;
        entity_name: string;
        entity_logo: string;
        entity_description: string;
        buyer_status: EntityStatus;
        seller_status: EntityStatus;
        contacts: {
            emails: Array<string>;
            websites: Array<string>;
            phone_numbers: Array<string>;
        };
        verification_status: string;
        is_virtual: boolean;
    };
    vendor_code: string;
    vendor_name: string;
    status: string;
    seller_address_information: any;
    seller_identifications: any;
    notes: string;
    tags: Array<any>;
    buyer_entity_count: number;
    primary_vendor_contact: {
        vendor_contact_id: string;
        buyer_entity: any;
        seller_entity: string;
        full_name: string;
        primary_email: string;
        emails: Array<any>;
        phone_numbers: Array<any>;
        tags: Array<any>;
        is_primary: boolean;
        user: any;
        status: string;
    };
    created_datetime: string;
    modified_datetime: string;
}

export const CLMApiSlice = baseApiSlice.injectEndpoints({
    endpoints: (build) => ({
        getContractItemsPaginated: build.query<
            {
                data: IContractItemDetailsNew[];
                metadata: {
                    current_page: number;
                    has_next: boolean;
                    has_previous: boolean;
                    total_pages: number;
                    page_range: {
                        start: number;
                        stop: number;
                    };
                    count: number;
                };
                counts: {
                    all: number;
                };
            },
            {
                dashboard_view: 'contract_items';
                tab: 'all';
                sort_fields: any[];
                search_text: string;
                items_per_page: number;
                page_number: number;
                query_data: {
                    contract_id: string;
                };
            }
        >({
            query: (body) => ({
                url: '/dashboard/',
                method: 'POST',
                body,
            }),
            transformResponse: (response: any) => {
                let newData = response.data.map((item: any) =>
                    convertBackendContractItemToFrontEnd(item)
                );
                return {
                    ...response,
                    data: newData,
                };
            },
        }),
    }),
});
export const {
    useGetContractItemsPaginatedQuery,
    useLazyGetContractItemsPaginatedQuery,
} = CLMApiSlice;

export const validateContractNameFromBackend = async (
    name: string,
    id?: string
) => {
    try {
        if (id) {
            let payload = {
                contract_name: name,
                contract_id: id,
            };
            let response = await post<any, any>(
                '/contracts/name/exists/',
                payload
            );
            return response;
        } else {
            let payload = {
                contract_name: name,
            };
            let response = await post<any, any>(
                '/contracts/name/exists/',
                payload
            );
            return response;
        }
    } catch (err) {
        return null;
    }
};

export const { useGetContractsDashboardDataMutation } =
    baseApiSlice.injectEndpoints({
        endpoints: (builder) => ({
            getContractsDashboardData: builder.mutation<
                IDashBoardDataCLM,
                {
                    dashboard_view: string;
                    page_number: number;
                    search_text: string;
                    tab: string;
                }
            >({
                query: ({
                    dashboard_view = 'contract_buyer',
                    page_number,
                    search_text,
                    tab,
                }) => ({
                    url: `/dashboard/`,
                    method: 'POST',
                    body: {
                        dashboard_view: dashboard_view,
                        items_per_page: 10,
                        page_number: page_number,
                        tab: tab,
                        search_text:
                            search_text?.length && search_text?.length > 0
                                ? search_text
                                : '',
                        sort_fields: [],
                    },
                }),
                transformResponse: (
                    baseQueryReturnValue: IDashBoardDataCLM,
                    meta,
                    arg
                ) => {
                    return baseQueryReturnValue;
                },
            }),
        }),
    });
